/*
STANDARDS

-- Naming Convention
id:
  - hyphenated
  - starts with the logical parent 

class names:
  - hyphenated

-- Colors
Primary text color: #000000
Secondary text color: 

-- Spacing
Keep spacing in multiples of 8px

*/
html {
  /* background-color: rgba(242, 242, 242, 0.515); */
}

body {
  margin: 0;
  font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(242, 242, 242, 0.515); */
  --t-color-dark: #333;
}

input {
  font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

div.center-children-row-horz {
  display: flex;
  justify-content: center;
}

div.center-children-row-vert {
  display: flex;
  align-items: center;
}

div.debug-debug {
  white-space: pre-wrap;
}

.unlink {
  text-decoration: none;
  color: unset;
}

#sidebar-left::-webkit-scrollbar {
  width: .3em;
}

#sidebar-left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
}

#sidebar-left::-webkit-scrollbar-thumb {
background-color: rgb(23, 64, 97);
outline: none;
}

.u-profile-circle-grey-left-side {
  border-radius: 50%;
  min-height: 36px;
  min-width: 36px;
  height: 36px;
  width: 36px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(75, 75, 75);
  margin-right: 12px;
}

.u-profile-circle-grey-header,
.u-profile-square-grey-header {
  border-radius: 50%;
  min-height: 24px;
  min-width: 24px;
  height: 32px;
  width: 32px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.u-profile-square-grey-header {
  border-radius: 15%;
}

.u-profile-circle-grey-left-side img,
.u-profile-circle-grey-header img,
.u-profile-square-grey-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.u-profile-circle-grey-left-side:hover,
.u-profile-square-grey-header:hover {
  cursor: pointer;
}

circle {
  transition: all 0.7s ease-in-out;
}

.general-table-container-full-width {
  width: 100%;
}

.general-table-container-bordered {
  box-sizing: border-box;
}

.general-table-flex-row {
  display: flex;
  border-bottom: 1px solid var(--ml-color-borders);
  border-left: 1px solid var(--ml-color-borders);
  border-right: 1px solid var(--ml-color-borders);
}

.general-table-flex-row:first-of-type {
  border-top: 1px solid var(--ml-color-borders);
}

.general-table-col {
  overflow: hidden;
  font-size: 14px;
  padding: 16px 2px;
  box-sizing: border-box;
}

.general-table-col-clickable {
  cursor: pointer;
  color: var(--ml-color-accent);
}

.general-table-col-underbadge {
  margin-top: 4px;
  overflow: hidden;
  display: flex;
  max-width: 200px;
}

.general-table-col-underbadge span {
  font-size: 12px;
  padding: 4px 14px;
  border-radius: 32px;
  background-color: rgb(218, 189, 175);
  color: white;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre;
  text-overflow: ellipsis;
}

.general-table-heading-sortable {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.general-table-heading-sortable svg {
  font-size: 16px;
}

.general-table-col-clickable:hover {
  text-decoration: underline;
  text-decoration-style: dashed;
}

.general-table-headings {
  /* background-color: rgb(242, 242, 242); */
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-right: 12px;
  margin-left: 12px;
  box-sizing: border-box;
  max-width: 100%;
}

.general-table-headings .general-table-col {
  display: flex;
  align-items: flex-end;
}

.general-table-rows-surrogate {
  position: fixed;
  left: 20px;
  right: 20px;
  top: 292px;
  bottom: 40px;
  overflow-y: auto;
  box-sizing: border-box;
}

.general-table-headings .general-table-col {
  font-size: 11px;
  color: rgb(54, 54, 54);
  font-weight: 700;
  padding: 6px 2px;
  box-sizing: border-box;
  text-transform: uppercase;
}

.general-table-flex-row.general-table-row-content:first-of-type {
  border-top: none;
}

.general-table-row-content-hoverable {
  transition: all 0.2s;
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
  border-bottom: 1px solid rgb(233, 233, 233);
  /* margin-top: 14px; */
  /* background-color: rgba(23, 104, 158, 0.045); */
  /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
}

.general-table-row-content-hoverable .general-table-col {
  display: flex;
  align-items: center;
}

.general-table-row-content-compact .general-table-col {
  padding: 8px 2px;
}

.general-table-row-content-hoverable:hover {
  background-color: rgba(23, 104, 158, 0.067);
}

.general-table-row-content:last-of-type {
  margin-bottom: 60px;
}

.general-table-container-bordered .general-table-row-content:last-of-type {
  margin-bottom: 0px;
}

.general-table-col-grow { flex-grow: 1 }
.general-table-col-2 { flex-basis: 2%; }
.general-table-col-5 { flex-basis: 5%; }
.general-table-col-8 { flex-basis: 8%; }
.general-table-col-10 { flex-basis: 10%; }
.general-table-col-12 { flex-basis: 12%; }
.general-table-col-15 { flex-basis: 15%; }
.general-table-col-20 { flex-basis: 20%; }
.general-table-col-30 { flex-basis: 30%; }
.general-table-col-40 { flex-basis: 40%; }
.general-table-col-50 { flex-basis: 50%; }
.general-table-col-60 { flex-basis: 60%; }

.general-table-col-align-right,
.general-table-row-content-hoverable .general-table-col-align-right {
  justify-content: flex-end;
}

.general-table-col-content-clickable-text {
  z-index: 20;
  cursor: pointer;
}

.general-table-col-content-clickable-text svg {
  min-width: 28px;
  font-size: 20px;
  padding: 4px;
  border-radius: 6px;
  transition: all 0.2s;
}

.general-table-col-content-clickable-text svg:hover {
  background-color: rgb(179, 208, 229);
}

.general-table-col-content-clickable-text:hover {
  text-decoration: underline;
}

.general-table-empty-text {
  font-size: 14px;
  color: grey;
  font-weight: 500;
}

.general-table-empty-cta {
  color: #2a73ae;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.general-table-empty-cta:hover { text-decoration: underline; }

.general-table-empty-symbol svg {
  font-size: 40px;
  color: #2a73ae79;
}

@media only screen and (max-width: 1200px) {
  .general-table-col-desktop-only,
  .general-table-row-content-hoverable .general-table-col.general-table-col-desktop-only,
  .general-table-headings .general-table-col-desktop-only {
    display: none
  }
}

.util-row {
  display: flex;
}

.util-row-reverse {
  flex-direction: row-reverse;
}

.util-align-stretch {
  align-items: stretch;
}

.util-col {
  flex-direction: column;
}

.util-space-between {
  justify-content: space-between;
}

.util-align-start {
  align-items: flex-start;
}

.util-align-end {
  align-items: flex-end;
}

.util-align-center { 
  align-items: center;
}

.util-justify-center {
  justify-content: center;
}

.util-justify-end {
  justify-content: flex-end;
}

.util-justify-start {
  justify-content: flex-start;
}