.wizard-page-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
}

.wizard-page { 
    width: 100%;
}

.wizard-navigation-section {
    padding-top: 24px;
    border-top: 1px solid grey;
    width: 100%;
}

.wizard-navigation-section-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.wizard-navigation-section-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.wizard-navigation-button-big {
    padding: 8px 20px;
    background-color: #174061;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

.wizard-navigation-button {
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 16px;
    font-weight: 500;
}

.wizard-navigation-button-primary {
    background-color: #174061;
    color: white;
}

.wizard-navigation-button-secondary {
    border: 1px solid #174061;
    background-color: white;
    color: #174061;
}

.wizard-navigation-button-big:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.wizard-logo {
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.wizard-page-heading {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #174061;
    margin-top: 48px;
}

.wizard-page-subheading {
    padding-top: 12px;
    padding-bottom: 12px;
    color: grey;
    font-size: 20px;
    text-align: center;
}

.wizard-page-subtitle {
    font-size: 14px;
    color: grey;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.wizard-input-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

.wizard-input-container input,
.wizard-input-good-job {
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    border-radius: 12px;
    padding: 4px;
    text-align: center;
}

.wizard-input-currency-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 0px;
}

.wizard-input-currency-symbol {
    background-color: rgb(223, 223, 223);
    font-size: 32px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    padding-left: 12px;
    padding-right: 12px;
}

input.wizard-input-currency {
    text-align: left;
    flex-grow: 1;
    border: none;
    outline: none;
    border-radius: 0;
    border: 1px solid grey;
    padding-left: 12px;
    border-left: none;
}

.wizard-input-good-job {
    color: green;
    text-decoration: underline;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
}

.wizard-input-instructions {
    text-align: center;
    margin-top: 12px;
    color: #006992;
    font-weight: 600;
}

.wizard-grid-outer-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.wizard-grid-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.wizard-grid-item {
    height: 180px;
    width: 180px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    min-width: 180px;
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    transition: all 0.2s;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
}

.wizard-grid-item-icon {
    font-size: 48px;
    color: rgb(105, 105, 105);
}

.wizard-grid-item-title {
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.wizard-proposed-tasks {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;
}

.wizard-proposed-task-container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.wizard-proposed-task-container svg {
    font-size: 20px;
    min-width: 32px;
}

.wizard-proposed-task-text {
    font-size: 20px;
    font-weight: 600;
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: pre;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.1s;
}

.wizard-proposed-task-text:hover {
    color: #006992;
    text-decoration: underline;
}

@media only screen and (min-width: 1200px) {
    .wizard-page-container { 
        padding: 0;
    }

    .wizard-page {
        max-width: 800px;
    }
}

/* WIZARD ONBOARDING */
.wizard-onboarding-container {
    width: 100%;
    max-width: 100%;
    position: relative;
    box-sizing: border-box;
    --t-wizard-color-positive: rgb(63, 115, 170);
}

.wizard-special-header {
    margin-bottom: 80px;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
}

.wizard-special-header-inner {
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.wizard-special-header-inner svg {
    height: 40px;
    width: 40px;
}

.wizard-onboarding-backdrop {
    position: fixed;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 1s;
    /* background-color: rgb(242, 242, 242); */
    background-color: rgba(20, 82, 148, 0.13);
}

.wizard-onboarding-form {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 32px;
    width: 30%;
    max-width: 600px;
    min-width: 480px;
}

.wizard-onboarding-container h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    line-height: 1.1;
    color: rgb(45, 45, 45);
    margin-bottom: 4px;
}

.wizard-onboarding-container h3 {
    font-size: 16px;
    color: rgb(56, 56, 56);
    margin: 0;
    margin-top: 20px;
    font-weight: 400;
    text-align: center;
}

.wizard-inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
    width: 100%;
    margin-top: 24px;
}

.wizard-input-basic p {
    margin: 0;
    font-size: 16px;
    color: rgb(45, 45, 45);
}

.wizard-input-basic input {
    margin-top: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid rgb(164, 164, 164);
    padding: 12px;
    text-overflow: ellipsis;
    font-size: 16px;
}

.wizard-input-basic textarea {
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid rgb(164, 164, 164);
    padding: 12px 20px;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 1.5;
    resize: none;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wizard-cta-section {
    margin-top: 80px;
}

.wizard-cta-section p {
    margin: 0;
    line-height: 1.8;
    font-size: 14px;
    text-align: center;
    color: rgb(39, 39, 39);
}

.wizard-navigation-bottom {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    gap: 14px;
}

.wizard-navigation-bottom-button-primary {
    background-color: var(--t-wizard-color-positive);
    color: rgb(242, 242, 242);
    text-align: center;
    padding: 14px 0px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer;
}

.wizard-navigation-bottom-button-secondary {
    color: grey;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.wizard-onboarding-loader {
    width: 40px;
    height: 40px;
    position: relative;
    --c:no-repeat linear-gradient(rgb(63, 115, 170) 0 0);
    background:
      var(--c) center/100% 10px,
      var(--c) center/10px 100%;
}

.wizard-onboarding-loader:before {
    content:'';
    position: absolute;
    inset: 0;
    background:
      var(--c) 0    0,
      var(--c) 100% 0,
      var(--c) 0    100%,
      var(--c) 100% 100%;
    background-size: 15.5px 15.5px;
    animation: l16 1.5s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes l16 {
     33%  {inset:-10px;transform: rotate(0deg)}
     66%  {inset:-10px;transform: rotate(90deg)}
     100% {inset:0    ;transform: rotate(90deg)}
}

@media screen and (max-width: 800px) {
    .wizard-onboarding-form {
        width: 95%;
        min-width: unset;
        max-width: unset;
    }
}