#home-container {
    display: flex;
    flex-direction: row;
    margin-top: 48px; /* accounts for the header */
}

.home-main {
    width: 100%;
    margin-left: 240px;
    /* margin-left: 200px; */
}

#home-left {
    position: relative;
}

#home-feed-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.home-feed {
    background-color: white;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}

#user-posts-container {
    width: 100%;
    max-width: 600px;
}

#user-posts-container hr {
    color: rgb(230, 230, 230);
    width: 50%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.user-single-post-container {
    margin-bottom: 24px;
    padding: 8px;
    padding-bottom: 12px;
    border-radius: 4px;
    border: 1px solid rgb(230, 230, 230);
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.user-single-post-summary {
    margin-bottom: 8px;
}

.user-post-time {
    font-size: 12px;
    font-style: italic;
    color: rgb(119, 119, 119);
}

.user-post-author {
    font-weight: 500;
    font-size: 16px;
}

.user-post-author:hover {
    text-decoration: underline;
    cursor: pointer;
}

.user-post-content {
    font-size: 20px;
    overflow-wrap: break-word;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-all;
}

.user-billing-button-cancel {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.2s;
}

.user-billing-button-manage {
    background-color: rgba(56, 74, 107, 1);
    color: white;
    border: 1px solid rgba(56, 74, 107, 1);
}

.user-billing-button-cancel:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.landing-container {
    width: 100%;
}

.landing-section {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
}

.landing-section-full-width {
    max-width: 100%;
    width: 100%;
}

.landing-section:first-of-type {
    margin-top: 2%;
}

.landing-section-vertical {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
}

.landing-section-badge {
    font-size: 10px;
    padding: 4px 16px;
    background-color: #DF928E;
    border-radius: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: black;
    letter-spacing: 2px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.landing-section-heading-grouped-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.landing-section h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

.landing-section-dark {
    background-color: #174061a1;
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-section-dark h1,
.landing-section-dark p {
    color: white;
}

.landing-section p {
    margin: 0;
    text-align: center;
    font-size: 12px;
    line-height: 2;
    font-weight: 500;
}

.landing-section-cta-button-grouped-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.landing-section-section-small-heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    color: rgb(88, 88, 88);
    font-size: 10px;
    margin-bottom: 12px;
}

.landing-section-cta-button-grouped-text div {
    font-size: 12px;
    background-color: #7286a03a;
    color: #7286A0;
    border: 1px solid #7286A0;
    font-weight: 600;
    padding: 6px 60px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
}

.landing-section-cta-button-grouped-text div svg {
    font-size: 16px;
}

.landing-section-cta-button-grouped-text div:hover {
    background-color: #7286a050;
}

.landing-section-cta-button-grouped-text span {
    font-size: 10px;
    color: grey;
}

.landing-hero-image {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.landing-hero-image img {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.landing-footer {
    position: relative;
    margin-top: 48px;
    padding-top: 32px;
}

.landing-footer-white-overflow {
    background-color: white;
    min-height: 32px;
    border-radius: 24px;
    position: absolute;
    top: -16px;
    width: 100%;
}

.landing-footer-copyright {
    color: white;
    font-size: 12px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
}

.landing-footer-upper {
    background-color: #272727;
    padding-top: 20px;
}

.landing-footer-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.landing-footer-logo svg {
    width: 180px;
}

.landing-footer-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin-left: 12px;
    margin-top: 12px;
}

.landing-footer-options span {
    color: white;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.landing-footer-options span:hover {
    text-decoration: underline;
}

.landing-section-multi-paragraph {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.landing-section-multi-paragraph p {
    text-align: left;
}

.landing-section-featured-navs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.landing-section-featured-nav {
    font-size: 10px;
    text-align: center;
    border-bottom: 2px solid #7286A0;
    color: #7286A0;
    flex-grow: 1;
    padding-bottom: 8px;
    cursor: pointer;
}

.landing-section-featured-nav:first-of-type {
    text-align: left;
}

.landing-section-featured-nav:last-of-type {
    text-align: right;
}

.landing-section-featured-nav-selected {
    border-bottom: 2px solid #BE6E46;
    color: #BE6E46;
    font-weight: 700;
}

.landing-section-featured-img {
    margin-top: 12px;
    padding: 8px;
    border: 1px solid #7286A0;
    border-radius: 8px;
    box-sizing: border-box;
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.landing-section-featured-img img {
    width: 100%;
}

.landing-section-cta-button-group-dark-mode {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;
    margin-top: 24px;
}

.landing-section-cta-button-group-dark-mode div {
    color: white;
    font-weight: 500;
    padding: 6px 24px;
    border-radius: 24px;
    border: 1px solid white;
    cursor: pointer;
}

.landing-section-cta-button-group-dark-mode div:hover {
    background-color: rgba(255, 255, 255, 0.103);
}

.landing-section-pricing-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 40px;
}

.landing-section-pricing-option {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 12px;
    border-radius: 4px;
    box-sizing: border-box;
    flex-basis: 50%;
}

.landing-section-pricing-title {
    font-size: 14px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.landing-section-pricing-subtitle {
    color: grey;
    font-size: 12px;
    margin-bottom: 20px;
}

.landing-section-pricing-price {
    font-size: 30px;
    font-weight: 700;
}

.landing-section-pricing-button {
    font-size: 14px;
    font-weight: 600;
    width: 70%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 24px;
    border: 1px solid #174061;
    background-color: #174061;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    cursor: pointer;
}

.landing-section-pricing-button-paid {
    background-color: white;
    border: 1px solid #174061;
    color: #174061;
}

.landing-section-pricing-features {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
}

.landing-section-pricing-feature {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.landing-section-pricing-feature svg {
    font-size: 20px;
    color: #174061;
    min-width: 20px;
}

.landing-section-pricing-feature span {
    font-size: 12px;
    padding-top: 2px;
    padding-left: 4px;
}

/* UNAUTHENTICATED HEADER */
.unauthenticated-header-outer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    /* border-bottom: 1px solid rgb(230, 230, 230); */
    background-color: white;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.unauthenticated-header {
    max-width: 1440px;
    width: 100%;
    min-height:  72px;
    padding: 20px 32px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.unauthenticated-header-side {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    gap: 18px;
}

.unauthenticated-header-side-desktop {
    display: flex;
}

.unauthenticated-header-side-mobile {
    display: none;
}

.unauthenticated-header-middle {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
}

.unauthenticated-header-logo {
    cursor: pointer;
    padding: 12px;
    box-sizing: border-box;
}

.unauthenticated-header-logo svg {
    height: 40px;
}

.unauthenticated-header-nav {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 0px;
    text-align: center;
    width: 100px;
    border-radius: 12px;
    transition: all 0.2s;
}

.unauthenticated-header-button-start {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 16px;
    text-align: center;
    border-radius: 12px;
    border: 1px solid #17406155;
    transition: all 0.2s;
    margin-left: 20px;
    background-color: #2A73AE;
    color: white;
}

@media screen and (max-width: 1020px) {
    .unauthenticated-header-button-start,
    .unauthenticated-header-nav,
    .unauthenticated-header-button-login {
        font-size: 14px;
    }

    .unauthenticated-header-nav {
        width: 80px;
    }

    .unauthenticated-header-middle {
        gap: 8px;
    }

    .unauthenticated-header-button-start {
        margin-left: 12px;
    }
}

.unauthenticated-header-button-login {
    cursor: pointer;
    padding: 6px 0px;
}

.unauthenticated-header-button-login:hover {
    text-decoration: underline;
}

.unauthenticated-header-nav:hover,
.unauthenticated-header-nav-sel {
    background-color: #1740612d;
    color: #174061;
}

.unauthenticated-header-side-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.unauthenticated-header-side-mobile-menu {
    position: fixed;
    top: 112px;
    z-index: 3;
    right: 0;
    left: 0;
    background-color: white;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.unauthenticated-header-side-mobile-menu span {
    border-bottom: 1px solid rgb(218, 218, 218);
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    padding: 20px;
}

.unauthenticated-header-side-mobile-menu span:first-of-type {
    border-top: 1px solid rgb(218, 218, 218);
}

.unauthenticated-header-side-icon svg {
    font-size: 36px;
    color: rgb(79, 79, 79);
}

@media screen and (max-width: 800px) {
    .unauthenticated-header-middle,
    .unauthenticated-header-side-desktop { 
        display: none;
    }

    .unauthenticated-header-side-mobile {
        display: flex;
        align-items: center;
        position: relative;
    }
}

/* REGISTRATION & LOGIN */
.register-container {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    position: relative;
}

.register-backdrop {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 1s;
    /* background-color: rgb(242, 242, 242); */
    background-color: rgba(20, 82, 148, 0.13);
}

.register-form {
    width: 95%;
    box-sizing: border-box;
    min-width: unset;
    max-width: unset;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    background-color: white;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 32px;
}

.register-form-animation-outer {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-form-animation-outer img {
    height: 150px;
    /* width: 191px; */
}

@media screen and (min-width: 600px) {
    .register-form {
        width: 30%;
        max-width: 600px;
        min-width: 480px;
    }
}

.register-form-inputs {
    margin-top: 40px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 14px;
}

.register-form-inputs .modal-workstream-settings-setting-single {
    margin-top: 0px;
}

.register-form-registration-method-buttons {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.register-form-registration-method-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    font-size: 16px;
    padding: 8px 0px;
    font-weight: 600;
    color: #3C3C3C;
    border-radius: 4px;
    border: 1px solid #3f3f3f40;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    cursor: pointer;
}

.register-form-registration-method-button-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 220px;
    gap: 16px;
}

.register-form-registration-method-button img,
.register-form-registration-method-button svg {
    height: 24px;
    width: 24px;
    min-width: 24px;
}

.register-submit-button {
    margin-left: auto;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    margin-right: auto;
    font-size: 16px;
    background-color: #2A73AE;
    color: white;
    padding: 12px 0px;
    margin-top: 20px;
    border-radius: 0px;
    font-weight: 500;
    border-radius: 4px;
}

.register-back-button {
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    cursor: pointer;
    color: grey;
}

.register-confirmation {
    width: 100%;
    box-sizing: border-box;
    max-width: 800px;
    padding: 20px;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: grey;
}

.register-confirmation h2 {
    margin: 0;
    font-size: 36px;
    margin-bottom: 20px;
    color: rgb(47, 47, 47);
}

.register-confirmation-error-button {
    background-color: rgba(63, 115, 170, 1);
    color: white;
    border-radius: 24px;
    padding: 12px 20px;
    margin-top: 40px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}

/* LANDING BASE */
.landing-base-container {
    width: 100%;
    box-sizing: border-box;
}

.landing-base-home-hero-texts-outer {
    width: 100%;
    /* background-color: #353e4d; */
    color: #353e4d;
}

.landing-base-home-hero-texts-outer-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
}

.landing-base-home-hero-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.landing-base-alt-hero-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    max-width: 1000px;
    width: 90%;
    gap: 64px;
    margin-left: auto;
    margin-right: auto;
}

.landing-base-pricing.landing-base-alt-hero-texts {
    max-width: unset;
    width: 100%;
}

.landing-base-pricing-limitation-pill {
    color: #3191e1;
    background-color: #3192e12c;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 20px;
    margin-top: 12px;
}

.landing-base-alt-hero-texts h1,
.register-form h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    line-height: 1.1;
    color: rgb(45, 45, 45);
    margin-bottom: 12px;
}

.landing-base-alt-hero-texts h3,
.register-form h3 {
    font-size: 20px;
    color: rgb(147, 147, 147);
    margin: 0;
    font-weight: 400;
    text-align: center;
}

.landing-base-home-hero-texts h1 {
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    margin: 0;
    line-height: 1.1;
    /* color: rgb(45, 45, 45); */
    /* color: rgb(226, 226, 226); */
    color: #353e4d;
    margin-bottom: 20px;
}

.landing-base-home-hero-texts h3 {
    font-size: 20px;
    margin: 0;
    font-weight: 400;
    text-align: center;
    /* color: rgb(226, 226, 226); */
    color: #353e4d;
}

.landing-base-home-hero-subtitle-decorative {
    line-height: 1.6;
}

.landing-base-home-hero-subtitle-decorative span,
.landing-base-home-feature-title span {
    margin-left: 0.07em;
    margin-right: 0.07em;
    padding: 2px 4px;
    border-radius: 6px;
    /* background-color: rgb(255, 185, 1); */
    /* background-color: rgba(252, 99, 107, 0.233); */
    background-color: #57acf22f;
    /* color: rgb(34, 34, 34); */
    /* color: rgb(252, 99, 107); */
    color: #3191e1;
    cursor: default;
}

.landing-base-home-cta {
    font-weight: 600;
    font-size: 17px;
    padding: 16px 48px;
    background-color: #2A73AE;
    border: 1px solid #2A73AE;
    border-radius: 6px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
    transition: all 0.4s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.landing-base-home-cta svg {
    font-size: 20px;
}

.landing-base-home-cta-alt {
    background-color: white;
    color: #2A73AE;
    border: 1px solid #2A73AE;
}

.landing-base-home-cta:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.landing-base-home-cta-watch {
    /* color: rgb(226, 226, 226); */
    font-weight: 500;
    font-size: 12px;
    margin-top: 1em;
    text-align: center;
    color: #6c6c6c;
}

.landing-base-home-subtext {
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
    color: rgb(50, 50, 50);
    text-align: center;
    margin-top: 12px;
}

.landing-base-home-subtext span {
    color: rgb(53, 53, 53);
    font-weight: 700;
}

.landing-base-primary-social-proof-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 80px;
    height: 36px;
}

.landing-base-primary-social-proof-logos img {
    max-height: 100%;
    filter: grayscale(100%);
}

.landing-base-primary-container-limited-width {
    width: 80%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
}

.landing-base-primary-container-full-width {
    width: 100%;
    box-sizing: border-box;
}

.landing-base-primary-basic-text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 700px;
    color: rgb(29, 29, 29);
}

.landing-base-primary-basic-text-section h4 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    margin: 0;
    text-align: center;
    align-self: center;
    margin-bottom: 22px;
}

.landing-base-primary-basic-text-section p {
    margin: 0;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    color: rgb(85, 85, 85);
    line-height: 32px;
    margin-bottom: 22px;
}

.landing-base-primary-basic-text-section p:last-of-type {
    margin-bottom: 0;
}

.landing-base-primary-basic-text-section p span {
    font-weight: 500;
    background-color: #2A73AE3b;
    color: #2A73AE;
    padding: 4px;
    border-radius: 4px;
    margin: 0px 4px;
}

.landing-base-home-big-capabilities {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    gap: 32px;
    margin-bottom: 120px;
    margin-top: 24px;
}

.landing-base-home-big-capability {
    border-radius: 6px;
    padding: 14px;
    box-sizing: border-box;
    box-shadow: unset;
}

.landing-base-home-big-capability p {
    margin: 0;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    color: rgb(85, 85, 85);
    line-height: 1.5;
    margin-top: 14px;
}

.landing-base-home-big-capability-title-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    font-size: 20px;
    font-weight: 400;
    color: rgb(64, 65, 69);
}

.landing-base-home-big-capability-title-block svg {
    font-size: 28px;
}

.landing-base-primary-container-background-alt {
    /* background-color: #1740615c; */
    background-color: #102b5019;
    margin-top: 120px;
    padding-top: 200px;
    padding-bottom: 200px;
    /* color: white; */
}

.landing-base-primary-container-background-alt-2 {
    background-color: rgb(247, 247, 247);
    margin-top: 20vh;
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.landing-base-primary-container-text-alt {
    /* color: rgb(235, 235, 235); */
}

.landing-page-carousel-desktop {
    display: none;
}

.landing-page-carousel-responsive {
    display: flex;
    margin-top: 40px;
}

.landing-base-home-feature-title {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
}

.landing-base-home-feature-title-centered {
    text-align: center;
}

.landing-base-home-feature-subtitle-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.landing-base-home-feature-subtitle {
    max-width: 600px;
    text-align: left;
    font-size: 24px;
    font-weight: 400;
    margin-top: 24px;
    line-height: 1.6;
    flex-basis: 45%;
}

.landing-base-home-feature-image-and-text-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    gap: 32px;
}

.landing-base-home-feature-image-and-text-container h4 {
    font-size: 28px;
    font-weight: 400;
    margin: 0;
    text-align: center;
}

.landing-base-home-feature-image-and-text-container p {
    font-size: 16px;
    margin: 0;
    text-align: center;
}

.landing-base-home-feature-text-container p {
    display: none;
}

.landing-base-home-feature-image-container p {
    margin-top: 20px;
    padding: 0px 20px;
    box-sizing: border-box;
    line-height: 1.8;
}

.landing-base-home-feature-image-container {
    width: 100%;
}

.landing-base-home-feature-image-container img,
.landing-base-home-feature-image-container video {
    max-width: 100%;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 8px;
    padding: 4px;
    box-sizing: border-box;
}

.landing-base-home-feature-image-container video {
    padding: 0;
    border-radius: 0;
}

@media screen and (min-width: 1200px) {
    .landing-base-home-feature-image-and-text-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        gap: 24px;
    }

    .landing-base-home-feature-image-and-text-container.reverse {
        flex-direction: row-reverse;
    }

    .landing-base-home-feature-text-container {
    }

    .landing-base-home-feature-text-container h4 {
        text-align: left;
        margin-bottom: 1em;
    }

    .landing-base-home-feature-image-container {
        min-width: 580px;
    }

    .landing-base-home-feature-text-container p {
        display: block;
        text-align: left;
        font-size: 20px;
        line-height: 1.8;
    }

    .landing-base-home-feature-image-container p {
        display: none;
    }
}

/* .landing-base-primary-container-text-alt.landing-base-home-feature-subtitle {
    color: rgb(167, 167, 167);
} */

/* .landing-base-primary-container-text-alt.landing-base-home-feature-subtitle span {
    color: rgb(242, 242, 242);
} */

.landing-base-home-feature-picture-video {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1600px;
    margin-top: 120px;
    display: none;
    overflow: hidden;
}

.landing-base-home-feature-picture-video video {
    width: 100%;
}

.landing-page-carousel-responsive-card {
    display: flex;
    flex-direction: column;
    gap: 8px;

    background-color: #ffffff;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.landing-page-carousel-responsive-card-title {
    font-size: 20px;
    font-weight: 600;
}

.landing-page-carousel-responsive-card-description {
    font-size: 17px;
    font-weight: 400;
}

.landing-base-home-hero-image-container {
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 0.3s;
    margin-top: 40px;
    transform: translateY(-140px);
    background-color: white;
}

.landing-base-home-hero-image-container img {
    width: 100%;
}

.landing-base-home-hero-video-container {
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 40px;
    background-color: white;
}

.landing-base-home-hero-video-container video {
    width: 100%;
    height: auto;
    max-width: 800px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.landing-base-section-interlink {
    color: #2A73AE;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.landing-base-section-interlink svg {
    font-size: 32px;
}

.landing-base-section-interlink:hover {
    text-decoration: underline;
}

.lnding-cta-responsive {
    display: none;
}

.landing-base-home-hero-videos {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.landing-base-home-other-features-desktop {
    display: none;
}

.landing-base-home-other-features-mobile {
    display: block;
}

.landing-base-home-other-features.desktop {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 32px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.landing-base-home-other-features-list-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    width: 280px;
    max-width: 280px;
    min-width: 280px;
}

.landing-base-home-other-features-list-desktop span {
    font-size: 20px;
    font-weight: 500;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s;
}

.landing-base-home-other-features-list-desktop span.active {
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: #2A73AE;
}

.landing-base-home-other-features-displayed-desktop {
    flex-grow: 1;
}

.landing-base-home-other-features-displayed-desktop h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.landing-base-home-other-features-displayed-desktop p {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    color: grey;
}

.landing-base-home-other-features-list-mobile {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0px 20px;
}

.landing-base-home-other-features-list-mobile-item span {
    color: #2A73AE;
    background-color: #2a73ae35;
    padding: 2px 12px;
    cursor: default;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
}

.landing-base-home-other-features-list-mobile-item h3 {
    margin: 0;
    font-size: 16px;
    color: var(--t-color-dark);
}

.landing-base-home-other-features-list-mobile-item p {
    margin: 0;
    font-size: 16px;
    color: grey;
    line-height: 1.6;
}

@media screen and (min-width: 800px) {
    .landing-base-home-hero-texts-outer-main {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100%;
        gap: 20px;
        flex-direction: column;
    }

    .landing-base-home-hero-texts h3 {
        font-weight: 400;
    }

    .landing-base-home-hero-texts {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .landing-base-home-subtext {
        font-size: 20px;
        text-align: center;
    }

    .landing-base-alt-hero-texts h1 {
        font-size: 48px;
    }

    .landing-base-alt-hero-texts h3 {
        font-size: 24px;
    }

    .lnding-cta-responsive {
        display: block;
    }

    .landing-base-home-feature-subtitle-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .landing-base-home-feature-picture-video {
        display: block;
    }

    .landing-page-carousel-desktop {
        display: block;
    }

    .landing-page-carousel-responsive {
        display: none;
    }
}

@media screen and (min-width: 1000px) {
    .landing-base-home-hero-texts {
        flex-basis: 60%;
    }

    .landing-base-home-hero-videos {
        flex-grow: 1;
    }

    .landing-base-home-other-features-desktop {
        display: block;
    }
    
    .landing-base-home-other-features-mobile {
        display: none;
    }

}

@media screen and (min-width: 1200px) {
    .landing-base-home-hero-texts-outer-main {
        flex-direction: column;
        padding-left: 80px;
        padding-top: 20px;
        padding-bottom: 60px;
        padding-right: 80px;
        box-sizing: border-box;
        width: 100%;
        gap: 20px;
        justify-content: center;
    }

    .landing-base-home-hero-texts {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    .landing-base-home-hero-texts h1 {
        text-align: center;
        max-width: 800px;
    }

    .landing-base-home-hero-texts h3 {
        text-align: center;
        max-width: 800px;
    }

    .landing-base-home-big-capabilities {
        flex-direction: row;
        justify-content: space-between;
    }

    .landing-base-home-big-capability-title-block {
        flex-direction: column;
        align-items: flex-start;
    }

    .landing-base-home-big-capability {
        max-width: 360px;
        flex-basis: 30%;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }
}

/* rgb(255, 185, 1) */

/* LEGACY LANDING - SOME STILL IN USE */

.lnding-container {
    width: 100%;
}

.lnding-about-section {
    flex-basis: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-left: auto;
    margin-right: auto;
}

.lnding-about-section h1 {
    font-weight: 600;
    font-size: 32px;
    margin: 0;
    color: #082740;
    text-align: center;
    padding: 24px 0px;
}

.lnding-about-section p {
    font-weight: 400;
    line-height: 1.6;
    font-size: 16px;
    margin: 0;
    color: #282828;
}

.lnding-about-section p span {
    font-weight: 700;
}

.lnding-about-outer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 40px;
}

.lnding-hero-outer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 40px;
}

.lnding-hero-section {
    flex-basis: 80%;
    max-width: 800px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}

.lnding-hero-section h1 {
    font-weight: 600;
    font-size: 40px;
    margin: 0;
    color: #082740;
    text-align: center;
}

.lnding-hero-section p {
    font-size: 20px;
    color: rgb(63, 63, 63);
    margin: 0;
    line-height: 1.8;
    text-align: center;
}

.lnding-hero-section button {
    background-color: #174061;
    border: none;
    border: 1px solid #174061;
    color: white;
    font-size: 18px;
    padding: 20px 60px;
    border-radius: 4px;
    flex-basis: 50%;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
}

.lnding-hero-section button:hover {
    background-color: #174061bd;
}

/* .lnding-hero-section button:first-of-type:hover {
    background-color: rgb(242, 241, 241);
}

.lnding-hero-section button:first-of-type {
    background-color: white;
    border: 1px solid #174061;
    color: #174061;
} */

.lnding-hero-section h1,
.lnding-hero-section p,
.lnding-hero-section-buttons {
    margin-bottom: 40px;
}

.lnding-hero-section-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;
}

.lnding-hero-button-subtext {
    font-size: 16px;
    color: grey;
    text-align: center;
}

.lnding-hero-section-images {
    display: none;
}

.lnding-hero-image-full {
    width: 100%;
    margin-top: 120px;
}

.lnding-hero-image-full img {
    width: 100%;
}

.lnding-hero-image-fade-in {
    opacity: 0;
    transition: opacity 1s ease-out;
}

.lnding-hero-image-fade-in.active {
    opacity: 1;
}

.lnding-partnerships {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.lnding-partnerships h2 {
    font-weight: 500;
    font-size: 18px;
    color: rgb(61, 61, 61);
    margin: 0;
    text-align: center;
}

.lnding-partnership-carousel {
    display: flex;
    flex-direction: column;
    gap: 28px;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
}

@media screen and (min-width: 800px) {
    .lnding-partnership-carousel {
        flex-direction: row;
        gap: 12px;
        max-width: 800px;
        margin-top: 5vh;
    }
}


.lnding-partnership-logo {
    flex-basis: 20%;
}

.lnding-partnership-logo img {
    width: 100%;
}

.lnding-hero-image-col {
    flex-basis: 45%;
}

.lnding-benefits-image {
    flex-basis: 50%;
}

.lnding-hero-image-col div,
.lnding-benefits-image div {
    width: 100%;
    max-width: 100%;
    box-shadow: 0 48px 100px 0 #110c2e26;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.5s;
}

.lnding-benefits-image div {
    box-shadow: none;
}

.lnding-hero-image-col div:hover {
    box-shadow: 0 60px 120px 0 #2e2e2e48;
}

.lnding-hero-image-col div img,
.lnding-benefits-image div img {
    width: 100%;
}

.lnding-hero-image-col-center {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.lnding-hero-image-col-spaced {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 40px;
}

.lnding-video-feature {
    width: 80%;
    margin: 60px auto;
    max-width: 1200px;
}

.lnding-video-wrapper {
    margin-top: 48px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.lnding-video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.lnding-dark-backdrop {
    background-color: #17406116;
}

.lnding-benefits {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.lnding-benefits-section.lndng-benefits-selector {
    display: none;
}

.lnding-benefits-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
}

.lnding-benefits-section-reverse {
    flex-direction: column;
}

.lnding-benefits-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 12px;
}

.lnding-benefits-buttons span {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid black;
    padding: 4px 24px;
    border-radius: 20px;
    cursor: pointer;
}

.lnding-benefits-buttons span:hover,
.lnding-benefits-button-selected {
    background-color: white;
}

.lnding-benefits-texts h3 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    color: rgb(45, 45, 45);
}

.lnding-benefits-texts p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.8;
    color: rgb(65, 65, 65);
}

.lnding-benefits-points {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.lnding-benefits-texts .landing-section-pricing-feature {
    display: flex;
    align-items: center;
}

.lnding-benefits-texts .landing-section-pricing-feature span {
    font-size: 20px;
    padding-top: 2px;
    padding-left: 4px;
    color: rgb(65, 65, 65);
}

.lnding-benefits-texts p {
    margin-top: 24px;
}

.lnding-benefits-texts { 
    flex-basis: 45%;
}

.lnding-pricing,
.lnding-differences {
    margin-top: 80px;
}

.lnding-cta {
    margin-top: 0px;
}

.lnding-pricing-options-outer {
    margin-top: 40px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.lnding-general-section-heading {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #082740;
    margin: 0;
}

.lnding-differences {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
}

.lnding-differences-big-ideas {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;
}

.lnding-differences-big-idea {
    padding: 20px 14px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    box-sizing: border-box;
    background-color: white;
    transition: all 0.3s;
    border-radius: 6px;
    cursor: default;
}

.lnding-differences-big-idea:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.lnding-differences-big-idea p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 4px;
}

.lnding-differences-big-idea span {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(65, 65, 65);
}

.lnding-differences-about {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    margin-top: 40px;
}

.lnding-differences-about p {
    font-size: 20px;
    color: rgb(65, 65, 65);
}

.lnding-differences-big-idea {
    flex-basis: 22%;
}

.lnding-cta {
    margin-bottom: 80px;
}

.lnding-cta-shoutout {
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: #253441;
    margin-top: 0px;
}

.lnding-cta-shoutout h2 {
    color: white;
}

.lnding-cta-shoutout p {
    color: #c3c6c8;
    font-size: 20px;
    line-height: 1.8;
}

.lnding-cta-button {
    margin-top: 20px;
}

.lnding-cta-button button {
    background-color: white;
    color: #253441;
    font-size: 18px;
    font-weight: 700;
    padding: 20px 120px;
    border: 1px solid #253441;
    cursor: pointer;
    text-transform: uppercase;
}

.lnding-cta-pro {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 0px 20px;
}

.lnding-cta-pro span {
    text-decoration: underline;
    cursor: pointer;
    color: #174061;
}

.lnding-footer {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #253441;
    color: white;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.lnding-footer-sections {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.lnding-footer-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.lnding-footer-section-heading {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.lnding-footer-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.lnding-footer-links span {
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
}

.lnding-footer-links span a {
    color: unset;
    text-decoration: none;
}

.lnding-footer-links span:hover {
    text-decoration: underline;
}

@media only screen and (min-width: 768px) {
    .home-main {
        margin-left: 240px;
    }
}

@media only screen and (min-width: 900px) {
    .lnding-container {
        min-width: 900px;
    }

    .lnding-hero-section h1 {
        font-size: 54px;
    }

    .lnding-hero-section h1,
    .lnding-hero-section p,
    .lnding-hero-section-buttons {
        margin-bottom: 28px;
    }

    .lnding-footer {
        flex-direction: row;
        gap: 0px;
    }

    .lnding-footer-sections {
        flex-direction: row;
        gap: 120px;
    }

    .lnding-differences-big-ideas {
        flex-direction: row;
    }

    .lnding-benefits-section.lndng-benefits-selector {
        display: flex;
    }

    .lnding-hero-section-images {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: stretch;
    }

    .lnding-benefits {
        gap: 140px;
        margin-top: 140px;
    }

    .lnding-pricing-options-outer {
        max-width: 800px;
    }

    .lnding-hero-outer {
        flex-direction: row;
        margin-top: 80px;
    }

    .lnding-hero-section-buttons {
        /* flex-direction: row-reverse; */
    }

    .lnding-benefits-section {
        display: flex;
        flex-direction: row;
    }
    
    .lnding-benefits-section-reverse {
        flex-direction: row-reverse;
    }

    .landing-section {
        width: 70%;
        max-width: 1000px;
        margin-top: 10%;
    }

    .landing-section-full-width {
        max-width: 100%;
        width: 100%;
    }

    .landing-section-badge {
        font-size: 12px;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .landing-section-heading-grouped-text {
        gap: 56px;
    }

    .landing-section h1 {
        font-size: 50px;
        line-height: 1.2;
    }

    .landing-section p {
        font-size: 18px;
        line-height: 1.4;
    }

    .landing-section-cta-button-grouped-text {
        gap: 16px;
        margin-top: 24px;
    }

    .landing-section-cta-button-grouped-text div {
        font-size: 18px;
        gap: 12px;
    }

    .landing-section-cta-button-grouped-text div svg {
        font-size: 24px;
    }

    .landing-section-cta-button-grouped-text span {
        font-size: 14px;
    }

    .landing-section-section-small-heading {
        font-size: 16px;
        margin-bottom: 20px;
        padding-bottom: 4px;
        border-bottom: 1px solid rgb(194, 194, 194);
    }

    .landing-section-multi-paragraph {
        gap: 24px;
    }

    .landing-section-featured-navs {
        width: 70%;
    }

    .landing-section-featured-nav {
        font-size: 18px;
    }

    .landing-section-pricing-options {
        flex-direction: row;
        gap: 40px;
    }

    .landing-section-pricing-title {
        font-size: 20px;
    }

    .landing-section-pricing-subtitle {
        font-size: 14px;
    }

    .landing-section-pricing-price {
        font-size: 36px;
    }

    .landing-section-pricing-feature svg {
        font-size: 24px;
        min-width: 24px;
    }

    .landing-section-pricing-feature span {
        font-size: 14px;
    }

    .landing-footer-options span {
        font-size: 16px;
    }

    .landing-footer-logo svg {
        width: 240px;
    }

    .landing-footer-copyright {
        font-size: 16px;
    }

    .lnding-cta-shoutout {
        padding: 40px 80px;
        margin-top: 24px;
    }

    .lnding-cta-pro {
        padding: 0px;
    }

    .lnding-cta {
        margin-top: 80px;
    }
}

@media only screen and (min-width: 1080px) {
    .lnding-container {
        min-width: 1080px;
    }
}

/* TOOLS */
.tools-container {
    width: 100%;
    --tools-generic-header-height: 104px;
}

.tools-error-message {
    margin-top: 30vh;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

.tools-generic-header-container {
    width: 100%;
    height: var(--tools-generic-header-height);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 40px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(224, 224, 224);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}

.tools-generic-header-tagline {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;
    color: rgb(120, 120, 120);
}

.tools-generic-header-container-titles {
    overflow: hidden;
    gap: 20px;
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.tools-generic-header-container-titles-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 100%;
}

.tools-generic-header-logo {
    height: 40px;
    box-sizing: border-box;
    padding: 8px;
    padding-right: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.public-tool-promotion-calendar-responsive-heading {
    display: none;
}

.tools-generic-header-logo svg {
    height: 32px;
}

.tools-generic-header-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    box-sizing: border-box;
    cursor: pointer;
}

.tools-generic-header-icon svg {
    font-size: 20px;
    /* color: white; */
}

.tools-generic-header-title {
    font-size: 20px;
    font-weight: 700;
    color: rgb(54, 54, 54);
    white-space: pre;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    display: flex;
    align-items: baseline;
    gap: 12px;
}

.tools-generic-header-title-suffix {
    color: grey;
    font-size: 13px;
    font-weight: 400;
}

.tools-generic-header-subtitle {
    font-size: 15px;
    color: grey;
    font-weight: 400;
}

.public-tool-beta-tag {
    padding: 2px;
    color: #ff4284;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    border-radius: 0px;
    position: absolute;
    right: 40px;
    top: -28px;
}

.public-tool-promotion-calendar-container {
    position: relative;
}

.public-tool-promotion-calendar-main {
    position: fixed;
    top: var(--tools-generic-header-height);
    right: 0;
    left: 0;
    bottom: 0;
    margin-top: 8px;
}

.public-tool-promotion-calendar-calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.public-tool-promotion-calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    margin-top: 8px;
    min-height: 40px;
}

.public-tool-promotion-calendar-header-actions {
    flex-basis: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}

.public-tool-promotion-calendar-header-action-icon-text {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.public-tool-promotion-calendar-header-action-icon-text span {
    font-size: 14px;
    font-weight: 600;
    color: #2A73AE;
}

.public-tool-promotion-calendar-header-action-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    min-width: 16px;
}

.public-tool-promotion-calendar-header-action-icon svg {
    font-size: 16px;
    color: #2A73AE;
}

.public-tool-promotion-calendar-header-create-item {
    color: #2A73AE;
    font-size: 14px;
    padding: 2px 14px;
    cursor: pointer;
    font-weight: 700;
}

.public-tool-promotion-calendar-header-create-item:hover {
    text-decoration: underline;
}

.public-tool-promotion-calendar-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 4px;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 4px;
}
.public-tool-promotion-calendar-icon-button:hover { background-color: rgb(216, 216, 216); }
.public-tool-promotion-calendar-icon-button svg { font-size: 18px; }

.public-tool-promotion-calendar-header-nav {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 12px;
    flex-grow: 1;
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.public-tool-promotion-calendar-header-nav-text {
    width: 220px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 2px;
    position: relative;
    min-height: 40px;
}

.public-tool-promotion-calendar-header-action-search {
    display: flex;
    align-items: center;
    gap: 20px;
    border: none;
    padding: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    position: relative;
}

.public-tool-promotion-calendar-header-action-search svg {
    width: 20px;
    min-width: 20px;
    position: absolute;
    margin-left: 16px;
}

.public-tool-promotion-calendar-header-action-search input {
    flex-grow: 1;
    padding-left: 40px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-right: 12px !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0 !important;
    border: none !important;
}

.public-tool-promotion-calendar-header-action-search input:focus {
    outline: none;
}

.public-tool-promotion-calendar-header-nav-text:hover {
    background-color: #dedee9;
    border-radius: 14px;
}

.public-tool-promotion-calendar-header-selection-container {
    position: absolute;
    top: 100%;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 4;
}

.public-tool-promotion-calendar-header-nav-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F9;
    border-radius: 16px;
    padding: 8px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    cursor: pointer;
    transition: all 0.2s;
}

.public-tool-promotion-calendar-header-nav-button:hover {
    background-color: #dedee9;
}

.public-tool-promotion-calendar-header-nav-button svg {
    font-size: 20px;
}

.public-tool-promotion-calendar-header-timespan-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}

.public-tool-promotion-calendar-header-timespan-container svg {
    font-size: 20px;
    color: rgb(51, 51, 51);
}

.public-tool-promotion-calendar-header-timespan-selected {
    cursor: pointer;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 4px;
    font-size: 14px;
    color: rgb(60, 64, 67);
    font-weight: 600;
    width: 120px;
    padding: 8px 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.public-tool-promotion-calendar-header-timespan-selected svg {
    font-size: 14px;
}

.public-tool-promotion-calendar-events.dragging-over {
    background-color: rgb(247, 247, 247);
}

.public-tool-promotion-calendar-header-timespan-menu {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    width: 120px;
    border: 1px solid rgb(225, 225, 225);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 2;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.public-tool-promotion-calendar-header-timespan-menu span {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    color: rgb(60, 64, 67);
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.public-tool-promotion-calendar-header-timespan-menu span:hover {
    background-color: rgb(233, 233, 233);
}

.public-tool-promotion-calendar-days {
    display: flex;
    flex-wrap: nowrap;
    height: 24px;
}

.public-tool-promotion-calendar-primary-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-top: 12px;
    --public-tool-promotion-calendar-border-color: rgb(216, 216, 216);
}

.public-tool-promotion-calendar-primary-container-list {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 40px;
}

.public-tool-promotion-calendar-primary-container-list-tasks {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 18px;
    max-width: 1200px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.public-tool-promotion-calendar-events {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
}

.public-tool-promotion-calendar-event-single:first-of-type {
    margin-top: 4px;
}

.public-tool-promotion-calendar-event-single {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer !important;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.public-tool-promotion-calendar-event-single-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    max-width: 95%;
    min-width: 0;
}

.public-tool-promotion-calendar-event-single-name.complete {
    color: rgb(78, 78, 78);
    font-style: italic;
}

.public-tool-promotion-calendar-event-single-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    min-width: 16px;
}

.public-tool-promotion-calendar-event-single-icon svg {
    color: rgb(78, 78, 78);
    font-size: 16px;
}

.public-tool-promotion-calendar-event-single:hover {
    background-color: rgb(228, 228, 228);
}

.public-tool-promotion-calendar-col-heading {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.public-tool-promotion-calendar-number {
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 24px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-top: 4px;
}

.public-tool-promotion-calendar-number.public-tool-promotion-calendar-number-today {
    color: white;
    background-color: rgb(32, 68, 137);
}

.public-tool-promotion-calendar-calendar-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 97%;
    box-sizing: border-box;
    border-top: 1.4px solid var(--public-tool-promotion-calendar-border-color);
}

.public-tool-promotion-calendar-row {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
}

.public-tool-promotion-calendar-col {
    flex: 1 1 0%;
    min-width: 0;
    overflow: hidden;
}

.public-tool-promotion-calendar-col.public-tool-promotion-calendar-cell {
    border-right: 1.4px solid var(--public-tool-promotion-calendar-border-color);
    border-bottom: 1.4px solid var(--public-tool-promotion-calendar-border-color);
}

.public-tool-promotion-calendar-month-decorator {
    position: absolute;
    left: 4px;
    top: 4px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 24px;
    min-height: 24px;
    color: rgb(182, 182, 182);
}

.public-tool-promotion-calendar-cell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
}

.public-tool-promotion-calendar-cell-overflow-button {
    height: 22px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
    max-width: 100%;
    transition: all 0.2s;
    position: relative;
}

.public-tool-promotion-calendar-cell-overflow-button:hover {
    background-color: rgb(228, 228, 228);
}

.public-tool-promotion-calendar-cell-overflow-button-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding-left: 20px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.public-tool-promotion-calendar-cell-overflow-button-item {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: all 0.2s;
}

.public-tool-promotion-calendar-cell-overflow-button-item:hover {
    text-decoration: underline;
}

.public-tool-promotion-calendar-cell-overflow-button-item-color {
    width: 10px;
    height: 10px;
    min-width: 10px;
    border-radius: 6px;
    border: 1px solid rgb(197, 197, 197);
}

.public-tool-promotion-calendar-cell-overflow-button-items span {
    font-size: 14px;
    white-space: pre;
    max-width: 100%;
    min-width: 0;
}

.public-tool-promotion-calendar-col.public-tool-promotion-calendar-cell:first-of-type {
    border-left: 1.4px solid var(--public-tool-promotion-calendar-border-color);
}

.public-tool-promotion-calendar-modal-overlay {
    position: fixed;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.409);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.public-tool-promotion-calendar-modal-container {
    color: #353C44;
    margin-top: calc(var(--tools-generic-header-height) + 100px);
    width: 60%;
    min-width: 480px;
    background-color: white;
    border: 1.5px solid rgb(227, 227, 227);
    border-radius: 4px;
    z-index: 104;
    padding: 14px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.public-tool-promotion-calendar-modal-container.public-tool-promotion-calendar-modal-container-settings {
    width: 100%;
    max-width: 600px;
}

.public-tool-promotion-calendar-modal-header h2 {
    margin: 0;
    font-size: 18px;
    margin-left: 20px;
}

.public-tool-promotion-calendar-modal-header,
.public-tool-promotion-calendar-modal-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.public-tool-promotion-calendar-modal-header {
    margin-bottom: 20px;
}

.public-tool-promotion-calendar-modal-header-action {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.public-tool-promotion-calendar-modal-header-action:hover {
    background-color: rgb(226, 226, 226);
}

.public-tool-promotion-calendar-modal-header-action svg {
    font-size: 24px;
    color: rgb(112, 112, 112);
}

.public-tool-promotion-calendar-modal-footer {
    margin-top: 48px;
}

.public-tool-promotion-calendar-modal-titles {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    padding-left: 20px;
}

.public-tool-promotion-calendar-modal-titles h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.public-tool-promotion-calendar-modal-timedate {
    font-size: 14px;
    color: grey;
    margin-top: 4px;
}

.public-tool-promotion-calendar-modal-description {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    white-space: pre-wrap;
    line-break: auto;
}

.public-tool-promotion-calendar-modal-color-selection-container {
    position: relative;
}

.public-tool-promotion-calendar-modal-color-selection-selected,
.public-tool-promotion-calendar-modal-color-selection {
    cursor: pointer;
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 20px;
    border: 1.5px solid rgb(210, 210, 210);
}

.public-tool-promotion-calendar-modal-color-selections {
    position: absolute;
    background-color: white;
    top: calc(100% + 8px);
    left: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 120px;
    gap: 8px;
    justify-content: space-between;
    padding: 12px;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.public-tool-promotion-calendar-modal-footer-save {
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #3D72AA;
    border: 1px solid #3D72AA;
    color: white;
}

.public-tool-promotion-calendar-modal-footer-save.public-tool-promotion-calendar-modal-footer-save-inverted {
    color: grey;
    border: 1px solid grey;
    background-color: white;
}

.public-tool-promotion-calendar-modal-footer-save svg {
    font-size: 20px;
}

.public-tool-promotion-calendar-modal-footer-delete {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #aa3d3daa;
    color: white;
    transition: all 0.2s;
}

.public-tool-promotion-calendar-modal-footer-delete:hover {
    background-color: #aa3d3dc1;
}

.public-tool-promotion-calendar-modal-text-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    box-sizing: border-box;
    margin-top: 36px;
    gap: 18px;
}

.public-tool-promotion-calendar-modal-text-button {
    width: 36px;
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}


@keyframes pulse {
    0% {
      transform: scale(1);
      background-color: #eee;
    }
    50% {
      transform: scale(0.9);
      background-color: #3d72aa32; /* Change to your preference */
    }
    100% {
      transform: scale(1);
      background-color: #eee;
    }
}
  
.button-animate {
    animation: pulse 0.5s ease;
}


.public-tool-promotion-calendar-modal-text-button:hover {
    background-color: rgb(234, 234, 234);
}

.public-tool-promotion-calendar-modal-text-button svg {
    font-size: 24px;
    color: grey;
}

.public-tool-promotion-calendar-modal-edits textarea {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 14px;
    padding-right: 56px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.8;
    resize: none;
    overflow-y: auto;
    width: 100%;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.public-tool-promotion-calendar-modal-edits input {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.public-tool-promotion-calendar-modal-edits-label {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
}

.public-tool-promotion-calendar-modal-edits-label svg {
    font-size: 20px;
    color: #3b3b3b;
}

.public-tool-promotion-calendar-modal-edits-label:first-of-type {
    margin-top: 0px;
}

.public-tool-promotion-calendar-list-task-container {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    cursor: pointer;
    background-color: #ffffff;
}

.public-tool-promotion-calendar-list-task-titles {
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
}

.public-tool-promotion-calendar-list-task-titles h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    line-height: 18px;
    color: #344563;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 6px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(187, 186, 186);
}

.public-tool-promotion-calendar-list-task-titles h3:hover {
    text-decoration: underline;
}

.public-tool-promotion-calendar-list-task-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 100px;
    padding-bottom: 20px;
    padding-left: 12px;
    border-bottom: 1px solid rgb(187, 186, 186);
}

.public-tool-promotion-calendar-list-task-meta svg {
    font-size: 14px;
}

.public-tool-promotion-calendar-list-task-meta span {
    font-size: 13px;
    font-weight: 500;
    width: 88px;
    text-align: right;
}

.public-tool-promotion-calendar-list-task-title-color {
    height: 14px;
    width: 14px;
    min-width: 14px;
}

.public-tool-promotion-calendar-wizard {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.public-tool-promotion-calendar-wizard-section {
    border: 1px solid #ddd;
    border-radius: 6px;
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.public-tool-promotion-calendar-wizard-section h4 {
    font-size: 18px;
    /* color: rgb(54, 54, 54); */
    font-weight: 600;
    padding: 6px 2px;
    box-sizing: border-box;
    margin: 0;
}

.public-tool-promotion-calendar-wizard-question {
    font-size: 13px;
    font-weight: 700;
    color: rgb(54, 54, 54);
    margin-bottom: 12px;
}

.public-tool-promotion-calendar-wizard-collection-row-col {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
}

.public-tool-promotion-calendar-wizard-answer-radios {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.public-tool-promotion-calendar-wizard-answer-radio {
    display: flex;
    align-items: center;
    gap: 28px;
    font-size: 14px;
    font-weight: 500;
}

.public-tool-promotion-calendar-wizard-answer-radio-button {
    height: 16px;
    width: 16px;
    min-width: 16px;
    border-radius: 4px;
    border: 1.5px solid rgb(206, 206, 206);
    background-color: white;
    cursor: pointer;
}

.public-tool-promotion-calendar-wizard-answer-radio-button:hover {
    background-color: #2A73AE;
}

.public-tool-promotion-calendar-wizard-subsection input {
    font-size: 14px;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 6px;
    border: 1px solid rgba(164, 164, 164, 1);
}


.public-tool-promotion-calendar-wizard-subsection textarea {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 14px;
    padding-right: 56px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.8;
    resize: none;
    overflow-y: auto;
    width: 100%;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.public-tool-promotion-calendar-wizard-submit-button {
    padding: 12px 20px;
    border-radius: 24px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    background-color: #2A73AE;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 14px;
}

.public-tool-promotion-calendar-wizard-disclaimer {
    font-size: 12px;
    color: grey;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.public-tool-promotion-calendar-wizard-final-confirmation-page {
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.public-tool-promotion-calendar-wizard-final-confirmation-page .public-tool-promotion-calendar-wizard-question {
    font-size: 16px;
    text-align: center;
}

.public-tool-promotion-calendar-wizard-new-account {
    background-color: #2A73AE;
    color: white;
    padding: 12px 20px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.public-tool-promotion-calendar-wizard-calendar-mobile {
    display: none;
}

.public-tool-promotion-calendar-wizard-subsection .modal-mgmt-section input[type=text], .modal-mgmt-select-dropdown-mode-display,
.public-tool-promotion-calendar-wizard-subsection .modal-mgmt-select-dropdown-option,
.public-tool-promotion-calendar-wizard-answer-radio {
    font-size: 14px;
}

.public-tool-promotion-calendar-responsive {
    display: none;
}

.public-tool-promotion-calendar-responsive h2 {
    font-size: 22px;
    font-weight: 700;
    color: rgb(47, 47, 47);
    margin: 0;
    margin-bottom: 4px;
}

@media screen and (max-width: 1030px) {
    .tools-generic-header-container {
        padding-top: 20px;
        padding-bottom: 12px;
        flex-direction: column-reverse;
        align-items: center;
    }

    .tools-generic-header-logo {
        height: 32px;
        box-sizing: border-box;
        padding: 0px;
        padding-right: 0px;
        padding-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px;
    }
    
    .tools-generic-header-logo svg {
        height: 24px;
    }

    .tools-generic-header-tagline {
        display: none;
    }

    .public-tool-promotion-calendar-wizard-collection-row-col {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 20px;
    }

    .tools-generic-header-icon {
        display: none;
    }

    .tools-generic-header-title {
        font-size: 16px;
        align-items: center;
        justify-content: center;
    }

    .tools-generic-header-subtitle {
        font-size: 11px;
        text-align: center;
    }

    .tools-generic-header-title-suffix {
        font-size: 11px;
    }

    .tools-generic-header-container-titles {
        width: 100%;
        display: none;
    }

    .tools-generic-header-container-titles-inner {
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .public-tool-promotion-calendar-responsive-heading {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }

    .public-tool-promotion-calendar-responsive-heading h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
    }

    .public-tool-promotion-calendar-responsive-heading p {
        font-size: 13px;
        color: rgb(27, 27, 27);
        line-height: 1.6;
        margin: 0;
        text-align: center;
    }

    .public-tool-promotion-calendar-wizard-calendar-desktop {
        display: none;
    }

    .public-tool-promotion-calendar-wizard-section h4 {
        font-size: 16px;
    }

    .public-tool-promotion-calendar-wizard-calendar-mobile {
        display: block;
    }

    .public-tool-promotion-calendar-wizard-subsection textarea,
    .public-tool-promotion-calendar-wizard-subsection input {
        font-size: 16px;
    }

    .public-tool-promotion-calendar-wizard-disclaimer {
        padding: 0px 12px;
        box-sizing: border-box;
        font-size: 10px;
        line-height: 1.5;
    }

    .public-tool-promotion-calendar-wizard-subsection .modal-mgmt-section input[type=text], .modal-mgmt-select-dropdown-mode-display,
    .public-tool-promotion-calendar-wizard-subsection .modal-mgmt-select-dropdown-option,
    .public-tool-promotion-calendar-wizard-answer-radio {
        font-size: 16px;
    }

    .public-tool-promotion-calendar-main {
        display: none;
    }

    .public-tool-promotion-calendar-responsive {
        display: flex;
        font-size: 14px;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        width: 80%;
        margin: 20% auto;
    }
}

.public-tool-promotion-calendar-wizard-loader {
    width: 80px;
    aspect-ratio: 1.154;
    clip-path: polygon(50% 0,100% 100%,0 100%);
    --c:no-repeat linear-gradient(#174061 0 0);
    background: var(--c),var(--c),var(--c),var(--c),var(--c);
    background-size: 100% calc(100%/5 + 1px);
    animation: l15 4s infinite;
  }
  @keyframes l15 {
    0%  {background-position: 0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
    20% {background-position: 0 calc(4*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
    40% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
    60% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4)}
    80% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(1*100%/4) ,0 calc(-2*100%/4)}
    100%{background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(1*100%/4) ,0 calc(0*100%/4)}
}

.tools-loading-loader {
    width: 60px;
    aspect-ratio: 1;
    --c:no-repeat linear-gradient(#174061 0 0);
    background:
      var(--c) 0 38px,
      var(--c) right 18px bottom 18px,
      var(--c) right 18px top    18px,
      var(--c) 18px 18px,
      var(--c) 18px 100%,
      var(--c) 100% 100%,
      var(--c) 100% 0,
      var(--c) 0 0;
    animation: 
      l8-1 4s infinite,
      l8-2 4s infinite;
}
@keyframes l8-1 {
    0%     {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   }
    6.25%  {background-size: 40px 4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   }
    12.5%  {background-size: 40px 4px,4px 20px,0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   }
    18.75% {background-size: 40px 4px,4px 20px,20px 4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   }
    25%    {background-size: 40px 4px,4px 20px,20px 4px,4px 40px,0    4px,4px 0   ,0    4px,4px 0   }
    31.25% {background-size: 40px 4px,4px 20px,20px 4px,4px 40px,40px 4px,4px 0   ,0    4px,4px 0   }
    37.5%  {background-size: 40px 4px,4px 20px,20px 4px,4px 40px,40px 4px,4px 100%,0    4px,4px 0   }
    43.75% {background-size: 40px 4px,4px 20px,20px 4px,4px 40px,40px 4px,4px 100%,100% 4px,4px 0   }
    48%,
    52%    {background-size: 40px 4px,4px 20px,20px 4px,4px 40px,40px 4px,4px 100%,100% 4px,4px 40px}
    56.25% {background-size: 0    4px,4px 20px,20px 4px,4px 40px,40px 4px,4px 100%,100% 4px,4px 40px}
    62.5%  {background-size: 0    4px,4px 0   ,20px 4px,4px 40px,40px 4px,4px 100%,100% 4px,4px 40px}
    68.75% {background-size: 0    4px,4px 0   ,0    4px,4px 40px,40px 4px,4px 100%,100% 4px,4px 40px}
    75%    {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,40px 4px,4px 100%,100% 4px,4px 40px}
    81.25% {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 100%,100% 4px,4px 40px}
    87.5%  {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   ,100% 4px,4px 40px}
    93.75% {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 40px}
    100%   {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   ,0    4px,4px 0   }
}
@keyframes l8-2 {
0%,49.9% {background-position:0 38px, right 18px bottom 18px, right 18px top 18px,18px 18px,18px 100%,100% 100%,100% 0,0 0}
50%,100% {background-position:right 18px bottom 18px, right 18px top 18px, 18px 18px,18px 100%,100% 100%,100% 0,0 0,bottom 18px left 0}
}

/* PRICING */

.landing-pricing h2 {
    text-align: center;
    font-size: 48px;
    font-weight: 800;
    color: var(--cp-color-dark);
    margin-top: 2vh;
    margin-bottom: 0;
    line-height: 1.2;
}
  
.landing-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}
  
.landing-plans-prices {
    margin-top: 24px;
    display: flex;
    gap: 24px;
    justify-content: center;
    width: 100%;
}
  
.pricing-plan {
    padding: 24px;
    border: 2px solid #ddd;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    flex-basis: 30%;
    min-width: 200px;
    max-width: 320px;
}
  
.pricing-plan.featured {
    border: 2px solid #174061;
}
  
.pricing-plan-badge-featured {
    position: absolute;
    background-color: white;
    left: calc(50% - 80px);
    border-radius: 4px;
    width: 160px;
    top: -12px;
    color: #174061;
    font-weight: 800;
    border: 2px solid #174061;
}
  
.pricing-plan-header {
    margin-bottom: 4px;
}
  
.pricing-plan-name {
    font-size: 20px;
    font-weight: 700;
    color: #174061;
}
  
.pricing-plan-subtitle {
    font-size: 14px;
    margin-top: 4px;
    color: grey;
    height: 42px;
}
  
.pricing-plan-cost {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 8px;
    margin-top: 8px;
    color: var(--t-color-dark);
}
  
.pricing-plan-cost-amount {
    font-size: 28px;
    font-weight: bold;
    color: var(--cp-color-dark);
}
  
.pricing-plan-cost-period {
    font-size: 16px;
    color: grey;
}
  
.pricing-plan-description {
    font-size: 14px;
    color: grey;
    font-weight: 600;
    border-bottom: 1px solid grey;
    padding-bottom: 8px;
    margin-bottom: 16px;
    text-transform: uppercase;
}
  
.pricing-plan-benefits {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    text-align: left;
}
  
.pricing-plan-benefits span {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--t-color-dark);
}
  
.pricing-plan-benefits span svg {
    color: #174061;
}
  
  .pricing-plan-cta {
    background-color: #2A73AE;
    color: white;
    margin-top: 20px;
    padding: 18px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    border: 1px solid #2A73AE;
}

.pricing-plan-cta-inverse {
    background-color: white;
    color: #2A73AE;
    cursor: default;
}

@media screen and (max-width: 1300px) {
    .landing-plans-prices {
        flex-wrap: wrap;
    }

    .pricing-plan {
        flex-basis: 40%;
    }
}

@media screen and (max-width: 800px) {
    .landing-plans-prices {
        flex-direction: column;
        width: 95%;
        align-items: center;
    }

    .pricing-plan {
        width: 100%;
        box-sizing: border-box;
        max-width: 360px;
    }
}


/* FAQ */
.faq-section {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    }

.faq-item {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.faq-question {
    font-size: 18px;
    font-weight: 600;
    color: var(--cp-color-dark);
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    padding: 0 0;
    color: var(--cp-color-dark);
}

.faq-answer.expanded {
    max-height: 500px; /* Adjust based on your content */
    padding: 10px 0;
}

.faq-answer span {
    font-size: 16px;
    line-height: 1.6;
}

/* FOUNDERS NOTE */
.landing-founders-note-container {
    --landing-founders-headshot-size: 240px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 80px;
    box-sizing: border-box;
    background-color: rgb(248, 248, 248);
    margin-bottom: 120px;
}

.landing-founders-note-inner-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 80px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.landing-founders-note-headshot {
    width: var(--landing-founders-headshot-size);
    min-width: var(--landing-founders-headshot-size);
    height: var(--landing-founders-headshot-size);
    border-radius: calc(var(--landing-founders-headshot-size) / 2);
    overflow: hidden;
    margin-bottom: 12px;
}

.landing-founders-note-headshot img {
    width: 100%;
    max-width: 100%;
}

.landing-founders-note-headshot-section {
    display: flex;
    flex-direction: column;
}

.landing-founders-note-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.landing-founders-note-content p {
    font-size: 18px;
    font-weight: 400;
    color: rgb(69, 69, 69);
    margin: 0;
    line-height: 1.6;
}

.landing-founder-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--t-color-dark);
}

.landing-founder-information h5 {
    margin: 0;
    font-size: 18px;
}

.landing-founder-information p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

.landing-founder-information-socials {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-top: 12px;
}

.landing-founder-information-socials a {
    height: 24px;
    width: 24px;
    min-width: 24px;
    color: rgb(68, 68, 68);
}

.landing-founder-information-socials a svg {
    font-size: 20px;
}

@media screen and (max-width: 1000px) {
    .landing-founders-note-inner-container {
        flex-direction: column-reverse;
    }

    .landing-founders-note-headshot {
        margin-bottom: 0px;
    }

    .landing-founders-note-headshot-section {
        flex-direction: row;
        gap: 80px;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .landing-founder-information {
        align-items: flex-start;
        flex-grow: 1;
    }

    .landing-founder-information h5 {
        font-size: 24px;
    }
}

/* TOGGLE */
.pricing-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .toggle-label {
    font-size: 14px;
    color: #333;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #174061;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  
  .save-label {
    background-color: #333;
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
  }