.table-card-heading {
    margin-left: 24px;
    margin-top: 12px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 24px;
}

.table-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-bottom: 20px;
}

.table-card-single {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 24px;
    height: 120px;
    width: 340px;
    padding: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid rgb(211, 211, 211);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: white;
}

.table-card-name {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 24px;
    color:rgb(52, 105, 148);
}

.table-card-create-new-workspace {
    background-color: rgb(52, 105, 148);
    font-size: 14px;
    display: flex;
    font-weight: 500;
    justify-content: center;
    border-radius: 14px;
    padding: 4px 24px;
    color: white;
    cursor: pointer;
}

.table-card-badge-archived {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 2px 6px;
    border-radius: 16px;
    background-color: rgb(255, 140, 105);
    color: white;
    margin-left: 8px;
}

.table-card-name:hover {
    cursor: pointer;
    color: rgb(86, 148, 199);
}

.table-card-button {
    padding: 8px;
    border: 1px solid rgb(52, 105, 148);
    border-radius: 4px;
    color: rgb(52, 105, 148);
    transition: all 0.2s;
}

.table-card-button:hover {
    cursor: pointer;
    background-color: rgba(52, 105, 148, 0.09);
}

.table-main-row {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.table-main-row-header {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 12px;
}

.table-main-row.table-main-row-header span {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
}

.table-main-row span {
    font-size: 14px;
}

.table-main-col-5 { flex-basis: 5%; }
.table-main-col-10 { flex-basis: 10%; }
.table-main-col-15 { flex-basis: 15%; }
.table-main-col-20 { flex-basis: 20%; }
.table-main-col-30 { flex-basis: 30%; }
.table-main-col-40 { flex-basis: 40%; }
.table-main-col-50 { flex-basis: 50%; }
.table-main-col-60 { flex-basis: 60%; }
.table-main-col-70 { flex-basis: 70%; }
.table-main-col-80 { flex-basis: 80%; }
.table-main-col-main { flex-grow: 1 }

.table-main-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4px;
    padding-right: 4px;
}

.table-main-row-content {
    border-bottom: 1px solid #d8d8d8;
}

.table-main-row-content .table-main-col {
    padding-top: 6px;
    padding-bottom: 6px;
}

.table-main-row-content-clickable {
    cursor: pointer;
}

.table-main-row-content-clickable:hover {
    background-color: rgb(251, 251, 251);
}