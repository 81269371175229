.application-feedback-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.application-feedback-banner {
    margin-top: 48px;
    background-color: rgb(153,0,0);
    color: white;
    font-size: 16px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 1.8em;
    box-sizing: border-box;
    width: 100%;
}

.application-feedback-width-control {
    width: 800px;
}

.application-feedback-headers {
    border-bottom: 1px solid lightgrey;
}

.application-feedback-section {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 24px;
}

.application-feedback-pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

.application-feedback-pills div {
    margin: 4px 8px 8px 0px;
    font-weight: 500;
    width: 120px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.application-feedback-pills div:hover {
    filter: brightness(90%);
    cursor: pointer;
}

.application-feedback-section textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 8em;
    margin-bottom: 0px;
    border-radius: 0px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    font-size: 16px;
    resize: vertical;
    outline: none;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.application-feedback-subtitle {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    font-style: italic;
}

.application-feedback-submissions {
    margin-top: 24px;
}

.application-feedback-submission-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    font-weight: 700;
    transition: all 0.2s;
}

.application-feedback-submission-button-nope {
    background-color: rgb(95, 108, 119);
}

.application-feedback-submission-button-nope:hover {
    cursor: not-allowed;
}

.application-feedback-submission-button-ready {
    background-color: rgb(23, 64, 97);
}

.application-feedback-submission-button-ready:hover {
    cursor: pointer;
    background-color: rgb(29, 104, 165);
}

/* Pill Colors */

.application-feedback-pill-optimistic {
    background-color: rgb(41, 115, 175);
    color: white;
}

.application-feedback-pill-confused {
    background-color: rgb(152, 95, 167);
    color: white;
}

.application-feedback-pill-angry {
    background-color: #fc636b;
    color: white;
}

.application-feedback-pill-inspired {
    background-color: #049921;
    color: white;
}

.application-feedback-pill-special {
    background-color: rgb(102, 102, 102);
    color: white;
}

.application-feedback-pill-selected {
    border: 4px solid #000000;
    font-weight: 700 !important;
}

/* End Pill Colors */

.application-feedback-instructions-modal {
    position: relative;
}

.application-feedback-instructions-message-container {
    background-color: rgba(0, 0, 0, 0.412);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.application-feedback-instructions-message {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 48px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid gold;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.application-feedback-instructions-message h2 {
    border-bottom: 1px solid grey;
    padding-bottom: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.application-feedback-instructions-message-width-control {
    max-width: 480px;
}

.application-feedback-gratitude {
    text-align: center;
    font-weight: 300;
    font-size: 48px;
    margin-top: 32px;
}

/* Tray Items */
.user-tray-container {
    position: relative;
    z-index: 100;
}

.user-tray {
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    gap: 32px;
    align-items: flex-end;
    bottom: 12px;
    height: 52px;
    right: 12px; /* left sidebar is 240px wide !*/
}

.user-tray-fixed-items,
.user-tray-floating-items {
    opacity: 0.7;
    transition: all 0.5s;
}

.user-tray-right-side-column {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    align-items: center;
}

.user-tray-fixed-items {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    gap: 32px;
    align-items: center;
    background-color: rgb(226, 226, 226);
    color: rgb(125, 145, 161);
    border-radius: 6px;
    padding: 8px 20px;
}

.user-tray-floating-items {
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 6px;
}

.user-tray-fixed-items:hover,
.user-tray-floating-items:hover {
    opacity: 1;
}

.user-tray-symbol {
    padding: 4px;
    font-size: 24px;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    transition: all 0.3s;
}

.user-tray-floating-symbol {
    background-color: rgb(255, 255, 255);
    color: rgb(125, 145, 161);
    padding: 4px;
    font-size: 24px;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.user-tray-symbol span {
    font-size: 9px;
    text-transform: uppercase;
}

.user-tray-symbol:hover,
.user-tray-floating-symbol:hover {
    background-color: grey;
    cursor: pointer;
    color: rgb(241, 241, 241);
}

.user-trays {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    gap: 12px;
}

.user-tray-display-card-container {
    width: 320px;
    padding-bottom: 8px;
    border-radius: 8px 8px 0px 0px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.user-tray-display-card-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(240, 240, 240);
}

.user-tray-display-action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: 12px;
    margin-right: 4px;
}

.user-tray-display-action {
    border-radius: 50%;
    padding: 4px;
    color: rgb(61, 61, 61);
    font-size: 13px;
    transition: all 0.2s;
}

.user-tray-display-action:hover {
    cursor: pointer;
    background-color: rgb(223, 223, 223);
}

.user-tray-display-card h3,
.user-tray-display-card p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.user-tray-display-card h3 {
    font-weight: 600;
    flex-grow: 1;
    font-size: 16px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    border-radius: 4px;
    padding-left: 4px;
    margin-left: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: all 0.3s;
}

.user-tray-display-card h3:hover {
    background-color: rgb(223, 223, 223);
    cursor: pointer;
}

.user-tray-display-card-main {
    padding-left: 6px;
    padding-right: 6px;
}

.user-tray-display-card-status-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
}

.user-tray-display-card-attribute-section {
    margin-top: 6px;
    margin-bottom: 16px;
}

.user-tray-display-card-attribute-label {
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(63, 63, 63);
}

/* Basic Global Items */
.basic-row-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Privacy Policy */
.privacy-policy {
    padding: 0px 12px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    margin-bottom: 120px;
    margin-top: 60px;
}

.privacy-policy-section {
    margin-top: 12px;
    margin-bottom: 24px;
}

.privacy-policy p,
.privacy-policy li {
    font-size: 14px;
    line-height: 20px;
}

.privacy-policy p {
    margin-bottom: 16px;
    line-height: 20px;
}

/* USER WORKSPACES PAGE */
.user-workspaces-main {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    max-width: 800px;
}

.user-workspaces-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.user-workspaces-search-container {
    position: relative;
    height: 48px;
    flex-grow: 1;
}

.user-workspaces-search-container input {
    height: 100%;
    box-sizing: border-box;
    border: none;
    width: 100%;
    padding-left: 36px;
    font-size: 14px;
    background-color: #F2F2F2;
    border-radius: 4px;
}

.user-workspaces-search-container svg {
    position: absolute;
    left: 8px;
    color: grey;
    top: 11px;
    font-size: 18px;
}

.user-workspaces-create-new-workspace {
    min-width: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
}

.user-workspaces-create-new-workspace svg {
    color: #2A73AE;
    font-size: 32px;
}

.user-workspaces-section {
    margin-top: 48px;
}

.user-workspaces-section:last-of-type {
    margin-bottom: 48px;
}

.user-workspaces-section h2 {
    margin: 0;
    font-size: 20px;
    margin-bottom: 20px;
}

.user-workspaces-empty-action-text {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
}

.user-workspaces-empty-action-text span {
    font-size: 14px;
    text-decoration: underline;
    color: #2A73AE;
    cursor: pointer;
}

.user-workspaces-workspace-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-workspaces-workspace-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.user-workspaces-nav-item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

.user-workspaces-nav-primary {
    display: flex;
    flex-direction: row;
    gap: 14px;
    justify-content: flex-start;
    align-items: stretch;
    flex-grow: 1;
    cursor: pointer;
}

.user-workspace-nav-network-decorator {
    font-size: 13px;
    color: rgb(139, 152, 165);
    font-weight: 400;
}

.user-workspaces-nav-symbol {
    height: 48px;
    width: 48px;
    min-width: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-workspaces-nav-symbol.messages-room-nav-symbol {
    border-radius: 50%;
}

.user-workspaces-nav-symbol svg {
    color: rgba(135, 135, 135, 0.574);
    font-size: 24px;
}

.user-workspaces-nav-symbol.messages-room-nav-symbol svg {
    color: white;
}

.user-workspaces-nav-information {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 4px;
}

.user-workspaces-nav-information h3 {
    margin: 0;
    font-size: 16px;
}

.user-workspaces-nav-information div {
    font-size: 14px;
    color: grey;
}

.user-workspaces-nav-pinned {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    cursor: pointer;
    position: absolute;
    bottom: 4px;
    right: 4px;
}

.user-workspaces-nav-pinned svg {
    font-size: 20px;
    color: #2A73AE;
}

.user-workspaces-nav-item-grid {
    flex-basis: 45%;
    margin-bottom: 20px;
    height: 160px;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer;
    transition: all 0.3s;
    min-width: 240px;
}

.user-workspaces-nav-item-grid:hover {
    box-shadow: rgba(67, 71, 85, 0.33) 0px 0px 0.4em, rgba(90, 124, 188, 0.149) 0px 0.4em 1.5em;
}

.user-workspaces-nav-item-grid-workspace-information {
    height: 80px;
    background-color: white;
    display: flex;
    flex-direction: column-reverse;
    padding: 8px;
    box-sizing: border-box;
    position: relative;
}

.user-workspaces-nav-item-grid-workspace-information h3 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 4px;
}

@media screen and (max-width: 600px) {
    .user-workspaces-workspace-grid {
        flex-direction: column;
    }

    .user-workspaces-nav-item-grid {
        flex-basis: 80%;
        min-height: 200px;
    }
}

/* SCROLLBAR */
.app-thin-scrollbar::-webkit-scrollbar {
    width: .3em;
}
  
.app-thin-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
}

.app-thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(23, 64, 97);
    outline: none;
}

.app-thin-scrollbar::-webkit-scrollbar {
    height: 0.3em;
}

/* BEHAVIOR */
body.app-scroll-prevention {
    overflow: hidden !important;
}