#sidebar-left,
#sidebar-left-collapsed {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    border-right: 1px solid rgb(228, 228, 228);
}

#sidebar-left {
    width: 240px;
    position: fixed;
    top: 48px;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: 100;
    overflow-x: hidden;
    transition: transform 0.1s;
    padding-bottom: 120px;
}

.sidebar-documentation-button {
    position: fixed;
    bottom: 0;
    /* height: 40px; */
    width: 239px;
    z-index: 2;
    background-color: white;
    left: 0;
    right: 0;
    border-right: 1px solid rgb(228, 228, 228);
}

.sidebar-documentation {
    margin: 4px;
    border-radius: 4px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    color: rgb(83, 83, 83);
}

.sidebar-documentation:hover {
    background-color: rgb(214, 214, 214);
}

.sidebar-pro-upgrade-button {
    font-weight: 600;
    /* background-color: rgba(255, 185, 1, 1); */
    background-repeat: no-repeat;
    background: linear-gradient(90deg, rgba(255, 185, 1, 1) 17%, #ffffff 17%);
    /* background-image: linear-gradient(to right, rgb(235, 189, 71), rgba(255, 185, 1, 1), rgba(255, 185, 1, 1), rgb(245, 195, 69), rgb(233, 204, 132), rgb(241, 218, 160)); */
    color: rgb(87, 87, 87);

}

.sidebar-pro-upgrade-button svg {
    margin-right: 14px;
}

.sidebar-pro-upgrade-button:hover {
    text-decoration: underline;
    /* background: unset;
    background-color: rgba(255, 185, 1, 1); */
}

.sidebar-move-left {
    transform: translateX(-240px); /* Important that this respects the width of .sidebar-show-button + #sidebar-left*/
}

.sidebar-hamburger-show-container {
    position: fixed;
    bottom: 12px;
    left: 12px;
    z-index: 100;
    --sidebar-hamburger-dimension: 48px;
}

.sidebar-hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 48px;
    width: var(--sidebar-hamburger-dimension);
    min-width: var(--sidebar-hamburger-dimension);
    height: var(--sidebar-hamburger-dimension);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.sidebar-hamburger svg {
    font-size: 24px;
    color: #174061;
}

.sidebar-show-button {
    width: 40px;
    position: absolute;
    right: 0;
    transition: all 0.2s;
}

.sidebar-show-button:hover {
    cursor: pointer;
}

.sidebar-left-title-container .sidebar-left-collapse-button {
    opacity: 0;
    transition: opacity 0.3s;
    visibility: hidden;
}

.sidebar-menu-icon {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    margin-left: 4px;
    margin-bottom: 8px;
    color: rgb(80, 80, 80);
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.sidebar-left-title-container:hover .sidebar-left-collapse-button {
    visibility: visible;
    opacity: 1;
}

.sidebar-menu-icon span {
    margin-left: 4px;
}

.sidebar-menu-icon:hover {
    cursor: pointer;
    background-color: lightgrey;
}

.sidebar-left-title {
    padding-top: 4px;
    margin-left: 4px;
    text-transform: uppercase;
    font-size: 10px;
    text-align: left;
    font-weight: bold;
}

.sidebar-project {
    box-sizing: border-box;
    height: 24px;
    padding-left: 4px;
    padding-right: 4px;
}

.sidebar-project-component-item {
    box-sizing: border-box;
    height: 24px;
    padding-left: 8px;
    transition: all 0.4s;
}

.sidebar-project-component-item .sidebar-font {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebar-project-component-item .sidebar-font:hover {
    cursor: pointer;
    text-decoration: underline;
}

.sidebar-project-link {
    text-decoration: none;
    color: black;
}

.sidebar-project p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.4s;
}

.sidebar-project p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.sidebar-project-symbol {
    margin-right: 4px;
    font-size: 10px;
}

.sidebar-desktop-only {
    display: none;
}

.sidebar-font {
    font-size: 12px;
}

.sidebar-general-item {
    margin-left: 4px;
    margin-bottom: 4px;
}

.sidebar-general-text {
    font-style: italic;
    font-size: 10px;
}

#create-project-button {
    text-align: center;
    padding-left: 6px;
    font-size: 12px;
    white-space: pre-wrap;
    text-decoration: none;
    color: rgb(119, 119, 119);
    text-align: center;
    transition: all 0.4s;
}

#create-project-button:hover {
    text-decoration: underline;
}

.sidebar-button-new-item {
    border: 1px solid rgb(230, 230, 230);
    padding-bottom: 8px;
    padding-top: 8px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    border-radius: 8px;
    text-transform: uppercase;
    background-color: #2973af;
    color: white;
    font-weight: 600;
    transition: all 0.4s;
    margin-top: 20px;
}

.sidebar-button-new-item:hover {
    cursor: pointer;
    background-color: rgb(56, 108, 150);
}

.sidebar-component-nav-container {
    margin-top: 8px;
    margin-left: 4px;
}

.sidebar-project-component-left-graphic {
    font-size: 8px;
    margin-right: 4px;
}

.sidebar-project-component-name {
    text-transform: capitalize;
    margin-bottom: 4px;
    font-size: 12px;
    transition: all 0.2s;
}

.sidebar-project-component-name:hover {
    cursor: pointer;
    color: rgb(23, 64, 97);
}

.sidebar-left-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sidebar-left-title-container h2 {
    margin-top: 0px;
    margin-bottom: 12px;
    font-size: 12px;
    color: rgb(80, 80, 80);
}

/* Sidebar Revamp */
.sidebar-left-section-workspace-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.sidebar-left-section-workspace-navigation-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    border-top: 1px solid rgb(209, 209, 209);
    font-size: 14px;
    padding-top: 6px;
    margin-bottom: 6px;
}

.sidebar-left-section-workspace-navigation-container-collapsible {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    transition: max-height 250ms cubic-bezier(0.4, 0.0, 0.2, 1);
    max-height: 400px;
}

.sidebar-left-section-workspace-navigation-container-collapsible-collapsed {
    transition: max-height 250ms cubic-bezier(0.4, 0.0, 0.2, 1);
    max-height: 0;
}

.sidebar-left-section-workspace-navigation-container-toggle-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4px;
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: border-box;
}

.sidebar-left-section-workspace-navigation-container-toggle-button span {
    font-size: 12px;
    color: grey;
    cursor: pointer;
}

.sidebar-left-section-workspace-navigation-container-toggle-button span:hover {
    text-decoration: underline;
}

.sidebar-left-section-workspace-navigation-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    border-radius: 4px;
    padding: 3px 0px;
    cursor: pointer;
    transition: all 0.1s;
    font-size: 14px;
    font-weight: 500;
    color: rgb(83, 83, 83);
}

.sidebar-left-section-workspace-navigation-item.sidebar-left-section-workspace-navigation-item-informational {
    cursor: auto;
}

.sidebar-left-section-workspace-navigation-item.sidebar-left-section-workspace-navigation-item-informational:hover {
    background-color: white;
}

.sidebar-left-section-workspace-navigation-item span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* font-weight: 500; */
}

.sidebar-left-section-workspace-navigation-item:hover {
    background-color: rgb(216, 216, 216);
}

.sidebar-left-section-workspace-navigation-item svg {
    min-width: 24px;
    color: rgb(133, 133, 133);
    font-size: 16px;
}

.sidebar-left-section-workspace-main {
    border-top: 1px solid rgb(167, 167, 167);
}

.sidebar-left-section-workspace-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.sidebar-left-section-workspace-name h2 {
    margin: 0px;
    font-size: 15px;
    font-weight: 600;
}

.sidebar-left-section-workspace-subtitle {
    color: grey;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    font-size: 13px;
    cursor: pointer;
}

.sidebar-left-section-user {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebar-left-section-user-name {
    font-size: 16px;
    font-weight: 700;
}

.sidebar-left-section-user-handle {
    font-size: 14px;
    font-weight: 300;
}

.sidebar-left-section-user-name:hover,
.sidebar-left-section-user-handle:hover {
    cursor: pointer;
}

.sidebar-left-section-footer {
    padding-top: 12px;
    margin-top: 8px;
    border-top: 1px solid rgb(167, 167, 167);
    font-size: 12px;
}

.sidebar-left-section {
    margin-top: 12px;
    color: #4F5D74;
}

.sidebar-left-section-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    margin-top: 28px;
    padding-left: 4px;
    margin-bottom: 4px;
}

.sidebar-left-section-heading-hoverable {
    cursor: pointer;
}

.sidebar-left-section-heading-hoverable:hover {
    background-color: rgb(167, 167, 167);
}

.sidebar-left-section-heading-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebar-left-section-heading-icon {
    font-size: 20px;
    margin-right: 8px;
    width: 20px;
}

.sidebar-left-section-items {
    /* margin-left: 28px; THIS SHOULD MATCH THE SUM OF THE ICON (ABOVE) MARGIN AND WIDTH */
    /* border-left: 2px solid rgb(128, 128, 128); */
    padding-top: 4px;
    padding-bottom: 4px;
    /* padding-left: 12px; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sidebar-left-section-item {
    position: relative;
    font-weight: 500;
    padding-left: 4px;
    padding-top: 2px;
    padding-right: 4px;
    padding-bottom: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    transition: all 0.2s;
}

.sidebar-left-section-item .sidebar-left-section-item-tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 120%;
    padding: 4px;
    box-sizing: border-box;
    color: rgb(245, 245, 245);
    border-radius: 4px;
    background-color: rgb(39, 39, 39);
}

.sidebar-left-section-item:hover .sidebar-left-section-item-tooltip {
    visibility: visible;
    opacity: 1;
}

.sidebar-left-section-item:hover .sidebar-left-section-item-tooltip:hover {
    visibility: hidden;
}

.sidebar-left-section-item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar-left-section-item-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebar-left-section-item-progress {
    color: rgb(177, 177, 177);
    margin-left: 8px;
}

.sidebar-left-section-item-subtitle {
    font-size: 12px;
    padding-top: 4px;
    text-transform: uppercase;
    font-style: italic;
}

.sidebar-left-section-item:hover {
    background-color: rgb(104, 104, 104);
    color: white !important;
    cursor: pointer;
}

.sidebar-left-section-item-selected {
    background-color: rgb(48, 48, 48);
    color: white !important;
}

.sidebar-left-section-item-selected:hover {
    background-color: rgb(48, 48, 48);
    color: white !important;
}

.sidebar-left-section-heading-collapse-toggle-icon {
    font-size: 20px;
}

.sidebar-left-workspace-nav-dropdown-decoration {
    font-size: 14px;
    margin-top: 8px;
    font-weight: 700;
    color: #4F5D74;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 6px;
}

.sidebar-left-workspace-nav-dropdown-decoration svg {
    font-size: 16px;
    /* color: grey; */
    color: rgb(41, 115, 175);
}

.sidebar-left-network-nav-dropdown-decoration {
    font-size: 14px;
    color: rgb(71, 71, 71);
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 8px;
    font-weight: 500;
    background-color: rgb(241, 241, 241);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.8px 1.8px 2.4px;
}

.sidebar-left-workspace-nav-dropdown-container {
    position: relative;
    overflow: visible;
    /* margin-top: 8px;
    margin-bottom: 12px; */
}

.sidebar-left-workspace-nav-dropdown-see-more {
    margin-top: 8px;
    text-align: center;
    color: #2973af;
    cursor: pointer;
    font-size: 12px;
}

.sidebar-left-workspace-nav-dropdown-see-more:hover {
    text-decoration: underline;
}

.sidebar-left-workspace-nav-dropdown-workspace-name-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    border-radius: 6px;
    width: 100%;
    padding-left: 4px;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
}

.sidebar-left-workspace-nav-dropdown-workspace-name {
    /* color: #4F5D74; */
    color: #383838;
    font-size: 15px;
    flex-basis: 90%;
    overflow: hidden;
}

.sidebar-left-workspace-nav-dropdown-workspace-name p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 700;
}

.sidebar-left-workspace-nav-dropdown-workspace-name-group svg {
    /* color: #595959; */
    color: #383838;
    font-size: 20px;
    flex-basis: 10%;
}

.sidebar-left-workspace-nav-dropdown-workspace-dropdown-container {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    top: calc(100% + 4px);
    color: #4F5D74;
    border: 1px solid rgb(222, 222, 222);
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    z-index: 2;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sidebar-left-workspace-nav-dropdown-workspace-dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 12px;
    box-sizing: border-box;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.1s;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
}

.sidebar-left-workspace-nav-dropdown-workspace-dropdown-item:hover {
    background-color: #eceef1;
}

.sidebar-slim-section-title {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 4px;
}

.sidebar-slim-section-title p {
    margin: 0;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    color: rgb(124, 124, 124);
    padding-top: 4px;
    padding-bottom: 4px;
}

.sidebar-slim-section-title svg {
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    opacity: 0;
}

.sidebar-slim-section-title:hover.sidebar-slim-section-title svg {
    opacity: 1;
}

.sidebar-slim-section-title svg:hover {
    background-color: rgb(223, 223, 223);
}

.sidebar-slim-component-item-title-row {
    display: flex;
    font-size: 14px;
    gap: 4px;
    padding: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    cursor: pointer;
    transition: all 0.1s;
}

.sidebar-slim-component-item-title-row svg {
    color: grey;
    cursor: pointer;
    padding: 2px;
    border-radius: 4px;
}

.sidebar-slim-component-item-symbol svg {
    font-size: 18px;
}

.sidebar-slim-component-item-symbol svg {
    transition: transform 0.1s;
}

.sidebar-slim-component-item-symbol-rotated {
    transform: rotate(90deg);
}

.sidebar-slim-component-item-symbol-arrow {
    display: none;
}

.sidebar-slim-component-item-symbol-folder {
    display: block;
}

.sidebar-slim-component-item-title-row:hover .sidebar-slim-component-item-symbol-arrow {
    display: block;
}

.sidebar-slim-component-item-title-row:hover .sidebar-slim-component-item-symbol-folder {
    display: none;
}

.sidebar-slim-component-item-title-row svg:hover {
    background-color: rgb(207, 207, 207);
}

.sidebar-slim-component-item-title-text {
    font-size: 14px;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
    color: rgb(83, 83, 83);
}

.sidebar-slim-component-item-title-text svg {
    font-size: 18px;
    min-width: 18px;
    color: rgb(133, 133, 133);
}

.sidebar-slim-component-item-title-text svg:hover {
    background-color: unset;
}

.sidebar-slim-component-item-title-row:hover {
    /* background-color: rgb(240, 240, 240); */
    background-color: rgb(223, 223, 223);
}

.sidebar-slim-component-item-child {
    padding-left: 4px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */

    .sidebar-left-title {
        font-size: 14px;
    }

    .sidebar-menu-icon {
        font-size: 16px;
    }
    
    .sidebar-menu-icon span {
        margin-left: 6px;
    }

    .sidebar-desktop-only {
        display: none;
    }

    .sidebar-font {
        font-size: 14px;
    }

    .sidebar-project {
        height: 32px;
    }

    .sidebar-general-text {
        font-size: 12px;
    }

    .sidebar-project-component-name {
        margin-bottom: 4px;
    }

    .sidebar-project-component-left-graphic {
        font-size: 10px;
        margin-right: 8px;
    }

    .sidebar-left-title-container h2 {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .sidebar-left-title {
        font-size: 14px;
    }

    .sidebar-menu-icon {
        font-size: 20px;
    }
    
    .sidebar-menu-icon span {
        margin-left: 8px;
    }

    #sidebar-left-collapsed {
        padding-left: 8px;
    }

    .sidebar-desktop-only {
        display: inline;
    }

    .sidebar-font {
        font-size: 16px;
    }

    .sidebar-project {
        height: 40px;
    }

    .sidebar-project-component-name {
        margin-bottom: 4px;
    }

    .sidebar-project-component-item {
        height: 30px;
    }

    #create-project-button {
        font-size: 14px;
    }

    .sidebar-general-text {
        font-size: 14px;
    }

    .sidebar-left-title-container h2 {
        font-size: 20px;
    }
}

/* INTERACTIVE SIDEBAR */

#sidebar-left.interactive-sidebar {
    border-right: 1px solid transparent;
}

.interactive-sidebar {
    --interactive-sidebar-border-color: rgb(207, 207, 207);
    /* background-color: #d5d8dd69; */
    background-color: #d5d8dd71;
}

.interactive-sidebar-border-bottom {
    border-bottom: 1px solid var(--interactive-sidebar-border-color);
}

.interactive-sidebar-menu-floating {
    position: fixed;
    background-color: white;
    border: 1px solid rgb(247, 247, 247);
    border-radius: 4px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 160px;
    max-width: 200px;
    overflow: hidden;
    padding: 4px 0px;
}

.interactive-sidebar-menu-floating span {
    padding: 12px 8px;
    font-size: 12px;
    cursor: pointer;
    color: rgb(45, 45, 45);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.interactive-sidebar-menu-floating span svg {
    font-size: 14px;
}

.interactive-sidebar-menu-floating span:hover {
    background-color: #2a73ae18;
}

.interactive-sidebar-sections {
    display: flex;
    flex-direction: column;
}

.interactive-sidebar-section-bucket-name {
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(147, 147, 147);
    font-weight: 600;
    margin-top: 14px;
    padding-bottom: 4px;
    cursor: default !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    position: relative;
}

.interactive-sidebar-section-bucket-name.interactive-sidebar-section-bucket-first {
    margin-top: 0px;
    border-bottom: unset;
    padding-bottom: 0px;
    display: none;
}

.interactive-sidebar-section-bucket-toggle {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    transition: all 0.2s;
    transform: rotate(90deg);
    cursor: pointer;
}

.interactive-sidebar-section-bucket-toggle.open {
    transform: rotate(0deg);
}

.interactive-sidebar-section-bucket-toggle svg {
    font-size: 14px;
}

.interactive-sidebar-menu-floating.interactive-sidebar-section-bucket-menu-floating {
    position: absolute;
    top: 100%;
    left: 8px;
}

.interactive-sidebar-section-bucket-menu-floating span {
    padding: 12px 8px;
    font-size: 12px;
    cursor: pointer;
    color: rgb(45, 45, 45);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    text-transform: none;
}

.interactive-sidebar-section.is-dragging {
    /* background-color: #f0f0f0; */
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
  
.interactive-sidebar-components.is-dragging-over {
    background-color: #e0e0e0;
}

.interactive-sidebar-empty-bucket {
    min-height: 6px;
}

.sidebar-slim-components-list .interactive-sidebar-droppable-area {
    transition: background-color 0.2s ease;
}

.sidebar-slim-components-list .interactive-sidebar-draggable-item {
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.sidebar-slim-components-list .interactive-sidebar-draggable-item.interactive-sidebar-is-dragging {
    opacity: 0.5;
    transform: scale(1.05);
}