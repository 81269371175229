#project-container {
    display: flex;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.3s;
    margin-top: 48px; /* accounts for the header */
}

.project-container-sidebar-hidden {
    margin-left: 0px !important;
}

#project-header-container {
    height: 48px;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgb(228, 228, 228);
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 4px;
    padding-right: 16px;
}

#project-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.workspace-header-left-new-button {
    color: white;
    font-size: 44px;
    font-weight: 400;
    height: 68px;
    width: 68px;
    background-color: rgb(23, 64, 97);
    display: flex;
    margin-right: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: fixed;
    right: 2%;
    bottom: 4%;
    transition: all 0.4s;
}

.workspace-header-left-new-button:hover {
    background-color: rgb(49, 113, 165);
    cursor: pointer;
    transform: rotate(180deg);
}

#project-header-project-name {
    font-size: 12px;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
    max-lines: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#project-header-right {
    display: flex;
    flex-direction: row;
}
 
#project-header-right .project-header-right-nav {
    font-size: 12px;
    transition: all 0.3s;
    margin-left: 8px;
}

#project-header-right .project-header-right-nav:hover {
    cursor: pointer;
    text-decoration: underline;
}

#project-lower-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

#project-lower-right { 
    font-size: 12px;
    flex-grow: 1;
}

/***************************
****** PROJECT PANEL *******
****************************/

.project-panel-nav-container {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 2px;
}

.project-panel-nav-container span {
    margin-right: 16px;
    color: rgb(129, 129, 129);
    transition: all 0.2s;
}

.project-panel-nav-container span:hover {
    cursor: pointer;
    border-bottom: 1px solid rgb(184, 184, 184);
    color: black;
}

.project-panel-nav-container span.project-panel-nav-selected {
    color: black;
    border-bottom: 1px solid rgb(184, 184, 184);
}

.project-panel-alignment-group-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.project-panel-alignment-grouping {
    margin-bottom: 12px;
}

.project-panel-alignment-grouping .project-panel-alignment-group-heading h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
}

.project-panel-alignment-grouping .project-panel-alignment-group-heading span {
    font-size: 10px;
    margin-left: 8px;
    color: rgb(73, 73, 73);
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 2px;
    height: 12px;
    width: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-panel-alignment-grouping .project-panel-alignment-group-heading span:hover {
    background-color: rgb(201, 201, 201);
    cursor: pointer;
    color: black;
}

/*
****** WORKSPACE PANEL - TEMPLATES ****
*/

.workspace-panel-templates-right-side {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
}

.workspace-panel-template-create-button {
    color: #2973af;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12px 8px;
    border-radius: 5px;
    transition: all 0.2s;
    padding: 4px 12px;
    text-align: center;
    cursor: pointer;    
}

.workspace-panel-template-item-description {
    color: grey;
    font-size: 14px;
}

.workspace-page-templates-empty-container {
    width: 580px;
    background-color: rgba(15, 54, 94, 0.071);
    padding: 40px;
    border-top-left-radius: 32px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
}

.workspace-page-templates-empty-main {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}

.workspace-page-templates-empty-sub {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
}

.workspace-page-templates-management-table-container .data-table-row-container {
    padding-top: 8px;
    padding-bottom: 8px;
}

.workspace-page-templates-filters {
    display: flex;
    gap: 16px;
}

.workspace-page-templates-filter {
    border: 1px solid rgb(183, 183, 183);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 12px;
    padding-left: 14px;
    cursor: pointer;
    position: relative;
}

.workspace-page-templates-filter:hover {
    background-color: rgb(236, 236, 236);
}

.workspace-page-templates-filter-display {
    font-size: 13px;
    font-weight: 600;
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgb(50, 50, 50);
}

.workspace-page-templates-filter-display-selected {
    background-color: #a6b4c8;
    border: 1px solid rgb(119, 119, 119);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    cursor: pointer;
    padding: 8px 12px;
    padding-left: 14px;
    position: relative;
}

.workspace-page-templates-filter-display svg {
    font-size: 12px;
}

.workspace-page-templates-filter-display-selected svg {
    font-size: 14px;
}

.workspace-page-templates-filter-display-selected .workspace-page-templates-filter-display {
    gap: 8px;
}

.workspace-page-templates-filter-menu-container {
    position: absolute;
    background-color: #ffffff;
    top: 100%;
    color: #4F5D74;
    font-size: 16px;
    font-weight: 500;
    margin-top: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
    min-width: 240px;
    z-index: 200;
    /* box-shadow: 0px 4px 4px rgba(163, 163, 163, 0.25); */
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.workspace-page-templates-filter-menu-dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    transition: all 0.1s;
    display: flex;
    gap: 8px;
    align-items: center;
}

.workspace-page-templates-filter-menu-dropdown-item svg {
    font-size: 16px;
}

.workspace-page-templates-filter-menu-dropdown-item:hover {
    background-color: #eceef1;
}

.workspace-page-templates-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start;
    margin-top: 24px;
    align-items: stretch;
}

.workspace-page-template-display {
    flex-basis: 20%;
    min-width: 200px;
    max-width: 320px;
    /* background-color: rgb(246, 246, 246); */
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    border-radius: 4px;
    background-color: #ffffff;
    min-height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.063);
    /* color: white; */
    padding: 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}

.workspace-page-template-display-heading-subtitle {
    font-size: 12px;
    margin-top: 4px;
    color: grey;
}

.workspace-page-template-display:hover {
    box-shadow: rgba(0, 0, 0, 0.237) 0px 4px 6px;
    /* background-color: rgb(246, 246, 246); */
    background-color: #F2F6FC;
}

.workspace-page-template-display-header {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    justify-content: flex-start;
}

.workspace-page-template-display-header h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.workspace-page-template-display-header svg {
    font-size: 24px;
}

.workspace-page-template-display-features {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.workspace-page-template-display-feature {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 12px;
    color: rgb(90, 90, 90);
}

.workspace-page-template-display-feature svg {
    font-size: 14px;
}

.workspace-page-template-display-network-badge {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

.workspace-page-template-display-network-badge span {
    background-color: rgba(128, 0, 128, 0.554);
    color: white;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 8px;
    text-transform: uppercase;
}

.workspace-page-template-display-description {
    margin-top: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid grey;
    font-size: 14px;
    color: grey;
}

.component-template-scope-badge-network {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.workspace-panel-template-create-button:hover {
    background-color: rgb(232, 232, 232);
}

.workspace-page-templates-management-table-container {
    margin-top: 16px;
    width: 100%;
    box-sizing: border-box;
}

.workspace-panel-template-icon-row {
    font-size: 14px;
}

.workspace-panel-template-icon-row svg {
    font-size: 20px;
}

/********************************************
****** WORKSPACE PANEL - MEASUREMENTS *******
*********************************************/
.workspace-page-measurements-lower-container {
    width: 100%;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
}

.workspace-page-measurements-lower-container-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 12px;
}

.workspace-page-measurements-lower-container-headings {
    flex-grow: 1;
}

.workspace-page-measurements-lower-container h2 {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 4px;
}

.workspace-page-measurements-cards {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 24px;
}

.workspace-page-measurements-card-container {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
    cursor: pointer;
}

.workspace-page-measurements-card-container:hover {
    filter: brightness(90%);
}

.workspace-page-measurements-cardbox {
    min-width: 140px;
    background-color: rgb(243, 243, 243);
    /* background-color: rgba(164, 194, 240, 0.534); */
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 24px 12px 12px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    border: 1px solid rgb(199,199,199);
}

.workspace-page-measurements-card-metric {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 4px;
}

.workspace-page-measurements-card-title {
    font-size: 14px;
    margin-right: 12px;
    margin-bottom: 4px;
    font-weight: 600;
    color: rgb(70, 70, 70);
}

.workspace-page-measurements-card-updated {
    color: grey;
    font-size: 12px;
}
/***********************************
****** WORKSPACE PAGE - KPIs *******
************************************/
.workspace-page-kpis {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 48px;
}

.workspace-page-kpi-empty-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.workspace-page-kpi-empty-new svg {
    font-size: 48px;
    padding: 4px;
    color: grey;
}

.workspace-page-kpi-empty-new p {
    font-size: 16px;
    margin: 0;
    color: grey;
}

.workspace-page-kpi-container {
    flex-basis: 20%;
    min-width: 200px;
    max-width: 320px;
    /* background-color: rgb(246, 246, 246); */
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    border-radius: 4px;
    background-color: #ffffff;
    min-height: 160px;
    border: 1px solid rgba(0, 0, 0, 0.063);
    /* color: white; */
    padding: 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}

.workspace-page-kpi-container:hover {
    box-shadow: rgba(0, 0, 0, 0.237) 0px 4px 6px;
    background-color: rgb(246, 246, 246);
}

.workspace-page-kpi-container h4 {
    margin: 0;
    font-weight: 600;
    height: 42px;
}

.workspace-page-kpi-value {
    font-size: 28px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: flex-end;
    gap: 6px;
}

.workspace-page-kpi-value span {
    font-size: 12px;
    color: grey;
    padding-bottom: 4px;
    font-weight: 400;
}

.workspace-page-kpi-descriptor {
    font-size: 11px;
    color: grey;
}

.workspace-page-kpi-container:hover .workspace-page-kpi-description-popup,
.workspace-page-template-display .workspace-page-template-description-popup {
    display: block; 
  }

.workspace-page-kpi-description-popup {
    position: absolute;
    bottom: 50%; /* Position above the KPI */
    left: 50%;
    background-color: white;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    padding: 10px;
    display: none;
    min-height: 40px;
    z-index: 10;
    width: 240px;
    max-width: 240px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: pre-wrap; /* In case the description needs to wrap */
}

.workspace-page-kpi-description-popup h4 {
    margin: 0;
}

.workspace-page-template-description-popup {
    position: absolute;
    top: calc(65% + 8px); /* Position above the KPI */
    left: 50%;
    background-color: white;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding: 10px;
    display: none;
    min-height: 40px;
    z-index: 10;
    width: 240px;
    max-width: 240px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    white-space: pre-wrap; /* In case the description needs to wrap */
}

.workspace-page-template-description-popup h4 {
    margin: 0;
}

.workspace-page-kpi-sparkline {
    position: absolute;
    bottom: 32px;
    right: 14px;
}

/****************************************
****** WORKSPACE PAGE - RESOURCES *******
*****************************************/
.workspace-page-resources-subheading {
    color: grey;
}

.workspace-page-resources-actions-bar {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(199,199,199);
    padding-bottom: 20px;
    margin-top: 20px;
}

.workspace-page-resources-create-button {
    background-color: #2973af;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 24px;
    font-size: 14px;
    border-radius: 8px;
    transition: all 0.2s;
    text-align: center;
    cursor: pointer;    
}

.workspace-page-resources-action-search-container {
    min-width: 200px;
    flex-basis: 25%;
    padding: 0.5em;
    border: 1px solid rgb(199,199,199);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.workspace-page-resources-action-search-container input {
    border: none;
    outline: none;
    flex-grow: 1;
}

.workspace-page-resources-lower-container {
    width: 100%;
    box-sizing: border-box;
}

.workspace-page-resources-view-action-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.workspace-page-resources-view-action-sortby {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.workspace-page-resources-resource-table-name {
    font-weight: 600;
    font-size: 1.2em;
}

.workspace-page-resources-resource-table-name:hover {
    text-decoration: underline;
    cursor: pointer;
}

.workspace-page-resources-resource-table-constant-height-container {
    min-height: 60px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-wrap: break-word;
    overflow: hidden;
    box-sizing: border-box;
}

.workspace-page-resources-resource-table-constant-height-container:hover .workspace-page-resources-resource-table-description {
    display: none;
}

.workspace-page-resources-resource-table-constant-height-container:hover .workspace-page-resources-resource-table-actions {
    display: flex;
}

.workspace-page-resources-resource-table-description {
    color: grey;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
}

.workspace-page-resources-resource-table-actions {
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
    margin-top: 6px;
    gap: 1em;
    display: none;
}

.workspace-page-resources-resource-table-action-button {
    min-width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.1s;
}

.workspace-page-resources-resource-table-action-button svg {
    font-size: 20px;
    color: rgb(80, 80, 80);
}

.workspace-page-resources-resource-table-action-button:hover {
    background-color: rgb(245, 245, 245);
}

.workspace-page-resources-empty-state {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

/*************************************
****** WORKSPACE PANEL - LISTS *******
**************************************/
.workcomp-board-control-panel-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 8px;
    border: 1.5px solid rgb(231, 231, 231);
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 0px;
    box-sizing: border-box;
    font-size: 14px;
    box-sizing: border-box;
    justify-content: space-between;
}

.workcomp-board-control-panel-inner-section {
    display: flex;
    align-items: stretch;
    gap: 8px;
}

.workcomp-board-control-panel-symbol-button,
.workcomp-board-control-panel-create-button,
.workcomp-board-control-panel-filter-container,
.workcomp-board-control-panel-filter-flyout-menu-container {
    border: 1.5px solid rgb(231, 231, 231);
    border-radius: 4px;
    min-width: 200px;
}

.workcomp-board-control-panel-create-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    background-color: #3D72AA;
    font-weight: 700;
    font-size: 14px;
    /* min-width: auto; */
    min-width: unset;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    min-height: 40px;
}

.workcomp-board-control-panel-create-button svg {
    font-size: 16px;
}

.workcomp-board-control-panel-symbol-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    min-width: 40px;
    cursor: pointer;
}

.workcomp-board-control-panel-symbol-button svg {
    font-size: 20px;
}

.workcomp-board-control-panel-progress {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;
    padding-bottom: 2px;
    gap: 8px;
    font-size: 14px;
    color: rgb(77, 77, 77);
    font-weight: 600;
}

.workcomp-board-control-panel-progress-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 200px;
}

.workcomp-board-control-panel-progress-right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 12px;
}

.workcomp-board-control-panel-progress-right p {
    margin: 0px;
    font-size: 20px;
    font-weight: 600;
    width: 40px;
}

.workcomp-board-control-panel-progress-right p span {
    font-size: 12px;
    font-weight: 300;
}

.workcomp-board-control-panel-search-container {
    position: relative;
    width: 240px;
}

.workcomp-board-control-panel-search-container input {
    height: 100%;
    box-sizing: border-box;
    border: none;
    width: 100%;
    padding-left: 36px;
    font-size: 14px;
    background-color: #F2F2F2;
    border-radius: 4px;
}

.workcomp-board-control-panel-search-container svg {
    position: absolute;
    left: 8px;
    color: grey;
    top: 11px;
    font-size: 18px;
}

.workcomp-board-control-panel-filter-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
}

.workcomp-board-control-panel-filter-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    cursor: pointer;
}

.workcomp-board-control-panel-filter-display svg {
    font-size: 20px;
}

.workcomp-board-control-panel-filter-flyout-menu-container {
    position: absolute;
    top: 48px;
    padding: 8px;
    right: 0;
    z-index: 50;
    background-color: white;
    width: 300px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: flex-end; */
    /* align-items: center; */
    /* gap: 20px; */
}

.workcomp-board-control-panel-filter-flyout-menu-container p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 600;
    line-height: 1.5;
}

.workcomp-board-control-panel-filter-flyout-menu-users {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.workcomp-board-control-create-task-megabar-holder {
    position: relative;
    min-height: 52px;
    z-index: 99;
}

.workcomp-board-control-create-task-megabar-holder-inner {
    position: fixed;
    min-width: 240px;
    width: 100%;
    max-width: 1400px;
}

@media screen and (min-width: 800px) {
    .workcomp-board-control-create-task-megabar-holder-inner { max-width: 500px }
}

@media screen and (min-width: 1000px) {
    .workcomp-board-control-create-task-megabar-holder-inner { max-width: 700px }
}

@media screen and (min-width: 1200px) {
    .workcomp-board-control-create-task-megabar-holder-inner { max-width: 900px }
}

@media screen and (min-width: 1400px) {
    .workcomp-board-control-create-task-megabar-holder-inner { max-width: 1100px }
}

.workspace-panel-controls-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(199, 199, 199);
    background-color: white;
    padding-left: 8px;
    margin-bottom: 0px;
    padding-right: 8px;
    box-sizing: border-box;
    font-size: 14px;
    box-sizing: border-box;
}

.workspace-panel-control-button {
    padding: 0.5em;
    font-size: 1em;
    background-color: #2973af;
    color: white;
    border-radius: 8px;
    width: 6em;
    text-align: center;
    cursor: pointer;
}

.workcomp-goal-new-task-button-outer-container {
    position: relative;
}

.workcomp-goal-new-task-button {
    position: fixed;
    bottom: 24px;
    right: 40px;
    width: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 50%;
    font-size: 24px;
    z-index: 2;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.2s;
}

.workcomp-goal-new-task-button:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 12px 16px -2px, rgba(0, 0, 0, 0.3) 0px 6px 14px -3px;
}

.workspace-panel-interactions-menu {
    display: none !important;
    position: absolute;
    top: 24px;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid rgb(199, 199, 199);
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 1;
}

.workspace-panel-interactions-menu-items {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.workspace-panel-interactions-button-menu:hover .workspace-panel-interactions-menu {
    display: block !important;
}

.workspace-panel-interactions-menu-items div {
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
    padding-left: 4px;
    box-sizing: border-box;
}

.workspace-panel-interactions-menu-items svg {
    color:rgb(56, 56, 56);
    padding-right: 8px;
    padding-left: 8px;
}

.workspace-panel-interactions-menu-items div:hover {
    background-color: lightgrey;
}

.workspace-panel-interactions-container div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 6px;
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(44, 44, 44);
    font-weight: 600;
}

.workspace-panel-interactions-button,
.workspace-panel-interactions-button-post {
    border-radius: 4px;
    padding: 4px;
    width: 100%;
    transition: all 0.2s;
}

.workspace-panel-interactions-button:hover {
    cursor: pointer;
}

.workspace-panel-interactions-button-post:hover {
    background-color: lightgrey;
}

.workspace-panel-interactions-button-menu {
    position: relative;
}

.workspace-panel-sort-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
}

.workspace-panel-sort-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    border-radius: 4px;;
    transition: all 0.2s;
}

.workspace-panel-sort-item-all {
    padding-right: 8px;
    padding-left: 8px;
    box-sizing: border-box;
    transition: all 0.2s;
}

.workspace-panel-sort-item-all:hover {
    text-decoration: underline;
    cursor: pointer;
}

.workspace-panel-sort-item:hover {
    cursor: pointer;
    color: white;
    background-color: rgb(23, 64, 97);
}

.workspace-panel-sort-item.workspace-panel-sort-selected {
    color: white;
    background-color: rgb(23, 64, 97);
}

.workspace-panel-unsort-selected {
    text-decoration: underline;
}

.workspace-panel-sort-item span {
    margin-left: 4px;
}

.workspace-panel-nav-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-bottom: 2px;
}

.workspace-panel-nav-container span {
    /* color: rgb(129, 129, 129); */
    transition: all 0.2s;
    padding: 4px;
    border-bottom: 2px solid transparent;
    text-align: center;
    margin-right: 16px;
    /* width: 72px; */
}

.workspace-panel-nav-container span:hover {
    cursor: pointer;
    /* color: white; */
    border-bottom: 2px solid rgb(23, 64, 97);
}

.workspace-panel-nav-container span.workspace-panel-nav-selected {
    /* color: white; */
    border-bottom: 2px solid rgb(23, 64, 97);
}

.workspace-panel-sort-item-desktop {
    display: none;
}

.workspace-panel-view-holder {
    max-width: 100%;
    position: absolute;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 24px;
}

/*****************************
****** WORKSPACE BOARD *******
******************************/
.workspace-board-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 14px;
    margin-top: 12px;
    padding-left: 4px;
}

.workspace-board-column {
    min-width: 260px;
    max-width: 264px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    padding: 8px;
    border-radius: 13px;
    border: 1px solid #F7F7F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);

    /* background-color: rgb(253, 253, 253); */
    background-color: #f6f7fb70;
}

.workspace-board-column-new {
    border: 1px solid white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
    /* border-radius: 0px; */
    /* border-right: 1px solid #F2F2F2; */
    border-radius: 13px;
}

.workspace-board-column-done {
    border: 1px solid white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
    /* border-radius: 0px; */
    /* border-left: 1px solid #F2F2F2; */
    border-radius: 13px;
}

.workspace-board-column-new-column-button-tripwire {
    position: absolute;
    z-index: 3;
    right: 15px;
    transform: translateX(32px);
    width: 20px;
    bottom: 0%;
    top: 0%;
}

.workspace-board-column-new-column-button {
    position: absolute;
    z-index: 4;
    visibility: hidden;
    color: black;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    right: 15px;
    top: 20px;
    transform: translateX(32px);
    transition: all 0.1s;
}

.workspace-board-column-new-column-button-permanent {
    visibility: visible;
    /* width: 100px; */
    left: calc(100% - 20px);
    border-radius: 3px;
    border: 1px solid #3D72AA;
    box-shadow: 0px 4px 4px rgba(125, 125, 125, 0.25);
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    min-width: 80px;
    cursor: pointer;
    color: #7D7D7D;
}

.workspace-board-column-new-column-button-tripwire:hover + .workspace-board-column-new-column-button,
.workspace-board-column-new-column-button:hover {
    visibility: visible;
}

.workspace-board-column-new-column-button:hover {
    cursor: pointer;
    background-color: rgb(202, 202, 202);
}

.workspace-board-column-new-column-button-permanent:hover {
    background-color: white;
}

.workspace-board-column-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    position: relative;
}

.workspace-board-column-header-options {
    position: relative;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid transparent;
    z-index: 3;
    color: rgb(105, 105, 105);
    font-size: 14px;
    cursor: pointer;
}

.workspace-board-column-header-option-info:hover + .workspace-board-column-tooltip {
    visibility: visible;
    opacity: 1;
}

.workspace-board-column-shift-control:hover {
    cursor: grab;
}

.workspace-board-column-header {
    min-height: 36px;
}

.workspace-board-column-header-title {
    margin: 0px;
    box-sizing: border-box;
    padding-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    
}

.workspace-board-column-header-title h2 {
    margin: 0px;
    /* color: #353C44; */
    color: #1e1e1e;
    font-weight: 700;
    font-size: 15px;
    flex-basis: 80%;
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.workspace-board-column-header-title-count {
    font-size: 14px;
    padding-bottom: 2px;
    color: #000000;
    flex-basis: 10%;
}

.workspace-board-column-header-title-edit {
    font-size: 14px;
    font-weight: 700;
    margin-left: 8px;
    border: none;
    background-color: white;
    padding-bottom: 2px;
    color: #353C44;
}

.workspace-board-column-header-column-tools {
    display: flex;
    flex-direction: row;
    width: 95%;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4px;
}

.workspace-board-column-header-column-tools input {
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    margin-bottom: 4px;
    margin-top: 4px;
    padding: 12px 8px;
    border: none;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    background-color: rgb(224, 224, 224);
    cursor: pointer;
}

.workspace-board-column-header-column-tools input:focus {
    cursor: text;
}

.workspace-board-column-header-column-tools svg {
    width: 20px;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.workspace-board-column-header-column-tools svg:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}

.workspace-board-column-cards {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
    gap: 12px;
    padding-bottom: 24px;
    min-height: 50vh;
    max-height: 60vh;
    margin-bottom: 12px;
    overflow-y: auto;
    overflow-x: hidden;
}

.workspace-board-column-cards::-webkit-scrollbar {
    width: .3em;
}
  
.workspace-board-column-cards::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
}

.workspace-board-column-cards::-webkit-scrollbar-thumb {
    background-color: rgb(23, 64, 97);
    outline: none;
}

.workspace-board-column-dragged-over {
    background-color: rgba(128, 128, 128, 0.075);
}

.workspace-board-card-show-display-action-show {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.workspace-board-card-show-display-action-edit {
    font-size: 16px;
}

.workspace-board-card-show-display-action-complete {
    font-size: 20px;
}

.workspace-board-column-card:hover .workspace-board-card-show-display-action-show {
    visibility: visible;
    opacity: 1;
}

.workspace-board-column-fetch-tasks-button {
    width: 100%;
    font-size: 0.8em;
    text-decoration: underline;
    color:rgb(23, 64, 97);
    text-align: center;
    cursor: pointer;
}

.workspace-board-column-card {
    width: 95%;
    position: relative;
    box-sizing: border-box;
    /* background-color: #f6f7fb; */
    /* border: 1px solid #E6E8F3; */
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    color: rgb(43, 43, 43);
    border: 1.5px solid rgb(235, 235, 235);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.055) 0px 2px 3px 1px;
}

.workspace-board-column-card-completed {
    background-color: #FCFDFE;
}

.workspace-board-column-card-completed .workspace-board-card-content-description {
    color: #344563;
}

.modal-board-card-appearance-color-choice {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.modal-board-card-appearance-colors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px;
    width: auto;
}

.workspace-board-column-card-demo-outer {
    padding: 2px;
    border: 2px solid transparent;
    border-radius: 4px;
}

.workspace-board-column-card-demo {
    width: 240px;
    margin: 8px;
    background-color: rgb(247, 247, 247);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 4px 0px, rgba(60, 64, 67, 0.15) 0px 4px 8px 4px;
    color: rgb(43, 43, 43);
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid transparent;
}

.workspace-board-column-card-demo-active {
    border: 2px solid rgb(23, 97, 182);
}

.workspace-board-column-card:hover .workspace-board-column-card-color-button {
    display: flex;
}

.workspace-board-column-card-color-button {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    cursor: pointer;
    border-radius: 12px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.workspace-board-column-card-color-button:hover {
    background-color: grey;
}

.workspace-board-column-card-color-button svg {
    font-size: 16px;
}

.workspace-board-column-card-dragging {
    background-color: rgb(229, 229, 229) !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.workspace-board-card-content-description {
    margin-top: 10px;
    margin-left: 12px;
    margin-right: 4px;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    padding-right: 32px;
    color: #344563;
}

.workspace-board-card-content-description:hover {
    cursor: pointer;
}

.workspace-board-card-content-description-expand {
    max-height: unset;
}

.workspace-board-card-show-display {
    margin-left: 12px;
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 26px;
}

.workspace-board-card-summary-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 10px;
    box-sizing: border-box;
    width: 100%;
}

.workspace-board-card-summary-icons-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #344563;
}

.workspace-board-card-summary-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.workspace-board-card-summary-icon span {
    letter-spacing: 0.5px;
}

.workspace-board-card-show-display svg {
    color: #3D72AA;
}

.workspace-board-card-show-display svg:hover {
    cursor: pointer;
}

.workspace-board-card-content-expanded-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 4px;
    margin-left: 12px;
    margin-right: 4px;
}

.workspace-board-card-content-expanded {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-wrap: break-word;
}

.workspace-board-card-content-expanded:hover {
    cursor: pointer;
    /* background-color: rgba(243, 243, 243, 0.541); */
}

.workspace-board-card-content-expanded-symbol {
    width: 40px;
    height: 24px;
}

.workspace-board-card-content-expanded-title {
    font-size: 14px;
    font-weight: 500;
}

.workspace-board-card-content-expanded-subtitle {
    font-size: 12px;
}

.workspace-board-card-content-expanded-details {
    font-size: 11px;
    height: 12px;
    color: #344563;
    line-height: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.workspace-board-card-expanded-show-details {
    border-top: 1px solid rgb(199, 199, 199);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    gap: 12px;
    padding: 8px 8px;
    transition: all 0.2s;
}

.workspace-board-card-expanded-show-details svg {
    font-size: 20px;
}

.workspace-board-card-expanded-show-details:hover {
    background-color: rgb(161, 161, 161);
    cursor: pointer;
    color: black;
}

/* */
.alignment-component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    transition: all 0.4s;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.alignment-component-container:hover {
    cursor: pointer;
    background-color: rgba(187, 187, 187, 0.16);
}

.alignment-component-container h4,
.alignment-component-container p {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
}

.alignment-component-container p {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 2px;
    margin-bottom: 8px;
    width: 70%;
    border-bottom: 1px solid lightgrey;
}

/* Chat */
.chat-container {
    position: fixed;  /* Essential for floating effect */
    bottom: 0px;     /* Position from the bottom */
    right: 12px;      /* Position from the right */
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: To give a slight shadow */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;  /* Prevents internal content from spilling out */
    box-sizing: border-box;
    z-index: 100;
    background-color: white;
    max-height: 70vh;
    display: unset;
}

@media screen and (max-width: 800px) {
    .chat-container,
    .chat-container-closed {
        display: none;
    }
}

.chat-container-closed {
    position: fixed;  /* Essential for floating effect */
    bottom: 0px;     /* Position from the bottom */
    right: 12px;      /* Position from the right */
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: To give a slight shadow */
    border-top-left-radius: 10px;  /* Rounded corners */
    border-top-right-radius: 10px;  /* Rounded corners */
    overflow: hidden;  /* Prevents internal content from spilling out */
    box-sizing: border-box;
    z-index: 100;
    background-color: white;
    width: 280px;
    cursor: pointer;
}

.chat-container.small {
    width: 280px;
    height: 400px; 
}
  
.chat-container.medium {
    width: 420px; 
    height: 600px;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chat-message-author {
    font-size: 12px;
    color: rgba(26, 32, 40);
    cursor: pointer;
}

.chat-message-author:hover {
    text-decoration: underline;
}

.chat-message {
    display: flex;
    gap: 2px;
    align-items: flex-start;
    flex-direction: row-reverse;
    margin-top: 14px;
    width: 100%;
    position: relative;
}

.chat-message:first-of-type {
    margin-bottom: 0px;
}

.chat-message-profile-picture-container {
    width: 40px;
    min-width: 40px;
    display: flex;
    justify-content: flex-end;
}

.chat-message-users .chat-message-profile-picture-container {
    justify-content: flex-start;
}

.chat-message.chat-message-users {
    flex-direction: row;
}

.chat-message-internal {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.chat-message-users p .gadget-hashtag {
    color: white;
    font-weight: 600;
}

.chat-message-link {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.chat-message-unread-count {
    width: 20px;
    height: 20px;
    background-color: rgba(61, 114, 170);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 50%;
}

.chat-message-controls {
    flex-grow: 1;
    align-self: center;
    opacity: 0;
    flex-direction: row-reverse;
    display: flex;
    gap: 14px;
    min-width: 60px;
}

.chat-message-controls span svg {
    color: grey;
    font-size: 14px;
}

.chat-message:hover .chat-message-controls {
    opacity: 1;
}

.chat-message p {
    /* background-color: rgb(250, 250, 250); */
    background-color: rgb(223, 223, 223);
    color: rgba(55, 59, 83);
    font-size: 14px;
    margin: 0;
    padding: 8px;
    border-radius: 4px;
    white-space: pre-wrap; 
    overflow-wrap: break-word;
    word-break: break-word; /* Additional property to handle non-white-space strings */
    max-width: 100%;
}

.chat-message-timestamp {
    font-size: 10px;
    color: grey;
    font-weight: 400;
}

.chat-message a {
    font-weight: 500;
}

.chat-message-users {
    align-self: flex-start;
    align-items: flex-start;
}

.chat-message-users a {
    color: white;
    font-weight: 500;
}

.chat-message-users p {
    background-color: rgba(61, 114, 170, 1);
    color: white;
}

.chat-header {
    /* background-color: #f5f5f5; */
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(207, 207, 207);
}

.chat-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-right: 8px;
}

.chat-header-controls {
    display: flex;
    align-items: center;
    gap: 14px;
}

.chat-body {
    overflow-y: auto;
    padding: 10px;
    height: calc(100% - 110px);
    box-sizing: border-box;
    margin-bottom: 8px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
}

.chat-body-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(173, 173, 173);
    font-size: 14px;
}

.chat-input-container {
    display: flex;
    border-top: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.chat-input-container input {
    flex: 1;  /* Let the input take most of the space */
    padding: 8px;
    margin-right: 10px;  /* Spacing between input and send button */
    border-radius: 6px;
    border: 1px solid rgb(219, 219, 219);
}

.chat-input-container button {
    padding: 5px 10px;
    background-color: rgb(61, 114, 170);
    color: white;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin-left: 12px;
}

.messages-room-desktop-inputs-container .chat-input-container button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.messages-room-desktop-inputs-container .chat-input-container button svg {
    font-size: 16px;
    padding: 0px 14px;
}

.chat-body::-webkit-scrollbar {
    width: 10px;
}

.chat-body::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

.chat-body::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
}

.chat-resize-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
  
.chat-resize-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-resize-icon svg {
    font-size: 20px;
    color: rgba(61, 114, 170, 1);
}

.chat-action-menu {
    position: absolute;
    z-index: 1000;
    right: 48px;
    top: calc(90%);
    width: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 6px;
}

.chat-message-users .chat-action-menu {
    left: 48px;
    top: calc(90%);
}

.chat-action-menu span {
    padding: 8px 20px;
    cursor: pointer;
}

/* WorkspaceHomeFeed */
.workspace-home-feed-banner {
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 280px;
}

.workspace-home-feed-banner img {
    width: 100%;
    max-height: 280px;
    /* max-width: 960px; */
    object-fit: cover;
}

.workspace-home-feed-banner-blank {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 128px;
    background-color: rgba(81, 117, 164, 0.06);
}

.workspace-home-feed-upper-header-container {
    padding: 16px 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid rgb(228, 228, 228);
}

.workspace-home-feed-main-title h1 {
    margin: 0px;
    font-weight: 700;
    font-size: 32px;
    color: #222222;
}

.workspace-home-feed-main-title {
    display: flex;
    flex-direction: column;
}

.workspace-home-feed-main-title p {
    color: grey;
    font-size: 16px;
    margin: 0;
    margin-bottom: 14px;
}

.workspace-home-feed-container {
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: #FAFAFA; */
    background-color: #F5F5F5;
}

.workspace-home-feed-main {
    flex-basis: 100%;
    margin: 12px;
}

.workspace-home-feed-main-inner {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
    background-color: rgb(252, 252, 252);
    padding: 20px;
    border-radius: 6px;
    box-shadow: 2px 4px 3px 0px rgba(165, 165, 165, 0.25);
}

.workspace-home-feed-side {
    display: none;
    margin-top: 12px;
}

.workspace-home-feed-greetings {
    font-size: 28px;
    font-weight: 400;
    color: #353C44;
}

@media only screen and (min-width: 1200px) {
    .workspace-home-feed-main {
        flex-basis: 65%;
    }

    .workspace-home-feed-main-inner {
        margin-left: auto;
        margin-right: auto;
        /* max-width: 800px; */
        padding-right: 2%;
        padding-left: 2%;
    }
    
    .workspace-home-feed-side {
        display: unset;
        flex-basis: 35%;
    }
}

.workspace-home-feed-main-subtitle {
    color: grey;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    font-size: 1em;
}

.workspace-home-feed-main-post-container {
    margin-top: 24px;
    width: 100%;
}

.workspace-home-feed-main-post-container-populated {
    border-top: 1px solid rgb(231, 231, 231);
}

.workspace-home-feed-main-feed-empty-heading {
    font-size: 24px;
    font-weight: 600;
    color: #50607D;
    margin-bottom: 24px;
}

.workspace-home-feed-main-feed-empty-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 24px;
}

.workspace-home-feed-main-feed-empty-options div {
    font-size: 14px;
    color: rgb(68, 68, 68);
    cursor: pointer;
    font-weight: 500;
}

.workspace-home-feed-main-feed-empty-options div:hover {
    color: #2A73AE;
    text-decoration: underline;
}

.workspace-home-feed-create-post-container {
    margin-top: 24px;
    display: flex;
    border: 1px solid rgb(218, 218, 218);
    flex-direction: row;
    align-items: center;
    gap: 12px;
    background-color: white;
    padding: 12px 16px;
    border-radius: 4px;
    transition: all 0.1s;
}

.workspace-home-feed-create-post-container:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.workspace-home-feed-main-feed-empty-instructions {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}

.workspace-home-feed-main-feed-empty-instructions p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.workspace-home-feed-main-feed-empty-button {
    background-color: #2973af;
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 12px 16px;
    border-radius: 8px;
    margin-top: 16px;
    cursor: pointer;
    margin-bottom: 24px;
}

.workspace-home-feed-create-post {
    background-color: white;
    /* padding: 4px; */
    border-radius: 4px;
    /* border: 1px solid rgb(218,218,218); */
    width: 100%;
}

.workspace-home-feed-create-post-button {
    background-color: rgb(23, 64, 97);
    color: white;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 4px;
}

.workspace-home-feed-create-post div {
    color: rgb(128, 128, 128);
    cursor: pointer;
    font-weight: 500;
    box-sizing: border-box;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1em;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
}

.workspace-home-feed-side-inner {
    top: 48px;
    width: 100%;
    padding-right: 12px;
    box-sizing: border-box;
}

.workspace-home-feed-side-title {
    margin-bottom: 4px;
}

.feed-right-side-heading-text {
    font-size: 16px;
    margin-left: 16px;
    font-weight: 700;
    color: #4F5D74;
}

.feed-right-side-about-container {
    margin-left: 16px;
    margin-right: 16px;
    font-size: 14px;
    line-height: 1.8;
}

.modal-workspace-onboarding-message-container {
    margin-left: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 20px;
}

.workspace-home-feed-side-title h2 {
    margin: 0px;
    font-weight: 700;
    font-size: 1em;
    color: #666666;
}

.workspace-home-feed-side-section-container {
    background-color: white;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 24px;
}

.workspace-home-feed-side-section-container-promotion {
    display: flex;
    padding: 0;
    background-color: rgb(63, 115, 170);
    color: white;
    overflow: hidden;
}

.workspace-home-feed-side-section-promotion-text {
    flex-basis: 50%;
    padding: 18px;
    box-sizing: border-box;
    font-size: 14px;
    min-width: 260px;
}

.workspace-home-feed-side-section-promotion-text p:first-of-type {
    font-weight: 600;
}

.workspace-home-feed-side-section-promotion-text p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 18px;
}

.workspace-home-feed-side-section-promotion-cta {
    font-size: 14px;
}

.workspace-home-feed-side-section-promotion-cta span {
    text-decoration: underline;
    cursor: pointer;
}

.workspace-home-feed-side-section-promotion-image {
    flex-basis: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.workspace-home-feed-side-section-promotion-image img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    scale: 1.2;
}

.workspace-home-feed-side-urgent-items-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 400px;
    overflow-y: auto;
}

.workspace-home-feed-side-urgent-items-container::-webkit-scrollbar {
    width: .3em;
  }
  
.workspace-home-feed-side-urgent-items-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
}
  
.workspace-home-feed-side-urgent-items-container::-webkit-scrollbar-thumb {
  background-color: rgb(23, 64, 97);
  outline: none;
}

.workspace-home-feed-side-urgent-items-caught-up {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
    color: grey;
}

.workspace-home-feed-side-urgent-items {
    display: flex;
    flex-direction: column;
}

.workspace-home-feed-side-urgent-item {
    display: flex;
    flex-direction: row;
    gap: 18px;
    align-items: center;
    padding: 12px 16px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s;
}

.workspace-home-feed-side-urgent-item-circle {
    width: 10px;
    height: 10px;
    min-width: 10px;
    background-color: #585858;
    border-radius: 50%;
}

.workspace-home-feed-side-urgent-item:hover {
    background-color: rgb(240, 240, 240);
}

.workspace-home-feed-side-urgent-title {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    color:rgb(44, 44, 44);
    margin-bottom: 2px;
}

.workspace-home-feed-side-urgent-subtitle {
    color: grey;
    font-size: 12px;
    font-weight: 500;
}

.workspace-home-feed-side-urgent-subtitle span {
    text-transform: capitalize;
}

.workspace-feed-side-updates {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.workspace-feed-side-update {
    border-bottom: 1px solid rgb(199,199,199);
    width: 100%;
    box-sizing: border-box;
    padding: 0.4em 0.4em;
    cursor: pointer;
    transition: all 0.2s;
}

.workspace-feed-side-update:hover {
    background-color: rgb(243, 243, 243);
}

.workspace-feed-side-update:last-child {
    border-bottom: 1px solid transparent;
}

.workspace-feed-side-update-title {
    font-size: 14px;
}

.workspace-feed-side-update-type {
    color: grey;
    font-size: 0.8em;
    text-transform: capitalize;
}

.workspace-home-feed-side-section-container-workspace-updates {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
} 

.workspace-feed-side-workspace-update {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
}

.workspace-feed-side-workspace-update-clickable {
    cursor: pointer;
}

.workspace-feed-side-workspace-update-clickable:hover {
    background-color: rgb(240, 240, 240);
}

/* Carousel Items */
.feed-carousels-outer-container {
    border-top: 1px solid rgb(218, 218, 218);
    padding: 12px;
    position: relative;
    z-index: 2;
}

.feed-carousels-navigation-arrow {
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    cursor: pointer;
}

.feed-carousels-navigation-arrow:hover {
    background-color: rgb(230, 230, 230);
}

.feed-carousels-navigation-arrow svg {
    padding: 4px;
    border-radius: 50%;
    margin: 4px;
    
}

.feed-carousel-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
}

.feed-carousel-container h4 {
    font-size: 1.1em;
    font-weight: 700;
    margin: 0px;
    margin-bottom: 0.8em;
}

.feed-carousel-display-value-now {
    font-size: 1.4em;
    font-weight: 600;
}

.feed-carousel-display-value-prev {
    font-size: 1em;
    color: rgb(153, 153, 153);
}

.feed-carousel-display-value-updated {
    margin-top: 0.8em;
    font-size: 1em;
}

/* Workspace Team Page */
.workspace-page-full-container {
    margin-top: 12px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
}

.dashboard-page-full-container {
    margin-top: 12px;
    width: 90%;
    font-size: 14px;
}

.workspace-team-full-container {
    width: 100%;
    box-sizing: border-box;
    position: relative;
}
h1.workspace-heading {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 8px;
    white-space: pre-wrap;
}

.workspace-subheading {
    font-size: 14px;
    color: grey;
}

h2.workspace-heading {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 8px;
    white-space: pre-wrap;
}

.workspace-team-form form {
    display: flex;
    flex-direction: column;
}

.workspace-team-form form input[type=submit] {
    width: 100%;
    max-width: 400px;
    margin-top: 12px;
    color: white;
    background-color: rgb(23, 64, 97);
    padding-top: 8px;
    padding-bottom: 8px;
}

.workspace-team-form input,
.workspace-team-form-dropdown {
    border: none;
    width: 100%;
    max-width: 400px;
    font-size: 12px
}

.workspace-team-section {
    margin-bottom: 24px;
    background-color: white;
}

.workspace-team-member-container {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 12px;
    border-bottom: 1px solid grey;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.workspace-team-member-container:first-of-type {
    border-top: 1px solid grey;
    margin-top: 16px;
}

.workspace-team-member-name {
    font-size: 12px;
    transition: all 0.2s;
}

.workspace-team-table-user-container {
    display: flex;
    align-items: center;
    gap: 14px;
}

.workspace-team-table-user-names {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
}

.workspace-team-table-user-status {
    font-weight: 400;
    font-size: 12px;
    margin-top: 4px;
    color: rgb(53, 53, 53);
    width: 100%;
    box-sizing: border-box;
    white-space: wrap;
    line-height: 1.5;
}

.workspace-team-table-user-name-first {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
}

.workspace-team-table-user-name-first:hover {
    text-decoration: underline;
}

.workspace-team-member-name:hover {
    text-decoration: underline;
    cursor: pointer;
}

.workspace-team-member-remove {
    transition: all 0.2s;
}

.workspace-team-member-remove:hover {
    cursor: pointer;
    color:rgb(23, 64, 97);
}

.workspace-team-member-leave {
    font-weight: 700;
    font-size: 0.8em;
    text-transform: uppercase;
    cursor: pointer;
    /* padding: 12px 8px; */
    border-radius: 5px;
    transition: all 0.2s;
    color: #2973af;
}

/* End Workspace Team Page */

/* Workspace Settings Page */
.workspace-settings-sections {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 640px;
}

.workspace-settings-section {
    border-bottom: 1px solid rgb(209, 209, 209);
    padding-bottom: 16px;
}

.workspace-settings-section h2 {
    margin: 0;
    font-size: 14px;
    color: grey;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 4px;
}

.workspace-settings-section h3 {
    margin: 0;
    font-size: 14px;
    color: grey;
    font-weight: 500;
    margin-bottom: 12px;
}

.workspace-settings-heading-row-button {
    padding: 4px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 4px;
    cursor: pointer;
}

.workspace-settings-heading-row-button:hover {
    background-color: rgb(245, 245, 245);
}

.markdown-wrapper-description-settings {
    border: 1px solid rgb(189, 189, 189);
    background-color: rgb(250, 250, 250);
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 4px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.workspace-settings-banner-image-preview {
    width: 412px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.workspace-settings-banner-image-preview img {
    width: 100%;
    object-fit: cover;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.workspace-settings-banner-edit-modal-inner-container {
    min-width: 800px;
    /* width: 30%; */
    margin-top: 120px;
    /* margin-bottom: 5%; */
    min-height: 120px;
    /* max-height: 90%; */
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    z-index: 101;
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.workspace-settings-banner-edit-modal-picture-container {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 720px;
    /* max-height: 100px; */
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    align-content: center;
}

.workspace-settings-banner-edit-modal-picture-container img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.markdown-wrapper-description-settings,
.modal-workspace-onboarding-message-container {
    font-size: 14px;
    line-height: 1.8;
}

.markdown-wrapper-description-settings h1,
.modal-workspace-onboarding-message-container h1,
.feed-right-side-about-container h1 {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-bottom: 14px;
}

.markdown-wrapper-description-settings h2,
.modal-workspace-onboarding-message-container h2,
.feed-right-side-about-container h2 {
    font-size: 16px;
    color: #555;
    font-weight: 500;
    margin-bottom: 8px;
}

.markdown-wrapper-description-settings h3,
.markdown-wrapper-description-settings h4,
.markdown-wrapper-description-settings h5,
.markdown-wrapper-description-settings h6,
.modal-workspace-onboarding-message-container h3,
.modal-workspace-onboarding-message-container h4,
.modal-workspace-onboarding-message-container h5,
.modal-workspace-onboarding-message-container h6,
.feed-right-side-about-container h3,
.feed-right-side-about-container h4,
.feed-right-side-about-container h5,
.feed-right-side-about-container h6 {
    font-size: 16px;
    color: #555;
    font-weight: 500;
    margin-bottom: 8px;
}

.markdown-wrapper-description-settings p,
.markdown-wrapper-description-settings li,
.modal-workspace-onboarding-message-container p,
.modal-workspace-onboarding-message-container li,
.feed-right-side-about-container p,
.feed-right-side-about-container li {
    font-size: 14px;
    color: #777;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;
}

.workspace-settings-heading-row-with-toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.workspace-settings-section-editable-text {
    font-size: 16px;
    font-weight: 500;
    padding: 4px;
    border: 1px solid rgb(179, 179, 179);
    width: 100%;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 4px;
}

.workspace-settings-heading-row-with-toggle h2 {
    margin: 0;
}

.workspace-settings-value {
    font-size: 16px;
}

.workspace-settings-section-save-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: center;
    width: 100%;
}

.workspace-settings-section-save-button {
    padding: 4px;
    font-size: 14px;
    border: 1px solid rgb(155, 155, 155);
    border-radius: 4px;
    background-color: rgb(235, 235, 235);
    cursor: pointer;
}

.workspace-settings-section-save-button:hover {
    background-color: rgb(245, 245, 245);
}

.workspace-settings-section-onboarded-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.workspace-settings-section-onboarded-item {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 14px;
}

.workspace-settings-section-onboarded-item svg {
    font-size: 18px;
}

.workspace-settings-section textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
    border: 1px solid rgb(189, 189, 189);
    border-radius: 4px;
    padding: 4px;
    overflow: hidden;
    font-size: 16px;
    resize: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.workspace-settings-input-and-button-group div {
    flex-basis: 15%;
}

.workspace-settings-buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}

.workspace-settings-button {
    border: 1px solid rgb(23, 64, 97);
    font-weight: 600;
    font-size: 14px;
    color: rgb(23, 64, 97);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
}

.workspace-settings-button-remove {
    color: rgb(181, 40, 40);
}

.workspace-settings-button-remove:hover,
.workspace-settings-team-remove span:hover {
    cursor: pointer;
    text-decoration: underline;
}

.workspace-settings-team-remove {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: rgb(181, 40, 40);
}

.user-settings-button-remove {
    background-color: rgb(181, 40, 40);
    color: white;
    padding: 1em;
    cursor: pointer;
}

.user-settings-license-status {
    color: white;
    background-color: #0258b3;
    padding: 0.5em 1em;
    font-size: 0.8em;
    font-weight: 600;
    border-radius: 1em;
    margin-left: 1em;
    margin-right: 1em;
    text-transform: uppercase;
}

.user-settings-api-key {
    color: white;
    background-color: #7e7e7e;
    padding: 0.5em 1em;
    font-size: 0.8em;
}

.user-settings-api-key-button {
    border: 1px solid rgb(199,199,199);
    padding: 0.2em 0.5em;
    margin-right: 1em;
    cursor: pointer;
}

.user-settings-page {
    padding: 12px 0px;
}

.user-settings-page h2 {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
}

.user-settings-plan {
    margin-top: 14px;
}

.user-settings-plan-benefits {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    margin-top: 20px;
}

.user-settings-plan-benefit {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: stretch;
    border: 1px solid rgb(188, 188, 188);
    border-bottom: none;
}

.user-settings-plan-benefit:last-of-type {
    border-bottom: 1px solid rgb(188, 188, 188);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.user-settings-plan-benefit:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.user-settings-plan-benefit h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: rgb(42, 42, 42);
}

.user-settings-plan-benefit-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-settings-plan-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
}

.user-settings-plan-action {
    background-color: rgb(63, 115, 170);
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    padding: 16px 20px;
    text-align: center;
    width: 180px;
    font-size: 14px;
}

.user-settings-plan-action.user-settings-plan-action-grey {
    background-color: rgb(202, 202, 202);
    color: rgb(20, 20, 20);
}

.user-settings-plan-benefits-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.user-settings-plan-benefits-list div {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.user-settings-plan-benefits-list div svg {
    font-size: 16px;
    color: rgb(63, 115, 170);
    min-width: 20px;
}

/* Plan Selection Page */
.account-plans-header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.account-plans-header svg {
    font-size: 18px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    min-width: 24px;
    color: rgb(54, 54, 54);
}

/* Pro Upgrade Page */
.pro-upgrade-page {
    margin-bottom: 200px;
}

.pro-upgrade-hero-box {
    width: 100%;
    background-color: #253441;
    color: white;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-top: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 6px;
    text-align: center;
}

.pro-upgrade-hero-box h2 {
    margin: 0;
    font-size: 36px;
    font-weight: 400;
}

.pro-upgrade-hero-box h2 span {
    font-weight: 700;
    color: rgba(255, 185, 1, 1);
}

.pro-upgrade-hero-box p {
    color: #c3c6c8;
    font-size: 20px;
}

.pro-upgrade-cta-buttons {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 40px;
}

.pro-upgrade-hero-box button {
    background-color: #fff;
    border: 1px solid #253441;
    color: #253441;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    width: 400px;
    text-align: center;
}

.pro-upgrade-disclaimer {
    font-size: 16px;
    margin-top: 20px;
    color: grey;
    text-align: center;
}

.pro-upgrade-section {
    margin-top: 24px;
    border-top: 1px solid #253441;
    padding-top: 36px;
}

.pro-upgrade-section h3 {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
}

.pro-upgrade-features {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 80%;
    margin: 80px auto;
}

.pro-upgrade-feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.pro-upgrade-feature-image {
    height: 120px;
    width: 120px;
    min-width: 120px;
    border-radius: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}

.pro-upgrade-feature-image svg {
    font-size: 60px;
    color: #40515f;
}

.pro-upgrade-feature-title {
    font-weight: 600;
    font-size: 20px;
}

.pro-upgrade-feature-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: grey;
}

.pro-upgrade-faq {
    margin-top: 48px;
}

.pro-upgrade-faq p {
    margin: 0;
    font-size: 20px;
}

.pro-upgrade-faq p:first-of-type {
    font-weight: 600;
    margin-bottom: 8px;
}

.pro-upgrade-mega-cta-box {
    margin-top: 80px;
}

.pro-upgrade-schedule-text {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.pro-upgrade-schedule-text span {
    color: #174061;
    cursor: pointer;
    text-decoration: underline;
}

.pro-license-info-disclaimer {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pro-license-info-disclaimer button {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.2s;
    margin-bottom: 20px;
}

.pro-license-info-disclaimer button:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.pro-license-info-disclaimer p {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
}

.pro-license-info-disclaimer span {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: rgb(75, 75, 75);
}

.pro-upgrade-tos {
    margin-top: 40px;
}

.pro-upgrade-tos h2 {
    text-align: center;
    margin: 0;
    font-size: 36px;
    margin-bottom: 4px;
}

.pro-upgrade-tos h2 span {
    font-weight: 300;
    text-transform: uppercase;
}

.pro-upgrade-tos p {
    text-align: center;
    margin: 0;
    font-size: 18px;
    color: grey;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid grey;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.pro-upgrade-tos-legalese {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.pro-upgrade-tos-legalese span {
    font-size: 16px;
    line-height: 1.8;
    color: rgb(39, 39, 39);
}

.pro-upgrade-tos-legalese-buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 20px;
}

.pro-upgrade-tos-legalese-buttons button {
    background-color: #253441;
    color: white;
    padding: 8px 32px;
    font-size: 16px;
    border: 1px solid #253441;
    font-weight: 600;
    cursor: pointer;
}

.pro-upgrade-tos-legalese-buttons button:last-of-type {
    color: #253441;
    background-color: white;
}

.workspace-settings-button:hover {
    background-color: rgb(245, 245, 245);
}

.workspace-heading-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.workspace-feedback-subtitle {
    margin-left: 8px;
    font-size: 12px;
    color: grey;
    font-style: italic;
}

/* End Workspace Settings Page */

/* Alignment Chart */
.workspace-panel-alignment-chart {
    background-color: white;
    height: 500px;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 1px solid rgb(199,199,199);
    border-radius: 4px;
}

.workspace-chart-component-type {
    margin: 0px;
    text-transform: uppercase;
    text-align: center;
    width: 70%;
    font-size: 8px;
    letter-spacing: 1px;
    padding-bottom: 2px;
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid lightgrey;
    margin-left: auto;
    margin-right: auto;
}

.workspace-chart-component-name {
    margin: 0px;
}

.react-flow__node-output .react-flow__handle,
.react-flow__node-input .react-flow__handle,
.react-flow__node-default .react-flow__handle {
    background: none !important;
    border: none !important;
}

/* USER ASSIGNMENTS */
.user-assignment-container-total {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    margin-top: 48px; /* accounts for the header */
}

.user-assignment-page-left-sidebar {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    margin-top: 8px;
}

.user-assignment-page-left-sidebar span {
    font-weight: 600;
    font-size: 14px;
}

.user-assignment-page-left-sidebar span:first-of-type {
    cursor: pointer;
}

.user-assignment-page-left-sidebar span:first-of-type:hover {
    text-decoration: underline;
}

.user-assignments-left-sidebar-section-header {
    font-size: 1em;
    font-weight: 700;
    color: rgb(44, 44, 44);
}

.user-assignment-page-main-content {
    padding-right: 12px;
}

.user-assignment-page-main-header {
    /* border-bottom: 1px solid rgb(199,199,199); */
    padding-bottom: 4px;
}

.user-assignment-page-main-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.user-assignment-page-main-filter {
    padding: 4px 12px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
}

.user-assignment-page-main-filter.user-assignment-page-main-filter-selected {
    background-color: rgb(233, 233, 233);
    font-weight: 700;
}

.user-assignments-header-workspaces-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.user-assignments-header-workspace-name {
    font-size: 0.9em;
    text-align: left;
    white-space: pre;
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: all 0.1s;
}

.user-assignments-header-workspace-name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.user-assignments-header-workspace-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.user-assignment-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px 24px;
    padding: 0px 24px;
}

.user-assignment-card-column {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 12px;
}

/* WORKSPACE SEARCH */
.workspace-search-container-responsive-width {
    --workspace-search-footer-height: 44px;
    --workspace-search-header-height: 80px;
    --workspace-search-border-colors: rgb(184, 184, 184);
    width: 95%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 0px 4px 10px 0px rgba(115, 115, 115, 0.25);
    border-radius: 24px;
}

@media only screen and (min-width: 1100px) {
    .workspace-search-container-responsive-width {
        width: 85%;
        max-width: 1000px;
    }
}

.workspace-search-header {
    height: var(--workspace-search-header-height);
    border-bottom: 1px solid var(--workspace-search-border-colors);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.workspace-search-header-inner,
.workspace-search-footer-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
}

.workspace-search-footer {
    height: var(--workspace-search-footer-height);
    border-top: 1px solid var(--workspace-search-border-colors);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.workspace-search-content-section {
    font-size: 14px;
}

.workspace-search-content-section h2 {
    margin: 0;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
}

.workspace-search-empty-searches-container {
    width: 100%;
}

.workspace-search-recent-searches {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: stretch;
}

.workspace-search-recent-search {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
}

.workspace-search-recent-search:hover {
    background-color: rgba(54, 54, 54, 0.067);
}

.workspace-search-footer-inner {
    justify-content: center;
}

.workspace-search-footer span {
    font-size: 12px;
    color: #8A8F9F;
}

.workspace-search-header-actions {
    display: flex;
    align-items: center;
    gap: 24px
}

.workspace-search-header-action {
    width: 36px;
    height: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
}

.workspace-search-header-action:hover {
    background-color: rgb(237, 237, 237);
}

.workspace-search-header-action svg {
    font-size: 24px;
    color: rgb(96, 96, 96);
}

.workspace-search-main-searchbar {
    flex-grow: 1;
    position: relative;
}

.workspace-search-main-searchbar input {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border: none;
    outline: none;
    padding-left: 48px;
    background-color: unset;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 24px;
}

.workspace-search-main-searchbar input:focus {
    border: none;
    outline: none;
    background-color: rgba(75, 151, 244, 0.111);
}

.workspace-search-main-searchbar-icon {
    position: absolute;
    top: 13px;
    left: 16px;
}

.workspace-search-main-searchbar-icon svg {
    font-size: 20px;
    color: grey;
}

.workspace-search-content {
    position: absolute;
    top: var(--workspace-search-header-height);
    bottom: var(--workspace-search-footer-height);
    left: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
}

.workspace-search-result {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.workspace-search-result-info span {
    font-size: 12px;
    font-weight: 400;
    color: grey;
}

.workspace-search-result:hover {
    background-color: rgba(54, 54, 54, 0.067);
}

.workspace-search-result-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-grow: 1;
}

.workspace-search-result-info-name {
    font-size: 16px;
    font-weight: 600;
}

.workspace-search-result-info-subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* DATA TABLES */
.data-table-full-container {
    background-color: rgba(238, 238, 238, 0.082);
}

.data-table-full-container-dashboard {
    margin-top: 16px;
}

.data-table-header-container {
    border-bottom: 1px solid rgb(219, 219, 219);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 1em;
    font-weight: bold;
    justify-content: space-between;
    padding-bottom: 4px;
}

.data-table-header-container span:first-of-type,
.data-table-row-container span:first-of-type {
    text-align: left;
    padding-right: 4px;
}

.data-table-header-container span,
.data-table-row-container span {
    text-align: left;
}

.data-table-header-container span:last-of-type,
.data-table-row-container span:last-of-type {
    text-align: right;
}

.data-table-row-container {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid rgb(219, 219, 219);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.data-table-row-special-hover {
    transition: 0.2s;
}

.data-table-row-special-hover:hover {
    background-color: rgba(151, 151, 151, 0.075);
}

.data-table-row-cell {
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    text-overflow: ellipsis;
    font-size: 14.4px;
    padding-right: 8px;
    margin-right: 12px;
}

.data-table-row-cell:last-child {
    margin-right: 0;
}

/* Makes a row colorful on hover */
.data-table-col-lg {
    overflow: hidden;
    flex-basis: 60%;
    flex-grow: 3;
}

.data-table-col-md {
    overflow: hidden;
    flex-basis: 30%;
    flex-grow: 2;
}

.data-table-col-sm {
    overflow: hidden;
    flex-basis: 10%;
    flex-grow: 1;
}

/* Special Data Table Entry Classes */
.data-table-red-pill,
.data-table-yellow-pill,
.data-table-blue-pill,
.data-table-green-pill,
.data-table-purple-pill,
.data-table-basic-pill {
    border-radius: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.data-table-capitalize {
    text-transform: capitalize;
}

.data-table-basic-pill {
    background-color: rgb(166, 215, 255);
    color: rgb(58, 58, 58);
}

.data-table-red-pill {
    background-color: rgb(138, 23, 23);
    color: white;
}

.data-table-blue-pill {
    background-color: #0258b3;
    color: white;
} 

.data-table-green-pill {
    background-color: #28a745 ;
    color: white;
} 

.data-table-yellow-pill {
    background-color: #ffc107 ;
    color: rgb(58, 58, 58);
} 

.data-table-purple-pill {
    background-color: rgb(105, 20, 101);
    color: white;
} 

.workspace-alignment-flow-container {
    margin-top: 24px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
}

.workspace-alignment-flow-container h2 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 8px;
}

.workspace-alignment-flow-graph {
    height: 800px;
    background-color: white;
    border: 1px solid rgb(199,199,199);
    border-radius: 4px;
}

/* Workspace Settings */
.workspace-panel-settings {
    width: 50%;
    min-width: 750px;
}

.workspace-panel-settings-section-horiz {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.workspace-panel-settings-section h2 {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
}

.workspace-panel-settings-section-heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

.workspace-panel-settings-section p {
    margin-bottom: 0px;
    margin-top: 0px;
    color: rgb(55, 85, 109);
}

.workspace-panel-settings-section-option-horiz {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}

.workspace-panel-setting h3 {
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(199, 199, 199);
}

.workspace-panel-setting:first-of-type {
    padding-top: 0em;
}

.workspace-panel-setting {
    padding-bottom: 2em;
    padding-top: 2em;
}

.workspace-panel-setting-mini {
    flex-basis: 45%;
}

.workspace-panel-settings-status-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
}

.workspace-panel-setting-dates {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: center;
    gap: 16px;
}

.workspace-panel-setting-iconography-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.workspace-panel-setting-action-launch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 1em;
    font-weight: 600;
    background-color: #2973af;
    border-radius: 12px;
    cursor: pointer;
    padding: 0.5em 1em;
    text-align: center;
    color: white;
    box-sizing: border-box;
    width: 100%;
}

.workspace-panel-setting-action-launch svg {
    color: white;
}

.workspace-panel-setting-action-launch-cancel {
    font-size: 0.9em;
    color:rgb(49, 113, 165);
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
}

.workspace-panel-setting-borders {
    border-bottom: 1px solid rgb(161, 161, 161);
}

.workspace-panel-setting-iconography-and-text svg {
    color: rgb(105, 105, 105);
    font-size: 1.1em;
}

.workspace-panel-setting-iconography-and-text div {
    font-size: 0.9em;
}

.workspace-panel-setting-update-buttons {
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 0.8em;
    font-weight: 700;
    gap: 8px;
}

.workspace-panel-setting-save-button {
    color: #2973af;
    padding: 12px 8px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}

.workspace-panel-setting-save-button:hover {
    background-color: rgb(232, 232, 232);
}

.workspace-panel-settings-section-option-text h4 {
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
}

.workspace-panel-settings-section-option-text p {
    margin-bottom: 0px;
    color: grey;
}

.workspace-panel-settings-section-option {
    margin-top: 12px;
    min-height: 32px;
}

.workspace-panel-settings-section-option input[type='date'] {
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
    border-bottom: 1px solid rgb(199,199,199);
    font-size: 1em;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 12px;
    padding-left: 4px;
    box-sizing: border-box;
}

.workspace-panel-settings-section-option select {
    border: none;
    border-bottom: 1px solid rgb(199,199,199);
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 12px;
    font-size: 1em;
    cursor: pointer;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    box-sizing: border-box;
}

.workspace-panel-settings-section-option input[type='radio'] { 
    transform: scale(1.4);
    margin-top: 6px;
}

.workspace-panel-settings-section-option input[type='text'] { 
    height: 36px;
    width: 100%;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 4px;
    padding: 6px;
    font-size: 1em;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.workspace-panel-settings-colors {
    display: flex;
    flex-direction: row;
    width: 200px;
    gap: 8px;
    flex-wrap: wrap;
}

.workspace-panel-settings-color {
    box-sizing: border-box;
    width: 32px;
    border-radius: 50%;
    height: 32px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.workspace-panel-settings-color:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 4px 6px;
}

.workspace-panel-settings-color-selected {
    border: 3px solid rgb(51, 51, 51);
}

.workspace-panel-settings-section-option input[type='radio']:hover {
    cursor: pointer;
}

.workspace-panel-setting-icon-button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 24px;
}

.workspace-panel-setting-icon-button-container div {
    cursor: pointer;
    align-self: flex-start;
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-weight: 500;
    font-size: 14px
}

.workspace-panel-setting-icon-button-container div:hover {
    text-decoration: underline;
}

/* TAGS PAGE */
.workspace-tags-page {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 40px;
}

.workspace-tags-header-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.workspace-tags-header-row h1 {
    margin: 0;
    color: #2A73AE;
    font-size: 40px;
    font-weight: 700;
}

.workspace-tags-not-loaded {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 200px;
}

.workspace-tags-empty-container {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    max-width: 800px;
    margin-top: 40px;
}

.workspace-tags-empty-text {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    margin-bottom: 6px;
}

.workspace-tags-empty-text svg {
    font-size: 32px;
}

.workspace-tags-empty-container p {
    margin: 0;
    color: grey;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 48px;
}

.workspace-tags-empty-text-instructions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 2px solid rgb(221, 221, 221);
    background-color: #f1f1f1;
    color: rgb(37, 37, 37);
    border-radius: 4px;
    padding: 16px 28px;
    box-sizing: border-box;
}

.workspace-tags-empty-text-instructions div {
    font-size: 16px;
}

.workspace-tags-component-table {
    border-top: 1px solid rgb(184, 184, 184);
    padding-top: 8px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */

    #project-header-container {
        height: 48px;
    }

    #project-header-project-name {
        font-size: 12px;
    }

    #project-header-right .project-header-right-nav {
        font-size: 14px;
    }

    #project-lower-right { 
        font-size: 0.9em;
    }

    h1.workspace-heading {
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 8px;
    }

    h2.workspace-heading {
        font-size: 14px;
        padding-bottom: 4px;
        border-bottom: 1px solid grey;
    }

    p.workspace-heading {
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 12px;
        font-weight: 600;
    }

    .workspace-team-form input,
    .workspace-team-form-dropdown {
        width: 100%;
        max-width: 600px;
        font-size: 14px
    }

    .workspace-team-form form {
        flex-direction: row;
        align-items: center;
    }

    .workspace-team-form form input[type=submit] {
        width: 200px;
        max-width: 400px;
        margin-top: 0px;
        margin-left: 12px;
    }

    .workspace-team-member-name {
        font-size: 14px;
    }

    .workspace-panel-sort-item-desktop {
        display: initial;
    }
    
}

@media only screen and (min-width: 768px) {

    #project-container {
        margin-left: 240px;
    }

    #project-header-container {
        height: 48px;
    }

    #project-header-project-name {
        font-size: 14px;
    }

    #project-lower-right { 
        font-size: 0.9em;
    }

    .project-panel-alignment-grouping {
        margin-bottom: 20px;
    }
    
    .project-panel-alignment-grouping .project-panel-alignment-group-heading h2 {
        font-size: 16px;
    }
    
    .project-panel-alignment-grouping .project-panel-alignment-group-heading span {
        font-size: 14px;
        margin-left: 12px;
        border-radius: 4px;
        padding: 2px;
        height: 16px;
        width: 16px;
    }

    .project-panel-feed-container {
        /* max-width: 700px; */
        margin-left: auto;
        margin-right: auto;
    }

    h1.workspace-heading {
        font-size: 20px;
    }

    h2.workspace-heading {
        font-size: 16px;
    }

    .workspace-team-form input,
    .workspace-team-form-dropdown {
        font-size: 14px
    }

    .workspace-team-member-name {
        font-size: 14px;
    }

    /* Workspace Navigation */    
    .workspace-panel-sort-container {
        display: flex;
        flex-direction: row;
    }
    
    .workspace-panel-sort-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 8px;
        border-radius: 4px;;
        transition: all 0.2s;
    }
    
    .workspace-panel-sort-item:hover {
        cursor: pointer;
    }
    
    .workspace-panel-sort-item span {
        margin-left: 4px;
    }
    
    .workspace-panel-nav-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 4px;
        padding-bottom: 2px;
    }
        
}

/* WORKSPACE LIBRARY */
.workspace-library {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    box-sizing: border-box;
    width: 100%;
}

.workspace-library-display-container {
    flex-grow: 1;
}

.workspace-library-section-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.workspace-library-section-header h1 {
    font-size: 20px;
    margin: 0;
    line-height: 1;
    color: var(--t-color-dark);
}

.workspace-library-section-header p {
    color: grey;
    font-size: 14px;
    margin: 0;
}