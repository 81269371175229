.public-component-page {
    width: 100%;
    padding-bottom: 24px;
}

.public-component-redirect-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 320px;
    padding: 12px;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    gap: 24px;
}

.public-component-redirect-message {
    font-size: 16px;
    font-weight: 400;
}

.public-component-redirect-action {
    background-color: #174061;
    padding: 12px 24px;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.public-component-container-main {
    position: relative;
    margin-top: 48px;
}

.public-component-heading {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 0px;
    padding-top: 60px;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(217, 217, 217);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 48px;
    background-color: white;
}

.public-component-summary-section {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid rgb(217, 217, 217);
}

.public-component-heading h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.public-component-subheading-workspace {
    text-align: center;
    font-size: 13px;
    color: grey;
}

.public-component-heading-decorators {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
    padding-top: 12px;
}

.public-component-heading-decoration {
    display: flex;
    align-items: center;
    gap: 8px;
}

.public-component-heading-decoration svg {
    font-size: 14px;
    min-width: 16px;
    width: 16px;
    color: grey;
}

.public-component-heading-decoration span {
    font-size: 12px;
    color: grey;
    text-transform: capitalize;
}

.public-component-heading-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
    font-size: 14px;
}

.public-component-heading-description {
    font-size: 13px;
    line-height: 1.5;
    margin-top: 1em;
}

.public-component-heading-description p {
    margin: 0;
}

.public-component-display-area {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 800px;
    margin-top: 36px;
    box-sizing: border-box;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(217, 217, 217);
}

.public-component-double-wide-sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.public-component-single-section {
    margin-top: 48px;
}

@media screen and (max-width: 800px) {
    .public-component-heading { top: 0px }
    .public-component-page { padding-left: 12px; padding-right: 12px; width: 100%; box-sizing: border-box; }
    .public-component-double-wide-sections { flex-direction: column; gap: 24px; }
    .public-component-heading-decorators { gap: 4px; flex-direction: column; justify-content: flex-start; align-items: flex-start; }
}

.public-component-double-wide-section {
    flex-basis: 40%;
}

.public-component-display-heading {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
}

.public-component-display-workstreams {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.public-component-child-heading {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
}

.public-component-workstream-progress-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.public-component-child-subtext {
    font-size: 12px;
    color: grey;
}

.public-component-goal-container {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
}

.public-component-empty-text {
    font-size: 13px;
    text-align: center;
}

.public-component-display-comments {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
}

.public-component-display-comment {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.public-component-display-comment-content-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.public-component-display-comment-content-author {
    font-size: 13px;
    font-weight: 600;
}

.public-component-display-comment-content {
    font-size: 13px;
    line-height: 1.5;
    white-space: pre;
    text-wrap: wrap !important;
}