.form-new-component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Used to control the width of the center form */
.form-new-component-width-control {
    width: 50%;
}

.form-new-component-heading {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 8px;
}

.form-new-component-subheading {
    font-size: 18px;
    margin-bottom: 8px;
}

.form-new-component-required {
    color: rgb(187, 0, 0);
}

.form-new-component-section {
    margin-top: 32px;
}

.form-new-component-section-row-single-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-new-component-section-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
}

.form-new-component-section-description {
    font-size: 18px;
}

.form-new-component-section-inline-title {
    font-size: 18px;
    margin-left: 12px;
}

.form-new-component-text-input-basic {
    border: none;
    width: 100%;
    border-bottom: 1px solid rgb(199,199,199);
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 8px;
    font-size: 18px;
}

.form-import-input-api-key {
    text-align: center;
    border: unset;
    background-color: rgb(230,230,230);
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 1em;
    /* background-color: #2973af2a; */
}

.form-new-component-section-dropdown {
    border: 1px solid rgb(199,199,199);
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 8px;
    font-size: 18px;
    width: 100%;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-new-component-text-input-textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 6em;
    border: 1px solid rgba(78, 78, 78, 0.589);
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    font-size: 18px;
    margin-top: 20px;
    resize: vertical;
    outline: none;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-new-component-section-date-single-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form-new-component-section-date-single-feedback {
    padding: 12px 0px;
    border-radius: 4px;
    margin-right: 12px;
    width: 50%;
}

.fonecosecdasife-date {
    font-size: 28px;
    font-weight: 300;
    padding-bottom: 8px;
    border-bottom: 1px solid rgb(199,199,199);
}

.fonecosecdasife-mention {
    font-size: 14px;
    color: grey;
    margin-top: 12px;
    margin-bottom: 12px;
}

.form-new-component-section-date-single-calendar-reset {
    font-size: 12px;
    text-transform: uppercase;
    color: #2973af;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.form-new-component-section-date-single-calendar-reset span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.form-new-component-section-date-range {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 48px;
}

.form-new-component-submission-section {
    margin-top: 24px;
    padding-top: 12px;
    display: flex;
    flex-direction: row-reverse;
}

.form-new-component-navigation-section {
    margin-top: 24px;
    padding-top: 12px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.form-import-back-button {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0.5em;
    transition: all 0.2s;
    border-radius: 4px;
}

.form-import-back-button:hover {
    background-color: rgb(199,199,199);
}

.form-trello-disclaimers {
    margin-top: 2em;
    background-color: rgb(235, 235, 235);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1em;
    gap: 1em;
}

.form-trello-disclaimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    font-size: 0.9em;
}

.form-trello-disclaimer-in svg {
    color: #049921;
}

.form-trello-disclaimer-out svg {
    color: #fc636b;
}

.form-trello-disclaimer svg {
    font-size: 1.5em;
}

.form-trello-disclaimers-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.form-new-component-submit,
.form-new-component-cancel {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12px 8px;
    border-radius: 5px;
    transition: all 0.2s;
}

.form-network-create-form-page-navigation-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}

.form-network-create-form-cancel {
    /* background-color: rgb(56, 101, 153); */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    border-radius: 8px;
    color: #727272;
    /* width: 100%; */
    padding: 0.5em;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    box-sizing: border-box;
}

.form-network-alignment-container {
    border: 2px solid transparent;
    background-color: rgb(247, 247, 247);
    border-radius: 8px;
    padding: 8px;
    transition: all 0.2s;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form-network-alignment-project-option-summary-group {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.form-network-alignment-project-option-summary-icon {
    min-width: 32px;
    width: 32px;
    font-size: 24px;
    color: green;
}

.form-network-alignment-project-option-summary {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.form-network-alignment-project-option-name {
    font-weight: 700;
}

.form-network-alignment-project-option-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: grey;
    font-size: 12px;
    font-weight: 400;
}

.form-network-alignment-project-option-arrow {
    font-size: 20px;
}

.form-network-alignment-container-selected {
    border: 2px solid rgb(221, 221, 221);
    background-color: rgb(238, 238, 238);
}

.form-network-alignment-outer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.form-network-alignments-vertical-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form-network-alignment-project-list {
    flex-basis: 40%;
}

.form-network-alignment-project-selected-information {
    flex-basis: 60%;
    /* border: 1px solid rgb(202, 202, 202); */
    border-left: 1px solid rgb(202, 202, 202);
    margin-left: 12px;
    box-sizing: border-box;
    /* border-radius: 8px; */
    padding: 24px 16px;
}

.form-network-alignment-project-detailed-name {
    font-size: 28px;
    align-self: center;
    font-weight: 400;
    text-align: center;
    padding-bottom: 4px;
    /* border-bottom: 1px solid grey; */
    cursor: pointer;
    color:rgb(44, 104, 160);
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
}

.form-network-alignment-project-detailed-date-range {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
    color: grey;
}

.form-network-alignment-project-detailed-date-range svg {
    font-size: 20px;
}

.form-network-alignment-project-detailed-description {
    font-size: 14px;
    overflow: hidden;
    word-break: break-all;
    white-space: pre-wrap;
    margin-top: 12px;
    margin-bottom: 12px;
}

.form-network-alignment-project-detailed-progress {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.form-network-alignment-project-detailed-alignment-button-instructions {
    text-align: center;
    color: grey;
}

.form-network-alignment-project-detailed-alignment-removal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.form-network-alignment-project-detailed-alignment-removal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 32px;
    height: 14px;
}

.form-network-alignment-project-detailed-alignment-removal-icon {
    text-align: center;
    cursor: pointer;
    font-size: 32px;
    padding: 8px;
    margin-left: 8px;
    margin-right: 8px;
    color: rgb(87, 87, 87);
    background-color: rgb(233, 233, 233);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.form-network-alignment-project-detailed-alignment-removal-line-outer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.form-network-alignment-project-detailed-alignment-removal-line-outer div {
    width: 100%;
    flex-basis: 50%;
}

.form-network-create-form-cancel:hover {
    text-decoration: underline;
}

.form-new-component-submit:hover,
.form-new-component-cancel:hover {
    background-color: rgb(232, 232, 232);
    cursor: pointer;
}

.form-new-component-submit {
    color: #2973af;
}

.form-new-component-cancel {
    color: #727272;
    margin-right: 8px;
}

form label {
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: 12px;
    font-weight: bold;
}

.form-container-centered {
    position: relative;
    margin-top: 80px;
    width: 80%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
}

.form-import-stage-containers {
    width: 100%;
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.form-import-stage-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    border-radius: 8px;
    padding: 0.5em;
}

.form-import-stage-container h3 {
    margin: 0px;
    margin-bottom: 0.5em;
    font-size: 1em;
    text-transform: uppercase;
}

.form-import-stage-container button { 
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.5em;
    font-size: 1em;
    cursor: pointer;
    background-color: #2973af;
    border: none;
    color: white;
}

.form-import-copy-button {
    cursor: pointer;
    background-color: lightgrey;
    border: 1px solid grey;
    color: black;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.5em;
    font-size: 1em;
    transition: all 0.2s;
    width: 25%;
    text-align: center;
}

.form-import-copy-button:hover {
    background-color: rgb(155, 155, 155);
}

.login-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container-panes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding-top: 120px;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.login-container-redirect-message {
    position: absolute;
    top: 0;
    color: #FC636B;
    font-weight: 500;
    font-size: 14px;
    padding: 12px;
    background-color: #fc636b34;
    border-radius: 4px;
    cursor: default;
}

.login-container-pane-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-left: 20px;
    padding-left: 20px;
}

.login-container-pane-form {
    flex-grow: 1;
}

.login-container-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.form-container-component-new {
    width: 80%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto; 
}

.login-application-name {
    font-size: 24px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 4px;
    color:rgb(23, 64, 97);
}

.login-application-subtitle {
    font-size: 16px;
    line-height: 1.8em;
}

.login-container-hero {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    text-align: left;
    color: rgb(41, 51, 60);
}

.login-container-form-button {
    color: rgb(58, 58, 58);
    background-color: rgb(196, 196, 196);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    align-self: flex-start;
    padding: 8px 20px;
    border-radius: 6px;
    margin-bottom: 14px;
    cursor: not-allowed;
}

.login-container-form-button.login-container-form-button-active {
   background-color: rgb(63, 115, 170);
   color: rgb(246, 246, 246);
   cursor: pointer;
}

.login-container-form-button-nosub {
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    margin-top: 12px;
    background-color: rgb(196, 196, 196);
    transition: all 0.2s;
}

.login-container-form-button-nosub:hover {
    cursor: not-allowed;
}

.form-container-centered form,
.form-container-component-new form {
    display: flex;
    flex-direction: column;
}

.form-container-centered form input,
.form-container-component-new form input,
.form-container-component-new form select,
.login-container-form-text {
    margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    border: none;
    border-bottom: 1px solid rgba(78, 78, 78, 0.589);
    font-size: 14px;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: rgba(242, 242, 242, 0.515); */
}

.login-container-form-text {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(78, 78, 78, 0.589);
    border-radius: 4px;
    padding: 12px 8px;
    font-size: 16px;
}

.form-container-component-new form select {
    width: 20%;
    text-align: center;
}

.form-container-component-new form textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 6em;
    margin-bottom: 16px;
    border: 1px solid rgba(78, 78, 78, 0.589);
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    font-size: 14px;
    resize: none;
    outline: none;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-container-centered form input[type=submit],
.form-container-component-new form input[type=submit],
.project-component-publish-button {
    margin-bottom: 16px;
    border: 1px solid rgb(0, 0, 0);
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: all 0.2s;
}

.form-container-component-new form input[type=date] {
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.project-component-publish-button {
    text-align: center;
}

.form-container-centered form input[type=submit]:hover,
.form-container-component-new form input[type=submit]:hover,
.project-component-publish-button:hover {
    background-color: rgb(206, 206, 206);
    cursor: pointer;
}

.form-text-link {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 12px;
    text-decoration: none;
}

.form-text-link:hover,
.login-container-form-text-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.form-container-feed-post input {
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(240, 240, 240, 0.659);
    border: 1px solid rgb(230, 230, 230);
    padding-left: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    border-radius: 4px;
}

.form-single-entry-text-input-and-button {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    box-sizing: border-box;
}

.form-single-entry-text-input-and-button input {
    flex-grow: 3;
    margin-right: 8px;
}

.form-single-entry-text-input-and-button button {
    font-size: 14px;
    flex-grow: 2;
    padding-top: 4px;
    padding-bottom: 4px;
    border: none;
    background-color: rgb(23, 64, 97);
    color: white;
    border-radius: 4px;
}

.form-input-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.form-input-row-container * {
    margin-bottom: 0px;
    margin-top: 0px;
}

.form-input-row-container:last-of-type {
    margin-bottom: 24px;
}

.form-input-row-container input,
.form-input-row-container input[type=checkbox] {
    margin-bottom: 0px;
}

.form-new-component-input-group {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 24px;
}

.form-new-component-input-group .form-input-row-container {
    margin-bottom: 0px;
    margin-top: 0px;
}

.form-new-component-input-group-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 8px;
}

.form-new-component-input-group-header p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
}

.form-new-component-input-group-header-close {
    color: rgb(88, 19, 19);
}

.form-new-component-input-group-header-close:hover {
    cursor: pointer;
}

.form-new-component-input-group-closed {
    transition: all 0.2s;
}

.form-new-component-input-group-closed:hover {
    background-color: rgb(226, 226, 226);
    cursor: pointer;
}

/* Template Selection */
.form-templates-grid {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.form-template-container-outer {
    border-radius: 6px;
    padding: 8px;
    flex-basis: 30%;
    cursor: pointer;
    border: 1px solid rgb(226, 226, 226);
    transition: all 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-template-selected {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgba(71, 128, 163, 0.24);
}

.form-option-import {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form-template-name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
}

.form-template-description {
    font-size: 14px;
    color: rgb(95, 99, 104);
    font-weight: 500;
}

.form-template-age {
    color: grey;
    font-size: 12px;
    margin-top: 12px;
}

/* New Network Form */
.form-network-create-container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-network-create-header-nav-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em;
    margin: 0.5em;
    cursor: pointer;
    color: grey;
    border-radius: 4px;
    transition: all 0.2s;
}

.form-network-create-header-nav-back svg {
    font-size: 1.5em;
}

.form-network-create-header-nav-back:hover {
    background-color: rgb(235, 235, 235);
}

.form-network-create-main {
    box-sizing: border-box;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
}

.form-network-create-main-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid grey;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.form-network-create-main-header h2 {
    margin: 0px;
    font-weight: 600;
}

.form-network-create-main-header svg {
    font-size: 1.5em;
    color: grey;
}

.form-network-create-main-description {
    font-size: 1em;
    color: white;
    padding: 1em;
    margin: 1em 0;
    font-weight: 500;
    background-color: rgb(56, 101, 153);
}

.form-network-create-form-section {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-top: 2em;
    margin-bottom: 1em;
}

.form-network-create-form-subsection {
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
}

.form-network-create-form-subsection-title {
    font-weight: 600;
    font-size: 0.9em;
    color: rgb(146, 146, 146);
}

.form-network-create-form-subsection-divider-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.form-network-create-form-subsection-divider {
    border-bottom: 1px solid rgb(199,199,199);
}

.form-network-create-form-section-radio-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.form-network-create-form-section-radio-item {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 18px 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: 180px;
    transition: all 0.1s;
    font-weight: 600;
    font-size: 14px;
}

.form-network-create-form-radio-item-subtitle {
    font-size: 14px;
    color: grey;
    margin-top: 12px;
}

.form-network-create-form-section-radio-item-selected {
    background-color: rgba(0, 128, 0, 0.062);
    color: green;
}

.form-network-create-form-metric-input-container {
    border-bottom: 1px solid grey;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
}

.form-network-create-form-metric-input-container input {
    border: none;
    flex-grow: 1;
    font-size: 14px;
    padding: 4px;
    width: 80px;
}

.form-create-date-selection-container {
    position: relative;
    border: 1px solid rgb(199,199,199);
    min-width: 10em;
    border-radius: 0.5em;
    padding: 0.2em;
    cursor: pointer;
}

.form-create-date-selection-container svg {
    color: grey;
    font-size: 1.5em;
}

.form-create-date-selection-clickable-display {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;  
}

.form-create-date-description {
    font-size: 1em;
}

.form-create-date-calendar-picker {
    position: absolute;
    box-sizing: border-box;
}

.form-network-create-form-section-label {
    margin: 0px;
    font-size: 0.9em;
    color: rgb(146, 146, 146);
    font-weight: 600;
}

.form-network-create-form-section-label-large {
    margin: 0px;
    font-size: 14px;
    color: rgb(146, 146, 146);
    font-weight: 600;
}

.form-network-create-form-section-input {
    flex-grow: 1;
}

.form-network-create-form-section-input-multi-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    margin-top: 8px;
}

.form-network-create-form-section-input-multi-select-item-container {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.form-network-create-form-section-input-multi-select-item-name {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
}

.form-network-create-form-section-input-multi-select-item-name p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.form-network-create-form-section-input-multi-select-item-name span {
    font-size: 13px;
    font-weight: 500;
    color: grey;
    text-transform: uppercase;
}

.form-network-create-form-section-input-multi-select-item-container svg {
    font-size: 24px;
}

.form-network-create-form-section-input-multi-select-item-container-sel svg {
    color: #2973af;
}

.form-network-create-form-section-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-network-create-form-section-input span {
    font-size: 1.5em;
    padding-bottom: 0.4em;
    padding-top: 0.4em;
}

.form-network-create-form-section-input input {
    width: 100%;
    border: 1px solid rgb(156, 156, 156);
    border-radius: 8px;
    font-size: 1.5em;
    padding: 0.4em;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-network-create-form-section-input select {
    width: 100%;
    border: 1px solid rgb(156, 156, 156);
    border-radius: 8px;
    font-size: 14px;
    padding: 0.4em;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-network-create-form-section-input textarea {
    width: 100%;
    border: 1px solid rgb(156, 156, 156);
    border-radius: 8px;
    font-size: 1.1em;
    box-sizing: border-box;
    padding: 0.4em;
    width: 100%;
    resize: none;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-network-create-form-section-input-placeholder-text {
    font-size: 1.5em;
    color: rgb(0, 123, 161);
}

.form-network-create-form-continue {
    background-color: rgb(56, 101, 153);
    color: white;
    padding: 0.5em;
    cursor: pointer;
}

.form-network-create-form-create {
    background-color: rgb(56, 101, 153);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 8px;
    color: white;
    width: 100%;
    padding: 0.5em;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 2em;
    box-sizing: border-box;
    font-size: 14px;
}

.form-network-create-form-proposed-title-preview {
    font-size: 20px;
    font-weight: 500;
}

.form-network-create-form-slug-feedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.form-network-create-form-slug-feedback svg {
    font-size: 1.5em;
}

.form-network-create-form-slug-feedback-good {
    color: green;
}

.form-network-create-form-slug-feedback-bad {
    color: rgb(139, 35, 35);
}

.network-page-header-back-navigation {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    cursor: pointer;
    color: grey;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
    transition: all 0.2s;
}

.network-page-header-back-navigation:hover { 
    background-color: rgb(240, 239, 239);
}

.form-network-create-form-keyword-bank {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 1em;
    align-items: center;
    justify-content: flex-start;
}

.form-network-create-form-keyword-proposed {
    font-size: 14px;
    padding: 4px 12px;
    color: white;
    background-color: rgb(0, 116, 224);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.form-application-container {
    height: 100vh;
}

.form-application-container-question-outer {
    padding-top: 40vh;
}

.form-application-container-question-inner {
    width: 90%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.form-application-container-question-outer h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 24px;
}

.form-application-container-question-inner input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 28px;
    border-bottom: 1px solid grey;
    background: transparent;
    padding: 12px;
    outline-width: 2px;
}

.form-application-container-question-inner select {
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 28px;
    border: 1px solid rgb(189, 189, 189);
    background: transparent;
    padding: 12px;
    outline-width: 2px;
}

.form-application-container-nav-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.form-application-nav-button {
    padding: 4px 24px;
    background-color: white;
    color: #174061;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-application-nav-button-next {
    background-color: #174061;
    color: white;
}

.form-application-nav-waiting-text {
    font-size: 14px;
    font-weight: 400;
    color: rgb(58, 58, 58);
    text-align: right;
}

.form-application-container-review-answers {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.form-application-container-review-answer-label {
    font-size: 14px;
    font-weight: 600;
}

.form-application-container-review-answer-value {
    font-size: 20px;
    font-weight: 300;
}

.form-application-container-review-consent {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.form-application-container-review-complete-button {
    width: 200px;
    padding: 12px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #174061;
    color: white;
    margin-top: 24px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    form label {
        font-size: 16px;
    }

    .form-container-centered {
        margin-top: 20px;
    }

    .form-container-centered form input,
    .form-container-component-new form input {
        font-size: 18px;
    }

    .form-container-component-new form textarea {
        font-size: 18px;
    }

    .form-text-link {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 16px;
    }

    .form-single-entry-text-input-and-button button {
        font-size: 16px;
    }

}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .form-container-centered {
        margin-top: 240px;
    }

    .form-container-component-new {
        margin-top: 20px;
    }

    .login-container-panes {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 60px;
        padding-top: 200px;
    }

    .login-application-name {
        font-size: 42px;
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 24px;
        color:rgb(23, 64, 97);
    }

    .login-application-subtitle {
        font-size: 24px;
        width: 500px;
        line-height: 1.8em;
    }
}

@media only screen and (min-width: 1100px) {
    .form-network-create-main {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0px;
        padding-right: 0px;
    }

    .form-network-create-form-create {
        width: 25%;
        margin-left: auto;
        margin-right: auto;
    }
}