/* GADGET SWITCH */
.gadget-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

.gadget-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.gadget-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.gadget-slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
}

.gadget-description-hashtag {
    color: red;
}

.gadget-switch input:checked + .gadget-slider {
    background-color: #174061;
}

.gadget-switch input:focus + .gadget-slider {
    box-shadow: 0 0 1px #174061;
}

.gadget-switch input:checked + .gadget-slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

.gadget-slider.gadget-round {
    border-radius: 17px;
}
  
.gadget-slider.gadget-round:before {
    border-radius: 50%;
}

/* GADGET: Collapsible List Section */
.gadget-collapsible-list-section-headings {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
    width: 100%;
    margin: 12px 0px;
    --ml-headings-color: rgb(52, 52, 52);
}

.gadget-collapsible-list-section-headings h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: var(--ml-headings-color);
}

.gadget-collapsible-list-section-headings svg {
    color: var(--ml-headings-color);
    font-size: 28px;
    padding: 6px;
    cursor: pointer;
}

.user-mydesk-assignments-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.gadget-collapsible-list-section-heading-decoration-row {
    display: flex;
    gap: 8px;
    align-items: center;
}

.gadget-collapsible-list-section-headings p {
    color: grey;
    font-size: 12px;
    margin: 0;
    margin-top: 2px;
}

.gadget-collapsible-list-section-heading-decoration-row span {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: rgb(131, 131, 131);
    color: white;
}

/* GADGET TOOLTIP */
.gadget-tooltip-container-outer {
    background-color: grey;
    position: absolute;
}

.gadget-tooltip-text {
    position: absolute;
    width: 120px;
    font-size: 12px;
    z-index: 99;
    display: block;
    background-color: grey;
    color: white;
    padding: 8px;
    border-radius: 8px;
}

.gadget-tooltip-text-bottom {
    top: 2em;
    left: -40px;
}

.gadget-hoverable-gadget-hoverable-tooltip-wrapper {
    display: inline-block;
    position: relative;
    overflow: visible !important;
    --tooltip-text-color: white;
    --tooltip-background-color: grey;
    --tooltip-arrow-size: 6px;
    --tooltip-margin: 100%;
}

.gadget-hoverable-tooltip-text-box {
    position: fixed;
    z-index: 400;
    padding: 8px;
    color: white;
    background-color: grey;
    font-size: 12px;
    border-radius: 4px;
}

/* OWNER SELECTOR DROPDOWN */
.gadget-owner-selector-dropdown-container {
    position: relative;
}

.gadget-owner-selector-clickable-container {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 48px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.gadget-owner-selector-clickable-container-remove-owner-button {
    z-index: 2;
    padding: 4px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}

.gadget-owner-selector-clickable-container-remove-owner-button:hover {
    background-color: rgba(211, 57, 57, 0.384);
}

.gadget-owner-selector-clickable-container-owner-info {
    gap: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
}

.gadget-owner-selector-search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgb(199,199,199);
}

.gadget-owner-selector-height-control {
    height: 56px;
    box-sizing: border-box;
    padding: 4px;
}

.gadget-owner-selector-height-control-small {
    height: 40px;
    box-sizing: border-box;
    padding: 4px;
}

.gadget-owner-selector-name-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.gadget-owner-selector-name-selected-name {
    font-weight: 700;
    color: rgb(36, 110, 160);
}

.gadget-owner-selector-name-selected-handle {
    font-weight: 500;
    color: rgb(107, 107, 107);
}

.gadget-owner-selector-team-select {
    position: absolute;
    top: 100%;
    background-color: white;
    width: 100%;
    z-index: 100;
    max-height: 240px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.gadget-owner-selector-team-select::-webkit-scrollbar {
    width: .3em;
  }
  
  .gadget-owner-selector-team-select::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
}

.gadget-owner-selector-team-select::-webkit-scrollbar-thumb {
  background-color: rgb(23, 64, 97);
  outline: none;
}

.gadget-owner-selector-selectable-teammate-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    cursor: pointer;
    gap: 12px;
}

.gadget-owner-selector-selectable-teammate-container:hover {
    background-color: #17406113;
}

/* COMPONENT DROPDOWN */
.gadget-context-dropdown-container {
    position: relative;
}

.gadget-context-dropdown-primary-display {
    border: 1px solid rgb(221, 221, 221);
    background-color: rgb(243, 243, 243);
    font-weight: 300;
    min-width: 200px;
    border-radius: 12px;
    padding: 6px 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 14px;
    align-items: center;
    transition: all 0.2s;
}

.gadget-context-dropdown-primary-display:hover {
    background-color: rgb(240, 240, 240);
}

.gadget-context-dropdown-dropdown-container {
    position: absolute;
    /* border: 1px solid rgb(194, 194, 194); */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    top: 120%;
    border-radius: 4px;
    background-color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    min-width: 120%;
    width: 240px;
}

.gadget-context-dropdown-context-item {
    padding: 8px 12px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.gadget-context-dropdown-context-item:hover {
    background-color: #f0f0f0;
}

/* CONTEXT BREADCRUMB */
.gadget-breadcrumb-container-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 14px;
}

.gadget-breadcrumb-component {
    cursor: pointer;
    padding: 4px;
    border-radius: 8px;
    transition: all 0.2s;
    max-width: 360px;
    overflow-x: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

.gadget-breadcrumb-component-current {
    font-weight: 700;
    max-width: 340px;
    white-space: nowrap;      
    overflow: hidden;         
    text-overflow: ellipsis;
}

.gadget-breadcrumb-component:hover {
    background-color: rgb(218,218,218);
}

.gadget-breadcrumb-separator {
    font-size: 20px;
    color: grey;
}

.gadget-breadcrumb-link-new-button-container {
    position: relative;
}

.gadget-breadcrumb-link-new-button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    color:rgb(36, 110, 160);
    font-weight: 600;
}

.gadget-breadcrumb-link-new-button svg {
    font-size: 16px;
    color: grey;
}

.gadget-breadcrumb-link-new-parents-container {
    position: absolute;
    top: 100%;
    width: 400px;
    z-index: 100;
    background-color: #F6F7F9;
    box-shadow: 0px 4px 4px rgba(163, 163, 163, 0.25);
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
}

.gadget-breadcrumb-link-new-parents-item {
    color: #4F5D74;
    padding: 4px 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
}

.gadget-breadcrumb-link-new-parents-item:hover {
    background-color: rgb(235, 235, 235);
}

/* FEED TOOLBAR */
.gadget-feed-toolbar {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px;
    box-sizing: border-box;
    background-color: rgb(236, 236, 236);
    border-radius: 6px;
}

.gadget-feed-toolbar span {
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
    padding: 6px 12px;
    color: rgb(145, 145, 145);
    cursor: pointer;
    min-width: 80px;
    text-align: center;
}

.gadget-feed-toolbar span.gadget-feed-toolbar-selected {
    color: rgb(60, 60, 60);
    background-color: white;
}


/* TEAM PROFILE PICTURE PREVIEWS */
.gadget-team-prof-pic-previews {
    display: flex;
    align-items: stretch;
    gap: 0;
    justify-content: flex-start;
}

.gadget-team-prof-pic-preview-image-container {
    position: relative;
    width: 36px;
}

.gadget-team-prof-pic-preview-image-shifter {
    position: absolute;
    left: -8px;
    top: 0;
}

/* TOAST */
.toast-outer-container {
    position: relative;
    z-index: 200;
}

.toast-inner-container {
    position: fixed;
    bottom: 1%;
    left: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(68, 68, 68);
    color: white;
    gap: 32px;
    padding: 12px 20px;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.toast-content {
    flex-grow: 1;
}

.toast-action {
    color: rgb(113, 162, 196);
    text-transform: uppercase;
    cursor: pointer;
}

/* HASHTAGS */
.gadget-hashtags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
    width: 100%;
    /* margin-top: 8px; */
}

.gadget-hashtag {
    color: #2A73AE;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.gadget-hashtag:hover {
    text-decoration: underline;
}

.user-activity-dot {
    border-radius: 20px;
}

/* CREATE TASK MEGABAR */
.gadget-create-task-megabar-container {
    font-size: 14px;
    position: relative;
    min-height: 52px;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
}

.gadget-create-task-megabar-container input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    line-height: 20px;
    font-size: 14px;
    padding: 18px 20px;
    padding-right: 120px;
    border-radius: 6px;
    border: 1px solid #A4A4A4;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
}

.gadget-create-task-megabar-container span {
    z-index: 2;
    color: #344563;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 11px;
    text-decoration: underline;
    cursor: pointer;
    padding: 4px 14px;
    display: flex;
    align-items: center;
}

/* LEXICAL TEXT EDITOR */
.gadget-lexical-editor {
    position: relative;
    min-height: 80px;
    max-height: 240px;
    width: 100%;
    font-size: 14px;
    /* padding: 12px; */
    /* border: 1px solid rgb(199, 199, 199); */
    border: none;
    /* border-top-left-radius: 6px; */
    /* border-top-right-radius: 6px; */
    box-sizing: border-box;
    overflow-y: auto;
}

.gadget-lexical-editor:focus {
    border-bottom: none;
    outline: none;
}

.gadget-lexical-editor p,
.gadget-lexical-displayed-text-container p {
    margin: 0;
    line-height: 20px;
    margin-bottom: 0.2em;
}

.gadget-lexical-editor ul,
.gadget-lexical-editor ol,
.gadget-lexical-displayed-text-container ul,
.gadget-lexical-displayed-text-container ol {
    margin: 0;
    margin-top: 0.1em;
    margin-bottom: 0.2em;
    padding-left: 2.5em;
    line-height: 1.5;
}

.gadget-lexical-placeholder {
    position: absolute;
    top: 48px;
    left: 2px;
    font-size: 14px;
    pointer-events: none;
}

.gadget-lexical-save-buttons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 12px;
    margin-top: 8px;
}

.gadget-lexical-save-buttons span {
    padding: 8px 20px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #3D72AA;
    cursor: pointer;
}

.gadget-lexical-edit-button span {
    padding: 2px 20px;
    border-radius: 6px;
    color: rgb(51, 51, 51);
    font-size: 12px;
    font-weight: 500;
    background-color: #f3f3f3;
    border: 1px solid rgb(194, 194, 194);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.gadget-lexical-displayed-text-container {
    position: relative;
    min-height: 24px;
    word-wrap: break-word;
    line-height: 1.8;
    font-size: 14px;
}

.gadget-lexical-edit-button {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
}

.gadget-lexical-displayed-text-container:hover .gadget-lexical-edit-button {
    opacity: 1;
    pointer-events: all;
}

.gadget-lexical-displayed-text-empty {
    font-size: 14px;
    color: #666;
    cursor: pointer;
}

.gadget-lexical-bold {
    font-weight: 600;
}

.gadget-lexical-italic {
    font-style: italic;
}

.gadget-lexical-underline {
    text-decoration: underline;
}

.gadget-lexical-toolbar {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px;
    margin-bottom: 14px;
    background-color: #F5F5F5;
}

.gadget-lexical-toolbar div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
    transition: all 0.2s;
    color: rgb(89, 89, 89);
}

.gadget-lexical-toolbar div:hover {
    background-color: rgb(224, 224, 224);
}

.gadget-lexical-toolbar div svg {
    font-size: 20px;
}

.gadget-blur-proof-input {
    flex-grow: 1;
}

span[data-lexical-beautiful-mention-trigger="@"],
.data-beautiful-mention {
    color: #3D72AA;
    font-weight: 700;
    cursor: pointer;
}

span[data-lexical-beautiful-mention-trigger="@"]:hover {
    text-decoration: underline;
}

.gadget-beautiful-mentions {
    color: #3D72AA;
    font-weight: 700;
}

.gadget-beautiful-mentions-focused {
    color: #3D72AA;
}

.gadget-beautiful-mentions-menu {
    background-color: rgb(255, 255, 255);
    list-style-type: none;
    margin-left: 0;
    padding: 8px 4px;
    border-radius: 6px;
    border: 1px solid rgb(249, 249, 249);
    display: flex;
    flex-direction: column;
    gap: 4px;box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    max-height: 360px;
    overflow-y: auto;
}

#typeahead-menu {
    z-index: 104;
}

.gadget-beautiful-mentions-menu-item,
.gadget-simple-mention-input-suggestion {
    color: rgb(22, 22, 22);
    font-size: 14px;
    min-width: 280px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 6px;
    align-items: center;
}

.gadget-beautiful-mentions-menu-item-selected {
    background-color: rgba(52, 60, 70, 0.127);
}

.gadget-beautiful-mentions-menu-item-names {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.gadget-beautiful-mentions-menu-item-names p {
    margin: 0;
    font-weight: 700;
}

.gadget-beautiful-mentions-menu-item-names span {
    font-weight: 400;
    color: grey;
}

/* SIMPLE MENTION INPUT */
.gadget-simple-mention-input {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.gadget-simple-mention-input input {
    width: 100%;
    box-sizing: border-box;
}

.gadget-simple-mention-input-suggestions-list-container {
    position: absolute; /* Positions the element in relation to its nearest positioned ancestor */
    z-index: 1000;      /* Ensures the list appears on top of other elements */
    background: white;  /* Background color for the list */
    border: 1px solid #ddd; /* Border for the list */
    border-radius: 4px; /* Rounded corners for the list */
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1); /* Shadow for a subtle 3D effect */
    max-height: 200px;  /* Maximum height of the list */
    overflow-y: auto;   /* Allows scrolling inside the list if the content is too long */
    width: 100%;        /* Makes the list as wide as the input field */
}

.gadget-simple-mention-input-suggestions-list-container::-webkit-scrollbar {
    width: .3em;
}
  
.gadget-simple-mention-input-suggestions-list-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
}

.gadget-simple-mention-input-suggestions-list-container::-webkit-scrollbar-thumb {
    background-color: rgb(23, 64, 97);
    outline: none;
}

.gadget-simple-mention-input-suggestions-list {
    display: flex;
    flex-direction: column;
    gap: 0;
    overflow-x: hidden;
}

.gadget-simple-mention-input-suggestions-list .gadget-simple-mention-input-suggestion:hover {
    background-color: rgba(52, 60, 70, 0.127);
}

@media only screen and (max-width: 1000px) {
    .gadget-breadcrumb-component {
        max-width: 120px;
    }
}

/* COMPONENT STATUS DROPDOWN */
.gadget-component-status-dropdown {
    position: relative;
    font-size: 13px;
    cursor: pointer;
    outline: none;
    --gadget-component-status-dropdown-bold-active: #1a689f;
    --gadget-component-status-dropdown-soft-active: #1a689f2c;
    --gadget-component-status-dropdown-bold-archived: rgb(252, 99, 107);
    --gadget-component-status-dropdown-soft-archived: rgba(252, 99, 107, 0.182);
    --gadget-component-status-dropdown-bold-completed: #049921;
    --gadget-component-status-dropdown-soft-completed: #04992228;
}
  
.gadget-component-status-dropdown.gadget-component-status-dropdown-non-interactive {
    cursor: default;
}
  
.gadget-component-status-dropdown-status-display {
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 700;
    box-sizing: border-box;
    text-transform: uppercase;
    gap: 6px;
    width: 120px;
    text-align: center;
}
  
.gadget-component-status-dropdown-status-display:focus {
    outline: none;
}
  
.gadget-component-status-dropdown-active-status {
    background-color: var(--gadget-component-status-dropdown-soft-active);
    color: var(--gadget-component-status-dropdown-bold-active);
}
  
.gadget-component-status-dropdown-archived-status {
    background-color: var(--gadget-component-status-dropdown-soft-archived);
    color: var(--gadget-component-status-dropdown-bold-archived);
}
  
.gadget-component-status-dropdown-completed-status {
    background-color: var(--gadget-component-status-dropdown-soft-completed);
    color: var(--gadget-component-status-dropdown-bold-completed);
}

.gadget-component-status-dropdown-dropdown-menu {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 0px;
    list-style-type: none;
    margin: 0;
    z-index: 10;
    width: 120px;
}

.gadget-component-status-dropdown-dropdown-item-active {
    background-color: var(--gadget-component-status-dropdown-bold-active);
}

.gadget-component-status-dropdown-dropdown-item-completed {
    background-color: var(--gadget-component-status-dropdown-bold-completed);
}

.gadget-component-status-dropdown-dropdown-item-archived {
    background-color: var(--gadget-component-status-dropdown-bold-archived);
}

.gadget-component-status-dropdown-dropdown-item {
    padding: 6px 12px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    text-align: center;
    transition: all 0.2s;
}

.gadget-component-status-dropdown-dropdown-item:hover,
.gadget-component-status-dropdown-dropdown-item:focus,
.gadget-component-status-dropdown-focused {
    filter: brightness(105%);
}

.gadget-simple-loading-page {
    width: 94%;
    max-width: 800px;
    margin: 120px auto;
}

.gadget-simple-loading-page-header {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    font-size: 28px;
    color: rgb(46, 46, 46);
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(88, 88, 88);
    margin-bottom: 60px;
}

.gadget-simple-loading-page-header div:first-of-type {
    font-weight: 600;
    font-size: 28px;
    color: rgb(46, 46, 46);
}

.gadget-simple-loading-page-loader-hypno {
    width: 50px;
    aspect-ratio: 1;
    border: 2px solid;
    box-sizing: border-box;
    border-radius: 50%;
    display: grid;
    animation: l11 1.5s infinite linear;
    transform-origin: 50% 80%;
    color: #2A73AE;
}

.gadget-simple-loading-page-loader-hypno:before,
.gadget-simple-loading-page-loader-hypno:after {
    content: "";
    grid-area: 1/1;
    border: inherit;
    border-radius: 50%;
    animation: inherit;
    animation-duration: 1.5s;
    transform-origin: inherit;
}

.gadget-simple-loading-page-loader-hypno:after {
    --s:-1;
}

@keyframes l11 {
     100% {transform:rotate(calc(var(--s,1)*1turn))}
}

.component-main-description-relative-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 200px;
}

.component-main-description-carousel-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
}

.component-main-description-carousel-button {
    background-color: #cfcfcf74;
    color: rgb(37, 37, 37);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    border-radius: 20px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -khtml-user-select: none;
    -moz-user-select: none;   
    -ms-user-select: none; 
    user-select: none;
    padding: 10px;
    font-size: 16px;
}

.component-main-description-carousel-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    /* max-width: 800px; */
    width: calc(100% - 80px); /* Subtract button width */
    gap: 20px;
    scrollbar-width: none; /* Firefox */
}

.component-main-description-carousel-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.component-main-description-carousel-item {
    flex: 0 0 auto;
    width: 200px; /* Define a specific width for each item */
    max-width: 200px;
    box-sizing: border-box;
}

/* INTERNAL MENU SELECTORS */
.gadget-internal-menu-selections {
    width: 200px;
}

.gadget-internal-menu-options {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2px;
    width: 100%;
}

.gadget-internal-menu-options span {
    padding: 4px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    transition: all 0.2s;
}

.gadget-internal-menu-options span.selected,
.gadget-internal-menu-options span.selected:hover {
    background-color: rgb(227, 227, 227);
}

.gadget-internal-menu-options span:hover {
    background-color: rgb(242, 242, 242);
}