.blog-landing-heading {
    width: 100%;
    box-sizing: border-box;
    /* background-color: rgba(25, 76, 139, 0.038); */
    background-color: #2a73ae1b;
}

.blog-landing-heading-inner {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.blog-landing-heading-badge {
    padding: 4px 28px;
    border: 1.2px solid #1d4d75;
    color: #1d4d75;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.blog-landing-heading-inner h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 800;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1d4d75;
    text-align: center;
}

.blog-landing-heading-inner p {
    font-size: 14px;
    margin: 0;
    color: rgb(64, 64, 64);
    font-weight: 400;
}

.blog-landing-list {
    display: flex;
    flex-direction: column;
    gap: 36px;
    max-width: 600px;
    margin: 36px auto;
    width: 95%;
}

.blog-landing-list-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 16px;
    background-color: #FAFAFA;
    border: 1px solid #f8f8f8;

}

.blog-landing-list-content-image {
    width: 100%;
    max-width: 100%;
    height: 240px;
}

.blog-landing-list-content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-landing-list-content-titles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    font-size: 14px;
    padding: 0px 12px;
    padding-bottom: 12px;
}

.blog-landing-list-content-titles h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

.blog-landing-list-content-tagline {
    font-size: 14px;
    color: rgb(79, 79, 79);
}

.blog-landing-list-content-categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;
    gap: 8px;
}

.blog-landing-list-content-categories span {
    font-size: 10px;
    padding: 4px 16px;
    border-radius: 2px;
    color: rgb(60, 91, 118);
    font-weight: 600;
    border: 1px solid rgb(219, 219, 219);
    cursor: pointer;
}

.blog-post {
    width: 85%;
    box-sizing: border-box;
    max-width: 704px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
}

.blog-post h1 {
    font-size: 32px;
    color: rgb(42, 53, 64);
    font-weight: 800;
    margin: 0;
    margin-bottom: 4px;
    line-height: 1;
}

.blog-post-tagline {
    font-size: 14px;
    color: rgb(93, 93, 93);
    color: rgba(24, 71, 106, 0.749);;
    padding: 4px 12px;
    text-align: center;
    background-color: rgba(24, 71, 106, 0.178);
    font-weight: 700;
}

.blog-post-image {
    display: none;
    width: 100%;
    box-sizing: border-box;
}

.blog-post-image img {
    max-width: 100%;
    box-sizing: border-box;
}

.blog-post-content {
    width: 100%;
    box-sizing: border-box;
    color: rgb(42, 53, 64);
    padding-bottom: 40px;
}

.blog-post-content h2,
.blog-post-content h2 b {
    font-size: 20px;
    margin: 0;
    font-weight: 700;
    margin-top: 40px;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.blog-post-content h3,
.blog-post-content h3 b {
    font-size: 17px;
    margin: 0;
    font-weight: 700;
    margin-top: 40px;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.blog-post-content p {
    overflow-wrap: break-word;
    color: rgb(42, 53, 64);
    box-sizing: border-box;
    margin-block-end: -9.2px;
    margin-block-start: 42.8px;
    margin-bottom: -9.2px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 1.6em;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: -0.003em;
}

.blog-post-content img {
    width: 100%;
    margin-top: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    border-radius: 4px;
}

.blog-post-content li p {
    margin-top: 0.5em;
}

.blog-post-metas {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 12px;
    box-sizing: border-box;
    color: rgb(42, 53, 64);
}

.blog-post-meta-container {
    flex-grow: 1;
}

.blog-post-meta-container label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
}

.blog-post-meta-container p {
    margin: 0;
    font-size: 12px;
}

.blog-post-footer {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.blog-post-footer-cta {
    padding: 32px;
    width: 100%;
    background-color: #f5faff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
}

.blog-post-footer-cta p {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: rgb(50, 55, 61);
    text-align: center;
}

.blog-post-footer-cta div {
    text-align: center;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #2A73AE;
    cursor: pointer;
    transition: all 0.3;
}

.blog-post-footer-cta div:hover {
    text-decoration: underline;
}

@media screen and (min-width: 1000px) {
    .blog-landing-heading-inner {
        min-height: 360px;
        align-items: flex-start;
        gap: 0px;
        padding: 20px 32px;
    }

    .blog-landing-heading-inner h1 {
        text-align: left;
        font-size: 52px;
        line-height: 1.1;
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .blog-landing-heading-inner p,
    .blog-landing-heading-badge {
        font-size: 16px;
    }

    .blog-landing-list {
        width: 100%;
        max-width: 1200px;
        padding: 20px 32px;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }

    .blog-landing-list-content {
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 36px;
        align-items: center;
        max-height: 200px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.2s;
    }

    .blog-landing-list-content:hover {
        box-shadow: rgba(0, 0, 0, 0.049) 4px 4px 4px;
    }

    .blog-landing-list-content-image {
        flex-basis: 30%;
    }

    .blog-landing-list-content-titles {
        flex-basis: 65%;
        padding: 0;
    }

    .blog-landing-list-content-titles h2 {
        font-size: 24px;
    }

    .blog-landing-list-content-tagline {
        font-size: 16px;
    }

    .blog-landing-list-content-categories span {
        font-size: 12px;
    }

    .blog-post h1 {
        max-width: 70%;
        font-size: 40px;
    }

    .blog-post-image {
        display: block;
        margin-top: 40px;
    }

    .blog-post-content p {
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.003em;
    }

    .blog-post-metas {
        gap: 80px;
    }

    .blog-post-meta-container {
        flex-grow: unset;
    }

    .blog-post-content h2,
    .blog-post-content h2 b {
        font-size: 24px;
    }

    .blog-post-content h3,
    .blog-post-content h3 b {
        font-size: 18px;
    }
}