/* My Desk - Weekly View */
.mydesk-row-typ {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mydesk-assignment-interface {
    margin-top: 12px;
    position: relative;
}

.mydesk-assignment-interface-controls {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 100;
}

.mydesk-assignment-interface-control {
    color: rgb(34, 34, 34);
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    border-radius: 4px;
}

.mydesk-assignment-interface-control:hover {
    background-color: rgba(65, 65, 65, 0.582);
}

.mydesk-assignment-interface-control-selected,
.mydesk-assignment-interface-control-selected.mydesk-assignment-interface-control:hover {
    color: white;
    background-color: rgb(65, 65, 65);
}

.mydesk-weekly-container {
    position: absolute;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 12px;
}

.mydesk-weekly-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    margin-bottom: 24px;
}

.mydesk-weekly-container-header h2 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mydesk-weekly-date-navs {
    -o-user-select: none;
    user-select: none;
}

.mydesk-weekly-container-date-nav-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.mydesk-weekly-container-date-nav-button-disabled,
.mydesk-weekly-container-date-nav-button-disabled:hover {
    color: rgb(190, 190, 190);
    background-color: white !important;
    cursor: default !important;
}

.mydesk-weekly-container-date-nav-buttons svg {
    font-size: 28px;
    padding: 4px;
    transition: all 0.25s;
    border-radius: 50%;
}

.mydesk-weekly-container-date-nav-buttons svg:hover {
    /* color: rgb(53, 198, 255); */
    cursor: pointer;
    background-color: rgb(185, 185, 185);
}

.mydesk-weekly-date-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mydesk-weekly-date-box {
    width: 15%;
}
.mydesk-weekly-date-box-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
    justify-content: flex-start;
}

.mydesk-weekly-date-box-header {
    border-bottom: 2px solid black;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 12px;
    margin-bottom: 12px;;
}

.mydesk-weekly-date-box-header h3 {
    text-transform: uppercase;
    margin: 0px;
    font-size: 16px;
}

.mydesk-weekly-date-box-header div {
    font-weight: 600;
}

.mydesk-weekly-date-box-item {
    overflow-x: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: all 0.2s;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mydesk-weekly-date-box-item:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    border-radius: 4px;
}

.mydesk-weekly-date-box-item-content {
    font-size: 14px;
    font-weight: 600;
    overflow-x: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

.mydesk-weekly-date-box-item-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 500;
    color: grey;
    text-transform: capitalize;
}

.mydesk-list-sections {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.mydesk-list-section-header {
    -o-user-select: none;
    user-select: none;
}

.mydesk-list-section-header h4 {
    margin: 0px;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
}

.mydesk-list-section-header svg {
    cursor: pointer;
    font-size: 20px;
}

.mydesk-list-section-header span {
    font-size: 12px;
    text-transform: uppercase;
    color: grey;
    font-weight: 600;
}

.mydesk-list-components {
    margin-top: 12px;
    margin-left: 24px;
    margin-right: 24px;
}

.mydesk-list-component {
    width: 100%;
    height: 48px;
    border-top: 1px solid rgb(199,199,199);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mydesk-list-component:last-of-type {
    border-bottom: 1px solid rgb(199,199,199);
}

.mydesk-list-component-titles {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 24px;
    margin-right: 24px;
    color: grey;
    padding-top: 40px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgb(199,199,199);
}

.mydesk-list-component-title {
    padding-left: 8px;
}

.mydesk-list-component-entry {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 16px;
}

.mydesk-list-component-entry:first-of-type {
    border-left: none;
    padding-left: 0px;
}

.mydesk-list-component-entry-name {
    flex-basis: 40%;
}

.mydesk-list-component-entry-type {
    flex-basis: 15%;
}

.mydesk-list-component-entry-date {
    flex-basis: 20%;
}

.mydesk-list-component-entry-progress {
    flex-basis: 20%;
}

.mydesk-list-component-entry-options {
    flex-basis: 5%;
}

.mydesk-list-component-entry-name.mydesk-list-component-entry {
    font-weight: 600;
    cursor: pointer;
}

.mydesk-list-component-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mydesk-list-component-type span {
    padding: 4px 12px;
    background-color: #17406128;
    font-weight: 600;
    border-radius: 8px;
}

.mydesk-list-component:hover {
    background-color: rgb(240, 240, 240);
}

.mydesk-list-component-entry-type.mydesk-list-component-entry {
    text-transform: uppercase;
    font-size: 14px;
}

.mydesk-list-overdue-text {
    font-size: 12px;
    font-weight: 600;
    color: rgb(201, 59, 59);
}

.mydesk-list-date-text {
    font-size: 12px;
    font-weight: 400;
    color: grey;
}

/* My Desk v0.3.0+ */

.user-mydesk-container {
    margin-top: 48px;
    box-sizing: border-box;
    min-height: calc(100vh - 48px);
}

.user-mydesk-header-sticky-container {
    position: sticky;
    top: 48px;
    background-color: white;
    z-index: 40;
    height: 90px;
}

.user-mydesk-header {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    background-color: white;
    padding-top: 20px;
}

.user-mydesk-header h2 {
    color: var(--t-color-dark);
    margin: 0;
    font-size: 18px;
    margin-bottom: 14px;
}

.user-mydesk-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.user-mydesk-nav span {
    font-size: 14px;
    transform: translateY(3px);
    font-weight: 500;
    width: 96px;
    cursor: pointer;
    text-align: center;
    padding-bottom: 8px;
    color: grey;
    border-bottom: 2px solid transparent;
}

.user-mydesk-nav span.active {
    font-weight: 700;
    color: #3D72AA;
    border-bottom: 2px solid #3D72AA;
}

.user-mydesk-assignments-container,
.user-mydesk-notifications-container {
    width: 100%;
    max-width: 900px;
    padding: 0px 20px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    padding-bottom: 48px;
}

.user-mydesk-assignments-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 24px;
    font-size: 14px;
}

.user-mydesk-assignments-header svg {
    font-size: 16px;
    color: grey;
}

.user-mydesk-assignments-header-filter-outer {
    position: relative;
    cursor: pointer;
}

.user-mydesk-assignments-header-filter-options {
    position: absolute;
    top: calc(100% + 8px);
    min-width: 280px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-height: 400px;
    overflow-y: auto;
    background-color: white;
    border-radius: 6px;
    padding: 8px 0px;
    z-index: 2;
    border: 1px solid rgb(227, 227, 227);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.user-mydesk-assignments-header-filter-options div {
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-mydesk-assignments-header-filter-options div svg {
    color: rgb(100, 100, 100);
    font-size: 16px;
}

.user-mydesk-assignments-header-filter-inner {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
}

.user-mydesk-assignments-header-filter-inner.active {
    background-color: #3d72aa31;
    font-weight: 600;
}

.user-mydesk-assignments-header-filter-options div:hover {
    background-color: rgb(242, 242, 242);
}

.user-mydesk-assignments-list-empty {
    font-size: 14px;
    color: grey;
    margin-bottom: 24px;
}

.component-list-task-container.user-mydesk-interactive-assignment-item-container {
    width: 100%;
}

.public-tool-promotion-calendar-primary-container-list-tasks .user-mydesk-interactive-assignment-item-container {
    background-color: white;
    cursor: pointer !important;
}

.user-mydesk-interactive-assignment-item-container .component-list-task-summary-titles h3 {
    font-size: 14px;
    line-height: unset;
    flex-grow: 1; /* Allow the middle item to grow */
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis;
    padding-right: 28px;
    max-width: unset;
}

.user-mydesk-assginments-overdue-actions span {
    font-size: 10px;
    padding: 4px 16px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #17406111;
    border-radius: 4px;
    border: 1px solid rgb(187, 187, 187);
    cursor: pointer;
}

.component-list-task-summary-titles-details {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 22px;
    width: 100%;
}

.user-mydesk-interactive-assignment-item-container .component-list-task-summary-titles p,
.user-mydesk-interactive-assignment-item-container .component-list-task-summary-titles-details {
    font-size: 12px;
    line-height: unset;
}

.user-mydesk-interactive-assignment-item-container .component-list-task-container-section-meta {
    min-width: 120px;
    flex-basis: unset;
}

.user-mydesk-interactive-assignment-item-container .component-list-task-section-meta span {
    font-size: 11px;
}

.user-mydesk-interactive-assignment-item-container .component-list-task-section-meta svg {
    font-size: 14px;
}

.component-list-task-summary-titles.user-mydesk-task-summary-subtext-clickable,
.user-mydesk-interactive-assignment-item-container .component-list-task-container-section-summary {
    overflow: unset;
    flex-basis: unset;
    flex-grow: 1;
}

.user-mydesk-interactive-assignment-item-container .component-list-task-summary-titles h3 {
    white-space: unset;
}

.user-mydesk-task-summary-subtext-clickable span,
.user-mydesk-task-summary-subtext-clickable h3 {
    cursor: pointer;
}

.user-mydesk-task-summary-subtext-clickable span:hover,
.user-mydesk-task-summary-subtext-clickable h3:hover {
    text-decoration: underline;
}

.task-state-selector-menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 2;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: white;
    border-radius: 8px;
    padding: 4px;
}

.task-state-selector-menu-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    width: 200px;
}

.task-state-selector-header {
    text-align: left;
    font-size: 11px;
    font-weight: 600;
    padding: 4px;
    color: grey;
    border-bottom: 1px solid rgb(229, 229, 229);
    line-height: 20px;
}

.task-state-selector-header-nav {
    display: flex;
    align-items: center;
    gap: 4px;
}

.task-state-selector-header-nav svg {
    font-size: 16px;
}

.task-state-selector-menu-items span {
    padding: 4px;
    font-size: 11px;
    font-weight: 600;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgb(32, 32, 32);
    line-height: 20px;
}

.task-state-selector-menu-items span svg {
    min-width: 16px;
    font-size: 14px;
}

.task-state-selector-menu-items span:hover {
    text-decoration: none;
    background-color: rgb(194, 194, 194);
}

.task-state-selector-asc-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgb(229, 229, 229);
    margin-top: 4px;
    padding-top: 4px;
}

.task-state-selector-option {
    flex-basis: 50%;
    font-weight: 400;
    font-size: 11px;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: all 0.2s;
    cursor: pointer;
}

.task-state-selector-option:hover {
    background-color: rgb(229, 229, 229);
}

.task-state-selector-option svg {
    font-size: 20px;
    color: grey;
}

.task-state-selector-option-selected {
    font-weight: 600;
}

.task-state-selector-option-selected svg {
    color: #3D72AA;
}

.task-state-selector-confirmation-buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(229, 229, 229);
    margin-top: 12px;
    padding-top: 4px;
}

.task-state-selector-confirmation-buttons div {
    flex-basis: 50%;
    font-size: 12px;
    text-align: center;
    padding: 4px;
    cursor: pointer;
}

.task-state-selector-confirmation-buttons div:hover {
    background-color: rgb(229, 229, 229);
}

.task-state-selector-confirmation-buttons div:first-of-type {
    border-right: 1px solid rgb(229, 229, 229);
}

.task-state-selector-confirmation-buttons div:first-of-type:hover {
    background-color: #3D72AA;
    color: white;
}

.user-mydesk-task-summary-subtext-state-decorator-container {
    position: relative;
}

.user-mydesk-task-summary-subtext-state-decorator {
    padding: 3px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: rgb(36, 36, 36);
    color: white;
    font-size: 10px;
    max-width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.user-mydesk-task-summary-subtext-state-decorator:hover {
    text-decoration: none !important;
}

.user-mydesk-interactive-assignment-item-container.component-list-task-container {
    cursor: unset;
}

/* My Desk Assignments */
.mydesk-assignments-container {
    width: 100%;
    box-sizing: border-box;
}

.mydesk-assignments-spotlight-container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}

.mydesk-assignments-spotlight {
    border-radius: 4px;
    border: 1.5px solid rgb(228, 228, 228);
    padding: 12px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    flex-basis: 20%;
}

.mydesk-assignment-spotlight-symbol {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mydesk-assignment-spotlight-symbol svg {
    font-size: 24px;
    color: rgb(59, 100, 146);
}

.mydesk-assignment-spotlight-text {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.mydesk-assignment-spotlight-text h3 {
    font-size: 12px;
    margin: 0px;
    color: grey;
    font-weight: 500;
}

.mydesk-assignment-spotlight-text p {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: black;
}

/* Assignment Table */
.assignment-table {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.assignment-table-filter-pills {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 12px;
    min-height: 32px;
}

.assignment-table-filter-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-radius: 4px;
    padding: 4px 12px;
    border: 1.5px solid rgb(233, 233, 233);
    background-color: rgb(245, 245, 245);
    font-size: 14px;
    font-weight: 600;
}

.assignment-table-filter-pill svg {
    font-size: 18px;
    color: rgb(83, 83, 83);
    cursor: pointer;
}

.assignment-table-filter-pill-decoration {
    display: flex;
    align-items: center;
    gap: 12px;
}

.assignment-table-filter-pill-decoration svg {
    font-size: 18px;
    color: #355070;
}

.assignment-table-outer-table {
    border: 1.5px solid rgb(228, 228, 228);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.assignment-table-control-panel {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1.5px solid rgb(228, 228, 228);
}

.assignment-table-controls-left,
.assignment-table-controls-right {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 12px;
}

.assignment-table-controls-left {
    flex-basis: 20%;
}

.assignment-table-controls-search {
    position: relative;
    width: 100%;
    border: 1.5px solid rgb(228, 228, 228);
    border-radius: 4px;
}

.assignment-table-controls-search-symbol {
    position: absolute;
    top: 25%;
    left: 8px;
}

.assignment-table-controls-search-symbol svg {
    color: grey;
}

.assignment-table-controls-search input {
    border: none;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-left: 32px;
    font-size: 14px;
}

.assignment-table-controls-filter-container {
    border: 1.5px solid rgb(228, 228, 228);
    border-radius: 4px;
    position: relative;
    min-width: 120px;
    cursor: pointer;
    background-color: white;
}

.assignment-table-controls-filter-container:hover {
    background-color: rgb(243, 243, 243);
}

.assignment-table-controls-filter-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    cursor: pointer;
}

.assignment-table-controls-filter-button svg {
    font-size: 16px;
    color: grey;
}

.assignment-table-controls-filter-dropdown-outer {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    min-width: 200px;
    background-color: white;
    z-index: 3;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid rgb(228, 228, 228);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.assignment-table-controls-filter-dropdown-inner {
    cursor: pointer;
    padding: 6px 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.assignment-table-controls-filter-dropdown-inner svg {
    font-size: 20px;
    color: rgb(177, 177, 177);
}

.assignment-table-filter-dropdown-option {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.assignment-table-filter-dropdown-option svg {
    font-size: 12px;
    color: #355070;
}

.assignment-table-header-and-row-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
}

.assignment-table-header-row,
.assignment-table-component-row {
    width: 100%;
    border-bottom: 1.5px solid rgb(228, 228, 228);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
}

.assignment-table-component-row:last-of-type {
    border-bottom: none;
}

.assignment-table-header-row div {
    font-size: 14px;
    color: grey;
    padding-bottom: 8px;
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.assignment-table-header-row div span {
    cursor: pointer;
}

.assignment-table-header-row div span:hover {
    text-decoration: underline;
}

.assignment-table-component-row div {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.assignment-table-component-row .assignment-table-col-item {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.assignment-table-component-row .assignment-table-col-item span {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.assignment-table-component-row .assignment-table-col-item span p {
    font-weight: 500;
    cursor: pointer;
    margin: 0;
}

.assignment-table-component-row .assignment-table-col-item span div {
    color: grey;
    font-size: 13px;
    font-weight: 400;
    padding: 0;
    cursor: pointer;
} 

.assignment-table-component-row .assignment-table-col-item span p:hover,
.assignment-table-component-row .assignment-table-col-item span div:hover {
    text-decoration: underline;
}

.assignment-table-component-row .assignment-table-col-status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.assignment-table-component-row .assignment-table-col-status span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.assignment-table-component-row .assignment-table-col-owner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.assignment-table-component-row .assignment-table-col-owner p {
    margin: 0px;
    font-size: 14px;
}

.assignment-table-component-row .assignment-table-col-owner span {
    color: grey;
}

.assignment-table-component-row .assignment-table-col-options {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.assignment-table-component-row .assignment-table-col-options svg {
    color: rgb(90, 90, 90);
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
}

.assignment-table-component-row .assignment-table-col-options svg:hover {
    background-color: rgb(235, 235, 235);
}

.assignment-table-component-row .assignment-table-col-workspace {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.assignment-table-component-row .assignment-table-col-workspace span {
    cursor: pointer;
}

.assignment-table-component-row .assignment-table-col-workspace span:hover {
    text-decoration: underline;
}

.assignment-table-component-row .assignment-table-col-date {
    display: flex;
    align-items: center;
    gap: 6px;
}

.assignment-table-col-options-menu {
    position: absolute;
    right: 0;
    top: calc(100% + 4px);
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 6;
    width: 180px;
    display: flex;
    flex-direction: column !important;
}

.assignment-table-col-options-menu span {
    padding: 6px 8px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.assignment-table-col-options-menu span:hover {
    background-color: rgb(240, 240, 240);
}

/* These widths are all very specifically chosen to add to 100 */
.assignment-table-col-selection-box,
.assignment-table-col-options {
    flex-basis: 2%;
}

.assignment-table-col-item {
    flex-basis: 40%;
    /* white-space: pre; */
    text-overflow: ellipsis;
    overflow: hidden;
}

.assignment-table-col-type,
.assignment-table-col-status {
    flex-basis: 7%;
}

.assignment-table-col-owner {
    flex-basis: 15%;
}

.assignment-table-col-date {
    flex-basis: 10%;
}

.assignment-table-col-workspace {
    flex-basis: 17%;
}


/* Calendar */
.calendar-outer-user-assignments {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    height: 36px;
    margin-top: 24px;
}

.calendar-container {
    min-height: 400px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
}

.calendar-sidebar {
    min-width: 240px;
}

.calendar-filter-section-title {
    font-weight: 700;
    border-bottom: 1px solid rgb(199,199,199);
}

.calendar-filter-section {
    font-size: 0.9em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.calendar-filter-filters-container {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 380px;
    overflow-y: auto;
}

.calendar-filter-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    transition: all 0.2s;
    cursor: pointer;
    white-space: pre;
    text-overflow: ellipsis;
}

.calendar-filter-item span:hover {
    background-color: rgb(245, 245, 245);
}

.calendar-filter-item span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.calendar-filter-item span:last-of-type {
    flex-grow: 1;
    padding: 4px;
    border-radius: 4px;
}

.calendar-filter-checkable-box {
    width: 1.2em;
    min-width: 1.2em;
    max-width: 1.2em;
    height: 1.2em;
    /* border: 2px solid rgb(0, 167, 233); */
    border-radius: 0.2em;
    cursor: pointer;
}

.calendar-filter-component-dropdown-mass-buttons {
    display: flex;
    align-items: center;
    padding: 0px 4px;
    gap: 12px;
}

.calendar-filter-component-dropdown-mass-buttons span {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    color: grey;
}

.calendar-filter-checkable-box.calendar-filter-checked {
    /* background-color: #f6bf26; */
    color: white;
}

.calendar-filter-component-dropdown-container {
    position: relative;
}

.calendar-filter-component-button {
    cursor: pointer;
    font-weight: 600;

    display: flex;
    gap: 8px;
    padding: 4px 12px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 8px;
    align-items: center; 
}

.calendar-filter-component-button svg {
    color: rgb(65, 65, 65);
    width: 14px;
    height: 14px;
    min-width: 20px;
}

.calendar-filter-component-dropdown {
    position: absolute;
    top: 32px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding: 8px 4px;
    z-index: 99;
    width: 120px;
}

.calendar-filter-component-dropdown-workstreams {
    width: 320px;
}

.calendar-rows {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0;
}

.calendar-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    flex-grow: 1;
}

.calendar-cell-width-control {
    flex-grow: 1;
    width: 0;
}

.calendar-cell-box:last-child {
    border-right: 1px solid #dadce0;;
}

.calendar-cell-box {
    border-left: 1px solid #dadce0;
    border-bottom: 1px solid #dadce0;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    font-size: 0.8em;
    padding-left: 4px;
    padding-right: 4px;
    position: relative;
    min-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 8px;
    cursor: pointer;
}

.calendar-row:nth-child(2) {
    border-top: 1px solid #dadce0;
}

.calendar-rows .calendar-row-header {
    height: 2em !important;
    flex-grow: 0;
}

.calendar-row-header .calendar-cell-width-control {
    border: none;
}

.calendar-row-header {
    font-size: 0.8em;
    font-weight: 600;
    color: grey;
}

.calendar-cell-content-header {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0.2em;
    height: 24px;
}

.calendar-cell-content-event {
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #174061;
    padding: 4px 8px;
    /* padding-left: 0.5em; */
    border-radius: 4px;
    color: white;
    cursor: pointer;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: pre-wrap;
}

.calendar-cell-content-event span {
    text-overflow: ellipsis;
    overflow: hidden;
}

.calendar-cell-content-overflow-message {
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 600;
    height: 16px;
}

.calendar-cell-content-overflow-message:hover {
    background-color: rgb(230, 230, 230);
}

.calendar-day-modal-container {
    position: absolute;
    padding: 1em;
    z-index: 2;
    background-color: white;
    right: 50%;
    overflow: hidden;
    top: -50%;
    width: 240%;
    border-radius: 1em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.calendar-day-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.calendar-day-modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calendar-day-modal-title-weekday {
    text-transform: uppercase;
    color: grey;
    font-weight: 600;
    font-size: 1em;
}

.calendar-day-modal-title-number {
    color: black;
    font-weight: 700;
    font-size: 2em;
}

.calendar-day-modal-cancel {
    border-radius: 36px;
    transition: all 0.2s;
    padding: 4px;
    font-size: 1.5em;
    color: grey;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.5em;
}

.calendar-day-modal-cancel:hover {
    background-color: rgb(231, 231, 231);
    cursor: pointer;
}

.calendar-day-modal-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
    overflow: hidden;
    margin-bottom: 1em;
    margin-top: 1em;
    margin-right: 1em;
    margin-left: 1em;
    box-sizing: border-box;
}

.calendar-item-modal-filter-identifier {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.5em;
    background-color: #174061;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}

.calendar-day-modal-line-item {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
}

.calendar-day-modal-line-item-name {
    font-size: 1em;
    padding: 0.2em;
    cursor: pointer;
    border-radius: 0.2em;
}

.calendar-day-modal-line-item-name:hover {
    background-color: rgb(241, 241, 241);
}

.calendar-day-modal-colored-circle {
    width: 1em;
    height: 1em;
    background-color: #174061;
}

.calendar-item-modal-container {
    position: absolute;
    right: 12px;
    width: 40%;
    min-width: 480px;
    top: 10px;
    background-color: white;
    z-index: 2;
    border-radius: 1em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 1em;
    padding-left: 1.5em;
    padding-bottom: 1.5em;
    font-size: 12px;
}

.calendar-item-modal-item-type {
    text-transform: capitalize;
    color: grey;
    font-weight: 600;
    font-size: 14px;
}

.calendar-item-modal-section-main {
    border-top: 1px solid rgb(199,199,199);
    margin-top: 1em;
}

.calendar-item-modal-display-name {
    font-size: 1.2em;
}

.calendar-item-modal-action-icon {
    transition: all 0.2s;
    padding: 4px;
    font-size: 18px;
    color: grey;
    cursor: pointer;
    border-radius: 4px;
}

.calendar-item-modal-cancel {
    border-radius: 16px;
}

.calendar-item-modal-delete {
    font-size: 14px;
    font-weight: 500;
    background-color: rgb(182, 48, 48);
    color: white;
    padding: 4px 8px;
}

.calendar-item-modal-action-icon:hover {
    background-color: rgb(231, 231, 231);
}

.calendar-item-modal-delete:hover {
    background-color: rgb(182, 48, 48);
}

.calendar-item-modal-header {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    gap: 20px;
}

.calendar-item-modal-item-identifiers {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    padding-bottom: 0.5em;
}

.calendar-item-modal-item-display-name {
    font-size: 18px;
    font-weight: 400;
}

.calendar-item-modal-item-workspace {
    margin-top: 8px;
}

.calendar-item-modal-item-workspace span {
    font-size: 13px;
    color: grey;
    font-weight: 400;
}

.calendar-item-modal-item-description {
    font-size: 1.2em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    white-space: pre-wrap;
}

.calendar-item-modal-item-descriptor-title {
    font-size: 14px;
    font-weight: 500;
    width: 80px;
}

.calendar-item-modal-item-date-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
    margin-top: 24px;
}

.calendar-item-modal-item-parent-name {
    margin-top: 8px;
}

.calendar-item-modal-item-parent-name span {
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: grey;
}

.calendar-item-modal-item-parent-name span:hover {
    text-decoration: underline;
}

.calendar-item-modal-item-status-action-complete {
    cursor: pointer;
    background-color: #019815;
    color: white;
    padding: 4px 1em;
    border-radius: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    transition: all 0.2s;
    font-weight: 600;
}

.calendar-item-modal-item-status-action-navigate {
    cursor: pointer;
    background-color: rgb(117, 117, 117);
    color: white;
    padding: 4px 1em;
    border-radius: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    font-weight: 600;
    transition: all 0.2s;
}

.calendar-item-modal-item-status-action-delete {
    cursor: pointer;
    background-color: rgb(182, 48, 48);
    color: white;
    padding: 4px 1em;
    border-radius: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    font-weight: 600;
    transition: all 0.2s; 
}

.calendar-item-modal-item-status-action-complete:hover,
.calendar-item-modal-item-status-action-navigate:hover,
.calendar-item-modal-item-status-action-delete:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.calendar-item-modal-item-status-action-navigate svg,
.calendar-item-modal-item-status-action-delete svg {
    color: white;
}

.date-box-editable-selection-container {
    position: relative;
    padding: 0.2em;
    cursor: pointer;
    min-width: 4em;
}

.date-box-editable-selection-container svg {
    color: grey;
    font-size: 14px;
}

.date-box-editable-selection-clickable-display {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    padding: 4px 12px;
    border-radius: 8px;
    font-weight: 300;
    text-decoration: underline;
    text-decoration-color: #b8b8b8;
    font-weight: 600;
    color: #174061;
    /* background-color: rgb(241, 241, 241);
    border: 1px solid rgb(197, 197, 197); */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}

.date-box-editable-section-label {
    margin: 0px;
    font-size: 0.9em;
    color: rgb(146, 146, 146);
    font-weight: 600;
}

.date-box-editable-inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.date-box-editable-description {
    font-size: 14px;
    /* width: 80px; */
    min-width: 80px;
    text-align: right;
}

.date-box-editable-calendar-picker {
    position: absolute;
    box-sizing: border-box;
    right: 0;
    z-index: 200;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.date-box-no-date-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.public-invitation-invitation-banner {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    background-color: rgb(255, 235, 205);
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.public-invitation-inputs {
    width: 60%;
    min-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
}

.public-invitation-input-label {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}

.public-invitation-input-sublabel {
    font-size: 20px;
    text-align: center;
}

.public-invitation-inputs input[type=password] {
    margin-bottom: 20px;
    margin-top: 20px;
}

.public-invitation-input-advance-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-self: center;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 28px;
}

.public-invitation-input-advance-button {
    background-color: #174061;
    padding: 8px 24px;
    font-size: 20px;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border-radius: 12px;
    transition: all 0.2s;
    cursor: pointer;
}

.public-invitation-input-advance-button:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.public-invitation-inputs input {
    width: 100%;
    text-align: center;
    border: none;
    border-bottom: 1px solid rgb(156, 156, 156);
    font-size: 28px;
    padding: 0.4em;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;  
}

.misc-bearing-badge-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 16px;
    box-sizing: border-box;
}

span.misc-bearing-badge {
    padding: 4px 12px;
    cursor: pointer;
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    color: #a54198;
    background-color: #f4dcf7;
}

.misc-bearing-badge-colors {
    position: absolute;
    bottom: 150%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 8px;
    border-radius: 8px;
}

.misc-bearing-badge-color {
    width: 28px;
    height: 28px;
    min-width: 28px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.misc-bearing-badge-texts-container {
    position: absolute;
    top: 150%;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 180px;
}

input.misc-bearing-badge-text-search {
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(39, 89, 155);
    font-size: 14px;
    padding: 8px;
    font-weight: 500;
}

.misc-bearing-badge-texts {
    display: flex;
    flex-direction: column;
}

.misc-bearing-badge-text {
    padding: 8px 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.misc-bearing-badge-text:hover {
    background-color: rgb(236, 236, 236);
}

/* MESSAGES */
.messages {
    margin-top: 48px;
    position: relative;
    font-size: 14px;
}

.messages-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: -200px;
    top: 0;
    z-index: 0;
}

.messages-flyout .messages-backdrop {
    z-index: 200;
}

.messages-flyout .messages-mobile,
.messages-flyout .messages-mobile-and-desktop,
.messages-flyout .messages-desktop {
    z-index: 201;
}

.messages-dark .messages-mobile .chat-message-author,
.messages-dark .messages-desktop .chat-message-author,
.messages-dark .chat-message-author {
    color: white !important;
}

.messages-dark .messages-backdrop {
    background-color: rgb(44, 44, 44);
}

.messages-light .messages-backdrop {
    background-color: #f9f9f9;
}

.messages-mobile,
.messages-desktop {
    position: relative;
    z-index: 1;
}

.messages-mobile { display: none; }
.messages-desktop { display: block; }
.messages-mobile-and-desktop {
    z-index: 201;
    position: relative;
}

.messages.messages-dark {
    color: white;
}

.messages.messages-light {
    color: black;
}

.messages-mobile-container {
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
}

.messages-mobile-container h2 {
    font-size: 28px;
    margin: 0;
    padding: 0;
}

.messages-desktop-container {
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    --messages-desktop-sidebar-width: 300px;
}

.messages-room-desktop-header-inner h2 {
    cursor: pointer;
}

.messages-room-desktop-header-inner h2:hover {
    text-decoration: underline;
}

.messages-desktop-sidebar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: var(--messages-desktop-sidebar-width);
    overflow-y: hidden;
}

.messages-desktop-room-display {
    position: absolute;
    left: var(--messages-desktop-sidebar-width);
    bottom: 0;
    right: 0;
    top: 48px;
}

.messages-desktop-sidebar-header {
    margin-top: 48px;
    padding-left: 12px;
    display: flex;
    align-items: center;
}

.messages-desktop-sidebar-header h2 {
    margin: 0;
}

.messages-debug-button {
    position: fixed;
    bottom: 40%;
    right: 12px;
    z-index: 3;
}

.messages-debug-button span {
    padding: 12px;
    background-color: pink;
    border-radius: 6px;
    font-size: 14px;
}

.messages-mobile-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 8px;
    margin-bottom: 20px;
}

.messages-desktop-list-scrollable-holder {
    position: relative;
    height: calc(100% - 100px);
}

.messages-desktop-list-scrollable-holder-inner {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: auto;
}

.messages-desktop-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-x: hidden;
    gap: 0px;
    margin-top: 8px;
    padding-bottom: 20px;
}

.messages-desktop-list:last-child {
    margin-bottom: 20x;
}

.messages-desktop-list-room {
    padding: 16px 12px;
    margin: 4px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
}

.messages-desktop-list-room-information {
    display: flex;
    flex-direction: row;
    gap: 14px;
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.messages-desktop-list-room-info-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.messages-desktop-list-room-info-timestamp {
    min-width: 60px;
    width: 60px;
    text-align: right;
}

.messages-desktop-list-room-info-timestamp span {
    font-size: 11px;
}

.messages-light .messages-desktop-sidebar {
    /* background-color: #f6f6f6; */
    background-color: #f4f4f4;
}

.messages-dark .messages-desktop-list-room {
    /* background-color: rgb(61, 61, 61); */
    background-color: rgb(61, 61, 61);
}

.messages-light .messages-desktop-list-room {
    /* background-color: #f1f1f1; */
    background-color: #ffffff;
}

.messages-desktop-list-room-selected {
    background-color: #3D72AA !important;
    color: white !important;
}

.messages-desktop-room-preview-info {
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.messages-desktop-list-room-info-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    flex-grow: 1;
    width: 100%;
}

.messages-room-desktop-inputs-container .chat-input-container {
    border-top: none;
}

.messages-mobile-workspace-room-preview-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    padding: 16px 0px;
}

.messages-mobile-list .messages-room-nav-symbol {
    height: 48px;
    width: 48px;
    min-width: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messages-room-chat-inputs {
    font-size: 16px;
}

.messages-mobile-list .messages-room-nav-symbol svg {
    color: rgb(255, 255, 255);
    font-size: 24px;
}

.messages-desktop-room-display .messages-room-mobile-header,
.messages-desktop-sidebar-header {
    height: 66px;
}

.messages-light .messages-desktop-room-display .messages-room-mobile-header {
    border-bottom: 2px solid #f4f4f4;
}

.messages-dark .messages-desktop-room-display .messages-room-mobile-header {
    border-bottom: 2px solid rgb(61, 61, 61);
}

.messages-mobile-list .messages-room-nav-activity,
.messages-mobile-container .messages-room-mobile-header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.messages-mobile-and-desktop .messages-mobile-container .messages-room-mobile-header,
.messages-mobile-and-desktop .messages-mobile-header,
.messages-mobile-and-desktop .messages-mobile-list {
    transform: translateY(-54px);
}

.messages-mobile-and-desktop .messages-room-mobile-messages-container-outer {
    top: 60px;
}


.messages-mobile-list .messages-room-nav-activity-room-name,
.messages-desktop-list .messages-room-nav-activity-room-name,
.messages-mobile-container .messages-room-mobile-header h2 {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.messages-desktop-list .messages-room-nav-activity-room-name {
    font-size: 12px;
    font-weight: 600;
}

.messages-room-mobile-header-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.messages-room-desktop-header-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-left: 14px;
}

.messages-room-desktop-header-flyout {
    margin-right: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    min-width: 40px;
}

.messages-room-desktop-header-flyout svg {
    font-size: 24px;
}

.messages-room-mobile-header-inner-leave-room {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    cursor: pointer;
}

.messages-room-mobile-header-inner-leave-room svg {
    font-size: 24px;
}

.messages-room-nav-activity-room-name {
    cursor: pointer;
}

.messages-mobile-container .messages-room-mobile-header h2 {
    font-size: 20px;
}

.messages-room-nav-activity {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.messages-room-nav-activity span {
    color: grey;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.messages-room-mobile-messages-container-outer {
    position: fixed;
    top: 120px;
    bottom: 92px;
    left: 0;
    right: 0;
    overflow: hidden;
}

.messages-room-desktop-messages-container-outer {
    position: fixed;
    top: 140px;
    bottom: 92px;
    left: var(--messages-desktop-sidebar-width);
    right: 0;
    overflow: hidden;
}

.messages-room-mobile-messages-container-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 93%;
    margin-left: 3.5%;
    margin-right: 3.5%;
    padding-right: 12px;
    box-sizing: border-box;
    overflow-y: auto;
}

.messages-room-desktop-messages-container-width-control {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.messages-room-mobile-inputs-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
}

.messages-room-desktop-inputs-container {
    position: fixed;
    left: var(--messages-desktop-sidebar-width);
    right: 0;
    bottom: 0;
    height: 80px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 760px) {
    .messages-mobile { display: block; }
    .messages-desktop { display: none; }

    .messages-mobile .chat-message p {
        font-size: 16px;
    }
}

.user-mydesk-header-loading-container {
    position: fixed;
    right: 0;
    top: 56px;
}

.loader-mydesk {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#4F5D74 94%,#0000) top/3px 3px no-repeat,
      conic-gradient(#0000 30%,#4F5D74);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
    animation: mydeskloading 0.5s infinite linear;
}
@keyframes mydeskloading { 
    100%{transform: rotate(1turn)}
}

/* TUTORIAL MODE */
.tutorial-tooltip-container {
    position: absolute;
    --t-tutorial-color: #3F73AA;
    --t-text-color: rgb(31, 31, 31);
}

.tutorial-tooltip {
    position: absolute;
    top: 100%;
    min-width: 360px;
    z-index: 100;
    background-color: white;
    border: 1px solid var(--t-tutorial-color);
    border-radius: 12px;
    padding: 8px 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.tutorial-tooltip-arrow-up {
    position: absolute;
    bottom: 100%;
    width: 0; 
    height: 0; 
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid var(--t-tutorial-color);
}

.tutorial-tooltip-arrow-down {
    position: absolute;
    top: 100%;
    width: 0; 
    height: 0; 
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid var(--t-tutorial-color);
}

.tutorial-tooltip-arrow-right {
    position: absolute;
    left: 100%;
    width: 0; 
    height: 0; 
    border-left: 12px solid var(--t-tutorial-color);
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
}

.tutorial-tooltip-arrow-left {
    position: absolute;
    right: 100%;
    width: 0; 
    height: 0; 
    border-right: 12px solid var(--t-tutorial-color);
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
}

.tutorial-tooltip-skip {
    position: absolute;
    top: 8px;
    right: 18px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    color: var(--t-tutorial-color);
    cursor: pointer;
}

.tutorial-tooltip-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.tutorial-tooltip-header h3 {
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--t-text-color);
}

.tutorial-tooltip p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    margin-top: 1em;
    color: var(--t-text-color);
}

.tutorial-tooltip-save {
    background-color: var(--t-tutorial-color);
    color: rgb(226, 226, 226);
    width: 100%;
    text-align: center;
    cursor: pointer;
    padding: 8px 0px;
    box-sizing: border-box;
    margin-top: 14px;
    font-size: 16px;
    font-weight: 500;
}

.tutorial-tooltip-resource-links {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tutorial-tooltip-resource-link {
    width: 100%;
    border: 1px solid rgb(228, 230, 241);
    border-radius: 8px;
    background-color: #F7F9FD;
    display: flex;
    align-items: stretch;
}

.tutorial-tooltip-resource-link span,
.tutorial-tooltip-resource-link input {
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
}

.tutorial-tooltip-resource-link span {
    border-right: 1px solid rgb(228, 230, 241);
    padding-right: 14px;
    color: rgb(182, 185, 206);
}

.tutorial-tooltip-resource-link input {
    flex-grow: 1;
    border: none;
    color: var(--t-text-color);
}

.tutorial-tooltip-workstreams-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100px;
    margin: 14px auto;
}

.tutorial-tooltip-workstreams-animation img {
    width: 100%;
}

/* USER NOTES */
.user-notes-container {
    display: flex;
    position: absolute;
    width: 100%;
}
  
.user-notes-navigation {
    width: 280px;
    min-width: 280px;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 138px;
    height: calc(100vh - 138px);
    max-height: calc(100vh - 138px);
    border-right: 1px solid rgb(213, 213, 213);
}

.user-notes-navigation h4 {
    margin: 0;
    font-size: 16px;
    color: var(--t-color-dark);
    margin-bottom: 20px;
}

.user-notes-navigation-empty-add {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #3D72AA;
    font-weight: 500;
    cursor: pointer;
}

.user-notes-navigation-empty-add:hover {
    text-decoration: underline;
}

.user-notes-navigation-empty-add svg {
    font-size: 16px;
}
  
.user-notes-navigation-notes {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 280px;
    max-width: 280px;
    box-sizing: border-box;
}
  
.user-notes-folder > span {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}
  
.user-notes-folder > .user-notes-navigation-notes {
    margin-left: 15px;
}
  
.user-notes-note {
    cursor: pointer;
    padding: 8px 4px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.user-notes-selected {
    background-color: #e0e0e0;
}
  
.user-notes-display-panel {
    flex-grow: 1;
    padding: 20px;
    margin-left: 320px; /* Match width of user-notes-navigation */
    display: flex;
    flex-direction: column;
}

.user-notes-display-controls {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 20px;
    transform: translateY(12px);
    z-index: 4;
}

.user-notes-action-remove {
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.user-notes-action-remove svg {
    color: #3D72AA;
    font-size: 16px;

}

.user-notes-action-remove:hover {
    background-color: rgb(233, 233, 233);
}

.user-notes-display-panel .gadget-lexical-editor {
    max-height: unset;
    font-size: 14px;
    min-height: 50vh;
}

.user-notes-display-panel .gadget-lexical-placeholder {
    font-size: 16px;
    top: 45px;
}

.user-notes-empty-text {
    margin: 0;
    font-size: 14px;
}
  
.user-notes-title-input {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 14px;
    width: 100%;
    padding: 8px 0px;
    box-sizing: border-box;
    outline: none;
    border: none;
    background-color: transparent;
}
  
.user-notes-content-input {
    flex-grow: 1;
    padding: 10px;
    font-size: 14px;
    resize: none;
}
  
.user-notes-add-button {
    position: absolute;
    right: 8px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-notes-add-button svg {
    font-size: 20px;
    color: #3D72AA;
}
  
.user-notes-add-button:hover {
    background-color: rgb(234, 234, 234);
}