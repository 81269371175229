.network-hub {
    width: 100%;
    margin-top: 48px;
    --network-hub-color-select: #2a73ae;
}

.network-hub-header {
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0px;
    border-bottom: 1px solid rgb(217, 217, 217);
}

.network-hub-header-left {
    font-weight: 600;
    font-size: 32px;
}

.network-hub-header-left svg {
    font-size: 16px;
    color: rgb(41, 115, 175);
    min-width: 16px;
    margin-left: 6px;
}

.network-hub-header-nav {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    margin-top: 14px;
}

.network-hub-header-nav span {
    border-bottom: 2px solid transparent;
    padding-bottom: 6px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(92, 92, 92);
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}

.network-hub-header-nav div.network-hub-header-nav-decorator {
    font-size: 10px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    margin: 0;
    border-radius: 20px;
    position: absolute;
    right: -22px;
    top: 0px;
    background-color: var(--network-hub-color-select-light);
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.network-hub-header-nav .network-hub-header-nav-sel,
.network-hub-header-nav span:hover {
    border-bottom: 2px solid var(--network-hub-color-select);
    color: var(--network-hub-color-select);
}

.network-hub-page {
    width: 100%;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
}

.network-hub-page-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.network-hub-page-header-text {
    margin: 0px;
    font-weight: 500;
    font-size: 20px;
    line-height: 48px;
    color: #222222;
}


/* LEGACY NETWORK STYLES */
.network-outer-container {
    display: flex;
    flex-direction: column;
    margin-left: 240px;
    transition: margin-left 0.3s;
    margin-top: 48px; /* accounts for the header */
}

.network-container-sidebar-hidden {
    padding-left: 0;
    margin-left: 0;
}

.network-lower-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.network-page-action-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transform: translateX(-8px)
}

.network-page-action-header-nav-back {
    font-size: 1.4em;
    color: grey;
    cursor: pointer;
    padding: 4px;
    transition: all 0.1s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.network-page-action-header-nav-back:hover {
    background-color: rgb(238, 238, 238);
}

.network-home,
.network-workspaces,
.network-page {
    width: 100%;
    box-sizing: border-box;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.3em;
    position: relative;
}

.network-home-title {
    margin-bottom: 1em;
    border-bottom: 1px solid rgb(199,199,199);
    padding-bottom: 1em;
}

.network-home-title h1,
.network-page-header-text h1 {
    margin: 0px;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #222222;
}

.network-home-subtitle,
.network-page-header-text p {
    color: grey;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    font-size: 1em;
    margin: 0px;
}

.network-home-instructional-text {
    font-size: 1em;
    color: grey;
    font-weight: 500;
}

.network-home-action-grid-outer {
    margin-top: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2em;
}

.network-home-action-grid-single-container {
    padding: 4px;
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1em;
    cursor: pointer;
    transition: all 0.1s;
}

.network-home-action-grid-single-container:hover {
    background-color: rgb(243, 243, 243);
}

.network-home-action-grid-single-symbol {
    flex-basis: 10%;
}

.network-home-action-grid-single-symbol svg {
    font-size: 1.5em;
    padding: 0.5em;
    border: 1px solid rgb(199,199,199);
    border-radius: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.network-home-action-grid-single-text-container {
    flex-basis: 90%;
}

.network-home-action-grid-single-text-container h2 {
    margin: 0px;
    font-weight: 700;
    font-size: 1.2em;
    color: #222222;
}

.network-home-action-grid-single-text-container p {
    margin: 0px;
    font-size: 1em;
    font-weight: 500;
    color: grey;
}

.network-page-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid rgb(199,199,199);
    padding-bottom: 1em;
}

.network-page-header-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}

.network-page-header-actions {
    display: flex;
    flex-direction: row-reverse;
    flex-basis: 30%;
}

.network-page-add-workspace {
    padding: 8px 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2em;
    box-shadow: 0px 4px 4px rgba(125, 125, 125, 0.25);
    background-color: #3D72AA;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
}

.network-page-add-workspace-alt {
    background-color: grey;
    cursor: default;
}

.network-page-add-workspace span {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.network-users-view-license-usage-badge {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
}

.network-users-view-license-usage-badge span {
    font-size: 12px;
    font-weight: 500;
    background-color: bisque;
    color: rgb(29, 29, 29);
    padding: 4px 20px;
    border-radius: 12px;
}

.network-page-table-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.network-page-table-empty-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2em;
    margin-bottom: 4em;
    margin-top: 4em;
}

.network-page-table-empty-heading h2 {
    margin: 0px;
    font-weight: 700;
    font-size: 1.2em;
    color: #222222;
}

.network-page-table-empty-heading p {
    margin: 0px;
    font-size: 1em;
    font-weight: 500;
    color: grey;
}

.network-page-empty-import-text {
    font-size: 0.9em;
    margin-top: 1em;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.network-settings-item {
    border-bottom: 1px solid rgb(199,199,199);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    min-height: 6em;
    padding-top: 1em;
    padding-bottom: 1em;
}

.network-settings-item.network-settings-item-toggles {
    align-items: flex-start;
}

.network-settings-item-text {
    flex-basis: 30%;
}

.network-settings-item-text h2 {
    margin: 0px;
    font-weight: 700;
    font-size: 1.2em;
    color: #222222;
}

.network-settings-item-text p {
    margin: 0px;
    font-size: 1em;
    font-weight: 500;
    color: grey;
}

.network-settings-item-nested-list-outer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-grow: 1;
}

.network-settings-item-nested-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.network-settings-item-nested-list-item-title {
    flex-grow: 1;
    font-size: 16px;
    color: grey;
}

.network-settings-item-nested-list-item-toggle {
    width: 48px;
    display: flex;
    justify-content: flex-start;
}

.network-settings-item-input {
    flex-grow: 1;
}

.network-settings-item-input textarea {
    height: 200px;
    max-height: 360px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
    border-bottom: 1px solid rgb(199,199,199);
    padding: 14px;
    font-size: 1em
}

.network-settings-item-input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.network-settings-item-input-feedback-text {
    font-size: 12px;
    color: grey;
    position: absolute;
    top: calc(100% + 8px);
}

.network-settings-item-inputs {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    flex-grow: 1;
}

.network-settings-item-input-text {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(199,199,199);
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-size: 1em;
}

.network-settings-save-discard-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.network-settings-button {
    padding: 4px 12px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    border-radius: 4px;
    transition: all 0.2s;
}

.network-settings-discard:hover {
    background-color: rgb(236, 236, 236);
}

.network-settings-save {
    background-color: rgb(23, 64, 97);
    color: white;
}

.network-settings-billing h2 {
    font-size: 28px;
    font-weight: 400;
    text-align: center;
}

.network-settings-billing h2 span {
    font-weight: 700;
}

.network-license-user-activity-main h3 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    margin-top: 40px;
}

.network-license-user-activity-main h3:first-of-type {
    margin-top: 0px;
}

.network-license-user-boxes {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 20px;
}

.network-license-user-box {
    max-width: 360px;
    min-width: 240px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
}

.network-license-user-box-spaced-between {
    justify-content: space-between;
}

.network-license-user-box-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 16px;
}

.network-license-user-box span {
    font-size: 32px;
    font-weight: 600;
    padding: 8px 0px;
}

.network-license-user-box p {
    margin: 0;
    margin-top: 16px;
    color: grey;
    font-size: 14px;
    font-style: italic;
}

.network-license-user-active-handles {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 12px;
}

.network-license-user-active-handles span {
    font-size: 16px;
    color: grey;
}

.network-license-user-activity-disclaimer {
    text-align: center;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    background-color: rgb(106, 127, 172);
    color: white;
    padding: 12px 0px;
}

.network-workspace-manage-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    padding: 12px;
    transition: all 0.2s;
    text-transform: uppercase;
    font-size: 0.8em;
}

.network-workspace-manage-user-symbol {
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 4px;
    padding: 12px 12px;
    transition: all 0.2s;
    text-transform: uppercase;
    font-size: 0.8em;
}

.network-team-table-team-name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.network-team-open-manage-button {
    border: none;
    background-color: transparent;
    border: 1px solid rgb(199,199,199);
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 12px;
    transition: all 0.2s;
    text-transform: uppercase;
    font-size: 0.8em;
}

.network-team-open-manage-symbol {
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 4px;
    transition: all 0.2s;
    font-size: 1.2em;
    color: rgb(78, 78, 78);
}


.network-team-open-manage-button:hover,
.network-workspace-manage-button:hover,
.network-workspace-manage-user-symbol:hover,
.network-team-open-manage-symbol:hover {
    background-color: rgb(233, 233, 233);
}

/* Network Modal Items */
.network-panel-controls-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(199, 199, 199);
    background-color: white;
    padding-left: 8px;
    margin-bottom: 1em;
    padding-right: 8px;
    font-size: 14px;
    box-sizing: border-box;
}

.network-panel-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-bottom: 2px;
}

.network-panel-nav-container svg {
    font-size: 1.5em;
    margin-right: 1em;
}

.network-panel-nav-container-actions {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.network-panel-action-button {
    padding: 4px 12px;
    font-size: 1em;
    background-color: rgb(180, 43, 73);
    color: white;
    cursor: pointer;
}

.network-panel-nav-container span {
    transition: all 0.2s;
    padding: 4px;
    border-bottom: 2px solid transparent;
    text-align: center;
    margin-right: 16px;
}

.network-panel-nav-container span:hover {
    cursor: pointer;
    border-bottom: 2px solid rgb(23, 64, 97);
}

.network-panel-nav-container span.network-panel-nav-selected {
    border-bottom: 2px solid rgb(23, 64, 97);
}

.modal-team-no-users-found-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    margin-top: 60px;
    margin-bottom: 2em;
    font-size: 14px;
}

.modal-network-manage-users-section { 
    margin-top: 1em;
    margin-bottom: 1em;
}

.modal-network-manage-users-form {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 1em;
}

.modal-network-manage-users-form label {
    flex-basis: 10%;
}

.network-users-form-dropdown {
    border: none;
    flex-grow: 1;
    font-size: 1em;
}

.network-users-form-submit {
    border: none;
    background-color: white;
    font-size: 0.9em;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid rgb(199,199,199);
    padding: 4px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.network-users-view-navigation {
   width: 100%;
   border-bottom: 1px solid rgb(219, 219, 219);
   box-sizing: border-box;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 36px;
}

.network-users-view-navigation-selected {
    color: rgba(0, 0, 0, 1) !important;
    border-bottom: 4px solid rgba(61, 114, 170, 1) !important;
}

.network-users-view-navigation span {
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border-bottom: 4px solid transparent;
    line-height: 36px;
}

.network-users-view-navigation.network-users-view-navigation-small span {
    font-size: 16px;
    line-height: 24px;
}

.network-users-view-navigation-small {
    margin-bottom: 24px;
}

.modal-network-manage-users-buttons {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-network-manage-users-button {
    padding: 0.5em;
    background-color: rgb(180, 43, 73);
    color: white;
    font-size: 0.9em;
    font-weight: 600;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.modal-team-add-search-box {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(199,199,199);
    border-radius: 4px;
    padding: 0.8em 0.5em;
    font-size: 1em;
}

.modal-team-add-network-users {
    margin-top: 1em;
    margin-bottom: 1em;
}

.modal-team-add-network-users-subtext {
    font-size: 0.9em;
    color: grey;
    text-align: center;
}

.network-page-teams-team-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.network-page-teams-team-section,
.network-page-roles-role-section {
    border: 1px solid #EAEAEA;
    padding: 24px;
}

.network-page-roles-attributes {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.network-page-roles-attribute-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.network-page-roles-attribute h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
}

.network-page-roles-attribute p {
    margin: 0;
    font-size: 22px;
    font-weight: 700;
}

.network-page-teams-team-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
}

.network-page-teams-team-name-noneditable,
.network-page-teams-team-name-editable {
    flex-grow: 1;
    font-weight: 700;
    box-sizing: border-box;
    font-size: 22px;
    padding-bottom: 4px;
    padding-top: 4px;
    border: 1px solid transparent;
    line-height: 30px;
}

.network-page-teams-team-name-editable {
    /* border: none; */
    border: 1px solid transparent;
}

.network-page-teams-team-name-editable:hover {
    border: 1px solid rgb(199,199,199);
}

.network-page-subsection-container-split {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 60px;
}

.network-page-subsection-split-titles {
    box-sizing: border-box;
    margin-left: 2px;
}

.network-page-subsection-split-details {
    box-sizing: border-box;
}

.network-page-subsection-title {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.network-page-subsection-title svg {
    font-size: 1.2em;
    padding: 0.2em;
    border-radius: 6px;
    transition: all 0.2s;
    cursor: pointer;
}

.network-page-subsection-title svg:hover {
    background-color: rgb(226, 226, 226);
}

.network-page-table-empty-state-text {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
    font-style: italic;
}

.modal-manage-user-membership-role-text-show-more {
    font-size: 0.9em;
    cursor: pointer;
    color: #017698;
}

.modal-manage-user-membership-role-text-show-more:hover {
    text-decoration: underline;
}

.network-users-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 52px;
}

.network-users-container {
    padding-bottom: 40px;
}

.network-users-view-outer {
    padding-top: 32px;
}

.network-users-header-row input,
.network-hub-page-header-row input {
    background-color: rgba(242, 242, 242, 1);
    border-radius: 4px;
}

.network-users-section-upper-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.network-users-section-upper-row p {
    margin: 0;
    font-size: 14px;
    color: rgba(0,0,0,1);
    line-height: 20px;
}

.network-users-active-users-table {
}

.network-users-active-users-columns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    min-height: 92px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
}

.network-users-active-users-columns.network-users-active-users-columns-headers {
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 20px;
    min-height: unset;
    padding: 12px 0px;
    color: rgb(54, 54, 54)
}

.network-users-active-users-columns span {
    font-size: 14px;
}

.network-users-col-prof { flex-basis: 6%;} 
.network-users-col-user { flex-basis: 19%;} 
.network-users-col-role { flex-basis: 15%;} 
.network-users-col-teams { flex-basis: 15%;} 
.network-users-col-workspaces { flex-basis: 23%;} 
.network-users-col-status { flex-basis: 12%;} 
.network-users-col-actions { flex-basis: 10%;} 

.network-users-pending-col-email { flex-basis: 20% }
.network-users-pending-col-teams { flex-basis: 25% }
.network-users-pending-col-workspaces { flex-basis: 25% }
.network-users-pending-col-status { flex-basis: 20% }
.network-users-pending-col-actions { flex-basis: 10% }

.network-users-col-workspaces,
.network-users-col-workspaces div,
.network-users-pending-col-workspaces,
.network-users-pending-col-workspaces div,
.network-users-pending-col-email,
.network-users-pending-col-email div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.network-users-col-actions,
.network-users-pending-col-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.network-users-col-action-edit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;
}

.network-users-col-action-edit svg,
.network-users-pending-col-actions svg {
    color: #3D72AA;
    font-size: 22px;
}

.network-users-team-outer {
    margin-top: 54px;
}

.network-team-users-teammate-cards {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 18px;
}

.network-team-users-teammate-card,
.network-team-users-workspace-card {
    flex-basis: 25%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(166, 166, 166, 0.25);
    display: flex;
    align-items: stretch;
    gap: 12px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.1s;
}

.network-team-users-workspace-card {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
}

.network-team-users-teammate-card:hover {
    background-color: rgba(134, 134, 134, 0.089);
}

.network-team-users-teammate-card-names {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.network-team-users-teammate-card-names div {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.network-team-users-teammate-card-names span {
    font-size: 14px;
    line-height: 20px;
}

@media only screen and (min-width: 1300px) {
    .network-home,
    .network-workspaces,
    .network-page {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .network-home-action-grid-single-container {
        flex-basis: 46%;
    }

    .network-home-action-grid-outer {
        gap: 3em;
    }

    .network-page-subsection-container-split {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1em;
    }
    
    .network-page-subsection-split-titles {
        flex-basis: 20%;
        order: 1;
        flex-grow: 1;
        box-sizing: border-box;
    }
    
    .network-page-subsection-split-details {
        flex-basis: 75%;
        order: 2;
        box-sizing: border-box;
        margin-bottom: 0;
    }
}