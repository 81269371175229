.alignment-modal-background,
.project-post-modal-background {
    position: fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.134);
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.alignment-modal-form-container {
    min-height: 120px;
    max-width: 400px;
    width: 80%;
    margin-top: 120px;
    background-color: white;
    border-radius: 4px;
    z-index: 101;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.project-post-form-container {
    min-height: 120px;
    max-width: 1000px;
    width: 80%;
    margin-top: 120px;
    background-color: white;
    border-radius: 6px;
    position: relative;
    z-index: 101;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.project-post-form-new-post-cancel-button-container {
    position: absolute;
    top: 28px;
    right: 16px;
}

.project-post-form-new-post-cancel-button-container svg {
    font-size: 24px;
    color: rgb(88, 88, 88);
}

.project-post-form-container textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 8em;
    margin-bottom: 16px;
    border: 1px solid rgba(78, 78, 78, 0);
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    font-size: 16px;
    resize: none;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.post-component-update-specific-container {
   width: 100%;
   margin-bottom: 24px;
}

.post-component-update-specific-container:last-of-type {
    margin-bottom: 0px;
}

.post-component-update-specific-container h5 {
    margin-top: 0px;
    margin-bottom: 8px;
    padding-bottom: 4px;
    color: black;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #C7C7C7;
}

.post-component-update-status-boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.post-component-update-status-boxes div {
    font-weight: 600;
    font-size: 14px;
    margin: 4px 8px 4px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 36px;
    box-sizing: border-box;
    transition: all 0.2s;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.post-component-update-status-boxes div:first-of-type {
    margin-left: 0px;
}

.post-component-update-status-boxes div:hover {
    filter: brightness(80%);
    cursor: pointer;
}

.status-box-new {
    background-color: rgb(152, 95, 167);
    color: white;
}

.status-box-draft {
    background-color: rgb(102, 102, 102);
    color: white;
}

.status-box-started {
    background-color: rgb(41, 115, 175);
    color: white;
}

.status-box-blocked {
    background-color: #ffb900;
    color:rgb(48, 48, 48);
}

.status-box-archived {
    background-color: #fc636b;
    color: white;
}

.status-box-completed {
    background-color: #049921;
    color: white;
}

.status-box-selected {
    border: 4px solid rgb(53, 53, 53);
    border-radius: 4px;
}

.post-component-update-specific-container textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    height: 8em;
    margin-bottom: 0px;
    border-radius: 0px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    font-size: 16px;
    resize: none;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alignment-modal-form-container h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
}

.project-post-form-container h3 {
    font-size: 20px;
    margin: 0px;
    margin-bottom: 20px;
    margin-top: 12px;
    font-weight: 700;
    text-align: center;
}

.project-post-form-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.project-post-form-rich-text-controls {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px 12px;
    border: 1px solid rgb(199,199,199);
}

.project-post-form-rich-text-control {
    font-size: 16px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    cursor: pointer;
    border-radius: 0.2em;
    transition: all 0.2s;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-post-form-rich-text-control svg {
    font-size: 20px;
}

.project-post-form-rich-text-editor {
    min-height: 400px;
    max-height: 600px;
    position: relative;
    overflow-y: auto;
    font-size: 16px;
    border-left: 1px solid rgb(199,199,199);
    border-right: 1px solid rgb(199,199,199);
    border-bottom: 1px solid rgb(199,199,199);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 12px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
}

.project-post-form-rich-text-editor-placeholder {
    position: absolute;
    color: grey;
}

.public-DraftEditorPlaceholder-inner {
    color: grey;
}

.project-post-form-rich-text-control:hover {
    background-color: rgb(199,199,199);
}

.project-post-form-header-identity {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.project-post-form-header-identity-name-full {
    font-size: 16px;
    font-weight: 700;
}

.project-post-form-header-identity-name-handle {
    color: grey;
}

.project-post-form-cancel {
    border-radius: 36px;
    transition: all 0.2s;
    padding: 4px;
}

.project-post-form-cancel:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.alignment-menu-new-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    transition: all 0.4s;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.alignment-menu-new-option:hover {
    cursor: pointer;
    background-color: rgba(187, 187, 187, 0.16);
}

.alignment-menu-new-option h4,
.alignment-menu-new-option p {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
}

.alignment-menu-new-option p {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 2px;
    margin-bottom: 8px;
    width: 70%;
    border-bottom: 1px solid lightgrey;
}

.project-post-post-button-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
}

.project-post-post-button,
.project-post-post-button-invalid {
    box-sizing: border-box;
    padding: 8px 24px;
    font-weight: 600; 
    text-align: center;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    transition: all 0.2s;
}

.project-post-post-button {
    cursor: pointer;
    background-color: #097969;
}

.project-post-post-button-cancel {
    color: grey;
    background-color: white;
    border: 1px solid grey;
}

.project-post-post-button:hover {
    background-color: rgb(146, 146, 146);
}

.project-post-post-button-invalid {
    background-color: #b3b3b3;
}

.project-post-post-button-invalid:hover {
    cursor: not-allowed;
}

.project-post-post-button-cancel:hover {
    color: white;
}

.workspace-post-form-alternate-update-vertical-divider {
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 28px;
    border-right: 1px solid rgb(214, 214, 214);
}

.modal-workspace-manage-users-form {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.modal-workspace-manage-users-form-section {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}

.modal-workspace-manage-users-back-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 8px;
    align-self: flex-start;
    transition: all 0.2s;
}

.modal-workspace-manage-users-back-nav:hover {
    background-color: rgb(240, 240, 240);
}

.modal-workspace-manage-users-form-giant-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;
}

.modal-workspace-manage-users-form-giant-button {
    width: 200px;
    height: 200px;
    cursor: pointer;
    border: 1px solid rgb(224, 224, 224);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.modal-workspace-manage-users-form-giant-button svg {
    font-size: 32px;
}

.modal-workspace-manage-users-form-giant-button div {
    font-size: 24px;
    font-weight: 400;
}

.modal-workspace-manage-users-form-giant-button:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.modal-workspace-manage-users-form-button-nevermind {
    margin-top: 24px;
    color: #3b3b3b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    cursor: pointer;
}

.modal-workspace-manage-users-form-button-nevermind:hover {
    text-decoration: underline;
}

.modal-workspace-manage-users-form-invite-input {
    padding: 4px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid rgb(199,199,199);
    font-size: 16px;
}

/* LIST TASK */
.modal-task-edit-section-group {
    margin-bottom: 28px;
}

.modal-task-edit-section-group-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    margin-bottom: 8px;
}

.modal-task-edit-section-group-title span {
    margin-left: 8px;
    font-weight: bold;
}

.modal-task-edit-section-group-content {
    font-size: 16px;
}

.modal-task-edit-section-details {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    width: 100% !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* resize: none; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 4px;
}

.modal-task-edit-section-details-display {
    white-space: pre-wrap;
    padding: 4px;
    padding-left: 0px;
    min-height: 24px;
}

.modal-task-edit-section-details-display:hover {
    background-color: rgba(241, 241, 241, 0.651);
    cursor: text;
}

.modal-task-edit-section-due-date {
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.modal-task-edit-section-description {
    font-size: 24px !important;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    width: 100% !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.modal-task-edit-section-save {
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgb(23, 64, 97);
    color: white;
}

.modal-task-edit-section-cancel-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal-task-edit-section-cancel {
    color: grey;
    text-align: center;
}

.modal-task-edit-section-save:hover {
    cursor: pointer;
    background-color: rgb(3, 15, 24);
}

.modal-task-edit-section-cancel:hover {
    text-decoration: underline;
    cursor: pointer;
}

/* CONFIRMATION POPUP */
.modal-interaction-confirm-container {
    min-height: 120px;
    max-height: 90%;
    min-width: 400px;
    width: 40%;
    margin-top: 5%;
    background-color: white;
    border-radius: 6px;
    z-index: 101;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    overflow-y: auto;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.modal-interaction-confirm-text-main {
    margin-top: 24px;
    font-size: 16px;
    white-space: pre-wrap;
}

/* FULL SCREEN TASK EDITING MODAL */
.modal-edit-task-container-responsive-width {
    --modal-edit-task-footer-height: 44px;
    --modal-edit-task-header-height: 80px;
    --modal-edit-task-border-colors: rgb(184, 184, 184);
    width: 95%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 0px 4px 10px 0px rgba(115, 115, 115, 0.25);
    border-radius: 24px;
}

.modal-full-screen-edit-task-modal-background {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: unset;
    width: unset;
}

.modal-edit-task-header {
    height: var(--modal-edit-task-header-height);
    border-bottom: 1px solid var(--modal-edit-task-border-colors);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.modal-edit-task-header-inner,
.modal-edit-task-footer-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    align-items: center;
}

.modal-edit-task-header-mini-heading {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.modal-edit-task-header-completed-text {
    font-size: 14px;
    font-weight: 500;
    color: #7c7c7c;
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-edit-task-header-completed-text svg {
    font-size: 14px;
    color: #049921;
}

.modal-edit-task-header-actions {
    display: flex;
    align-items: center;
    gap: 24px
}

.modal-edit-task-header-action {
    width: 36px;
    height: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
}

.modal-edit-task-header-action:hover {
    background-color: rgb(237, 237, 237);
}

.modal-edit-task-header-action svg {
    font-size: 24px;
    color: rgb(96, 96, 96);
}

.modal-edit-task-header-action-dropdown {
    position: absolute;
    z-index: 200;
    top: calc(100% + 6px);
    right: 4px;
    width: 240px;
    border-radius: 6px;
}

.modal-edit-task-header-action-menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 6px;
    gap: 2px;
    width: 100%;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 8px;
}

.modal-edit-task-header-action-menu span {
    font-size: 13px;
    font-weight: 500;
    padding: 10px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-edit-task-header-action-menu span svg {
    font-size: 14px;
    width: 20px;
    min-width: 20px;
}

.modal-edit-task-header-action-menu span:hover {
    background-color: rgb(239, 239, 239);
}

.modal-edit-task-header-action-menu-colors {
    position: absolute;
    right: calc(100% + 14px);
}

.modal-edit-task-footer {
    height: var(--modal-edit-task-footer-height);
    border-top: 1px solid var(--modal-edit-task-border-colors);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal-edit-task-footer span {
    font-size: 12px;
}

.nothing-at-all {
    background-color: #ffc0420b;
}

.modal-edit-task-content {
    position: absolute;
    top: var(--modal-edit-task-header-height);
    bottom: var(--modal-edit-task-footer-height);
    left: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
}

.modal-edit-task-content-section {
    margin-top: 24px;
}

.modal-edit-task-content-section-width-control {
    max-width: 1000px;
    width: 100%;
}

.modal-edit-task-content-section h3 {
    margin: 0;
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgb(59, 59, 59);
}

.modal-edit-task-resources-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 14px;
    margin-top: 12px;
    position: relative;
}

.modal-edit-task-resource-reference {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    padding: 12px;
    border-radius: 4px;
    background-color: #f7f7f7;
    cursor: pointer;
    transition: all 0.2s;
}

.modal-edit-task-resource-reference:hover {
    background-color: #ebebeb;
}

.modal-edit-task-resource-reference-name {
    font-size: 13px;
    font-weight: 600;
    color: rgb(65, 65, 65);
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.modal-edit-task-resource-reference-name svg {
    color: rgb(65, 65, 65);
    font-size: 20px;
}

.modal-edit-task-resource-remove {
    min-width: 20px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-edit-task-resource-remove svg {
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
    color: grey;
}

.modal-edit-task-resource-remove svg:hover {
    background-color: rgba(51, 51, 51, 0.276);
}

.modal-edit-task-header-owner-container {
    min-width: 240px;
}

.modal-edit-task-owner-date-mobile .modal-edit-task-header-owner-container {
    min-width: 200px;
}

.modal-edit-task-owner-date-mobile {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-edit-task-owner-date-desktop {
    display: none;
    gap: 40px;
    align-items: flex-end;
}

@media only screen and (min-width: 480px) {
    .modal-edit-task-owner-date-desktop { display: flex; }
    .modal-edit-task-owner-date-mobile { display: none; }
}

/* FULL SCREEN TASK EDITING MODAL LEGACY */
.modal-intermediate-outer-container {
    position: fixed;
    top: 80px;
    bottom: 12px;
    left: 0;
    right: 0;
    display: flex;
    overflow: visible;
    justify-content: center;
}

.modal-full-screen-task-edit-container-outer {
    position: absolute;
    /* top: 0; */
    left: 40px;
    padding-bottom: 12px;
    right: 40px;
    /* bottom: 0; */
    max-height: 95%;
    /* width: 100%; */
    margin: 0px 80px;
    overflow-y: auto;
    /* margin: 56px 80px 48px 80px; */
    background-color: white;
    border-radius: 6px;
    z-index: 103;
    /* overflow: hidden; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.modal-full-screen-task-edit-container-outer::-webkit-scrollbar {
    width: .3em;
}
  
.modal-full-screen-task-edit-container-outer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
}

.modal-full-screen-task-edit-container-outer::-webkit-scrollbar-thumb {
    background-color: rgb(23, 64, 97);
    outline: none;
}

.modal-full-screen-task-edit-footer {
    margin-left: 28px;
    font-size: 12px;
    color: #262626;
    margin-top: 60px;
}

.modal-full-screen-task-edit-control-close {
    cursor: pointer;
    color: rgb(40, 40, 40);
    right: 0;
    width: 36px;
    height: 36px;
}

.modal-full-screen-task-edit-container-inner {
    display: flex;
    flex-direction: row;
    /* height: 100%; */
    box-sizing: border-box;
    overflow: hidden;
    align-items: flex-start;
}

.modal-full-screen-task-edit-container-primary {
    flex-basis: 100%;
    /* height: 100%; */
    margin-left: 18px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.modal-full-screen-task-edit-container-secondary {
    overflow: hidden;
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.modal-full-screen-task-comments-box {
    margin-top: 28px;
    /* box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04); */
    /* background-color: #FEFFFF; */
    border: 1px solid #E7E7E7;
    border-radius: 6px;
    width: 380px;
    min-width: 380px;
    max-height: 400px;
    padding: 12px 18px 36px 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal-full-screen-task-comments-box h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #344563;
    margin-bottom: 8px;
}

.modal-full-screen-task-edit-container-primary::-webkit-scrollbar,
.modal-full-screen-task-edit-comments-upper::-webkit-scrollbar {
    width: 5px;
}
  
.modal-full-screen-task-edit-container-primary::-webkit-scrollbar-track,
.modal-full-screen-task-edit-comments-upper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F0F0F0;
}

.modal-full-screen-task-edit-container-primary::-webkit-scrollbar-thumb,
.modal-full-screen-task-edit-comments-upper::-webkit-scrollbar-thumb {
    /* background-color: rgb(23, 64, 97); */
    background-color: #D9D9D9;
    outline: none;
}

.modal-full-screen-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-right: 32px;
}

.modal-full-screen-header-title,
.modal-full-screen-header-row-edit input {
    margin: 0px;
    font-weight: 600;
    font-size: 1.5em;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-full-screen-header-row-edit {
    flex-grow: 1;
}

.modal-full-screen-header-row-edit input {
    width: 100%;
    padding: 0px;
    margin: 0px;
    border: none;
    border-bottom: 1px solid grey;
}

.modal-full-screen-header-row-static span {
    color: transparent;
    transition: all 0.2s;
    cursor: pointer;
}

.modal-full-screen-header-row-static {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-full-screen-header-row-static:hover.modal-full-screen-header-row-static span {
    color: rgb(107, 107, 107);
}

.modal-full-screen-header-row-static span:hover {
    text-decoration: underline;
}

.modal-full-screen-task-edit-container-discussion {
    flex-basis: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: hidden;
    display: none;
}

.modal-full-screen-task-edit-container-discussion h3 {
    margin: 0px;
    font-size: 1em;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    margin-top: 12px;
    border-bottom: 1px solid rgb(199, 199, 199);
}

.modal-full-screen-task-edit-top-navigation {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 32px 10px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #AEAEAE;
}

.modal-full-screen-task-edit-action-row {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
}

.modal-full-screen-task-edit-action-row-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: grey;
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.modal-full-screen-task-edit-action-row-action svg {
    font-size: 10px;
}

.modal-full-screen-task-edit-action-row-action:hover {
    background-color: rgb(221, 221, 221);
}

.modal-full-screen-task-edit-criteria-item-list {
    display: flex;
    /* max-height: 240px; */
    /* overflow-y: auto; */
    flex-direction: column;
    gap: 12px;
}

.modal-full-screen-task-edit-criteria-item-new-container {
    border: 1px solid rgb(199, 199, 199);
    border-radius: 8px;
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modal-full-screen-task-edit-criteria-item-new {
    border: none;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.9em;
    font-weight: 500;
    flex-grow: 1;
    background-color: transparent;
    width: 100%;
}

.modal-full-screen-task-edit-criteria-item-new::placeholder {
    color: rgb(158, 158, 158);
}

.modal-full-screen-task-edit-criteria-item-new:focus {
    outline: none;
}

.modal-full-screen-task-edit-comments-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-full-screen-task-edit-comments-container-inner {
    padding-left: 12px;
    padding-right: 12px;
}

.modal-full-screen-assignable .gadget-lexical-editor {
    min-height: 100px;
    max-height: 180px;
}

.modal-full-screen-outside-assignables-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* max-height: 80%; */
    overflow-y: auto;
}

.modal-full-screen-assignables {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    margin-top: 12px;
    flex-grow: 1;
    overflow: hidden;
}

.modal-full-screen-assignable {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    position: relative;
}

.modal-full-screen-assignable-details {
    min-height: 240px;
    overflow-y: auto;
}

.modal-full-screen-task-edit-owner,
.modal-full-screen-task-edit-date {
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-full-screen-task-edit-date {
    border: none;
}

.modal-full-screen-assignable-title {
    font-size: 16px;
    margin: 0px;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    width: 100px;
    min-width: 100px;
    /* position: fixed; */
}

.modal-full-screen-task-edit-details-container {
    flex-grow: 1;
    overflow: hidden;
}

.modal-full-screen-task-edit-details-text {
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 1.6em;
    min-height: 2em;
    border: 1px solid transparent;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-full-screen-task-edit-details-editing-actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
    font-size: 12px;
}

.modal-full-screen-task-edit-details-editing-actions span {
    cursor: pointer;
    color:rgb(41, 115, 175);
}

.modal-full-screen-task-edit-details-editing-actions span:hover {
    border-bottom: 1px solid rgb(71, 71, 71);
}

.modal-full-screen-task-edit-details-editing textarea {
    max-width: 100%;
    line-height: 1.6em;
    width: 100%;
    box-sizing: border-box;
    min-height: 6em;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    overflow: auto;
    resize: none;
    outline: none;
    border: 1px solid rgb(199, 199, 199);
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-full-screen-task-edit-owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.modal-full-screen-task-edit-owner-select {
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 12px;
    min-width: 10em;
    cursor: pointer;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-full-screen-task-edit-quick-edit {
    border-bottom: 1px solid rgb(71, 71, 71);
    cursor: pointer;
    font-size: 12px;
}

.modal-full-screen-task-edit-criteria-container {
    flex-grow: 1;
}

.modal-full-screen-task-edit-comments-upper {
    overflow-y: auto;
    flex-grow: 1;
    margin-top: 24px;
    padding-bottom: 40px;
}

.modal-full-screen-task-edit-comments-lower {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #A4A4A4;
    padding: 18px;
    position: relative;
    padding-bottom: 28px;
    border-radius: 6px;
    margin-top: 24px;
}

.modal-full-screen-task-edit-comment-new {
    border: none;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    width: 100%;
}

.modal-full-screen-task-edit-comment-new:focus {
    outline: none;
}

.modal-full-screen-task-edit-comment-post {
    position: absolute;
    bottom: 8px;
    right: 4px;
    width: 44px;
    min-width: 44px;
    color: #3D72AA;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    letter-spacing: 0.5px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-full-screen-task-edit-criteria-item-new-post {
    color:rgb(41, 115, 175);
    font-size: 0.9em;
    padding: 4px 12px;
    cursor: pointer;
}

.modal-full-screen-task-edit-comments-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.modal-full-screen-edit-task-color-change-container {
    position: relative;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
}

.modal-full-screen-edit-task-color-change-toggle-button {
    color: grey;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.modal-full-screen-edit-task-color-change-color-circle {
    width: 18px;
    height: 18px;
    min-height: 18px;
    text-align: center;
    min-width: 18px;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(221, 221, 221);
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-full-screen-edit-task-color-change-menu {
    /* position: absolute;
    top: calc(100% + 4px); */
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    z-index: 40;
    padding: 8px;
    border-radius: 8px;
    width: 132px;
}

.modal-full-screen-edit-task-color-change-menu:hover {
    background-color: white;
}

.modal-full-screen-edit-task-color-change-menu-title {
    margin-bottom: 12px;
    font-size: 12px;
    color: grey;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}

.modal-full-screen-edit-task-color-change-colors {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    cursor: default;
}

.modal-full-screen-edit-task-color-change-menu .modal-full-screen-edit-task-color-change-color-circle {
    cursor: pointer;
    margin-bottom: 4px;
}

.modal-full-screen-task-resources-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    position: relative;
}

.modal-full-screen-resource-reference {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    cursor: pointer;
    gap: 12px;
    transition: all 0.2s;
}

.modal-full-screen-resource-reference:hover {
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 3px 4px 0px, rgba(60, 64, 67, 0.15) 0px 4px 8px 4px;
}

.modal-full-screen-resource-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-full-screen-resource-symbol svg {
    font-size: 20px;
    padding: 4px;
    min-width: 28px;
    color: rgb(41, 115, 175);
}

.modal-full-screen-resource-main {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
}

.modal-full-screen-resource-information {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
}

.modal-full-screen-resource-information div {
    font-size: 16px;
    font-weight: 500;
}

.modal-full-screen-resource-information p {
    margin: 0;
    font-size: 14px;
    color: rgb(83, 83, 83);
}

.modal-full-screen-task-resources-add-button {
    font-size: 14px;
    color:#3D72AA;
    cursor: pointer;
}

.modal-full-screen-task-resources-add-button:hover {
    text-decoration: underline;
}

.modal-full-screen-resource-remove {
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-full-screen-resource-remove svg {
    font-size: 16px;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.modal-full-screen-resource-remove svg:hover {
    background-color: rgba(159, 31, 31, 0.276);
}

/* USER MANAGEMENT */
.modal-manage-user-membership-container {
    color: black;
    /* min-height: 120px; */
    max-height: 90%;
    min-width: 400px;
    width: 30%;
    margin-top: 120px;
    background-color: white;
    border-radius: 6px;
    z-index: 104;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    overflow-y: auto;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.modal-manage-user-membership-section {
    margin-bottom: 2em;
}

.modal-manage-user-membership-section:last-child {
    margin-bottom: unset;
}

.modal-manage-user-membership-role-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.modal-manage-user-membership-role p {
    margin: 0px;
    font-weight: 600;
    margin-bottom: 1em;
}

.modal-manage-user-membership-role-permissions-box {
    display: flex;
    flex-direction: row;
    gap: 0.2em;
    flex-wrap: wrap;
}

.modal-manage-user-membership-role-permissions-box span {
    background-color: rgb(231, 231, 231);
    padding: 0.1em 1em;
    font-size: 14px;
    border-radius: 12px;
    margin-bottom: 0.5em;
}

.modal-manage-user-membership-buttons {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    justify-content: center;
}

.modal-manage-user-membership-buttons div {
    font-size: 0.9em;
    font-weight: 600;
    text-align: center;
    width: 5em;
    border-radius: 4px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    cursor: pointer;
}

.modal-manage-user-membership-save-button {
    background-color: #2973af;
    color: white;
}

.modal-manage-user-membership-cancel-button {
    border: 1px solid rgb(199,199,199);
}

.modal-manage-user-membership-revoke-button {
    color: #be3b41;
    font-size: 0.8em;
    cursor: pointer;
}

.modal-manage-user-membership-revoke-button:hover {
    text-decoration: underline;
}

.modal-manage-user-membership-form {
    width: 100%;
}

.modal-manage-user-membership-dropdown {
    width: 100%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    font-size: 14.4px;
    cursor: pointer;
    border: 1px solid rgb(199,199,199);
    border-radius: 4px;
}

.modal-manage-user-membership-section-title {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 1em;
}

/* UPDATE KEY RESULT */
.modal-key-result-update-container {
    color: black;
    max-height: 90%;
    width: 800px;
    margin-top: 200px;
    background-color: white;
    border-radius: 6px;
    z-index: 104;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; 
}

.modal-key-result-update-form-options p {
    margin: 0;
    font-size: 14px;
    text-align: center;
}

.modal-key-result-update-progress-blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.modal-key-result-update-progress-block {
    height: 32px;
    flex-grow: 1;
    border-top: 1px solid rgb(189, 189, 189);
    border-bottom: 1px solid rgb(189, 189, 189);
    border-left: 1px solid rgb(189, 189, 189);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: grey;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.modal-key-result-update-progress-block-shaded {
    background-color: #50607D;
    color: white;
}

.modal-key-result-update-progress-block:last-child {
    border-right: 1px solid rgb(189, 189, 189);
}

/* CREATE NEW ITEM IN PROJECT */
.modal-project-create-child-container {
    color: black;
    max-height: 90%;
    width: 480px;
    margin-top: 200px;
    background-color: white;
    border-radius: 6px;
    z-index: 104;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; 
}

.modal-project-create-child-container h1,
.modal-key-result-update-container h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #50607D;
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 2px solid #F2F2F3;
}

.modal-project-create-child-form-options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
    border-bottom: 2px solid #F2F2F3;
}

.modal-project-create-child-form-option-boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}

.modal-project-create-child-form-option-boxes div {
    border: 2px solid rgb(216, 216, 216);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
}

.modal-project-create-child-form-option-box-selected {
    background-color: rgb(195, 213, 236);
}

.modal-project-create-child-form-options h2 {
    margin: 0;
    font-size: 16px;
    /* text-transform: uppercase; */
    color: #50607D;
    margin-bottom: 4px;
}

.modal-project-create-child-form-options input {
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 16px;
    outline: none;
    border: none;
    font-weight: 400;
    color: rgb(88, 88, 88);
}

.modal-project-create-child-form-options select {
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
    color: rgb(88, 88, 88);
}

.modal-project-create-child-form-buttons {
    width: 100%;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;
}

.modal-project-create-child-form-button {
    border: 1px solid #50607D;
    color: #50607D;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.modal-project-create-child-form-button-create {
    background-color: #50607D;
    color: white;
}

/* TASK CREATION */
.modal-task-create-container {
    color: black;
    min-height: 120px;
    max-height: 90%;
    max-width: 800px;
    width: 640px;
    margin-top: 120px;
    background-color: white;
    border-radius: 6px;
    z-index: 104;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 32px;
    overflow-y: auto;
    padding-bottom: 28px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.modal-task-create-container-overflows {
    color: black;
    min-height: 120px;
    position: absolute;
    overflow-y: auto;
    z-index: 101;
    top: 0;
    bottom: 0;
    left: calc(50% - 360px);
    max-width: 800px;
    width: 640px;
    margin-top: 60px;
    background-color: white;
    border-radius: 6px;
    z-index: 104;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 32px;
    padding-bottom: 28px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.modal-task-create-container-overflows::-webkit-scrollbar {
    width: .3em;
  }
  
.modal-task-create-container-overflows::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
}
  
.modal-task-create-container-overflows::-webkit-scrollbar-thumb {
  background-color: rgb(23, 64, 97);
  outline: none;
}

.modal-task-create-section-split {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 28px;
}

.modal-task-create-section-split .modal-task-create-section {
    flex-grow: 1;
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-task-create-section {
    margin-top: 20px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-task-create-input-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 14px;
}

.modal-task-edit-colors {
    display: flex;
    flex-direction: row;
    gap: 4px;
    flex-wrap: wrap;
}

.modal-task-create-section .gadget-lexical-editor {
    min-height: 100px;
    max-height: 180px;
}

.modal-task-edit-color {
    box-sizing: border-box;
    width: 32px;
    border-radius: 50%;
    height: 32px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.modal-task-edit-color-selected {
    border: 3px solid rgb(51, 51, 51);
}

.modal-task-edit-color:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 4px 6px;
}

.modal-task-create-section input[type=text] {
    border: 1px solid #A4A4A4;
    font-size: 14px;
    padding: 16px 24px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
}

.modal-task-create-section input[type=date] {
    border: 1px solid #A4A4A4;
    font-size: 14px;
    padding: 16px 24px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.modal-task-create-section textarea {
    width: 100%;
    box-sizing: border-box;
    height: 10em;
    border: 1px solid rgba(199,199,199);
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    font-size: 16px;
    resize: vertical;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-task-create-section select {
    border: 1px solid #A4A4A4;
    font-size: 14px;
    padding: 16px 24px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
}

.modal-task-select-loading {
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    height: 12px;
    position: relative;
    overflow: hidden;
}

.modal-task-select-loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

.modal-task-create-complex-select-container {
    width: 100%;
    position: relative;
}

.modal-task-create-complex-select-selected {
    padding: 12px 18px;
    border: 1px solid #A4A4A4;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.modal-task-create-complex-select-options {
    position: absolute;
    top: calc(100% + 4px);
    background-color: white;
    border-radius: 6px;
    padding: 12px 0px;
    width: 100%;
    box-sizing: border-box;
    max-height: 212px;
    overflow-y: auto;
}

.modal-task-create-complex-select-options span {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    padding: 12px 18px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.modal-task-create-complex-select-options span:hover {
    background-color: rgb(237, 237, 237);
}

.modal-task-create-button-create {
    background-color: #3D72AA;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    padding: 8px 16px;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #3D72AA;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(125, 125, 125, 0.25);
}

.modal-task-create-button-create-save-and-next {
    background-color: white;
    color: #3D72AA;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    padding: 8px 16px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #3D72AA;
    box-shadow: 0px 4px 4px rgba(125, 125, 125, 0.25);
}

.modal-task-create-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 8px;
    border-bottom: 1px solid lightgrey;
}

.modal-task-create-header-subtitle-action-menu {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-end;
}

.modal-task-create-header-title {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 24px;
    font-weight: 500;
}

.modal-task-create-submission-section {
    margin-top: 24px;
    padding-top: 12px;
    display: flex;
    flex-direction: row-reverse;
}

.modal-task-edit-submission-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.modal-edit-big-button-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    text-transform: uppercase;
    font-size: 13px;
}

.modal-edit-big-button-main {
    width: 50%;
    box-sizing: border-box;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #2973af;
    color: white;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
}

.modal-edit-big-button-cancel {
    color: rgb(58, 58, 58);
    cursor: pointer;
}

.modal-edit-big-button-delete {
    cursor: pointer;
    margin-top: 16px;
    color: #be3b41;
    transition: all 0.2s;
}

.modal-edit-big-button-delete:hover {
    text-decoration: underline;
}

.modal-edit-action-delete-container {
    margin-top: 24px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.modal-edit-action-text-bold{
    font-size: 18px;
    font-weight: 600;
}

.modal-edit-action-text-underline {
    font-style: italic;
}

.modal-task-create-submit,
.modal-task-create-cancel,
.modal-task-create-complete,
.modal-task-create-delete {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12px 8px;
    border-radius: 5px;
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.modal-task-create-submit:hover,
.modal-task-create-cancel:hover,
.modal-task-create-complete:hover,
.modal-task-create-delete:hover {
    background-color: rgb(232, 232, 232);
    cursor: pointer;
}

.modal-task-create-submit {
    color: #2973af;
    margin-right: 8px;
}

.modal-task-create-complete {
    color: #049921;
}

.modal-task-create-delete {
    color: #be3b41;
    margin-right: 8px;
}

.modal-task-create-cancel {
    color: #727272;
    margin-right: 8px;
}

/* GENERAL WORKSPACE EDITING MODAL */
.modal-workspace-component-edit-container {
    min-width: 600px;
    width: 35%;
    margin-top: 5%;
    margin-bottom: 5%;
    min-height: 120px;
    max-height: 90%;
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    z-index: 101;
    padding: 12px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.modal-workspace-component-edit-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(199, 199, 199);
}

.modal-workspace-component-edit-footer {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: 8px;
}

.modal-workspace-component-edit-button {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12px 8px;
    border-radius: 5px;
    transition: all 0.2s;
}

.modal-workspace-component-edit-button:hover {
    background-color: rgb(232, 232, 232);
    cursor: pointer;
}

.modal-workspace-component-edit-button-save {
    color:#2973af;
}

.modal-workspace-component-edit-button-cancel {
    color: #727272;
}

.modal-workspace-component-edit-header h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
}

.modal-workspace-component-edit-cancel {
    padding: 8px;
    font-size: 14px;
    border-radius: 16px;
    transition: all 0.2s;
}

.modal-workspace-component-edit-cancel:hover {
    background-color: rgb(228, 228, 228);
    cursor: pointer;
}

.modal-workspace-component-edit-section {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 24px;
}

.modal-workspace-component-edit-subsection {
    width: 100%;
    margin-top: 0px;
}

.modal-workspace-component-edit-section-doublewide {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
}

.modal-workspace-component-edit-subtasks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-workspace-component-edit-section h4 {
    margin-top: 0px;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 12px;
}

.modal-workspace-component-edit-section input[type=text] {
    height: 36px;
    width: 100%;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 4px;
    padding: 6px;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.modal-workspace-component-edit-section input[type=date] {
    height: 36px;
    width: 100%;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 4px;
    padding: 6px;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.modal-workspace-component-edit-section select {
    height: 36px;
    width: 100%;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 4px;
    padding: 6px;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.modal-workspace-component-edit-section textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    min-height: 140px;
    border: 1px solid rgba(78, 78, 78, 0.589);
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    resize: vertical;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* GOAL MARKERS */
.goal-marker-modal-form-container {
    width: 60%;
    overflow-y: initial !important;
    margin-top: 5%;
    background-color: white;
    border-radius: 4px;
    z-index: 101;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.goal-marker-modal-form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 4px;
}

.goal-marker-modal-form-page-description {
    color: grey;
    font-size: 14px;
    margin-top: 16px;
    font-weight: 500;
}

.goal-marker-modal-form-header h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
}

.gomano-message-text {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 24px;
}

.gomano-message-button {
    font-size: 14px;
    font-weight: 600;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.gomano-message-button:hover {
    background-color: rgb(228, 228, 228);
    cursor: pointer;
}

.goal-marker-modal-form-cancel {
    padding: 8px;
    font-size: 14px;
    border-radius: 16px;
    transition: all 0.2s;
}

.goal-marker-modal-form-cancel:hover {
    background-color: rgb(228, 228, 228);
    cursor: pointer;
}

.goal-marker-new-option-card-container {
    margin-top: 24px;
    overflow-y: auto;
    max-height: 70vh;
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;
}

.goal-marker-new-option-card {
    border: 1px solid rgb(199, 199, 199);
    height: 160px;
    width: 160px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    transition: all 0.2s;
    position: relative;
    margin-right: 32px;
    margin-bottom: 24px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.gomano-card-add-button {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.863);
    color: black;
    font-size: 1.4em;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: opacity 0.1s;
}

.goal-marker-new-option-card:hover {
    cursor: pointer;
}

.goal-marker-new-option-card:hover .gomano-card-add-button {
    visibility: visible;
    opacity: 1;
}

.gomano-card-top {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.gomano-card-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.gomano-card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.gomano-card-component-symbol {
    padding-left: 4px;
    padding-top: 4px;
    padding-right: 8px;
    color: grey;
}

.gomano-card-progress-bar {
    flex-grow: 10;
    margin-left: 4px;
}

.gomano-card-progress-label {
    flex-grow: 1;
    text-align: left;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
    color: #474747;
}

.gomano-card-component-metric {
    border: none;
    color: grey;
    font-size: 48px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-top: 4px;
    height: unset;
    font-weight: 400;
    width: 100%;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.gomano-card-component-metric-target-input {
    border: none;
    color: rgb(78, 147, 204);
    border-radius: 4px;
    font-size: 48px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-top: 4px;
    height: unset;
    font-weight: 400;
    width: 100%;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.goal-marker-modal-form-page-description-names {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.goal-marker-modal-form-page-description-name,
.goal-marker-modal-form-page-description-text {
    font-size: 32px;
    font-weight: 700;
    color:rgb(78, 147, 204);
}

.goal-marker-modal-form-page-description-text {
    color: rgb(65, 65, 65);
}

.goal-marker-modal-form-page-description-type {
    text-transform: uppercase;
    color: grey;
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
}

.gomano-card-component-type {
    padding-top: 3px;
    font-weight: 400;
    text-transform: uppercase;
}

.gomano-card-middle {
    overflow: hidden;
}

.gomano-card-component-name {
    font-weight: 600;
    padding-left: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    word-wrap: break-word;
}

.gomano-card-component-status-archived {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 16px;
    background-color: rgb(255, 140, 105);
    color: white;
    margin-left: 4px;
}

.gomano-search-container {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0.5em;
    border: 1px solid rgb(199,199,199);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.gomano-search-container input {
    border: none;
    outline: none;
    flex-grow: 1;
}

/* Side Edit Modal */
.modal-side-edit-container-outer {
    position: fixed;
    right: 12px;
    bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 101;
    background-color: rgba(199, 199, 199, 0.26);
}

.modal-side-edit-container-inner {
    background-color: white;
    border: 1px solid rgb(243, 243, 243);
    border-radius: 8px;
    width: 400px;
    padding: 4px 12px;
    z-index: 200;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.modal-side-edit-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid rgb(199, 199, 199);
    margin-bottom: 12px;
}

.modal-side-edit-header-row-modal-title {
    font-size: 14px;
}

.modal-side-edit-header-row svg {
    padding: 8px;
    height: 16px;
    width: 16px;
    border-radius: 25%;
    transition: all 0.2s;
    color: rgb(97, 97, 97);
}

.modal-side-edit-header-row svg:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}

.modal-side-edit-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    white-space: pre-wrap;
}

.modal-side-edit-title-edit {
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    margin-bottom: 12px;
}

.modal-side-edit-title-edit textarea {
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    border: none;
    border-bottom: 1px solid grey;
    border: none;
    padding: 0px;
    overflow: hidden;
    outline: none;
    resize: none;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-side-edit-tab-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-bottom: 8px;
    font-size: 14px;
}

.modal-side-edit-tab-row-item {
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
    padding-bottom: 4px;
    color:rgb(63, 63, 63);
}

.modal-side-edit-tab-row-item:hover {
    cursor: pointer;
    border-bottom: 2px solid rgb(63, 63, 63);
}

.modal-side-edit-tab-row-item-selected {
    border-bottom: 2px solid rgb(63, 63, 63);
}

.modal-side-edit-tab-details {
    font-size: 14px;
    line-height: 1.5em;
    white-space: pre-wrap;
    margin-top: 16px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(199,199,199);
}

.modal-side-edit-tab-details-edit,
.modal-side-edit-tab-details {
    max-height: 400px;
    min-height: 200px;
    overflow-y: auto;
}

.modal-side-edit-tab-details-edit {
    font-size: 14px;
    width: 100% !important;
    line-height: 1.5em;
    white-space: pre-wrap;
    margin-top: 16px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(199,199,199);
}

.modal-side-edit-tab-details-edit textarea {
    border: none;
    width: 100% !important;
    font-size: 14px;
    line-height: 1.5em;
    padding: 0px;
    overflow: hidden;
    outline: none;
    resize: none;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-side-edit-tab-detail-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;
    font-size: 14px;
}

.modal-side-edit-tab-detail-owner-select {
    font-size: 14px;
    /* width: 100%; */
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 12px;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-side-edit-tab-detail-owner-select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.modal-side-edit-tab-detail-owner-select:hover {
    cursor: pointer;
}

.modal-side-edit-tab-detail-row input[type=date] {
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
}

.modal-side-edit-colors {
    display: flex;
    flex-direction: row;
    gap: 4px;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

.modal-side-edit-color {
    box-sizing: border-box;
    width: 20px;
    border-radius: 50%;
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.modal-side-edit-color-selected {
    border: 2px solid rgb(51, 51, 51);
}

.modal-side-edit-color:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 4px 6px;
}

.modal-component-template-create-section-container {
    margin-bottom: 40px;
}

.modal-component-template-create-from-template-button {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgb(23, 64, 97);
    color: white;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
    text-transform: uppercase;
}

.modal-component-template-create-section-prompt {
    width: 100%;
    padding-bottom: 16px;
}

.modal-component-template-create-section-prompt h4 {
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 4px;
    border-bottom: 1px solid rgb(199, 199, 199);
}

.modal-component-template-create-section-prompt span {
    color: grey;
    font-size: 14px;
}

.modal-component-template-create-section-component-list-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-component-template-create-section-component-item {
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid rgb(199,199,199);
    cursor: pointer;
    transition: all 0.2s;
}

.modal-component-template-create-section-component-item:last-of-type {
    border-bottom: 1px solid rgb(199, 199, 199);
}

.modal-component-template-create-section-component-item:hover,
.modal-component-template-create-section-component-item-selected {
    background-color: rgb(235, 235, 235);
}

.modal-component-template-create-section-component-list {
    flex-basis: 50%;
    max-height: 320px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.modal-component-template-create-section-component-preview {
    flex-basis: 45%;
    max-height: 320px;
    overflow-y: auto;
}

.modal-component-template-create-section-component-preview-title {
    font-weight: 600;
    text-decoration: underline;
}

.modal-component-template-create-section-component-preview-subtitle {
    font-size: 14px;
    color: grey;
}

.modal-component-template-create-section-component-preview-task-items {
    font-size: 13px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

.modal-component-template-create-section-component-preview-task-items div {
    padding-top: 4px;
    padding-bottom: 4px;
}

.modal-component-template-create-section-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1em;
}

.modal-component-template-create-section-form label {
    font-size: 14px;
    color: rgb(63, 63, 63);
    font-weight: 600;
}

.modal-component-template-create-section-form input {
    margin-top: 4px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid rgb(199, 199, 199);
    font-size: 16px;
    width: 80%;
    padding-top: 6px;
    padding-bottom: 6px;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
}

.modal-component-template-create-section-action-links {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: flex-start;
}

.modal-component-template-create-section-action-link {
    font-size: 0.9em;
    color: rgb(71, 71, 71);
    text-decoration: underline;
    cursor: pointer;
}

.modal-component-template-information-section-save-button {
    background-color: rgb(43, 130, 180);
    color: white;
    font-size: 1em;
    padding: 0.4em 0.8em;
    cursor: pointer;
    border-radius: 8px;
}

.modal-component-template-create-promotion-buttons {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 1em;
    width: 100%;
}

.modal-component-template-create-promotion-button:first-of-type {
    border: none;
    background-color: rgba(61, 114, 170, 1);
    /* background-color: #049921; */
    color: white;
}

.modal-component-template-create-promotion-button {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(199,199,199);
    border-radius: 1em;
    padding: 0.5em;
    overflow-wrap: normal;
    cursor: pointer;
}

.modal-component-template-information-section-delete-button {
    color: rgb(180, 43, 73);
    cursor: pointer;
    transition: all 0.2s;
}

.modal-component-template-information-section-delete-button:hover {
    text-decoration: underline;
}

.modal-component-template-edit-details-list-item {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 13px;
    border-top: 1px solid rgb(199, 199, 199);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.modal-component-template-edit-details-list-item:last-of-type {
    border-bottom: 1px solid rgb(199, 199, 199);
}

.modal-timeline-container-outer-relative {
    position: relative;
    --timeline-container-width: 480px;
    --timeline-container-footer-height: 80px;
    --timeline-container-header-height: 60px;
}

.modal-component-template-create-content-containers {
    display: flex;
    gap: 14px;
    flex-direction: column;
    width: 100%;
    max-height: 160px;
    overflow-y: auto;
}

.modal-component-template-create-content-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.modal-component-template-create-content-summary {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    flex-grow: 1;
}

.modal-component-template-create-content-summary h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.modal-component-template-create-content-descriptor {
    font-size: 14px;
    color: #797979;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 360px;
}

.modal-component-template-create-creator {
    font-size: 12px;
    text-align: center;
}

.modal-timeline-container-backdrop {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.089);
    z-index: 9;
}

.modal-timeline-container {
    position: fixed;
    box-sizing: border-box;
    padding: 20px 16px;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 100;
    width: var(--timeline-container-width);
    top: 48px;
    /* background-color: rgb(247, 247, 247); */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modal-timeline-header-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: var(--timeline-container-header-height);
}

.modal-timeline-header-row-edit-title {
    display: flex;
    align-items: center;
    gap: 16px;
}

.modal-timeline-header-row h3 {
    margin: 0px;
}

.modal-timeline-header-row svg {
    font-size: 16px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.1s;
}

.modal-timeline-header-row-edit-title svg {
    font-size: 16px;
    min-width: 16px;
    height: 16px;
    color: rgb(58, 58, 58);
}

.modal-timeline-header-row svg:hover {
    background-color: rgb(211, 211, 211);
}

.modal-timeline-new-task-inputs-scrollable {
    position: absolute;
    bottom: var(--timeline-container-footer-height);
    top: calc(20px + var(--timeline-container-header-height));
    overflow-y: auto;
    overflow-x: hidden;
    right: 16px;
    left: 16px;
    box-sizing: border-box;
    padding-right: 12px;
}

.modal-timeline-new-task-inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    box-sizing: border-box;
}

.modal-timeline-new-task-input-title {
    font-size: 14px;
    font-weight: 600;
}

.modal-timeline-new-task-input-row-2 {
    display: flex;
    gap: 12px;
    justify-content: space-between;
}

.modal-timeline-new-task-input-row-2 .modal-timeline-new-task-input {
    flex-basis: 50%;
}

.modal-timeline-new-task-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.modal-timeline-comments-list {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modal-timeline-new-task-comments {
    border-top: 1px solid var(--border-color);
    padding-top: 12px;
    margin-top: 32px;
    margin-bottom: 12px;
}

.modal-timeline-new-task-display {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.modal-timeline-new-task-display-title {
    font-size: 14px;
    font-weight: 600;
    color: #2a73ae;
    cursor: pointer;
}

.modal-timeline-new-task-display-title:hover {
    text-decoration: underline;
}

.modal-timeline-new-task-display-name {
    font-size: 20px;
    font-weight: 600;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.modal-timeline-new-task-display-description {
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: rgb(61, 61, 61);
}

.modal-timeline-new-task-display-key-attributes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 16px;
    border-top: 1px solid var(--border-color);
}

.modal-timeline-new-task-display-key-attribute {
    display: flex;
    align-items: center;
    gap: 16px;
}

.modal-timeline-new-task-display-key-attribute span {
    font-weight: 500;
    width: 140px;
}

.modal-timeline-new-task-display-subtitle-badge {
    font-size: 12px;
    padding: 4px 12px;
    background-color: #50607D;
    color: white;
    font-weight: 500;
    border-radius: 4px;
}

.modal-timeline-new-task-input input,
.modal-timeline-new-task-input select {
    width: 100%;
    font-size: 14px;
    padding: 4px 8px;
    box-sizing: border-box;
    border: 1px solid rgb(82, 82, 82);
    border-radius: 4px;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-timeline-new-task-input textarea {
    height: 120px;
    max-height: 120px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 1px solid rgb(82, 82, 82);
    border-radius: 4px;
    padding: 4px 8px;
}

.modal-timeline-new-task-actions {
    position: absolute;
    bottom: 0;
    height: var(--timeline-container-footer-height);
    left: 0;
    right: 0;
    padding-bottom: 12px;
}

.modal-timeline-new-task-actions-alignment {
    display: flex;
    flex-direction: column-reverse;
}

.modal-timeline-new-task-actions-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.modal-timeline-new-task-actions-buttons div:first-child {
    background-color: #049921;
    color: white;
    border: 1px solid #0e501b00;
}

.modal-timeline-new-task-actions-buttons div {
    flex-basis: 30%;
    font-size: 14px;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    border: 1px solid rgb(175, 175, 175);
    margin-bottom: 12px;
    border-radius: 8px;
    cursor: pointer;
}

.modal-timeline-new-task-date-selector-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.modal-timeline-new-task-date-selector-date-button {
    border: 1.5px solid rgb(231, 231, 231);
    border-radius: 4px;
    min-width: 120px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 12px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.modal-timeline-new-task-date-selector-date-button svg {
    font-size: 16px;
    color: grey;
}

.modal-timeline-new-task-clear-date-button {
    width: 50%;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 1px solid grey;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    color: grey;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
}

.modal-timeline-new-task-additional-action {
    margin-bottom: 12px;
    padding-top: 12px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(68, 68, 68);
}

.modal-timeline-new-task-additional-action svg {
    font-size: 20px;
    cursor: pointer;
    color: rgb(68, 68, 68);
}

/* MODAL SIMPLE NAV BAR */
.modal-simple-nav-bar {
    border-bottom: 1px solid rgb(217, 217, 217);
    width: 100%;
    box-sizing: border-box;
    padding-left: 0;
}

.modal-simple-nav-bar-nav {
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.modal-simple-nav-bar-nav span {
    border-bottom: 2px solid transparent;
    color: rgb(92, 92, 92);
    cursor: pointer;
    transition: all 0.2s;
    font-size: 14px;
    padding-bottom: 6px;
    font-weight: 600;
}

.modal-simple-nav-bar-nav span:hover,
.modal-simple-nav-bar-nav span.modal-simple-nav-bar-nav-sel {
    border-bottom: 2px solid #2a73ae;
    color: #2a73ae;
}


/* USER MANAGEMENT */
.modal-mgmt-background-overlay {
    position: fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.171);
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-mgmt-container {
    color: #353C44;
    width: 30%;
    min-width: 800px;
    margin-top: 80px;
    background-color: white;
    border-radius: 10px;
    z-index: 104;
    padding: 24px 36px;
    box-shadow: 0px 4px 10px 0px rgba(115, 115, 115, 0.25);
}

.modal-mgmt-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.modal-mgmt-header-row h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 48px;
}

.modal-mgmt-header-row svg {
    font-size: 32px;
    cursor: pointer;
}

.modal-mgmt-section-cols {
    display: flex;
    align-items: stretch;
    gap: 20px;
}

.modal-mgmt-section-cols.modal-mgmt-sections {
    flex-grow: 1;
}

.modal-mgmt-sections {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.modal-mgmt-instructional-text-box p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.modal-mgmt-instructional-text-box {
    background-color: rgba(251, 255, 0, 0.13);
    padding: 14px;
    border-radius: 6px;
}

.modal-mgmt-user-membership-navs {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.modal-mgmt-user-membership-navs span {
    flex-basis: 50%;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-bottom: 3px solid rgb(164, 164, 164);
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
}

.modal-mgmt-user-membership-navs span.modal-mgmt-user-membership-nav-selected {
    border-bottom: 3px solid #3D72AA;
    color: rgba(0, 0, 0, 1);
}

.modal-mgmt-section-heading-feedback {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.modal-mgmt-user-membership-teams {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-right: 4px;
    box-sizing: border-box;
    max-height: 220px;
    overflow-y: auto;
}

.modal-mgmt-user-membership-teams::-webkit-scrollbar,
.modal-mgmt-user-workspace-permission-sets::-webkit-scrollbar,
.modal-full-screen-assignable .gadget-lexical-editor::-webkit-scrollbar,
.modal-full-screen-assignable::-webkit-scrollbar,
.modal-component-template-create-content-containers::-webkit-scrollbar {
    width: .3em;
}
  
.modal-mgmt-user-membership-teams::-webkit-scrollbar-track,
.modal-mgmt-user-workspace-permission-sets::-webkit-scrollbar-track,
.modal-full-screen-assignable::-webkit-scrollbar-track,
.modal-full-screen-assignable .gadget-lexical-editor::-webkit-scrollbar-track,
.modal-component-template-create-content-containers::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F0F0F0;
}

.modal-mgmt-user-membership-teams::-webkit-scrollbar-thumb,
.modal-full-screen-assignable::-webkit-scrollbar-thumb,
.modal-mgmt-user-workspace-permission-sets::-webkit-scrollbar-thumb,
.modal-full-screen-assignable .gadget-lexical-editor::-webkit-scrollbar-thumb,
.modal-component-template-create-content-containers::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    outline: none;
}

.modal-mgmt-user-membership-team {
    display: flex;
    align-items: center;
    gap: 8px;
}

.modal-mgmt-user-membership-workspace {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-mgmt-user-workspace-permission-sets {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 14px;
    max-height: 240px;
    overflow-y: auto;
    padding-bottom: 20px;
}

.modal-mgmt-user-workspace-permission-set {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    box-shadow: 0px 4px 4px rgba(166, 166, 166, 0.25);
    z-index: 4;
    border-radius: 6px;
}

.modal-mgmt-user-workspace-name { 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 14px;
}

.modal-mgmt-user-workspace-name div {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-mgmt-user-membership-team span {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.modal-mgmt-user-membership-team svg {
    font-size: 20px;
    cursor: pointer;
    min-width: 20px;
}

.modal-mgmt-user-membership-workspace select {
    font-size: 14px;
    padding: 4px;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal-mgmt-section h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
    margin-bottom: 14px;
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
}

.modal-mgmt-section h3 span {
    color: rgb(38, 38, 38);
    font-size: 12px;
}

.modal-mgmt-section p {
    font-size: 14px;
    color: grey;
    margin: 0;
    margin-bottom: 14px;
    line-height: 1.8;
}

.modal-mgmt-section .gadget-lexical-editor p {
    margin: 0;
    color: rgb(0, 0, 0);
}

.modal-mgmt-section .gadget-lexical-editor p:first-of-type {
    margin-top: 12px;
}

.modal-mgmt-section h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 0px;
    margin-bottom: 14px;
}

.modal-mgmt-section-heading-feedback span {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px;
}

.modal-mgmt-section input[type=text],
.modal-mgmt-select-dropdown-mode-display {
    width: 100%;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid rgba(164, 164, 164, 1);
}

.modal-mgmt-info-box {
    padding: 14px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(28, 94, 132, 0.092);
    border-radius: 4px;
    border: 1px solid rgba(28, 94, 132, 0.492);
}

.modal-mgmt-info-box p {
    margin-bottom: 0px;
}

.modal-mgmt-checkmarks {
    display: flex;
    align-items: center;
    gap: 40px;
}

.modal-mgmt-checkmarks div {
    display: flex;
    align-items: center;
    gap: 18px;
}

.modal-mgmt-checkmarks div span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.modal-mgmt-checkmark {
    color: rgb(192, 192, 192);
    height: 22px;
    width: 22px;
    min-width: 22px;
    cursor: pointer;
}

.modal-mgmt-checkmark-selected {
    color: rgba(61, 114, 170, 1);
}

.modal-mgmt-select-dropdown-mode-display {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-mgmt-select-dropdown-mode-display p {
    margin: 0;
}

.modal-mgmt-select-dropdown-mode-display svg {
    font-size: 20px;
}

.modal-mgmt-select-dropdown-options {
    border: 1px solid rgba(164, 164, 164, 1);
    overflow: visible;
    position: absolute;
    z-index: 40;
    background-color: white;
    width: 100%;
}

.modal-mgmt-select-dropdown-search {
    display: flex;
    align-items: center;
    gap: 20px;
    border: none;
    padding: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    position: relative;
}

.modal-mgmt-select-dropdown-search svg {
    width: 20px;
    min-width: 20px;
    position: absolute;
    margin-left: 16px;
}

.modal-mgmt-select-dropdown-search input {
    flex-grow: 1;
    padding-left: 40px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-right: 12px !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0 !important;
    border: none !important;
}

.modal-mgmt-select-dropdown-search input:focus {
    outline: none;
}

.modal-mgmt-select-dropdown-options-container {
    top: 100%;
    overflow-y: auto;
    --modal-mgmt-height: 48px;
    max-height: calc(3*var(--modal-mgmt-height));
}

.modal-mgmt-select-dropdown-option {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    padding-left: 14px;
    padding-right: 14px;
    height: var(--modal-mgmt-height);
    box-sizing: border-box;
    cursor: pointer;
    color: grey;
    font-weight: 500;
    font-size: 14px;
}

.modal-mgmt-select-dropdown-option svg {
    font-size: 16px;
}

.modal-mgmt-select-dropdown-option:hover {
    background-color: rgb(240, 240, 240);
}

.modal-mgmt-section-advance {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal-mgmt-button-advance {
    background-color: rgba(61, 114, 170, 1);
    color: white;
    font-weight: 700;
    border: 1px solid rgba(61, 114, 170, 1);
    font-size: 14px;
    border-radius: 6px;
    padding: 12px 16px;
    cursor: pointer;
}

.modal-mgmt-button-advance.modal-mgmt-button-advance-invert {
    background-color: white;
    color: rgba(61, 114, 170, 1);
    font-weight: 700;
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 12px 16px;
    cursor: pointer;
}

.modal-mgmt-confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 440px;
    box-sizing: border-box;
    margin-bottom: 80px;
}

.modal-mgmt-confirm-container svg {
    margin-bottom: 48px;
}

.modal-mgmt-confirm-container h3 {
    font-size: 32px;
    line-height: 48px;
    margin: 0px;
}

.modal-mgmt-confirm-container p {
    margin: 0px;
    font-size: 24px;
    line-height: 40px;
    font-weight: 400;
}

.modal-mgmt-expandable-review-text {
    color: rgba(61, 114, 170, 1);
    text-decoration: underline;
    cursor: pointer;
}

.modal-mgmt-container-workstream-settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50vh;
    overflow-y: auto;
}

.modal-mgmt-container-creator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    max-width: 560px;
    min-width: 180px;
    width: 100%;
    box-sizing: border-box;
}

.modal-creator-complex-radio-selections {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: 8px;
    margin-top: 8px;
}

.modal-creator-complex-radio-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px;
    cursor: pointer;
    background-color: rgba(36, 75, 119, 0.057);
}

.modal-creator-complex-radio-selection-selected {
    background-color: rgba(36, 75, 119, 0.183);
}

.modal-creator-complex-radio-selection-identifier {
    width: 42px;
    min-width: 42px;
}

.modal-creator-complex-radio-selection-identifier svg {
    font-size: 24px;
    color: rgb(86, 103, 120);
}

.modal-creator-complex-radio-selection-radio-button {
    width: 24px;
    min-width: 24px;
}

.modal-creator-complex-radio-selection-radio-button svg {
    font-size: 20px;
    color: rgb(86, 103, 120);
}

.modal-creator-complex-radio-selection-names {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.modal-creator-complex-radio-selection-names h5 {
    margin: 0;
    font-size: 14px;
}

.modal-creator-complex-radio-selection-names p {
    margin: 0;
    font-size: 13px;
}

.modal-mgmt-container-initiative-settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80vh;
    overflow-y: auto;
    width: 50%;
    position: relative;
    --modal-initiative-settings-header-height: 142px;
    --modal-initiative-settings-footer-height: 60px;
}

.modal-initiative-settings-header {
    position: absolute;
    left: 32px;
    top: 24px;
    right: 32px;
}

.modal-initiative-settings-footer {
    position: absolute;
    bottom: 0;
    left: 32px;
    background-color: white;
    right: 32px;
    height: var(--modal-initiative-settings-footer-height)
}

.modal-initiative-settings-scrollable-container {
    position: absolute;
    top: calc(var(--modal-initiative-settings-header-height) + 12px);
    bottom: calc(var(--modal-initiative-settings-footer-height) + 12px);
    left: 32px;
    right: 32px;
    overflow-y: auto;
    padding-right: 12px;
}

.modal-initiative-settings-project-owner-area {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

.modal-initiative-settings-project-owner-display {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
    padding: 20px;
    border: 24px;
}

.modal-initiative-settings-project-owner-menu {
    position: relative;
}

.modal-initiative-settings-project-owner-name {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 14px;
    border: 1px solid rgb(234, 234, 234);
    border-radius: 4px;
    cursor: pointer;
}

.modal-initiative-settings-project-owner-name svg {
    font-size: 18px;
}

.modal-initiative-settings-owner-change-menu {
    position: absolute;
    top: 40px;
    min-width: 240px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background-color: white;
    z-index: 4;
    box-shadow: 0 0 0 1px rgba(136,152,170,.1),0 15px 35px 0 rgba(49,49,93,.1),0 5px 15px 0 rgba(0,0,0,.08);
    max-height: 240px;
    overflow-y: auto;
}

.modal-initiative-settings-owner-change-menu input {
    width: 100%;
    box-sizing: border-box;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 600;
    color: grey;
    border: none;
    outline: transparent;
    border-bottom: 1px solid rgb(232, 232, 232);
}

.modal-initiative-settings-owner-change-menu span {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.modal-initiative-settings-owner-change-menu span:hover {
    background-color: rgb(234, 234, 234);
}

.modal-workstream-settings-setting-single {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    margin-top: 40px;
}

.modal-workstream-settings-setting-single:first-of-type {
    margin-top: 18px;
}

.modal-workstream-settings-setting-single.modal-workstream-settings-setting-single-align-center {
    align-items: center;
}

.modal-workstream-settings-setting-single.modal-workstream-settings-setting-single-vertical {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
}

.modal-workstream-settings-setting-name {
    font-size: 14px;
    font-weight: 600;
}

.modal-workstream-settings-setting-name-description {
    color: grey;
    font-size: 13px;
    min-height: 18px;
}

.modal-workstream-settings-setting-name-button {
    margin-top: 12px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-self: flex-start;
    gap: 6px;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 4px;
    padding: 8px;
    padding-left: 14px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 100px;
}

.modal-workstream-settings-setting-name-button:hover {
    background-color: rgb(238, 238, 238);
}

.modal-workstream-settings-setting-name-button svg {
    font-size: 14px;
}

.modal-workstream-settings-setting-name-button-blue {
    border: 1px solid #2973af;
    background-color: #2973af3b;
    color: #2973af;
}

.modal-workstream-settings-setting-name-button.modal-workstream-settings-setting-name-button-blue:hover {
    background-color: #2973af59;
}

.modal-workstream-settings-setting-names {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.modal-workstream-settings-setting-value {
    flex-grow: 1;
}

.modal-workstream-settings-buttons {
    margin-top: 40px;
}

.modal-workstream-settings-text-input-container {
    width: 100%;
    box-sizing: border-box;
}

.modal-workstream-settings-text-input-container input {
    width: 100%;
    font-size: 14px;
    padding: 10px; 
    border: 1px solid #cccccc; 
    border-radius: 4px; 
    outline: none; 
    width: 100%; 
    box-sizing: border-box;
}

.modal-workstream-settings-text-input-container input:focus {
    border-color: #666666; 
}

.modal-workstream-settings-text-input-container input::placeholder {
    color: #999999;
}

.modal-workstream-settings-file-input-container {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.modal-workstream-settings-file-input-container p {
    margin: 0;
    font-size: 14px;
    color: grey;
    margin-top: 14px;
}
  
.modal-workstream-settings-file-input-container.modal-workstream-settings-file-dragging {
    background-color: #f0f8ff;
    border-color: #3D72AA;
}
  
.modal-workstream-settings-file-upload-icon {
    font-size: 24px;
    color: #3D72AA;
}
  
.modal-workstream-settings-file-input {
    display: none;
}
  
.modal-workstream-settings-file-label {
    color: #3D72AA;
    text-decoration: underline;
    cursor: pointer;
}
  
.modal-workstream-settings-file-progress-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
}

.modal-workstream-settings-file-feedback-error {
    color: rgb(135, 35, 35);
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
    margin: 0;
    margin-top: 4px;
    text-align: center;
}
  
.modal-workstream-settings-file-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
  
.modal-workstream-settings-file-name {
    font-weight: bold;
}
  
.modal-workstream-settings-file-close-icon {
    cursor: pointer;
    font-size: 20px;
    color: #666;
}
  
.modal-workstream-settings-file-progress-bar-container {
    background-color: #e0e0e0;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
}
  
.modal-workstream-settings-file-progress-bar {
    background-color: #3D72AA;
    height: 100%;
    transition: width 0.3s ease;
}
  
.modal-workstream-settings-file-progress-text {
    margin-top: 5px;
    font-size: 12px;
    color: #666;
}

.modal-manage-project-resource-resources {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 360px;
    overflow-y: auto;
    padding-right: 8px;
    box-sizing: border-box;
}

.modal-manage-project-resource-header {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 40px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .alignment-modal-form-container,
    .project-post-form-container {
        width: 80%;
        margin-top: 160px;
    }

    .alignment-modal-form-container h3 {
        font-size: 14px;
    }

    .modal-task-edit-section-group-title {
        font-size: 14px;
    }

}

@media only screen and (min-width: 800px) {
    /* For desktop: */
    .alignment-modal-form-container,
    .project-post-form-container {
        width: 80%;
        margin-top: 200px;
    }

    .project-post-form-container {
        /* max-width: 700px; */
        width: 60%;
        margin-top: 120px;
    }

    .alignment-modal-form-container h3 {
        font-size: 16px;
    }

    .modal-task-edit-section-group-content {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1100px) {

    .modal-full-screen-task-edit-container-primary {
        flex-basis: 65%;
    }
    .modal-full-screen-task-edit-container-discussion {
        display: flex;
        flex-basis: 35%;
    }

    .modal-edit-task-container-responsive-width {
        width: 85%;
        max-width: 1000px;
    }
}

@media only screen and (max-width: 1450px) {
    .modal-full-screen-task-edit-container-secondary {
        /* display: none; */
    }

    .modal-full-screen-outside-assignables-container {
        width: 90%;
    }

    .modal-full-screen-task-edit-container-inner {
        flex-direction: column;
    }

    .modal-full-screen-assignable-details {
        min-height: 60px;
    }

    .modal-full-screen-task-edit-container-primary,
    .modal-full-screen-task-edit-container-secondary {
        flex-basis: 90%;
        width: 100%;
    }

    .modal-full-screen-task-edit-container-primary {
        min-height: 550px;
    }

    .modal-full-screen-task-comments-box {
        width: 100%;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
    }

    .modal-full-screen-task-edit-container-secondary {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        gap: 20px;
    }

}

/* MULTIMEDIA POST MODAL */
.modal-mm-post-outer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.226);
    z-index: 101;
}

.modal-mm-post {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 520px;
    max-height: 80vh;
    width: 100%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
}

.modal-mm-post-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.modal-mm-post-header-row h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

.modal-mm-post-header-row svg {
    font-size: 24px;
    cursor: pointer;
}

.modal-mm-text-title-container {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 32px;
}

.modal-mm-text-title-container input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12px;
    padding-left: 0px;
    outline: none;
    font-size: 14px;
    font-weight: 600;
}

.modal-mm-text-title-container input::placeholder {
    color: lightgrey;
}

.modal-mm-text-title-container input,
.modal-mm-text-title-container input:focus {
    border: 1px solid transparent;
    border-bottom: 1px solid grey;
}

.modal-mm-text-editor {
    position: relative;
    z-index: 202;
}

.modal-mm-uploads {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    box-sizing: border-box;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
}

.modal-mm-uploads span {
    font-size: 14px;
}

.modal-mm-upload-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.modal-mm-upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s;
}

.modal-mm-upload-button svg {
    font-size: 24px;
}

.modal-mm-upload-button:hover {
    background-color: rgb(221, 221, 221);
}

.modal-mm-image-preview-container {
    display: flex;
    overflow-x: auto;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
}

.modal-mm-image-preview {
    max-width: 120px;
    max-height: 100px;
    object-fit: contain;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.modal-mm-image-preview-remove {
    position: absolute;
    top: 4px;
    right: 4px;
    color: rgb(61, 114, 170);
    background-color: white;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}