#header {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    z-index: 102;
    color: white;
    background-color: #4F5D74;
}

#header-light {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    height: 48px;
    margin-top: 16px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-light-left-links {
    flex-basis: 33%;
    position: relative;
}

.header-light-left-links svg {
    font-size: 24px;
    cursor: pointer;
}

#header-left-side.header-left-side-responsive {
    display: flex;
}

.header-left-side-icon-navigation {
    display: none;
}

.header-light-left-link-outer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
    width: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    top: 100%;;
}

.header-light-left-link-wide {
    display: none;
}

.header-light-left-link-outer span {
    color: black;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.header-light-left-link-wide span {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.header-light-left-link-wide span:hover {
    text-decoration: underline;
}

.header-light-left-link-outer span:hover {
    background-color: rgb(240, 240, 240);
}

.header-light-left-links-hamburger {
    display: block;
}

.header-light-center-logo-container {
    height: 40px;
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-basis: 33%;
}

.header-light-center-logo-container svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.header-light-right-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex-basis: 33%;
    justify-content: flex-end;
}

.header-light-button-login {
    font-size: 12px;
    font-weight: 500;
    padding: 4px 12px;
    border: 1px solid rgb(68, 68, 68);
    border-radius: 20px;
    cursor: pointer;
}

#header-title,
#header-login,
#header-logout,
.header-username,
#header-signup,
.header-title {
    font-size: 14px;
}

#header-signup {
    color: rgb(158, 181, 199);
}

.header-profile-name-row {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}

#header-logout,
#header-login,
.header-username {
    color: white;
}

#header-login:hover,
#header-logout:hover,
#header-signup:hover {
    text-decoration: underline;
    cursor: pointer;
}

#header-title,
.header-title {
    font-weight: 500;
    margin-right: 16px;
}

#header-title a,
.header-title a {
    text-decoration: none;
    color: white;
}

.header-nav-text {
    font-size: 14px;
    margin-right: 12px;
    min-width: 60px;
}

.header-nav-text-active {
    text-decoration: underline;
    text-underline-offset: 0.4em;
    font-weight: 600;
}

.header-nav-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

#header-left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

#header-left-side svg {
    height: 14px;
    /* margin-right: 14px; */
}

.sticky {
    position: fixed;
    top: 0;
    z-index: 103;
}
/* Unfortunately requires an additional padding on the underlying elements so they don't get swallowed up */

.header-right-side-auth-items {
    display: flex;
    gap: 32px;
    flex-direction: row;
    align-items: center;
}

.header-right-side-auth-items-inner {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.header-profile-dropdown-container {
    position: absolute;
    background-color: #F6F7F9;
    top: 100%;
    min-width: 200px;
    right: 0;
    color: #4F5D74;
    font-size: 14px;
    font-weight: 500;
    margin-top: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(163, 163, 163, 0.25);
}

.header-profile-dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    transition: all 0.1s;
}

.header-profile-dropdown-item:hover {
    background-color: #eceef1;
}

.header-right-side-auth-links {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.header-right-side-feedback-button {
    width: 120px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: gold; */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffffff 0%, #f3f3f3 50%, #ededed 51%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#f3f3f3 50%,#ededed 51%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #ffffff 0%,#f3f3f3 50%,#ededed 51%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    color: rgb(34, 34, 34);
    font-size: 10px;
    font-weight: 700;
    border-radius: 4px;
    text-transform: uppercase;
    transition: all 0.2s;
}

.header-right-side-feedback-button:hover {
    background-color: rgb(255, 233, 109);
}

.header-right-side-auth-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px;
    align-items: flex-end;
}

/* Notifications */
.header-notifications-container,
.simple-list-action-menu-container {
    position: relative;
    overflow: visible;
}

.header-notification-bell-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
}

.header-notification-red-dot {
    position: absolute;
    bottom: -2px;
    right: -2px;
    background-color: red;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    content: "";
}

.header-notifications-dropdown,
.simple-list-action-menu-dropdown {
    position: absolute;
    top: 100%;
    right: 50%;
    z-index: 300;
}

.simple-list-action-menu-primary-display {
    background-color: white;
    color: black;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 8px;
    width: 180px;
}

.simple-list-action-menu-item {
    font-size: 0.85em;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0.5em;
    font-weight: 600;
}

.simple-list-action-menu-item:hover {
    background-color: rgb(241, 241, 241);
}

.simple-list-action-menu-items {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    align-items: flex-start;
}

.header-notifications-primary-display {
    background-color: white;
    color: black;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 8px;
    padding-top: 16px;
    padding-left: 8px;
    padding-bottom: 16px;
    padding-right: 8px;
    width: 400px;
}

.header-notifications-primary-header-row h2 {
    margin: 0px;
    font-size: 1em;
    font-weight: 600;
}

.header-notifications-primary-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-notifications-primary-clear-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(57, 88, 156);
    cursor: pointer;
}

.header-notifications-primary-clear-text:hover {
    text-decoration: underline;
}

.header-notifications-notifications-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 80vh;
    overflow-x: auto;
}

.header-notification-single-container-outer {
    border-bottom: 1px solid rgb(199,199,199);
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
}

.header-notification-single-container-inner {
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    transition: all 0.2s;
    cursor: pointer;
}

.header-notification-single-container-inner:hover {
    background-color: rgb(221, 221, 221);
}

.header-notification-caught-up {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.9em;
    color: rgb(48, 48, 48);
    font-weight: 600;
}

.notification-color {
    content: "";
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: 8px;
}

.header-notification-message {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    padding-right: 12px;
}

.header-notification-message-read {
    background-color: white;
}

.user-mydesk-notifications-container .header-notification-message-read {
    background-color: transparent;
}

.header-notification-message-read .header-notification-message {
    font-weight: 400;
}

.header-notification-single-container-outer:last-of-type {
    border-bottom: none;
}

.header-notification-timestamp {
    color: grey;
    text-transform: capitalize;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
}

/* HEADER SMART BAR */
.header-smart-bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.header-smart-bar-inner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: white;
}

.header-smart-bar-display-text {
    font-size: 13px;
    font-weight: 600;
    color: rgb(242, 242, 242);
    padding: 4px 14px;
    border-radius: 6px;
    background-color: #46576e;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    max-width: 20%;
    min-width: 200px;
    transition: all 0.2s;
}

.header-smart-bar-display-text svg {
    font-size: 14px;
    color: rgb(242, 242, 242);
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 16px;
}

.header-smart-bar-display-text:hover {
    background-color: #3f4e62;
    border-radius: 0px;
}

.header-smart-bar-display-text span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.header-smart-bar-update-panel {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
}

.header-smart-bar-update-panel-inner {
    min-width: 320px;
    max-width: 480px;
    background-color: white;
    border-radius: 12px;
    margin-top: 12px;
    padding: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.header-smart-bar-update-panel-controls {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    color: #46576e;
    font-size: 14px;
    position: relative;
}

.header-smart-bar-update-panel-controls button {
    background-color: #386C96;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 8px;
    width: 80px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 14px;
    padding: 4px 18px;
    border-radius: 4px;
}

.header-smart-bar-update-status-input {
    flex-grow: 1;
    width: 360px;
    border: 1px solid #cccccc;
    font-size: 14px;
    padding: 12px 12px;
    border-radius: 4px;
    padding-right: 100px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #ffffff;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
}

.header-smart-bar-update-status-input:focus {
    border-color: #007bff; /* Highlighted border color on focus */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1), 0 0 0 2px rgba(0,123,255,0.25); /* More pronounced shadow on focus */
}

.header-smart-bar-update-panel-existing-status {
    font-size: 14px;
    line-height: 1.6;
    font-weight: 500;
    width: 100%;
    color: black;
    white-space: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
}

.header-smart-bar-update-panel-existing-status svg {
    min-width: 20px;
    font-size: 14px;
    color: grey;
    padding: 3px;
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    #header {
        height: 36px;
    }

    .header-nav-text {
        font-size: 14px;
        margin-right: 16px;
    }

    #header-title,
    #header-login,
    #header-signup,
    .header-title {
        font-size: 14px;
    }

}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    #header {
        height: 48px;
    }

    #header-light {
        width: 90%;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .header-light-left-links-hamburger {
        display: none;
    }

    .header-light-left-link-outer {
        display: none;
    }

    .header-light-left-link-wide {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .header-light-button-login {
        font-size: 14px;
    }

    .header-nav-text {
        font-size: 14px;
        margin-right: 16px;
    }

    #header-title,
    #header-login,
    #header-signup,
    .header-title {
        font-size: 16px;
    }
}

@media only screen and (max-width: 540px) {
    #header-left-side.header-left-side-responsive {
        display: none;
    }

    .header-left-side-icon-navigation {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
    }

    .header-nav-text-icon {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        color: white;
    }

    .header-nav-text-icon svg {
        height: 16px;
        min-width: 28px;
    }

    .header-right-side .header-right-side-responsive {
        display: none;
    }

    .header-profile-dropdown-container {
        min-width: 200px;
    }

    .header-profile-dropdown-container {
        right: 0;
    }

    .header-notifications-dropdown {
        position: fixed;
        top: 60px;
        left: 12px;
        width: 90%;
    }

    .header-notifications-primary-display {
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .header-smart-bar {
        display: none;
    }
}

