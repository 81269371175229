.project-panel-feed-container {
    width: 80%;
    max-width: 600px;
}

.project-panel-feed-container hr {
    color: rgb(230, 230, 230);
    width: 50%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.project-single-post-container {
    padding-bottom: 14px;
    padding-top: 14px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(231, 231, 231);
}

.project-single-post-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding-top: 12px;
    box-sizing: border-box;
    position: relative;
}

.post-options-container-outer {
    position: absolute;
    top: 8px;
    right: 8px;
}

.post-options-button {
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 4px;
    transition: all 0.2s;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.post-options-button:hover {
    background-color: rgb(211, 211, 211);
}

.project-single-post-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #48555B;
    cursor: pointer;
}

.project-single-post-header-special {
    font-size: 12px;
    margin-left: 4px;
    color: rgb(58, 58, 58);
}

.project-single-post-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.project-single-post-summary span {
    padding-left: 4px;
    padding-right: 4px;
}

.post-subheading-clickable-workspace {
    cursor: pointer;
}

.post-subheading-clickable-workspace:hover {
    text-decoration: underline;
}

.project-post-time {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #5E6F78;
}

.feed-story-subtitle {
    font-size: 0.8em;
    color: rgb(119, 119, 119);
}

.project-post-author {
    font-weight: 500;
    font-size: 12px;
    color: rgb(119, 119, 119);
}

.project-post-author:hover {
    text-decoration: underline;
    cursor: pointer;
}

.project-post-content {
    font-size: 14px;
    font-weight: 400;
    overflow-wrap: break-word;
    display: block;
    white-space: pre-wrap;
    margin-top: 14px;
    margin-bottom: 4px;
    padding: 0px 12px;
    padding-left: 0px;
    line-height: 1.7;
    width: 100%;
    box-sizing: border-box;
    color: var(--t-color-dark);
}

.project-post-content p {
    margin: 0;
}

.project-post-content.gadget-lexical-displayed-text-container p {
    line-height: 20px;
    margin-bottom: 0.2em;
} 

.project-post-content p:empty::before {
    content: "\200b"; /* unicode zero width space character */
}

.project-post-title {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
    margin-top: 14px;
    color: var(--t-color-dark);
}

.project-post-discussion {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
    font-size: 0.9em;
    color: var(--t-color-dark);
}

.feed-post-discussion-item {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    padding: 4px;
    border-radius: 4px;
}

.project-post-reaction {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 24px;
    width: 60px;
    background-color: #E6E8F3;
    /* background-color: #ecedf3a2; */
    border-radius: 100px;
    cursor: pointer;
}

.project-post-reaction:hover {
    background-color: #dadbe7;
}

.project-post-reaction span {
    font-size: 12px;
}

.feed-post-discussion-item:hover {
    background-color: rgb(219, 219, 219);
}

.project-post-discussion svg {
    font-size: 1.4em;
    color: grey;
}

.project-post-discussion span {
    color: #48555C;
    /* margin-right: 12px; */
    transition: all 0.2s;
}

.project-post-discussion span:hover {
    cursor: pointer;
    text-decoration: underline;
    color: black;
}

.project-post-comment-form {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    max-width: 100%;
    padding: 12px 36px;
    padding-left: 0;
    box-sizing: border-box;
}

.project-post-comment-form textarea {
    width: 100%;
    box-sizing: border-box;
    background-color: #FAFAFA;
    border: none;
    border-radius: 20px;
    padding: 20px 18px;
    font-size: 14px;
    color: #444444;
    resize: none;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.project-post-comments {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.project-post-comment-container {
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    position: relative;
}

.project-post-comment-container:hover .project-post-floating-tools {
    display: flex;
}

.project-post-floating-tools {
    position: absolute;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 4px;
    width: 22px;
    height: 22px;
    right: 8px;
    top: 12px;
    cursor: pointer;
}

.project-post-floating-tools svg {
    font-size: 16px;
    color: rgb(112, 112, 112);
}

.project-post-comment-content-enclosure {
    flex-grow: 1;
    background-color: #FAFAFA;
    border-radius: 20px;
    padding: 18px 24px;
    margin-right: 32px;
}

.project-post-comment-content-button-submit {
    background-color: #3D72AA;
    color: white;
    font-weight: 700;
    font-size: 14px;
    box-shadow: 0px 4px 4px 0px rgba(125, 125, 125, 0.25);
    border-radius: 6px;
    width: 90px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 14px;
}

.project-post-comment-header-titles {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 8px;
}

.workspace-post-comment-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.project-post-comment-header p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.project-post-comment-header-author {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    letter-spacing: 0.02em;
}

.project-post-comment-header-created {
    font-size: 12px;
    color: #070707;
    opacity: 0.5;
    font-weight: 400;
    margin: 0px;
}

.project-post-comment-header-opt {
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
    transition: all 0.2s;
}

.project-post-comment-content {
    font-size: 14px;
    color: #1C1C1C;
    opacity: 0.8;
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.7;
}

.project-post-comment-header-opt:hover {
    cursor: pointer;
    background-color: rgb(172, 172, 172);
}

.post-component-update-status-container {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.post-component-update-status-current {
    width: 120px;
    text-align: center;
}

.post-component-update-progress-current {
    text-align: left;
    font-size: 16px;
}

.post-component-update-progress-target {
    width: 120px;
    text-align: left;
    font-size: 16px;
}

.post-component-update-status-options option,
.post-component-update-status-options,
.post-component-update-status-current {
    font-size: 16px;
    text-transform: capitalize;
}

.post-component-update-status-options {
    margin-top: 12px;
    border: 1px solid lightgrey;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
}

.post-component-update-status-radio {
    display: flex;
    flex-direction: column;
}

.post-component-update-progress-input {
    font-size: 16px;
    border: none;
    text-align: right;
    width: 80px;
    font-weight: bold;
}

.post-component-update-progress-input-container {
    border-bottom: 1px solid lightgrey;
}

.post-component-update-status-arrow {
    font-size: 28px;
    width: 120px;
}

.post-component-update-progress-arrow {
    font-size: 28px;
    width: 80px;
}

.post-component-update-progress-container {
    display: flex;
    flex-direction: column;
}

.post-component-update-progress-label {
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 12px;
}

.post-component-update-progress-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}

.post-component-update-progress-row span {
    font-size: 12px;
    color: grey;
    margin-left: 12px;
}

.feed-story-carousel {
    width: 100%;
    font-size: 0.9em;
}

.feed-story-header {
    cursor: pointer;
    transition: all 0.2s;
}

.feed-story-header:hover {
    background-color: rgb(230, 230, 230);
}

.feed-story-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.feed-story-footer-button {
    font-size: 0.9em;
    padding: 8px 16px;
    background-color: rgb(53, 53, 53);
    color: white;
}

/* POST IMAGE */
.post-image-preview-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

.post-image-single {
    position: relative;
    width: 100%; /* Container width is 100% of its parent */
    height: 0;
    padding-bottom: 100%; /* Keeps aspect ratio of 1:1 */
    overflow: hidden; 
    border-radius: 12px;
    cursor: pointer;
}

.post-image-single::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 27, 27, 0.057); /* Adjust the last value (0.5) for opacity */
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* This allows clicks to pass through to the underlying element */
}

.post-image-single:hover::after {
    opacity: 1;
}

.post-image-single img {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: translateY(-50%);
}

.post-image-carousel-container {
    background-color: #21212181;
}

.post-image-carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #cfcfcf74;
    color: rgb(37, 37, 37);
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    border-radius: 20px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -khtml-user-select: none;
    -moz-user-select: none;   
    -ms-user-select: none; 
    user-select: none;
    opacity: 0;
    transition: all 0.5s;
}

.post-image-carousel-container:hover .post-image-carousel-arrow,
.post-media-full-view-pane-media .post-image-carousel-arrow {
    opacity: 1;
}

.post-image-carousel-left-arrow {
    left: 10px;
}

.post-image-carousel-right-arrow {
    right: 10px;
}

/* VIEWER */
.post-media-full-view-container {
    color: #353C44;
    width: 90%;
    height: 100%;
    max-height: 800px;
    min-height: 300px;
    margin-top: 120px;
    margin-bottom: 5%;
    max-width: 1100px;
    background-color: white;
    border-radius: 10px;
    z-index: 104;
    box-shadow: 0px 4px 10px 0px rgba(115, 115, 115, 0.25);
    overflow: hidden;
}

.post-media-full-view-panes {
    display: flex;
    align-items: stretch;
    height: 100%;
    justify-content: space-between;
    position: relative;
}

.post-media-full-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1B1F23;
}

.post-media-full-view img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.post-media-full-view-pane-media {
    flex-grow: 1;
    position: relative;
}

.post-media-full-view-watermark {
    position: absolute;
    opacity: 0.4;
    transition: all 0.2s;
    display: flex;
}

.post-media-full-view-watermark:hover {
    opacity: 1;
}

.post-media-full-view-watermark-pic {
    bottom: 12px;
    right: 12px;
}

.post-media-full-view-watermark-name {
    left: 12px;
    top: 12px;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1em;
    cursor: pointer;
}

.post-media-full-view-pane-meta {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .post-media-full-view-pane-meta {
        display: none;
    }
}

.post-media-full-view-pane-meta-header {
    padding-top: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}

.post-media-full-view-meta-header-texts {
    max-width: 240px;
    width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.post-media-full-view-meta-header-texts div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.post-media-full-view-meta-author {
    font-size: 14px;
    font-weight: 700;
    transition: all 0.2s;
    cursor: pointer;
}

.post-media-full-view-meta-author:hover {
    text-decoration: underline;
    color: #3D72AA;
}

.post-media-full-view-meta-created {
    font-size: 13px;
    color: grey;
}

.post-media-full-view-content {
    overflow-y: auto;
}

.post-media-full-view-pane-meta-close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
}

.post-media-full-view-pane-meta-close svg {
    font-size: 28px;
    color: rgb(53, 53, 53);
}

.youtube-embed-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-top: 14px;
    border-radius: 12px;
}

.youtube-embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* POST PAGE */
.post-page-outer-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 48px;
    bottom: 0;
    overflow-y: auto;
    z-index: 101;
}

.post-page-outer-container-sidebar {
    left: 240px;
    padding-right: 240px;
}

.post-page-width-control {
    width: 100%;
    max-width: 680px;
    min-width: 480px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.post-page-header {
    position: absolute;
    display: flex;
    transform: translateX(calc(-100% - 14px));
    margin-top: 8px;
    align-items: center;
    gap: 24px;
    width: 28px;
}

.post-page-header-back {
    width: 28px;
    height: 28px;
    min-width: 28px;
    background-color: rgb(223, 230, 239);
    border-radius: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-page-header-back svg {
    font-size: 16px;
    color: rgb(155, 155, 155);
}

.post-page-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    gap: 14px;
    font-weight: 500;
    margin-top: 20vh;
}

.post-page-main-container {
    color: var(--t-color-dark);
}

.post-page-main-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 1em;
}

.post-page-main-author-timestamp {
    display: flex;
    align-items: stretch;
    gap: 14px;
    margin-top: 14px;
    position: relative;
}

.post-page-main-author {
    font-size: 14px;
    font-weight: 600;
}

.post-page-main-timestamp {
    font-size: 12px;
    color: grey;
}

.post-page-engagement-container {
    margin-top: 36px;
    border-top: 1px solid rgb(208, 208, 208);
    border-bottom: 1px solid rgb(208, 208, 208);
    padding: 12px 0px;
}

.post-page-comments-section {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    margin-bottom: 180px;
}

.post-page-comment-container {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    position: relative;
}

.post-page-comment-delete {
    position: absolute;
    right: 0;
    top: 14px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s;
}

.post-page-comment-delete svg {
    font-size: 18px;
    color: rgb(122, 122, 122);
}

.post-page-comment-container:hover .post-page-comment-delete {
    opacity: 1;
}

.post-page-comment-author-timestamp {
    display: flex;
    align-items: baseline;
    font-size: 14px;
    font-weight: 600;
    gap: 8px;
    margin-bottom: 4px;
}

.post-page-comment-author-timestamp span {
    font-size: 13px;
    color: grey;
    font-weight: 400;
}

.post-page-comment-content {
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.7;
}

.post-page-comment-proposed {
    margin-top: 20px;
}

.post-page-right-decorations {
    position: fixed;
    right: 20px;
    top: 68px;
    width: 280px;
    background-color: rgb(244, 244, 244);
    border-radius: 6px;
    box-sizing: border-box;
    max-height: 80vh;
    overflow-y: auto;
    padding-bottom: 14px;
}

.post-page-right-decorations h3 {
    font-size: 16px;
    margin: 0;
    padding: 14px;
    padding-bottom: 0px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow-x: hidden;
    color: var(--t-color-dark);
}

.post-page-right-decoration-section {
    border-bottom: 1px solid rgb(171, 171, 171);
    padding: 4px 14px;
    font-size: 14px;
}
.post-page-right-decoration-section:last-of-type { border-bottom: none; }

.feed-right-side-about-container.post-page-right-side-about-container {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1200px) {
    .post-page-header {
        transform: unset;
    }

    .post-page-main-author-timestamp {
        margin-left: 42px;
    }

    .post-page-right-decorations {
        display: none;
    }

    .post-page-outer-container-sidebar {
        padding-right: 0px;
    }
}

@media screen and (min-width: 1500px) {
    .post-page-right-decorations {
        width: 420px;
    }

}