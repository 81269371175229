/* Profile page main */
.profile-page {
    margin-top: 48px;
    --tetheros-profile-banner-height: 240px;
}

.profile-page-main {
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 12px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
}

.profile-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 40px;
    margin-bottom: 20px;
}

.profile-page-header-identification {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.profile-page-header-identification h2 {
    margin: 0;
    font-size: 34px;
    color: rgb(41, 41, 41);
    font-weight: 700;
}

.profile-page-header-headline {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    color: rgb(41, 41, 41);
}

.profile-page-header-meta {
    font-size: 16px;
    color: grey;
}

.profile-identity-website {
    display: flex;
    align-items: center;
    font-weight: 700;
    gap: 4px;
    font-size: 16px;
}

.profile-identity-website a {
    text-decoration: none;
    color: #3D72AA;
}

.profile-identity-website a:hover {
    text-decoration: underline;
}

.profile-page-personal-tagline {
    font-size: 16px;
    font-weight: 500;
    color: rgb(75, 84, 93);
    line-height: 1.6;
    white-space: pre-wrap;
}

.profile-page-header-primary {
    margin-bottom: 12px;
}

.profile-page-section-heading {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-page-section h3 {
    font-size: 24px;
    margin: 0;
    color: rgb(41, 41, 41);
}

.profile-page-section-heading svg {
    font-size: 18px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    color: rgba(37, 71, 98, 0.335);
    cursor: pointer;
    transition: all 0.2s;
}

.profile-page-section-heading svg:hover {
    color: rgb(37, 71, 98);
}

.profile-page-section p {
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
    font-size: 16px;
    white-space: pre-wrap;
}

@media screen and (max-width: 800px) {
    .profile-page-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 12px;
        margin-bottom: 12px;
    }

    .profile-page-header-primary {
        margin-bottom: 0px;
    }

    .profile-page-header-identification {
        gap: 8px;
    }

    .profile-page-header-identification h2 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 0px;
    }

    .profile-identity-website {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile-page-header-headline,
    .profile-page-header-meta,
    .profile-page-personal-tagline,
    .profile-identity-website {
        font-size: 14px;
        text-align: center;
    }

    .profile-page-section h3 {
        font-size: 18px;
    }

    .profile-page-section p {
        font-size: 14px;
        line-height: 20px;
    }
}

.profile-page-upper-banner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.2;
}

.profile-page-header-picture {
    cursor: pointer;
}

.profile-information-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 380px;
    padding-right: 40px;
    margin-top: 14px;
}

.profile-interaction-options {
    display: flex;
    align-items: stretch;
    padding-top: 14px;
    padding-bottom: 14px;
    gap: 12px;
}

.profile-username {
    font-size: 32px;
    margin: 0px;
    font-weight: 700;
    margin-bottom: 0px;
    display: flex;
    gap: 12px;
}

.profile-identity-attributes {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 12px;
    margin-top: 8px;
}

.profile-identity-handle {
    font-size: 16px;
    font-weight: 400;
}

.profile-identity-headline {
    font-size: 16px;
    font-weight: 500;
    color: rgb(44, 44, 44);
}

.profile-page-extended-container {
    margin-top: 100px;
    padding: 0px 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 60px;
}

.profile-page-general-section {
    border: 1px solid #EAEAEA;
    background-color: var(--tetheros-profile-background);
    padding: 12px 20px;
    border-radius: 6px;
}

.profile-page-general-section h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin: 0px;
    margin-bottom: 12px;
}

.profile-page-main-sections,
.profile-page-side-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.profile-page-side-sections {
    flex-basis: 40%;
    max-width: 600px;
}

.profile-page-main-sections { 
    flex-basis: 65%;
}

.profile-page-bio {
    white-space: pre-wrap;
    line-height: 1.8;
    font-size: 14px;
}

/* Edit profile picture modal */
.profile-user-edit-modal-inner-container {
    min-width: 600px;
    width: 30%;
    margin-top: 120px;
    margin-bottom: 5%;
    min-height: 120px;
    /* max-height: 90%; */
    /* overflow-y: auto; */
    background-color: white;
    border-radius: 4px;
    z-index: 101;
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.profile-user-edit-modal-header-row,
.component-template-edit-modal-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.profile-user-edit-modal-header-title {
    font-size: 32px;
    margin: 0;
    line-height: 48px;
    font-weight: 700;
}

.component-template-edit-modal-header-title {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 24px;
    font-weight: 300;
}

.profile-user-edit-modal-header-close,
.component-template-edit-modal-header-close {
    padding: 8px;
    height: 16px;
    width: 16px;
    border-radius: 25%;
    transition: all 0.2s;
    color: rgb(97, 97, 97);
    cursor: pointer;
}

.profile-user-edit-modal-header-close:hover,
.component-template-edit-modal-header-close:hover {
    background-color: rgb(228, 228, 228);
}

.profile-user-edit-modal-picture-container-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-user-edit-modal-picture-container {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.profile-user-edit-modal-picture-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-pic-main-form {
    margin-top: 8px;
}

.profile-user-update-pic-container input[type="file"] {
    display: none;
}

.profile-pic-label-save {
    background-color: rgb(23, 64, 97);
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 12px;
}

.profile-pic-label-save:hover {
    cursor: pointer;
}

.profile-pic-label-cancel {
    color: grey;
    margin-top: 8px;
    cursor: pointer;
    transition: all 0.2s;
}

.profile-pic-label-cancel:hover {
    text-decoration: underline;
}

.profile-pic-label {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin-top: 0px;
    margin-bottom: 0px;
    transition: all 0.2s;
    color: #2973af;
    text-align: center;
}

.profile-pic-label:hover {
    text-decoration: underline;
}

.profile-user-pic-submit-col {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.profile-user-pic-submit-col div {
    font-size: 14px;
    color: grey;
}

.profile-user-pic-submit-col input[type="submit"] {
    border: none;
    background-color: white;
    padding: 4px;
    padding-right: 8px;
}

.profile-user-pic-submit-col input[type="submit"]:hover {
    text-decoration: underline;
}

.profile-user-no-image-preview {
    background-color: #2973af;
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-user-no-image-preview svg {
    color: white;
    font-size: 24px;
}

/* Profile Tooltip */
.prof-tooltip-container {
    background-color: white;
    position: fixed;
    z-index: 9999;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 4px;
    padding: 20px 12px;
    padding-bottom: 4px;
    cursor: default;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.prof-tooltip-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 12px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.prof-tooltip-dot {
    position: absolute;
    top: 0;
    right: 0;
}

.prof-tooltip-header-information {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.prof-tooltip-username {
    font-weight: 600;
    color: black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 20px;
}

.prof-tooltip-handle {
    font-size: 13px;
    color: grey;
}

.prof-tooltip-status {
    font-size: 12px;
    margin-top: 12px;
    text-align: left;
    font-weight: 400;
    width: 100%;
    white-space: wrap;
    line-height: 1.5;
    color: rgb(53, 53, 53);
}