#component-menu-container {
    width: 100%;
    margin-top: 48px; /* accounts for the header */
}

#component-menu-top-bar {
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.component-text-link {
    font-size: 12px;
    color: rgb(119, 119, 119);
    transition: all 0.2s;
    text-decoration: none;
}

.component-text-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.component-menu-width-control {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.component-menu-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 8px;
}

.component-new-header-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.component-new-header-container h4 {
    margin-top: 12px;
    margin-bottom: 12px;
    color: rgb(23, 64, 97);
}

#core-values-new-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

#core-values-new-container span.core-value-pill {
    border: 1px solid rgb(23, 64, 97);
    font-weight: bold;
    font-size: 12px;
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 4px;
    margin: 4px;
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
}

.core-value-pill-trash {
    margin-right: 8px;
    transition: all 0.2s;
}

.core-value-pill-trash:hover {
    color: salmon;
    cursor: pointer;
}

.component-text-save-status {
    font-size: 12px;
    color: rgb(119, 119, 119);
    transition: all 0.2s;
    text-decoration: none;
    font-style: italic;
}

.component-button-nav {
    font-size: 10px;
    color: white;
    font-weight: bold;
    background-color: rgb(23, 64, 97);
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    text-transform: uppercase;
    border: 1px solid rgb(230, 230, 230);
    width: 160px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.component-button-nav:hover {
    cursor: pointer;
    background-color: rgb(56, 108, 150);
}

#component-menu-new-items-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.component-menu-new-item-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 40px;
}

.component-menu-new-item-section h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
    width: 100%;
}

.component-menu-new-item-section-subtitle {
    font-size: 14px;
    color: grey;
    margin-bottom: 14px;
    width: 100%;
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(199,199,199);
}

.component-menu-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid rgba(199, 199, 199, 0.515);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px;
    width: 320px;
    margin-right: 24px;
}

.component-menu-item h5,
.component-menu-item p {
    margin: 0;
}

.component-menu-item h5 {
    font-size: 18px;
    font-weight: 700;
}

.component-menu-item p {
    font-size: 14px;
    margin-bottom: 16px;
    margin-top: 8px;
    line-height: 1.8em;
}

.component-menu-item-add {
    font-size: 10px;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    background-color: rgb(23, 64, 97);
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.2s;
}

.component-menu-item-add:hover {
    cursor: pointer;
    background-color: rgb(56, 108, 150);
}

.component-menu-grid-desktop {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

#component-view-title-text {
    font-size: 1.5em;
    font-weight: 800;
    white-space: pre-wrap;
    margin-top: 0px;
    margin-bottom: 8px;
}

.component-view-title-text-component-type {
    font-weight: 700;
    text-transform: uppercase;
    color: #fc636b;
}

.component-view-title-meta-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: grey;
    font-size: 12px;
    text-transform: uppercase;
}

.component-view-title-meta-breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
}

.component-view-action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    margin-top: 8px;
    margin-bottom: 12px;
    margin-left: 12px;
}

.component-view-action-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

.component-view-action-button input[type=date] {
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
}

.component-view-title-meta-breadcrumb-link {
    color: #017698;
}

.component-view-title-meta-status-active {
    font-size: 10px !important;
    font-weight: 600;
    text-transform: uppercase !important;
    padding: 2px 8px;
    border-radius: 16px;
    background-color: #2973af;
    color: white !important;   
}

.component-view-title-meta-status-private {
    font-size: 10px !important;
    font-weight: 600;
    text-transform: uppercase !important;
    padding: 2px 8px;
    background-color: #606060;
    color: white !important;
    cursor: pointer;
}

.component-view-title-meta-status-public {
    font-size: 10px !important;
    font-weight: 600;
    text-transform: uppercase !important;
    padding: 2px 8px;
    background-color: #217e51;
    color: white !important;
    cursor: pointer;
}

.component-view-title-meta-about-status-active,
.component-view-title-meta-about-status-completed,
.component-view-title-meta-about-status-archived {
    color: #2973af;
    font-weight: 600;
    text-transform: capitalize;
    border: 1px solid #2973af;
    padding: 4px 8px;
    border-radius: 16px;
}

.component-view-title-meta-about-status-completed {
    border: 1px solid #049921;
    color: #049921;
}

.component-view-title-meta-about-status-archived {
    border: 1px solid rgb(255, 140, 105);
    color: rgb(255, 140, 105);
}

.component-view-title-meta-status-completed {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 16px;
    background-color: #049921;
    color: white;   
}

.component-view-title-meta-status-archived {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 16px;
    background-color: rgb(255, 140, 105);
    color: white;
}

.component-view-title-description {
    margin-bottom: 8px;
    color: grey;
    font-weight: 400;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 1.8em;
}

.component-view-subtitle-text-container {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.component-view-subtitle-text-container div {
    margin-right: 8px;
    display: flex;
    flex-direction: row;
}

.component-view-subtitle-text-container div div span {
    color: rgb(92, 92, 92);
    margin-left: 4px;
}

#component-view-edit-mission {
    color: white;
    background-color: rgb(23, 64, 97);
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    align-self: flex-end;
    transition: all 0.3s;
}

#component-view-edit-mission:hover {
    cursor: pointer;
    background-color: rgb(56, 108, 150);
}

#component-view-core-values-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px;
    padding-bottom: 12px;
    border-radius: 4px;
    border: 1px solid rgb(230, 230, 230);
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    max-width: 600px;
}

#component-view-core-values-container h2 {
    margin-top: 0px;
    margin-bottom: 12px;
    font-size: 12px;
}

#component-view-core-values-container .component-view-core-values-graphic {
    color: green;
}

#component-view-core-values-container #component-view-core-values-options-container .component-view-core-values-graphic {
    color: unset;
    margin-right: 4px;
}

.component-view-core-values-graphic {
    margin-right: 8px;
}

#component-view-core-values-container p {
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: 12px;
}

#component-view-core-values-container hr {
    border: none;
    border-top: 1px solid rgb(173, 173, 173);
    width: 100%;
    margin-bottom: 8px;
}

#component-view-core-values-options-container {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

#component-view-core-values-options-container div {
    margin-right: 12px;
}

#component-view-core-values-options-container div:hover {
    text-decoration: underline;
    cursor: pointer;
}

#component-view-list-task-total-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.component-view-total-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 8px;
    box-sizing: border-box;
}

.component-view-list-task-new-task-form {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch !important;
    justify-content: space-between;
    margin-bottom: 0px;
    gap: 1em;
    border-radius: 8px;
    font-size: 12px;
    background-color: white;
}

.component-view-list-task-new-task-form-full {
    padding: 8px;
    border-radius: 20px;
    font-size: 16px;
    height: 20px;
    width: 20px;
    color: white;
    font-weight: 300;
    background-color: rgb(23, 64, 97);
    transition: all 0.4s;
    margin-left: 8px;
}

.component-view-list-task-new-task-form-full:hover {
    cursor: pointer;
    background-color: rgb(49, 113, 165);
    transform: rotate(45deg);
}

.component-view-list-task-new-task-form input {
    flex-grow: 1;
    padding: 12px 16px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.2s;
    border: 1px solid rgb(199, 199, 199);
}

.component-view-list-task-new-task-form input:hover {
    border: 1px solid rgb(73, 73, 73);
}

.component-view-list-task-new-task-form input:focus {
    cursor: text;
}

.component-view-list-task-new-task-create-button {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 8px;
    font-weight: 600;
    box-sizing: border-box;
    background-color: rgb(180, 43, 73);
    color: white;
    cursor: pointer;
    transition: all 0.2s;
}

.component-view-info-underbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.component-view-info-metrics-row-clickable:hover {
    cursor: pointer;
}

/* The actual view by button */
.component-view-list-view-by-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: grey;
    gap: 8px;
    text-transform: uppercase;
    transition: all 0.2s;
}

.component-view-list-view-by-button span {
    width: 72px;
    font-size: 14px;
}

.component-view-list-view-by-button:hover {
    cursor: pointer;
    color: black;
}

.component-view-info-metrics-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.component-view-info-metrics-row div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: grey;
}

#component-view-list-task-total-container hr {
    border: none;
    width: 80%;
    margin-bottom: 12px;
    margin-top: 12px;
    border-bottom: 1px solid rgb(173, 173, 173);
}

.component-view-list-task-single-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.component-view-list-task-checkbox {
    color: rgb(92, 92, 92);
    min-width: 14px;
    margin-right: 12px;
    transition: all 0.2s;
    font-size: 14px;
    border-radius: 50%;
}

.component-view-list-task-checkbox-restore {
    color: rgb(92, 92, 92);
    min-width: 14px;
    margin-right: 12px;
    transition: all 0.2s;
    font-size: 14px;
    border-radius: 50%;
    background-color: rgb(23, 64, 97);
    color: rgb(23, 64, 97);
}

.component-view-list-task-checkbox-restore:hover {
    cursor: pointer;
    color: rgb(92, 92, 92);
    background-color: initial;
}

.component-view-list-task-checkbox:hover {
    cursor: pointer;
    color: rgb(23, 64, 97);
    background-color: rgb(23, 64, 97);
}

.component-view-list-task-description-text {
    font-size: 14px;
    flex-basis: 75%;
    padding-right: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 24px;
    height: 100%;
}

.component-view-list-task-description {
    flex-grow: 1;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border-top: 1px solid rgb(184, 184, 184);
    /* border-radius: 8px; */
    background-color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
}

.component-view-list-task-single-container:last-of-type .component-view-list-task-description {
    border-bottom: 1px solid rgb(184, 184, 184);
}

.component-view-list-task-single-container:last-of-type {
    margin-bottom: 16px;
}

.component-view-list-task-description:hover {
    background-color: rgb(241, 241, 241);
    cursor: pointer;
}

.component-view-list-tray-grip {
    padding-left: 4px;
    padding-right: 4px;
}

.component-view-list-tray-grip:hover {
    cursor: grab;
}

.component-view-list-task-tray {
    margin-top: 4px;
    width: 100%;
}

.component-view-list-tray-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    color:rgb(119, 119, 119)
}

.component-view-list-tray-meta span {
    color: black;
}

.component-view-list-tray-edit-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    transition: all 0.2s;
    border-radius: 4px;
}

.component-view-list-tray-edit-button span {
    margin-left: 4px;
}

.component-view-list-tray-edit-button:hover {
    background-color: rgb(212, 212, 212);
    cursor: pointer;
}

.component-view-list-tray-details,
.component-view-multiline-value {
    margin-top: 8px;
    color: rgb(78, 78, 78);
    font-size: 12px;
    white-space: pre-wrap;
}

.component-view-list-tray-date {
    display: flex;
    flex-direction: row;
    color: rgb(78, 78, 78);
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input[type="date"].component-view-list-tray-date::-webkit-calendar-picker-indicator { 
    color: rgb(78, 78, 78);
}

.component-view-list-tray-date span {
    margin-left: 8px;
}

.component-view-list-task-edit-action-container,
.component-view-edit-action-container {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 8px;
}

.component-view-list-task-edit-action-save,
.component-view-edit-action-save,
.component-view-list-task-edit-action-cancel,
.component-view-list-task-cancel,
.component-view-list-task-edit-action-save-error {
    margin-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 48px;
    text-align: center;
    border-radius: 4px;
    transition: all 0.2s;
}

.component-view-list-task-edit-action-save:hover,
.component-view-edit-action-save:hover,
.component-view-list-task-edit-action-cancel:hover,
.component-view-list-task-cancel:hover {
    cursor: pointer;
}

.component-view-list-task-edit-action-cancel,
.component-view-list-task-cancel {
    border: 1px solid rgba(78, 78, 78, 0.589);
}

.component-view-list-task-edit-action-cancel:hover,
.component-view-list-task-cancel:hover {
    background-color: rgb(223, 223, 223);
}

.component-view-list-task-edit-action-save,
.component-view-edit-action-save {
    background-color: rgb(23, 64, 97);
    color: white;
}

.component-view-list-task-edit-action-save-error {
    background-color: rgb(141, 169, 192);
    color: white;
}

.component-view-list-task-edit-action-save-error:hover {
    cursor: not-allowed;
}

.component-view-list-task-edit-container {
    width: 100%;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.component-view-list-task-edit-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.component-view-list-task-edit-form input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding-left: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
}

.component-view-list-task-edit-form input:focus {
    outline: none;
}

.component-view-list-task-edit-form textarea,
.component-view-subsection-container textarea {
    width: 100% !important;
    box-sizing: border-box;
    height: 6em;
    border: 1px solid rgba(78, 78, 78, 0.589);
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    resize: none;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
}

.component-view-list-task-edit-form textarea::placeholder,
.component-view-list-task-edit-form input::placeholder,
.component-view-subsection-container textarea::placeholder {
    font-style: italic;
    color: rgba(78, 78, 78, 0.589);
}

.component-view-list-task-edit-form label {
    font-size: 10px;
}

.component-view-list-tray-preview {
    flex-basis: 20%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 12px;
    color: rgb(153, 153, 153);
    transition: all 0.2s;
}

.component-view-list-tray-preview span:hover {
    cursor: pointer;
    color: rgb(94, 94, 94);
}

.component-view-list-tray-preview span {
    margin-right: 12px;
    transition: all 0.2s;
}

.component-view-subsection-container {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 12px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgba(196, 196, 196, 0.871);
    background-color: white;
}

.component-view-subsection-container h2 {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 4px;
}

.component-view-initiative-date-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 4px;
    padding-bottom: 4px;
}

.component-view-initiative-date-box input[type=date] {
    border: none;
}

.component-view-text-click-to-edit {
    padding-top: 4px;
    transition: all 0.2s;
}

.component-view-text-click-to-edit:hover {
    text-decoration: underline;
    cursor: pointer;
}

.component-view-row-space-between {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.component-view-subtitle-pill-blue {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(23, 64, 97);
    color: white;
    border-radius: 4px;
}

.component-view-data-editable {
    transition: all 0.2s;
    color: rgb(71, 144, 204)
}

.component-view-data-editable:hover {
    cursor: pointer;
    color: rgb(151, 151, 151);
    text-decoration: underline;
}

/* ************************ */
/* Component: List Sections */
/* ************************ */

.comp-list-section-container {
    width: 100%;
    margin-top: 12px;
}

.comp-list-section-title-group {
    border-bottom: 2px solid rgb(0, 0, 0);
    padding-bottom: 4px;
    margin-bottom: 16px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.comp-list-section-title {
    padding-left: 4px;
    padding-right: 4px;
    font-weight: bold;
}

.comp-list-section-task-count {
    color: grey;
    font-size: 10px;
    margin-left: 8px;
}

.comp-list-section-title-toggle {
    margin-right: 4px;
}

.comp-list-section-title-toggle:hover {
    cursor: pointer;
}

/* *******************************************/
/* Workspace Components (workcomp) Standards */
/* ***************************************** */
.component-page-outer {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    box-sizing: border-box;
}

.component-page-row {
    display: flex;
    flex-direction: row;
}

.workcomp-page-container {
    display: flex;
    flex-direction: column;
}

.workcomp-page-container-dark {
    background-color: #FAFAFA;
    min-height: 80vh;
    padding-bottom: 20vh;
}

.component-page-action-button {
    padding: 0.5em 1em;
    font-size: 0.9em;
    border: 1px solid #017698;
    color: #017698;
    border-radius: 12px;
    align-self: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4em;
    box-sizing: border-box;
    font-weight: 600;
    transition: all 0.2s;
}

.component-page-action-button-complete {
    border: 1px solid #019815;
    color: #019815;;
    font-weight: 800;
}

.component-page-action-button:hover {
    background-color: #c4e0e9;
}

.component-page-action-button-complete:hover {
    background-color: #c4e9d0;
}

.component-page-action-button svg {
    font-size: 1.3em;
}

.component-page-settings-symbol {
    font-size: 1.5em;
    padding: 0.3em;
    transition: all 0.2s;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 32px;
}

.component-page-settings-symbol:hover {
    background-color: rgb(228, 228, 228);
}

.component-page-title {
    font-size: 32px;
    font-weight: 700;
}

.component-page-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(199, 199, 199);
    background-color: white;
    margin-bottom: 1em;
    box-sizing: border-box;
    font-size: 0.9em;
    gap: 1.5em;
}

.component-page-tabs-initiative {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 32px;
    margin-bottom: 24px;
    margin-top: 12px;
}

.component-page-tabs-initiative-tab {
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    transition: all 0.1s;
    color: #8791A3;
    padding-bottom: 2px;
    border-bottom: 2px solid transparent;
}

.component-page-tab-initiative-selected {
    color: #344563;
    border-bottom: 2px solid #2A73AE;
}

.component-page-kresult-section-centered-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.component-page-kresult-section-progress {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.component-page-kresult-val-subtitles,
.component-page-kresult-kpi-name {
    font-weight: 500;
    color: grey;
    font-size: 14px;
}

.component-page-kresult-kpi-name {
    color: rgb(29, 29, 29);
    cursor: pointer;
}

.component-page-kresult-kpi-name:hover {
    text-decoration: underline;
}

.component-page-kresult-kpi-subtitle{
    color: grey;
    font-size: 12px;
    margin-top: 8px;
}

.component-page-kresult-kpi-update-button {
    background-color: #50607D;
    color: white;
    margin-top: 16px;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.component-page-kresult-kpi-update-button svg {
    font-size: 18px;
}

.component-page-initiative-subview-navigation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 16px; */
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.component-page-initiative-sidebar-outer {
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 100%;
}

.component-page-initiative-sidebar-section {

}

.component-page-initiative-sidebar-section-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.component-subview-tab-initiative:first-of-type {
    text-align: left;
    padding-left: 0px;
}

.component-subview-tab-initiative {
    font-weight: 700;
    transition: all 0.1s;
    text-align: center;
    color: #8791A3;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 3px solid #cacdcf;
    font-size: 16px;
    /* text-transform: uppercase; */
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    /* letter-spacing: 0.5px; */
}

.component-subview-tab-initiative-count {
    font-size: 14px;
    font-weight: 500;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgb(222, 222, 222);
}

.component-subview-tab-initiative-filler {
    flex-grow: 1;
    color: transparent;
    cursor: unset;
}

.component-subview-tab-initiative-selected {
    color: #344563;
    border-bottom: 3px solid #2A73AE;
}

.component-page-tab {
    transition: all 0.2s;
    padding: 4px;
    border-bottom: 2px solid transparent;
    text-align: center;
    cursor: pointer;
}

.component-page-tab:hover {
    border-bottom: 2px solid rgb(23, 64, 97);
}

.component-page-tab-selected {
    border-bottom: 2px solid rgb(23, 64, 97);
}

.component-page-task-tab-section-inner {
    display: flex;
    flex-direction: column;
    gap: 3em;
    padding-bottom: 2em;
}

.component-page-initiative-summary-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    /* padding-bottom: 32px; */
    margin-bottom: 24px;
}

.component-page-initiative-alignments-section-container-shadows {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding: 20px 14px;
    border-radius: 6px;
    margin-bottom: 24px;
    /* background-color: rgb(234, 238, 245); */
    background-color: rgba(0, 0, 0, 0.015);
}

.component-page-initiative-summary-section-container-width-control {
    width: 50%;
}

.component-page-initiative-summary-item-description-container {
    /* flex-basis: 100%; */
    width: 100%;
}

.component-page-initiative-summary-item-container {
    border: 0.5px solid #384A6B;
    background-color: white;
    flex-basis: 30%;
    padding: 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.component-page-initiative-summary-item-title {
    font-size: 16px;
    color: #50607D;
    font-weight: 600;
}

.component-page-initiative-summary-item-value {
    font-size: 48px;
    font-weight: 600;
}

.component-page-initiative-prog-overview-subtitle {
    font-size: 14px;
    font-weight: 600;
    color: #4F5D74;
    margin-bottom: 12px;
    margin-top: 32px;
    text-transform: uppercase;
}

.component-page-initiative-sidebar-section .component-page-initiative-prog-overview-subtitle {
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
    color: grey;
    margin-bottom: 0px;
}

.component-page-initiative-prog-overview-subtitle:first-of-type {
    margin-top: 0px;
}

.component-page-initiative-prog-overview-subtitle-description {
    font-size: 14px;
    color: grey;
}

.workcomp-page-main-display-split-secondary {
    display: flex;
    align-items: flex-start;
    gap: 36px;
    justify-content: space-between;
    width: 100%;
}

.workcomp-page-main-initiative-boxes {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.workcomp-page-main-initiative-boxes div {
    min-height: 40px;
    min-width: 40px;
    background-color: red;
}

.component-page-initiative-prog-overview-dates-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #525252; */
    background-color: #7c7c7c;
    color: white;
    font-weight: 500;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 12px;
    border-radius: 16px;
    position: relative;
    margin-top: 8px;
    margin-bottom: 0px;
}

.component-page-initiative-prog-overview-date-value {
    z-index: 15;
    flex-basis: 50%;
    position: relative;
    cursor: pointer;
}

.component-page-initiative-prog-overview-date-value:last-of-type {
    text-align: right;
}

.component-page-initiative-prog-overview-dates-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    width: 0%;
    border-radius: 16px;
    /* background-color: #9e9e9e; */
    /* background-color: #9F6ADB; */
    /* background-color: #6078c7; */
    background-color: #2A73AE;
    transition: all 0.5s;
}

.component-page-initiative-prog-overview-overdue-task-notice {
    font-weight: 700;
    color: #b91d1d !important;
    cursor: pointer;
}

.component-page-initiative-prog-overview-overdue-task-notice svg {
    color: #b91d1d !important;
}

.component-page-initiative-prog-overview-overdue-task-notice:hover {
    text-decoration: underline;
}

.component-page-initiative-summary-link-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12px;  
}

.component-page-initiative-summary-link-text div {
    font-size: 12px;
    text-decoration: underline;
    color: grey;
    cursor: pointer;
}

.component-page-initiative-summary-items-bottom-align .workspace-panel-setting-dates {
    margin: 0px;
}

.component-page-initiative-summary-subtitle-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 13px;
    margin-top: 8px;
}

.component-page-initiative-summary-subtitle-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 36px;
}

.component-page-initiative-alignments-section-container {
    width: 100%;
    box-sizing: border-box;
}

.component-page-initiative-section-heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}

.component-page-initiative-section-subheading {
    font-size: 14px;
    font-weight: 400;
    color: grey;
    margin-top: 12px;
}

.workspace-comp-initiative-alignment-item-t-empty-container {
    margin-top: 12px;
    border: 1px solid rgb(110, 110, 110);
    color: #1A689F;
    padding: 24px 16px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s;
}

.workspace-comp-initiative-alignment-item-t-empty-container:hover {
    background-color: rgb(240, 240, 240);
}

.component-page-initiative-section-alignment-item-empty {
    font-size: 16px;
    font-weight: 600;
    color: grey;
    margin-top: 12px;
}

.workspace-comp-initiative-alignment-item-t-empty-icon {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.workspace-comp-initiative-alignment-item-t-empty-display-name {
    font-weight: 600;
}

.component-page-intiative-section-alignment-item-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgb(201, 201, 201);
}

.component-page-initiative-section-aligment-resource-container {
    gap: 0px;
    margin-top: 12px;
}

.component-page-initiative-searchable-resources-table {

}

.component-page-initiative-searchable-resources-table-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(209, 209, 209);
}

.component-page-initiative-searchable-resources-table-item {
    border-right: 1px solid rgb(209, 209, 209);
    padding-left: 12px;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.component-page-initiative-searchable-resources-table-item:first-child {
    padding-left: 0;
}

.component-page-initiative-searchable-resources-table-item:last-child {
    border-right: none;
}

.component-page-initiative-searchable-resources-table-row-header .component-page-initiative-searchable-resources-table-item {
    color: grey;
    font-weight: 500;
    font-size: 14px;
}

.comp-pg-init-srchble-rsrcs-tbl-itm-lg {
    flex-basis: 38%;
}

.comp-pg-init-srchble-rsrcs-tbl-itm-sm {
    flex-basis: 12%;
}

.component-page-initiative-searchable-resources-table-item-resource-name {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.component-page-initiative-searchable-resources-table-item-resource-name a {
    text-decoration: underline;
    text-decoration-color: rgb(172, 172, 172);
    text-decoration-style: dashed;
    text-underline-offset: 2px;
    cursor: pointer;
    margin: 0;
}

.component-page-initiative-searchable-resources-table-item .file {
    cursor: pointer;
}

.component-page-initiative-searchable-resources-table-item .file:hover {
    text-decoration: underline;
}

.component-page-initiative-searchable-resources-table-item-resource-edit-button {
    display: none;
    position: absolute;
    right: 2%;
    top: calc(50% - 10px);
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 2px 16px;
    height: 14px;
    border: 1px solid rgb(207, 207, 207);
    cursor: pointer;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    transition: all 0.2s;
}

.component-page-initiative-searchable-resources-table-item-resource-edit-button:hover {
    background-color: rgb(245, 245, 245);
}

.component-page-initiative-searchable-resources-table-item-resource-name:hover .component-page-initiative-searchable-resources-table-item-resource-edit-button {
    display: block;
}

.comp-pg-alignment-resource-destination-update-container {
    position: absolute;
    background-color: white;
    z-index: 3;
    bottom: 120%;
    border-radius: 4px;
    border: 1px solid rgb(235, 235, 235);
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.comp-pg-alignment-resource-destination-update-container p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}

.comp-pg-alignment-resource-destination-update-container input {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
}

.comp-pg-alignment-resource-destination-update-copy-link-button {
    margin-top: 6px;
    padding: 4px;
    border: 1px solid grey;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}

.component-page-initiative-searchable-resources-table-item-new-container {
    position: relative;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: all 0.1s;
    cursor: pointer;
}

.component-page-initiative-searchable-resources-table-item-new-container p {
    color: #2A73AE;
    margin: 0px;
    text-decoration: underline;
}

.component-page-initiative-searchable-resources-table-item-new-container:hover {
    background-color: rgb(233, 233, 233);
}

.component-page-initiative-searchable-resources-table-item-resource-manage-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.component-page-initiative-searchable-resources-table-item-resource-manage-button svg {
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.1s;
}

.component-page-initiative-searchable-resources-table-item-resource-manage-button svg:hover {
    background-color: rgb(216, 216, 216);
}

.component-page-initiative-searchable-resources-table-item-resource-manage-menu {
    position: absolute;
    top: 80%;
    right: 10%;
    width: 160px;
    background-color: white;
    z-index: 3;
    border-radius: 4px;
    border: 1px solid rgb(235, 235, 235);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.component-page-initiative-searchable-resources-table-item-resource-manage-menu div {
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.component-page-initiative-searchable-resources-table-item-resource-manage-menu div:hover {
    background-color: rgb(245, 245, 245);
}

.workspace-comp-initiative-alignment-item-t-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.workspace-comp-initiative-alignment-t-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.workspace-comp-initiative-alignment-t-button-remove {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    padding: 4px;
    border-radius: 4px;
}

.workspace-comp-initiative-alignment-t-button-remove:hover {
    background-color: rgb(243, 221, 221);
}

.workspace-comp-initiative-alignment-item-t-icon {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    min-width: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border: 2px solid rgb(182, 182, 182);
    background-color: rgb(230, 230, 230);
}

.workspace-comp-initiative-alignment-item-t-overflow-control {
    overflow: hidden;
    padding-right: 16px;
    max-width: 480px;
    text-overflow: ellipsis;
}

.workspace-comp-initiative-alignment-item-t-display-name {
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.workspace-comp-initiative-alignment-item-t-status {
    font-size: 12px;
    font-weight: 500;
    color: green;
}

.workspace-comp-initiative-alignment-item-t-display-name span {
    font-size: 12px;
    color: grey;
    font-weight: 400;
}

.workspace-comp-initiative-alignment-item-t-display-name span:hover {
    text-decoration: none;
    cursor: unset;
} 

.workspace-comp-initiative-alignment-item-t-display-name:hover {
    text-decoration: underline;
}

.component-page-intiative-section-measurements-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.component-page-initiative-section-heading-row {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}

.component-page-initiative-section-heading-action-buttons-container {
    position: relative;
}

.component-page-initiative-section-heading-action-buttons-container-empty-state {
    padding-right: 16px;
    margin-top: 12px;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
}

.component-page-initiative-section-heading-action-button-menu {
    position: absolute;
    background-color: #F6F7F9;
    top: 100%;
    color: #4F5D74;
    font-size: 14px;
    font-weight: 500;
    z-index: 2;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 200px;
    box-shadow: 0px 4px 4px rgba(163, 163, 163, 0.25);
}

.component-page-initiative-section-heading-action-button-menu-opt {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    transition: all 0.1s;
}

.component-page-initiative-section-heading-action-button-menu-opt:hover {
    background-color: #eceef1;
}

.component-page-initiative-section-heading-action-button {
    font-size: 16px;
    font-weight: 600;
    /* color: rgb(78, 78, 78); */
    color: #2A73AE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.component-page-initiative-section-heading-action-button:hover {
    background-color: rgb(226, 226, 226);
}

.workspace-component-initiative-key-measurement-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
}

.workspace-component-initiative-key-measurement-text-value {
    font-size: 20px;
    font-weight: 600;
}

.workspace-component-initiative-key-measurement-text-title {
    color: grey;
    font-size: 13px;
}

.workspace-component-initiative-key-measurement-nav {
    font-size: 20px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.workspace-component-initiative-key-measurement-nav:hover {
    background-color: rgb(211, 211, 211);
}

.workcomp-main-section-biglittle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 12px;
    padding-right: 12px;
}

.workcomp-main-section-biglittle-big {
    flex-basis: 100%;
}

.workcomp-main-section-biglittle-little {
    flex-basis: 0%;
    display: none;
}

.workcomp-main-header {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    /* margin-bottom: 16px; */
}

.workcomp-main-header-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    color: black;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
}

.workcomp-main-header-actions svg {
    height: 18px;
    width: 18px;
    color: rgb(83, 83, 83);
}

.workcomp-main-header-action:hover {
    background-color: rgb(228, 228, 228);
}

.workcomp-page-component-type {
    color: grey;
    font-size: 16px;
}

.workcomp-page-row-centered {
    /* A basic row that aligns items centered */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.workcomp-page-row-vertical-divider {
    border-right: 2px solid rgb(199,199,199);
}

.workcomp-main-header h1 {
    margin: 0px;
    font-weight: 800;
    font-size: 1.5em;
    color: #222222;
    margin-bottom: 8px;
}

.workcomp-main-header-subtitle {
    font-size: 12px;
    margin: 0px;
    color: grey;
}

.workcomp-date-overdue-circle {
    content: " ";
    height: 12px;
    width: 12px;
    background-color: rgb(201, 59, 59);
    border-radius: 50%;
}

.workcomp-sm-colored-circle {
    content: " ";
    height: 12px;
    width: 12px;
    background-color: rgb(201, 59, 59);
    border-radius: 50%;
}

.workcomp-sm-colored-circle-active {
    background-color: #2973af;
}

.workcomp-sm-colored-circle-archived {
    background-color: rgb(255, 140, 105);
}

.workcomp-sm-colored-circle-completed {
    background-color: #049921;
}

.workcomp-date-incomplete-circle {
    content: " ";
    height: 12px;
    width: 12px;
    background-color: rgb(245, 228, 0);
    border-radius: 50%;
}

.workcomp-main-action-button {
    background-color: rgb(31, 31, 31);
    padding: 12px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: white;
    transition: all 0.3s;
}

.workcomp-main-action-button:hover {
    background-color: rgb(54, 54, 54);
}

.workcomp-main-highlight {
    cursor: pointer;
    border-radius: 16px;
    padding: 4px;
    transition: all 0.3s;
    min-width: 220px;
}

.workcomp-main-highlight:hover {
    background-color: rgb(238, 238, 238);
}

.workcomp-main-highlight-circle {
    height: 56px;
    width: 56px;
    margin-right: 12px;
    /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; */
}

.workcomp-main-highlight-circle-blue {
   color: #3c89d1;
   font-size: 24px;
   background-color: #3c89d149;
}

.workcomp-main-highlight-circle-red {
    color: #d13c3c;
    font-size: 24px;
    background-color: #d13c3c49;
}

.workcomp-main-highlight-title {
    font-size: 16px;
    font-weight: 600;
}

.workcomp-main-highlight-subtitle {
    color: grey;
    font-size: 12px;
}

.workcomp-main-heading {
    margin: 0px;
    padding-bottom: 12px;
    font-weight: 700;
    font-size: 16px;
    color: rgb(0, 0, 0);
}

.workcomp-main-side-section {
    margin-bottom: 24px;
    /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; */
    padding: 12px 12px;
    border-radius: 16px 0px 16px 0px;
}

.workcomp-main-side-section-header {
    margin-top: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgb(65, 65, 65);
}

.workcomp-main-side-section-blue {
    background-color: #0084ff15;
}

.workcomp-main-side-section-purple {
    background-color: #bd00d611;
}

.workcomp-main-comments {
    margin-top: 32px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.workcomp-main-comments-publish {
    align-self: flex-end;
    padding: 8px 12px;
    background-color: rgb(32, 32, 32);
    color: white;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
}

.workcomp-main-comments-publish:hover {
    background-color: rgb(121, 121, 121);
}

/* ******************************************/
/* Workspace Components (workcomp) Elements */
/* **************************************** */
    /* Projects (previously, Initiatives) */
.workcomp-initiative-alignments {
    /* background-color: rgba(48, 48, 48, 0.041); */
    padding-top: 1em;
    /* border-radius: 16px 0px 16px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; */
}

.workcomp-initiative-alignment-component-name {
   font-size: 14px;
   font-weight: 600;
   margin-bottom: 4px; 
}

.workcomp-initiative-alignment-component-type {
    font-size: 12px;
    text-transform: uppercase;
    color: grey;
    margin: 0px;
}

.workcomp-initiative-alignment-status {
    font-size: 12px;
    text-transform: uppercase;
    color: black;
    font-weight: 700;
}

.workcomp-initiative-alignment-options-button {
    font-size: 20px;
    cursor: pointer;
}

.workcomp-initiative-assignments-section-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.workcomp-initiative-assignments-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
    color:rgb(21, 89, 167);
    margin-bottom: 8px;
}

.workcomp-initiative-assignments-section-header svg {
    cursor: pointer;
    font-size: 24px;
}

.workcomp-initiative-assignments-table-container {
    
}

.table-assignment-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.table-assignment-row-data-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.table-assignment-column,
.table-assignment-column-header {
    padding: 4px;
    margin: 2px;
    box-sizing: border-box;
}

.table-assignment-column {
    background-color: rgba(241, 241, 241, 0.603);
    overflow: hidden;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.table-assignment-column-header {
    text-align: center;
    font-weight: 600;
    color: grey;
    font-size: 14px;
}

.table-assignment-col-centered {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.table-assignment-clickable-name {
    cursor: pointer;
    transition: all 0.2s;
}

.table-assignment-clickable-name:hover {
    background-color: rgba(241, 241, 241, 0.699);
}

.table-assignment-column-overflow-stop-outer {
    position: relative;
    box-sizing: border-box;
}

.table-assignment-column-overflow-stop {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 12px;
}

.table-assignment-col-text-heavy {
    font-weight: 600;
}

.table-assignment-column-display-name {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
}

.table-assignment-col-relative-wrapper {
    position: relative;
    overflow: unset;
    transition: all 0.1s;
}

.table-assignment-col-date-box-picker-date-displayed {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.table-assignment-col-date-box-picker-container {
    position: absolute;
    box-sizing: border-box;
    top: 100%;
    z-index: 202;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.table-assignment-col-owner-picker-container {
    position: absolute;
    text-align: left;
    box-sizing: border-box;
    background-color: white;
    width: 240px;
    top: 100%;
    right: 0;
    z-index: 202;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


.table-assignment-col-date-box-picker-buttons {
    width: 100%;
    background-color: white;
    padding: 12px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    box-sizing: border-box;
}

.table-assignment-col-date-box-picker-buttons div {
    flex-basis: 40%;
    border: 1px solid rgb(238, 238, 238);
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
}

.table-assignment-col-date-box-picker-buttons div:hover {
    background-color: rgb(231, 231, 231)
}

.table-assignment-column-2 {
    flex-basis: 50%;
}

.table-assignment-column-3 {
    flex-basis: 33%;
}

.table-assignment-column-4 {
    flex-basis: 25%;
}

.table-assignment-column-5 {
    flex-basis: 20%;
}

.table-assignment-column-md {
    flex-basis: 15%;
}

.table-assignment-column-sm {
    flex-basis: 10%;
}

.table-assignment-column-max {
    flex-grow: 1;
}

.workcomp-alignment-add-marker-button {
    cursor: pointer;
    font-size: 14px;
    background-color: #2973af;
    color: white;
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 600;
}

.workcomp-alignment-marker-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2em;
    box-sizing: border-box;
}

.workcomp-alignment-marker-container-goal {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    flex-basis: 30%;
    min-width: 200px;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;
}

.workcomp-alignment-marker-container-kpi {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    width: 180px;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;
}

.workcomp-alignment-marker-container-goal:hover {
    background-color: rgb(241, 250, 255);
}

.workcomp-alignment-marker-information-container {
    overflow-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
}

.workcomp-alignment-marker-displayname {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
}

.workcomp-alignment-marker-type {
    font-size: 12px;
    text-transform: uppercase;
    color: grey;
}

.workcomp-alignment-marker-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    max-width: 200px;
}

.workcomp-alignment-marker-measurement-value {
    align-self: center;
    font-size: 16px;
}

.workcomp-alignment-marker-actions {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 8px;
    margin-top: 8px;
    display: none;
    z-index: 2;
    font-size: 16px;
    cursor: pointer;
}

.workcomp-alignment-marker-container-goal:hover .workcomp-alignment-marker-actions,
.workcomp-alignment-marker-container-kpi:hover .workcomp-alignment-marker-actions {
    display: block;
}

    /* Goals */
.workcomp-goal-progress {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 24px;
    margin-bottom: 32px;
}

.workcomp-goal-progress h2 {
    font-size: 14px;
    margin: 0px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.workcomp-goal-progress span {
    color: rgb(160, 160, 160);
}

.workcomp-goal-description {
    min-height: 240px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
}

.workcomp-goal-description h3 {
    margin: 0px;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 16px;
    color: rgb(63, 63, 63);
}

.workcomp-goal-header-link {
    margin: 0px;
    padding-bottom: 12px;
    color:rgb(23, 64, 97);
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.workcomp-goal-marker-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

/* ******** */
/* Hashtags */
/* ******** */
.hashtag-outer {
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 106, 211);
    cursor: pointer;
}

.workcomp-page-hashtag-create {
    color: rgb(59, 59, 59);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
}

.hashtag-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

/* ****** */
/* Lists  */
/* ****** */
.component-list-control-bar {
    display: flex;
    border-bottom: 2px solid #F2F2F2;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 36px;
    margin-bottom: 32px;
}

.component-list-tasks-navigation {
   box-sizing: border-box;
   display: flex;
   align-items: flex-end;
   gap: 90px;
}

.component-list-tasks-navigation span.component-list-tasks-navigation-selected {
    border-bottom: 6px solid #3D72AA;
}

.component-list-tasks-navigation span {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 4px;
    cursor: pointer;
    border-bottom: 6px solid transparent;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-width: 112px;
    gap: 20px;
}

.component-workstream-responsive {
    display: none;
}

.component-list-tasks-navigation span div {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.component-list-controls-misc {
    display: flex;
    align-items: flex-end;
    gap: 36px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.component-list-controls-sort-container{
    position: relative;
}

.component-list-controls-misc-symbol-button {
    width: 40px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    border-radius: 24px;
    background-color: #e7e7e7;
    cursor: pointer;
}

.component-list-controls-misc-symbol-button.component-list-controls-misc-symbol-button-selected {
    background-color: #3D72AA;
}

.component-list-controls-misc-symbol-button svg {
    font-size: 16px;
    color: rgb(66, 66, 66);
}

.component-list-controls-misc-symbol-button-selected svg {
    color: white;
}

.component-list-tasks-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 60px;
}

.component-list-task-container {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    /* border: 1px solid #E6E8F3; */
    /* background-color: #F6F7FB; */
    border-radius: 6px;
    padding: 16px 14px 18px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;

    /* border: 3px solid rgb(252, 252, 252); */
    background-color: #ffffff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.component-list-task-container-section-summary {
    flex-basis: 70%;
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: hidden;
}

.component-list-task-summary-titles {
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
}

.component-list-task-summary-titles h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    line-height: 18px;
    color: #344563;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
}

.component-list-task-summary-titles p {
    margin: 0;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    color: #344563;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
}

@media screen and (min-width: 800px) {
    .component-list-task-summary-titles h3, .component-list-task-summary-titles p { max-width: 240px;}
}

@media screen and (min-width: 1000px) {
    .component-list-task-summary-titles h3, .component-list-task-summary-titles p { max-width: 400px;}
}

@media screen and (min-width: 1200px) {
    .component-list-task-summary-titles h3, .component-list-task-summary-titles p { max-width: 600px;}
}

.component-list-task-action-circle {
    min-width: 18px;
    min-height: 18px;
    height: 18px;
    width: 18px;
    border: 1px solid #C8CBD7;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}

.component-list-task-action-circle:hover {
    background-color: rgba(17, 109, 145, 0.072);
}

.component-list-task-container-section-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    flex-basis: 30%;
}

.component-list-task-section-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.component-list-task-section-meta svg {
    color: #3D72AA;
}

.component-list-task-section-meta span {
    font-size: 10px;
    font-weight: 600;
    color: #344563;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.component-list-empty-text {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #344563;
}

.component-list-empty-text svg {
    margin-bottom: 24px;
    color: #0092c71a;
    font-size: 80px;
}

.component-board-column-options-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    min-width: 32px;
    height: 20px;
    transition: all 0.2s;
}

.component-board-column-options-symbol:hover,
.component-board-column-options-symbol-active {
    background-color: rgb(222, 222, 222);
}

/* ************************* */
/* Structural Component View */
/* ************************* */

.comp-view-flex-container {
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.comp-view-flex-container-board {
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.comp-view-main {
    /* padding-top: 12px; */
    flex-grow: 1;
    max-width: 100%;
    /* padding-right: 100px; THIS APPEARED TO BE MAKING PAGES UNNECESSARILY WIDE BUT MAY HAVE BEEN HERE FOR A REASON... */
    position: relative;
    /* margin-bottom: 20px; */
}

.comp-view-right-sidebar {
    margin-left: 12px;
    margin-top: 0px;
    border-top: 0px;
    padding-top: 24px;
    width: 220px;
}

.component-view-title-nav-text {
    font-size: 10px;
    margin-bottom: 8px;
}

/* ************************ */
/* Component Side Pane View */
/* ************************ */
.component-sidepane-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    border-radius: 4px;
    transition: all 0.2s;
}

.component-sidepane-container:hover {
    background-color: rgba(236, 236, 236, 0.548);
    cursor: pointer;
}

.component-sidepane-info {
    margin-left: 16px;
}

.component-sidepane-moji {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.component-sidepane-info-heading-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
}

.component-sidepane-info-heading-container p {
    margin-top: 0px;
    margin-bottom: 0px;
    color: grey;
    font-size: 10px;
}

.component-sidepane-info-heading-container p:first-of-type {
    margin-right: 8px;
}

.component-sidepane-info-value {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: pre-wrap;
}

/* ********************** */
/* Workspace Options Pane */
/* ********************** */
.workspace-options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
    padding-right: 8px;
    box-sizing: border-box;
    border: 1px solid rgb(199, 199, 199);
    background-color: white;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 12px;
    padding-top: 4px;
    border-radius: 4px;
}

.workspace-options-container h2 {
    font-size: 20px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.514);
}

.workspace-option-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 12px;
    padding-top: 12px;
}

.workspace-option-value {
    text-transform: capitalize;
    padding-left: 12px;
    box-sizing: border-box;
    white-space: pre-wrap;
    text-align: right;
    color:rgb(27, 27, 27);
}

.workspace-option-group p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
}

.workspace-option-group p:first-of-type {
    font-weight: bold;
}

.workspace-option-button-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.workspace-option-button-edit {
    width: 100%;
    text-align: center;
    background-color: rgb(53, 53, 53);
    color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: all 0.2s;
    margin-bottom: 12px;
    margin-top: 12px;
}

.workspace-option-button-save {
    width: 100%;
    text-align: center;
    background-color: rgb(23, 64, 97);
    color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: all 0.2s;
    margin-bottom: 12px;
    margin-top: 12px;
}

.workspace-option-button-edit:hover {
    background-color: rgb(146, 146, 146);
    cursor: pointer;
}

.workspace-option-button-save:hover {
    background-color: rgb(12, 24, 34);
    cursor: pointer;
}

.workspace-option-button-delete {
    color: rgb(136, 40, 40);
    transition: all 0.2s;
}

.workspace-option-button-cancel {
    color: darkgrey;
    transition: all 0.2s;
}

.workspace-option-button-delete:hover,
.workspace-option-button-cancel:hover {
    text-decoration: underline;
    color: black;
    cursor: pointer;
}

.workspace-option-edit-dropdown,
.workspace-option-edit-date,
.workspace-option-edit-text {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    border: none;
}

.workspace-option-edit-text {
    text-align: right;
    width: 100%;
    margin-left: 12px;
    box-sizing: border-box;
}

.workspace-option-edit-textarea {
    text-align: right;
    width: 100% !important;
    margin-left: 12px;
    font-size: 12px;
    box-sizing: border-box;
    resize: none;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.workspace-option-edit-date {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
}

/************* MAIN COMPONENT PAGES **************/
.component-view-goal-main-container,
.component-view-main-container {
    width: 100%;
}

.component-view-main-container {
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
}

.component-view-goal-progress-pie {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid rgb(199, 199, 199);
    background-color: white;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    margin-top: 24px;
}

.component-view-card-summary-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px 24px;
}

.dashboard-view-dive-heading {
    font-size: 1.5em;
    font-weight: 600;
}

.dashboard-view-dive-subheading {
    color: grey;
    font-size: 1em;
}

.dashboard-view-card-summary-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px 24px;
    margin-bottom: 24px;
    align-items: flex-start;
}

.dashboard-calendar-container {
    position: relative;
    width: 100%;
}

.dashboard-calendar-container-absolute-dark {
    background-color: #FAFAFA;
}

.component-view-page-separator {
    border-top: 1px solid rgb(199, 199, 199);
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.component-view-summary-card {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 12px;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid rgb(199, 199, 199);
    background-color: white;
    width: 300px;
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-top: 12px;
    overflow-y: auto;
}

.component-view-summary-card-borderless {
    box-shadow: unset;
    border: none;
}

.component-view-summary-card-lg {
    width: unset;
    flex-basis: 60%;
}

.component-view-summary-card-sm {
    width: unset;
    flex-basis: 35%;
}

.dashboard-view-summary-card {
    font-size: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 12px;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid rgb(199, 199, 199);
    background-color: white;
    width: 300px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    overflow-y: auto;
}

.component-view-summary-card-doublewide {
    width: 600px;
}

.component-view-summary-card-expand-y {
    height: auto;
}

.dashboard-view-summary-card-doublewide {
    width: 45%;
}

.component-view-card-footer {
    position: absolute;
    bottom: 0;
    height: 20px;
    font-size: 12px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    border-top: 1px solid rgb(199,199,199);
    padding-top: 4px;
    padding-bottom: 4px;
    gap: 8px;
    transition: all 0.2s;
}

.component-view-card-footer:hover {
    background-color: rgb(237, 237, 237);
    cursor: pointer;
}

.component-view-goal-markers-table-container,
.component-view-main-markers-table-container {
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    background-color: white;
    margin-top: 24px;
    box-sizing: border-box;
    /* padding-right: 1em;
    padding-bo */
    border-radius: 4px;
    /* border: 1px solid rgb(199, 199, 199); */
    width: 100%;
}

.component-view-goal-markers-table-header,
.component-view-main-markers-table-header {
    margin-bottom: 12px;
}

.component-view-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.component-view-card-header-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
}

.component-view-card-header-extra-info {
    font-size: 14px;
    color: grey;
}

.component-view-card-header-subtitle {
    color: grey;
    font-size: 12px;
}

.covica-centered-data {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.covica-centered-data-vh {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.covica-header-subtitle {
    color: rgb(157, 157, 157);
    font-size: 14px;
}

.covica-text-data-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-top: 12px; */
    margin-bottom: 4px;
    width: 100%;
    white-space: pre-wrap;
    transition: all 0.2s;
}

.covica-text-data-column-editable-border {
    border-radius: 4px;
    transition: all 0.2s;
}

.covica-text-data-column-editable-description-static {
    white-space: pre-wrap;
    font-size: 14px;
}

.covica-text-data-column-editable-border textarea {
    width: 100% !important;
    border: none;
    overflow: hidden;
    outline: none;
    resize: none;
    box-sizing: border-box;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    background-color: transparent;
}

.covica-text-data-column-editable-border p {
    padding-bottom: 1em;
    color: black;
}

.dashboard-table-entry-summary-data,
.covica-item-recent-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dashboard-table-entry-summary-data-right-align {
    align-items: flex-end;
}

.dashboard-table-entry-summary-data h5,
.covica-item-recent-header-container h5 {
    font-weight: 600;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-bottom: 2px;
    margin-top: 0px;
}

.dashboard-table-entry-summary-data p,
.covica-item-recent-header-container p {
    color: grey;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    text-transform: capitalize;
}

.covica-text-data-column-clickable:hover {
    background-color: rgb(242, 242, 242);
    cursor: pointer;
}

.covica-text-data-column h5 {
    margin-bottom: 4px;
    margin-top: 0px;
    font-weight: 600;
    font-size: 14px;
}

.covica-text-data-column p {
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
}

.covica-text-data-column .covica-text-data-column-full {
    width: 100%;
}

.covica-column-recents {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}

.covica-item-recent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.covica-square {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    background-color: grey;
}

.covica-square-status-active {
    background-color: #2973af;
}

.covica-square-status-completed {
    background-color: #28a745;
}

.covica-square-status-archived {
    background-color: #fc636b;
}

.component-view-card-menu-ellipsis {
    font-size: 14px;
    font-weight: 300;
    padding: 8px;
    border-radius: 16px;
    transition: all 0.2s;
}

.component-view-card-header:hover .component-view-card-menu-edit {
    visibility: visible;
    opacity: 1;
}

.component-view-card-menu-edit {
    font-size: 14px;
    font-weight: 300;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.3s;
}

.component-view-card-menu-edit:hover {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
}

.component-view-card-menu-ellipsis:hover {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
}

.component-view-goal-markers-table-container h2,
.component-view-main-markers-table-container h2,
.component-view-goal-progress-pie h2,
.component-view-phase-progress-pie h2,
/* .component-view-card-header h2, */
.covica-text-data-column-heading {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.9em;
    font-weight: 600;
    /* color: grey; */
    text-align: left;
}

.component-view-card-header h2 {
    font-size: 20px;
    border-bottom: 1px solid grey;
    width: 100%;
    margin-bottom: 12px !important;
}

.component-view-goal-progress-pie h2,
.component-view-phase-progress-pie h2 {
    align-self: flex-start;
}

.component-view-goal-marker-button {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.component-view-goal-marker-new {
    margin-top: 16px;
}

.component-view-goal-marker-button span {
    padding: 4px 12px;
    color: white;
    font-size: 14px;
    background-color: rgb(23, 64, 97);
    border-radius: 4px;
    transition: all 0.2s;
}

.component-view-goal-marker-button span:hover {
    cursor: pointer;
    background-color: rgb(57, 109, 151);
}

.component-view-goal-marker-remove:hover {
    cursor: pointer;
}

/********** GOAL *********/
.component-view-goal-summary-completion-section {
    width: 65%;
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.component-view-goal-summary-goal-markers-section {
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.component-view-goal-summary-completion-section h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 0px;
    color: rgb(46, 46, 46);
}

.component-view-goal-summary-goal-markers-section h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgb(46, 46, 46);
}

.component-view-goal-summary-completion-section h2 span {
    color: rgb(78, 147, 204);
}

.component-view-goal-summary-completion-section-subtitle {
    color: grey;
    font-size: 16px;
    font-weight: 600;
}

.component-view-goal-marker-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
}

.component-view-goal-marker-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.component-view-goal-marker-container h3 {
    margin: 0px;
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 700;
    transition: all 0.3s;
}

.component-view-goal-marker-container h3:hover {
    text-decoration: underline;
}

.component-view-goal-marker-header-type {
    color: grey;
    text-transform: uppercase;
    font-size: 13px;
}

.component-view-goal-marker-header-acro {
    font-size: 12px;
    color: rgb(136, 15, 160);
    margin-left: 4px;
}

.component-view-goal-marker-header-trash {
    font-size: 14px;
    margin-left: 4em;
    color: grey;
    transition: all 0.2s;
    padding: 2px 4px;
    opacity: 0;
    transition: opacity 0.2s;
}

.component-view-goal-marker-container:hover .component-view-goal-marker-header-trash {
    opacity: 1;
}

.component-view-goal-marker-header-trash:hover {
    color: rgb(56, 56, 56);
}

.component-view-goal-marker-metric-information-progress {
    font-size: 20px;
    color: rgb(56, 56, 56);
    font-weight: 700;
}

.component-view-goal-marker-metric-information-goal {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
}

.component-view-goal-marker-metric-information-initial {
    font-size: 14px;
    color: grey;
    font-style: italic;
    font-weight: 600;
}

.component-view-goal-marker-metric-information-data-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    color: grey;
    font-weight: 600;
}
/********* TASK **********/

.component-view-task-container {
    background-color: white;
    margin-top: 24px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid grey;
}

.component-view-task-container h2 {
    margin-top: 0px;
    margin-bottom: 24px;
    font-size: 20px;
    text-align: left;
}

.component-view-task-details-text {
    border-radius: 4px;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px;
    padding: 4px;
}

.component-view-task-section-title {
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 20px;
    margin-top: 48px;
    border-bottom: 1px solid grey;
}

.component-view-task-section-title:first-of-type {
    margin-top: 0px;
}

.component-view-task-details-display {
    white-space: pre-wrap;
    min-height: 140px;
    border: 1px solid transparent;
    box-sizing: border-box;
}

.component-view-task-details-display:hover {
    border: 1px solid rgba(78, 78, 78, 0.589);
}

.component-view-task-container textarea {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    min-height: 140px;
    border: 1px solid rgba(78, 78, 78, 0.589);
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    resize: none;
    outline: none;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.component-view-task-complete-button {
    width: 100%;
    color: white;
    background-color: rgb(15, 133, 48);
    border-radius: 4px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 32px;
    margin-bottom: 12px;
    transition: all 0.2s;
}

.component-view-task-complete-button:hover {
    cursor: pointer;
    background-color: rgb(12, 63, 26);;
}

.component-view-task-delete-button,
.component-view-task-completed {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.component-view-task-delete-button span {
    color: rgb(141, 26, 26);
}

.component-view-task-completed span {
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight: 700;
    color: rgb(15, 133, 48);
}

.component-view-task-delete-button span:hover {
    text-decoration: underline;
    cursor: pointer;
}

/********* PHASE **********/
.component-view-goal-phase-container {
    width: 100%;
}

.component-view-phase-progress-pie {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 24px;
    border-radius: 4px;
    border: 1px solid rgb(199, 199, 199);
    background-color: white;
}

.component-view-phase-progress-unstarted {
    width: 50%;
    box-sizing: border-box;
    border: 1px solid rgb(199, 199, 199);
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
    background-color: rgba(255, 217, 0, 0.22);
}

.component-view-phase-progress-unstarted-subtitle {
    font-weight: 500;
    margin-top: 12px;
    font-size: 14px;
}

.component-view-phase-main-container {
    background-color: white;
    margin-top: 24px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgb(199,199,199);
}

.component-view-phase-main-container h2 {
    margin-top: 0px;
    margin-bottom: 12px;
    font-size: 20px;
}

.component-view-phase-main-timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.component-view-phase-main-timeline-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 20px;
}

.component-view-phase-main-timeline-symbol {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    width: 24px;
    margin-right: 36px;
    margin-left: 8px;
}

.component-view-phase-main-timeline-symbol span {
    font-size: 12px;
    margin-top: 4px;
    font-weight: 600;
}

.component-view-phase-main-timeline-date {
    font-size: 20px;
    font-weight: 400;
}

.criteria-item-active {
    font-weight: 400;
}

.criteria-item-completed {
    text-decoration: line-through;
}

.criteria-item-editing {
    width: 100%;
    font-size: 14px;
    border: none;
    padding: 0.2em;
    box-sizing: border-box;
}

.criteria-item-list {
    max-height: 400px;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
}

.criteria-item {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    font-size: 14px;
    padding-right: 12px;
    flex-grow: 1;
}

.criteria-item-options-button {
    min-width: 32px;
    margin-left: 4px;
    color: grey;
    position: relative;
}

.criteria-item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.criteria-item-options-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    right: 0;
    gap: 12px;
    opacity: 0;
    background-color: white;
    transition: all 0.2s;
}

.criteria-item-row:hover .criteria-item-options-container {
    opacity: 1;
}

.criteria-item-row:hover .criteria-item-active {
    text-decoration: underline;
}

.criteria-item-options-option:hover {
    cursor: pointer;
}

.criteria-item-circle {
    min-height: 16px;
    min-width: 16px;
    /* border-radius: 50%; */
    border: 1px solid rgb(199,199,199);
    transition: all 0.2s;
}

.criteria-item-circle:hover {
    cursor: pointer;
}

.criteria-item-circle-completed,
.criteria-item-circle:hover {
    background-color: rgb(23, 64, 97);
    border: 1px solid rgb(23, 64, 97);
}

.criteria-item-new-item-container {
    margin-top: 1em;
    margin-bottom: 1em;
}

.criteria-item-new-item-container svg {
    color: grey;
}

.criteria-item-new-item-container input {
    width: 100%;
    font-size: 14px;
    flex-grow: 1;
    padding: 12px 16px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.2s;
    border: 1px solid rgb(199, 199, 199);
}

.component-comments-new-comment-container {
    border: 1px solid grey;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 12px;
}

.component-comments-new-comment-container textarea {
    border: none;
    padding: 4px;
    margin: 4px;
    overflow: hidden;
    outline: none;
    resize: none;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: black;
}

.component-comments-new-comment-publish {
    align-self: flex-end;
    margin: 8px;
    padding: 8px 12px;
    background-color: rgb(32, 32, 32);
    color: white;
    border-radius: 4px;
    transition: all 0.2s;
}

.component-comments-new-comment-publish:hover {
    cursor: pointer;
    background-color: rgb(121, 121, 121);
}

.component-comment-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
}

.component-comment-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 1em;
}

.component-comment-item-option {
    opacity: 0;
    transition: opacity 0.2s;
}

.component-comment-item-option:hover {
    cursor: pointer;
}

.component-comment-item:hover .component-comment-item-option {
    opacity: 1;
}

.component-comment-item-container {
    width: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
    display: block;
    white-space: pre-wrap;
    max-width: 100%;
    overflow: hidden;
}

.component-comment-item-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.component-comment-item-options-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    color: grey;
    font-size: 14px;
}

.component-comment-item-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
    margin-bottom: 8px;
}

.component-comment-item-header div {
    font-weight: 600;
    font-size: 14px;
}

.component-comment-item-header span {
    font-size: 12px;
    color: grey;
}

.component-comment-item-content {
    font-size: 14px;
    overflow-wrap: break-word;
    display: block;
    white-space: pre-wrap;
    max-width: 100%;
    overflow: hidden;
}

.component-comment-item-content a,
.modal-full-screen-task-edit-details-container a,
.workspace-board-card-content-expanded-container a,
.project-post-content-clickable,
.project-post-content a {
    color: #2A73AE;
    font-weight: 700;
    text-decoration: none;
}

.project-post-content-clickable {
    cursor: pointer;
}

.project-post-content-clickable:hover {
    text-decoration: underline;
}

.component-comment-item-content a:hover,
.modal-full-screen-task-edit-details-container a:hover,
.workspace-board-card-content-expanded-container a:hover,
.project-post-content a:hover {
    text-decoration: underline;
}

.component-comment-item-content-edit {
    width: 100%;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 4px;
    padding: 4px;
    margin: 0px;
    margin-right: 12px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    outline: none;
    resize: none;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: rgb(65, 65, 65);
}

.component-comment-item-content-edit-update {
    color:rgb(49, 113, 165);
    text-transform: uppercase;
    font-size: 12px;
}

.component-comment-item-content-edit-update:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* Context */
.context-frame-section {
    display: flex;
    flex-direction: column;
}

.context-frame-reports,
.context-frame-dependencies {
    margin-bottom: 12px;
    margin-top: 0px;
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    color: rgb(59, 59, 59);
    padding-bottom: 4px;
}

.context-frame-component-type-header-row {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-bottom: 12px;
    position: relative;
}

.context-frame-component-type-header-row svg {
    height: 20px;
    width: 20px;
}

.context-frame-component-type-header-row svg:hover {
    cursor: pointer;
}

.context-frame-component-type-header-title {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    color: rgb(59, 59, 59);
}

.context-frame-component-type-header-count {
    font-size: 14px;
    color: grey;
}

.context-frame-component-group {
    box-sizing: border-box;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-right: 12px;
    border-radius: 12px;
    font-size: 14px;
    transition: all 0.3s;
    cursor: pointer;
    align-self: flex-start;
}

.context-frame-component-group:hover {
    background-color: rgb(245, 245, 245);
}

.context-frame-component-display-name {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
}

.context-frame-component-type {
    text-transform: uppercase;
    font-size: 12px;
}

.context-frame-component-type-symbol-circle {
    min-width: 44px;
    height: 44px;
    font-size: 18px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.context-frame-add-button {
    color: rgb(23, 64, 97);
}

.workcomp-page-main-display-split-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: 2em;
}

.workcomp-page-main-display-split-primary {
    width: 100%;
    box-sizing: border-box;
}

.workcomp-page-main-display-split-width-small {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
}

.workcomp-page-main-display-split-sidebar {
    width: 100%;
    box-sizing: border-box;
}

.workcomp-page-task-title-editable {
    font-size: 20px;
    font-weight: 600;
    border: none;
    flex-grow: 1;
    box-sizing: border-box;
    text-overflow: ellipsis;
    background-color: transparent;
}

textarea.workcomp-page-task-title-editable {
    overflow: hidden;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    white-space: pre-wrap;
    resize: none;
    border: none;
    width: 100%;
}

.workcomp-page-task-title-editable-initiative {
    font-size: 32px;
    background-color: transparent;
}

.workcomp-page-kpi-description-editable {
    font-size: 14px;
    color: grey;
    border: none;
    width: 50%;
    resize: none;
    box-sizing: border-box;
    min-width: 240px;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    overflow-wrap: break-word !important;
    word-wrap: break-word;
    word-break: break-all;
    font-family: -apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.workcomp-page-kpi-value {
    font-size: 60px;
    font-weight: 600;
}

.workcomp-page-kpi-shadow-box {
    margin-top: 8px;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.workcomp-page-kpi-value-updatedat {
    font-style: italic;
}

.workcomp-page-kpi-aligned-goals {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.workcomp-page-kpi-buttons {
    gap: 20px;
    align-items: center;
    margin-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.workcomp-page-kpi-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(125, 125, 125, 0.25);
}

.workcomp-page-kpi-button-update {
    background-color: #2973af;
    color: white;
}

.workcomp-page-kpi-button-update:hover {
    background-color: #185281;
}

/* .workcomp-page-kpi-button-settings {
    box-shadow: inset 0 0 0 1px #939393;
} */

.workcomp-page-kpi-button-settings:hover {
    box-shadow: 0px 8px 8px rgba(125, 125, 125, 0.25);
    /* box-shadow: inset 0 0 0 2px #939393; */
}

.workcomp-page-kpi-component-label {
    color: #cf9b00;
    font-weight: 600;
    font-size: 14px;
    border-radius: 14px;
    border: 2px solid #FFBF00;
    background-color: #ffbf001c;
    padding: 4px 12px;
}

.workcomp-modal-title {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 24px;
    font-weight: 600; 
}

.workcomp-page-kpi-graph-missing {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: grey;
    font-style: italic;
    gap: 12px;
}

.workcomp-page-kpi-graph-date-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1em;
}

.workcomp-page-kpi-graph-date-filter-selected {
    border-bottom: 2px solid rgb(26, 102, 146) !important;
}

.workcomp-page-kpi-graph-date-filters span {
    padding: 4px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.workcomp-page-kpi-graph-date-filters span:hover {
    border-bottom: 2px solid rgb(26, 102, 146);
}

h2.dashboard-view-table-section-heading {
    font-size: 20px;
    font-weight: 500;
    color:rgb(21, 89, 167);
}

.dashboard-assignment-table-details-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    cursor: pointer;
}

.dashboard-assignment-table-details-button:hover {
    text-decoration: underline;
}

.dashboard-table-entry-summary-data-colorful-left-side {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 8px;
}

.dashboard-entry-colorful-block {
    min-width: 8px;
    background-color: #017698;
}

.dashboard-table-title-wrapless-container {
    align-self: stretch;
    padding-right: 12px;
    box-sizing: border-box;
}

h5.dashboard-table-title-wrapless {
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* TIMELINE COMPONENT */
.component-timeline-page-container {
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: auto;
    position: relative;
    min-height: calc(100vh - 48px);
    /* --border-color: rgb(228, 228, 228); */
    --border-color: rgb(221, 221, 221);
    --content-width: 360px;
    --weekend-background: rgb(245, 245, 245);
    --row-background: rgb(240, 240, 240);
    --column-width: 52px;
}

.component-timeline-container {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
    position: absolute;
    left: 0;
    top: 60px;
    box-sizing: border-box;
    right: 0;
    bottom: 0;
    overflow-x: auto;
    overflow-y: auto;
}

.component-timeline-row-workstream-date-headers-sticky {
    position: sticky;
    top: 0;
    z-index: 6;
}

.component-timeline-swimlanes {
    border-right: 1px solid var(--border-color);
    /* margin-bottom: 24px; */
}

.component-timeline-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    min-height: 24px;
    min-width: calc(var(--column-width) * 60) /* There are 60 displayed days
    /* max-width: 100dvw; /* This works for some reason */
}

.component-timeline-side-title {
    width: var(--content-width);
    border-right: 1px solid var(--border-color);
    position: sticky;
    left: 0;
    right: 4px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow-x: auto;
    z-index: 6;
}

.component-timeline-options-container {
    position: relative;
}

.component-timeline-side-title-heading,
.component-timeline-side-title-task {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.component-timeline-side-title-heading {
    font-size: 14px;
    font-weight: 700;
    padding-left: 6px;
}

.component-timeline-side-title-timeline-groups {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
    box-sizing: border-box;
    gap: 12px;
}

.component-timeline-side-title-timeline-groups svg {
    font-size: 18px;
    color: rgb(58, 58, 58);
    cursor: pointer;
}

.component-timeline-side-content-header-sticky {
    position: sticky;
}

.component-timeline-swimlanes .component-timeline-side-title-heading {
    margin-top: 24px;
    margin-left: 4px;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 4px;
}

.component-timeline-side-title-swimlane {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-right: 12px;
    background-color: rgb(238, 238, 238);
}

.component-timeline-swimlane-collapse-button,
.component-timeline-swimlane-settings-button {
    font-size: 18px;
    color: rgb(66, 66, 66);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    cursor: pointer;
    z-index: 99;
}

.component-timeline-swimlane-settings-button {
    position: relative;
    z-index: 9;
    overflow: visible !important;
    z-index: 100;
}

.component-timeline-swimlane-settings-menu {
    overflow: visible;
    z-index: 101;
    position: absolute;
}

.component-timeline-swimlane-settings-menu-inner{
    position: absolute;
    background-color: white;
    width: 120px;
    border: 1px solid grey;
    z-index: 10;
    top: 100%;
    right: 0;
    z-index: 102;
}

.component-timeline-swimlane-display-name {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
    cursor: pointer;
}

.component-timeline-side-title-task {
    font-size: 12px;
    padding-left: 20px;
    padding-right: 24px;
    color: rgb(112, 112, 112);
    font-weight: 500;
    cursor: pointer;
}

.component-timeline-side-title-empty-tasks {
    font-size: 12px;
    color: rgb(112, 112, 112);
    font-weight: 500;
    padding-left: 20px;
    font-style: italic;
}

.component-timeline-side-title-task-unscheduled {
    padding-left: 20px;
    font-size: 12px;
    color: rgb(43, 43, 43);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.component-timeline-side-title-task-unscheduled svg {
    font-size: 16px;
}

.component-timeline-side-title-task:hover {
    text-decoration: underline;
}

.component-timeline-side-content {
    max-width: 100%;
    position: absolute;
    border-right: 1px solid var(--border-color);
    left: calc(var(--content-width) - 104px); /* Allows tasks to flow under even when past the keyDate */
}

.component-timeline-content-heading-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 24px;
    /* border-right: 1px solid var(--border-color); */
}

.component-timeline-content-col-width {
    min-width: var(--column-width);
    width: var(--column-width);
    padding: 2px;
    box-sizing: border-box;
}

.component-timeline-content-heading-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    min-width: var(--column-width);
    padding: 2px;
    min-height: 20px;
    position: relative;
}

.component-timeline-content-col-weekend-sat {
    background-color: var(--weekend-background);
}

.component-timeline-content-col-weekend-sun {
    background-color: var(--weekend-background);
}

.component-timeline-content-heading-col .component-timeline-content-task-name {
    position: absolute;
    font-size: 12px;
    left: 6px;
    top: 2px;
    bottom: 2px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    /* background-color: rgb(224, 224, 224); */
    box-sizing: border-box;
    /* height: 18px; */
    z-index: 4;
}

.component-timeline-content-heading-col .component-timeline-content-task-name p {
    font-weight: 600;
    color: rgb(90, 90, 90);
    cursor: pointer;
}

.component-timeline-content-heading-col .component-timeline-content-task-name p:hover {
    text-decoration: underline;
}

.component-timeline-content-heading-col .component-timeline-content-task-name svg {
    font-size: 18px;
    margin-right: calc((var(--column-width)/2) + 9px);
    color: black;
}

.component-timeline-content-heading-col .component-timeline-content-task-name span {
    width: var(--column-width);
    background-color: grey;
    color: white;
    height: 18px;
    min-height: 18px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    /* cursor: grab; */
}

.component-timeline-content-heading-month-slot {
    position: relative;
    min-height: 20px;
}

.component-timeline-content-heading-month-slot span {
    position: absolute;
    left: 0;
    width: 200px;
    font-size: 11px;
    font-weight: 600;
}

.component-timeline-content-heading-day-number-slot {
    font-size: 12px;
    font-weight: 700;
    display: flex;
    min-height: 28px;
}

.component-timeline-content-heading-day-number-slot span,
.component-timeline-content-heading-day-number-slot div {
    cursor: pointer;
    width: 18px;
    height: 18px;
    min-width: 18px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.component-timeline-content-heading-day-number-slot div {
    cursor: default;
    font-weight: 600;
    color: rgb(150, 150, 150);
}

.component-timeline-content-heading-day-number-slot span:hover {
    background-color: rgb(207, 207, 207);
}

.component-timeline-date-selector-container {
    display: flex;
    align-items: stretch;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
}

.component-timeline-date-selector-symbol-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    min-width: 32px;
    cursor: pointer;
    color: rgb(58, 58, 58);
}

.component-timeline-date-selector-symbol-button:hover,
.component-timeline-date-selector-date-button:hover {
    background-color: rgb(224, 224, 224);
}

.component-timeline-date-selector-date-button {
    border: 1.5px solid rgb(231, 231, 231);
    border-radius: 4px;
    min-width: 120px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.component-timeline-swimlane-add-task-row {
    display: flex;
    justify-content: flex-start;
    margin-left: 12px;
}

.component-timeline-swimlane-add-task {
    font-size: 12px;
    font-weight: 600;
    color: grey;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 16px;
    border-radius: 4px;
    margin-top: 20px;
    justify-content: flex-start;
    cursor: pointer;
    transition: all 0.1s;
}

.component-timeline-swimlane-add-task:hover {
    background-color: rgb(234, 240, 247);
}

.component-timeline-swimlane-add-task svg {
    font-size: 16px;
}

@media only screen and (min-width: 600px) {    
    .component-view-subtitle-text-container {
        font-size: 12px;
    }

    #component-view-core-values-container h2 {
        font-size: 14px;
        margin-bottom: 16px;
    }

    #component-view-core-values-container hr {
        margin-bottom: 12px;
    }

    #component-view-core-values-container p {
        margin-bottom: 12px;
        font-size: 14px;
    }

    .component-view-list-task-new-task-form {
        font-size: 12px;
    }

    .component-view-list-task-description {
        padding-left: 8px;
        padding-right: 8px;
    }

    .component-view-list-tray-meta {
        font-size: 10px;
    }

    .component-view-list-tray-details-add-button {
        font-size: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .component-view-text-click-to-edit {
        font-size: 12px;
    }

    .component-view-title-nav-text {
        font-size: 12px;
    }

    .workspace-options-container {
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 12px;
        padding-top: 8px;
        border-radius: 4px;
    }

    .workspace-options-container h2 {
        font-size: 20px;
        padding-bottom: 4px;
    }

    .workspace-option-group p {
        font-size: 16px;
    }

    .workspace-option-edit-dropdown,
    .workspace-option-edit-date,
    .workspace-option-edit-text,
    .workspace-option-edit-textarea {
        font-size: 16px;
    }
    
    .component-sidepane-moji {
        font-size: 28px;
    }
    
    .component-sidepane-info-heading-container p {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 12px;
    }
    
    .component-sidepane-info-heading-container p:first-of-type {
        margin-right: 8px;
    }
    
    .component-sidepane-info-value {
        font-size: 14px;
    }

    .comp-list-section-title-group {
        font-size: 14px;
    }

    .comp-list-section-task-count {
        font-size: 12px;
    }

    .workcomp-page-main-display-split-width-small {
        width: 50%;
        min-width: 600px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 48px;
    }

}

@media only screen and (min-width: 768px) {
    .component-page-settings-symbol {
        margin-right: 0px;
    }

    .component-menu-item-add {
        align-self: flex-end;
        font-size: 14px;
        text-transform: uppercase;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    #component-view-core-values-container p {
        margin-bottom: 14px;
        font-size: 16px;
    }

    #component-view-core-values-options-container {
        font-size: 14px;
    }

    .component-view-list-task-new-task-form input {
        /* height: 40px;
        font-size: 16px; */
    }

    .component-view-list-task-description {
        font-size: 16px;
    }

    .component-view-list-tray-meta {
        font-size: 12px;
    }

    .component-view-text-click-to-edit {
        font-size: 14px;
    }

    .component-view-title-nav-text {
        font-size: 12px;
    }

    .component-view-subsection-container {
        margin-bottom: 14px;
        padding-bottom: 12px;
    }
    
    .component-view-subsection-container h2 {
        font-size: 16px;
    }

    .component-view-list-task-edit-form textarea,
    .component-view-subsection-container textarea {
        font-size: 16px;
    }

    .component-view-list-tray-details,
    .component-view-multiline-value {
        font-size: 16px;
    }

    #component-view-list-task-total-container {
        min-width: 640px;
    }

    .comp-list-section-title-group {
        font-size: 16px;
    }

    .comp-list-section-task-count {
        font-size: 14px;
    }

    .component-view-list-task-new-task-form {
        font-size: 16px;
    }

    .component-view-list-task-new-task-form-user {
        height: 40px;
        width: 40px;
    }

    .workcomp-page-main-display-split-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .workcomp-page-main-display-split-primary {
        /* flex-grow: 1; */
        flex-basis: 70%;
        max-width: 70%;
        /* width: 100%; */
        /* flex: 1 0 auto; */
    }
    
    .workcomp-page-main-display-split-sidebar {
        flex-basis: 30%;
        /* flex: 1 0 auto; */
        width: auto;
        max-width: 30%;
    }

}

@media only screen and (min-width: 1100px) {
    .workcomp-main-section-biglittle-big {
        flex-basis: 65%;
    }
    
    .workcomp-main-section-biglittle-little {
        flex-basis: 30%;
        display: unset;
    }

    .component-workstream-responsive {
        display: unset;
    }
}

@media only screen and (min-width: 1300px) {
    .component-page-initiative-summary-section-container {
        gap: 0px;
        flex-direction: row;
        justify-content: space-between;
    }
}


/* INITIATIVES */
.initiative {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    --initiative-backdrop-dark: rgb(60, 60, 60);
    /* --initiative-backdrop-light: #FAFAFA; */
    --initiative-backdrop-light: #ffffff;
    --network-hub-color-select-light: #2a73ae;
    --network-hub-color-select-dark: #67bafd;
}

.initiative-backdrop-theme {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s;
}

.initiative-header {
    position: sticky;
    top: 48px;
    z-index: 60;
    background-color: var(--initiative-backdrop-light);
}

.initiative-header-row {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 20px;
    gap: 24px;
}

.initiative-subheader-row {
    padding: 0px 20px;
    display: flex;
    align-items: flex-end;
    gap: 40px;
    width: 100%;
    box-sizing: border-box;
}

.initiative-subheader-item {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    position: relative;
}

.initiative-subheader-item-vertical {
    flex-direction: column;
    align-items: stretch;
    gap: 6px;
}

.initiative-subheader-title {
    font-size: 11px;
    font-weight: 500;
    color: rgb(81, 81, 81);
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

.initiative-subheader-value {
    font-size: 12px;
    font-weight: 600;
    color: rgb(28, 36, 45);
    white-space: nowrap;
}

.initiative-setting-delete-text {
    cursor: pointer;
    /* background-color: rgb(181, 40, 40); */
    background-color: rgba(181, 40, 40, 0.303);
    color: rgb(181, 40, 40);
    font-size: 13px;
    font-weight: 600;
    /* color: white; */
    display: flex;
    align-items: center;
    padding: 6px 8px;
    border-radius: 4px;
    gap: 6px;
}

.initiative-settings-prompt-container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    width: 80%;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.initiative-settings-delete-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.initiative-settings-delete-subtitle {
    font-size: 16px;
    text-align: center;
}

.initiative-settings-delete-button {
    background-color: rgb(181, 40, 40);
    border: 2px solid rgb(181, 40, 40);
    color: white;
    width: 240px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.initiative-settings-delete-button-archive {
    background-color: white;
    border: 2px solid rgb(252, 99, 107);
    color: rgb(252, 99, 107);
    border-radius: 4px;
}

.initiative-settings-delete-button-create {
    background-color: #2973af;
    border: 2px solid #2973af;
    color: white;
    border-radius: 4px;
}

.initiative-settings-delete-button-create.initiative-settings-delete-button-create-invalid {
    background-color: white;
    border: 2px solid #2973af;
    color: #2973af;
}

.initiative-settings-back-button {
    font-size: 14px;
    cursor: pointer;
}

.initiative-settings-back-button:hover {
    text-decoration: underline;
}

.initiative-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 8px;
    transition: all 0.3s;
    cursor: pointer;
}
.initiative-icon-button:hover { background-color: rgb(216, 216, 216); }
.initiative-icon-button svg { font-size: 20px; }

.initiative-icon-button-public {
    color: #6aa8db;
}

.initiative-navs {
    border-bottom: 1px solid rgb(217, 217, 217);
    width: 100%;
    margin-top: 32px;
    box-sizing: border-box;
    padding: 0px 20px;
}

.initiative-sections-side-optional-outer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
}

.initiative-sections-side {
    flex-basis: 35%;
    /* border-left: 1px solid rgb(217, 217, 217); */
    position: relative;
    min-width: 240px;
    padding: 32px 16px;
}

.initiative-sections-main {
    flex-basis: 65%;
    padding: 32px;
}

.initiative-sections-responsive {
    display: none;
}

.initiative-section-border {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 6px;
    padding: 20px 12px;
}

@media screen and (max-width: 1300px) {
    .initiative-sections-side { display: none }
    .initiative-sections-main { flex-basis: 100% }
    .initiative-sections-responsive { display: block }
}

.initiative-sections {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.initiative-section-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.initiative-workstream-menu-container {
    background-color: white;
    min-width: 200px;
    z-index: 60;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    top: calc(50% + 4px);
    left: 85%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.initiative-workstream-menu-option {
    padding: 12px 4px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
}

.initiative-workstream-menu-option svg {
    font-size: 15px;
    padding: 2px;
    min-width: 20px;
    z-index: 1;
}

.initiative-workstream-menu-option:hover {
    background-color: rgb(206, 206, 206);
}

.initiative-weekly-tasks {
    width: 100%;
    box-sizing: border-box;
}

.initiative-weekly-tasks-controls {
    display: flex;
    align-items: center;
    gap: 4px;
}

.initiative-weekly-tasks-controls div {
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    transition: all 0.2s;

    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.initiative-weekly-tasks-controls div:hover {
    background-color: rgb(190, 190, 190);
}

.initiative-weekly-tasks-controls div svg {
    font-size: 18px;
    color: grey;
}

.initiative-weekly-tasks-columns {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 100%;
}

.initiative-weekly-tasks-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px; 
    box-sizing: border-box; 
    margin-bottom: 12px;
    max-width: 33%;
    width: 33%;
    min-width: 33%;
    overflow: hidden;
}

.initiative-weekly-tasks-column-heading {
    font-size: 15px;
    font-weight: 700;
    color: #1e1e1e;
}

.initiative-weekly-tasks-column-heading span {
    font-size: 12px;
    color: grey;
    text-transform: none;
    font-weight: 500;
}

.initiative-weekly-tasks-column-tasks {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    /* max-width: 200px; */
    max-width: 100%;
    box-sizing: border-box;
    min-width: 120px;
    overflow: hidden;
    align-items: flex-start;
    padding-right: 12px;
    padding-bottom: 12px;
}

.initiative-weekly-tasks-column-task {
    font-size: 12px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 8px 12px;
    padding-right: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 4px;
    color: rgb(43, 43, 43);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.055) 0px 2px 3px 1px;
    border: 1.5px solid rgb(235, 235, 235);
    position: relative;
}

.initiative-weekly-tasks-column-task-name {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    font-size: 13px;
    white-space: pre-wrap;
    color: #344563;
    line-height: 18px;
}

.initiative-weekly-tasks-column-task-workstream {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 11px;
    height: 12px;
    color: #344563;
    line-height: 11px;
    margin-top: 6px;
    padding-right: 6px;
}

.initiative-weekly-tasks-column-show-more {
    font-size: 12px;
    font-weight: 700;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.initiative-weekly-tasks-column-task:hover {
    background-color: rgba(69, 69, 69, 0.075);
}

.initiative-weekly-tasks-column-show-more:hover {
    background-color: rgba(69, 69, 69, 0.075);
}

.initiative-weekly-tasks-column-task-overdue {
    /* border-left: 2px solid rgba(201, 59, 59, 0.76); */
    border-left: 1.5px solid rgba(201, 59, 59, 0.76);
}

.initiative-weekly-tasks-column-task.initiative-weekly-tasks-column-task-overdue:hover {
    background-color: rgba(201, 59, 59, 0.075);
}

.initiative-weekly-tasks-column-task.initiative-weekly-tasks-column-task-blank {
    color: grey;
    font-style: italic;
    cursor: auto;
    padding: 0px;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.initiative-weekly-tasks-column-task.initiative-weekly-tasks-column-task-blank:hover {
    text-decoration: none;
    box-shadow: none;
}

.initiative-goals {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.initiative-goal-container {
    width: 22%;
    min-width: 200px;
    max-width: 260px;
    padding-top: 40px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 3em;
    gap: 40px;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
}

.initiative-goal-container:hover {
    box-shadow: rgba(149, 157, 165, 0.334) 0px 10px 14px;
}

.initiative-workstream-status-badge {
    font-size: 9px;
    border-radius: 4px;
    padding: 6px;
    width: 60px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgb(55, 55, 55);
    color: white;
}

.initiative-goal-container:hover .initiative-goal-delete-button {
    display: flex;
}

.initiative-goal-delete-button {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 12px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 6px;
}

.initiative-goal-delete-button:hover {
    background-color: rgb(206, 206, 206);
}

.initiative-goal-delete-button svg {
    font-size: 16px;
    color: rgb(63, 63, 63);
}

.initiative-goal-badge {
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 4px;
    font-size: 9px;
    padding: 4px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgb(55, 55, 55);
    color: white;
}

.initiative-goal-badge-new,
.initiative-workstream-status-badge-active {
    background-color: #1A689F;
    color: white;
}
.initiative-workstream-status-badge-archived {
    background-color: #fc636b;
    color: white;
}

.initiative-goal-badge-past {
    color: white;
    background-color: rgba(201, 59, 59, 0.76);
}

.initiative-goal-badge-imminent {
    background-color: #a121c8;
    color: white;
}

.initiative-goal-badge-due-soon,
.initiative-workstream-status-badge-completed {
    background-color: #049921;
    color: white;
}

.initiative-goal-container-inner {
    display: flex;
    align-items: stretch;
    gap: 8px;
    flex-direction: column;
}

.initiative-goal-kpi-value {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.initiative-goal-kpi-name {
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
}

.initiative-goal-name {
    text-align: center;
    font-weight: 700;
    font-size: 13px;
}

.initiative-goal-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.initiative-goal-progress-val {
    min-width: 20px;
    font-size: 13px;
    font-weight: 400;
}

.initiative-goal-progress-bar {
    flex-grow: 1;
}

.initiative-goal-footer-decoration {
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: grey;
}

.initiative-discussions-main {
    width: 100%;
    box-sizing: border-box;
    padding: 32px;
}

.initiative-discussions-proposal-input {
    box-sizing: border-box;
    position: relative;
}

.initiative-discussions-proposal-input-float {
    position: fixed;
    bottom: 12px;
    left: 272px;
    right: 12px;
    z-index: 2;
}

.initiative-discussions-proposal-input-faded-box {
    z-index: 1;
    position: fixed;
    bottom: 0;
    background: linear-gradient(to top, white, transparent);
    height: 120px;
    width: 100%;
}
  
.initiative-discussions-textarea {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 14px;
    padding-right: 56px;
    border: 1px solid #ababab;
    background-color: white;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.5;
    resize: none;
    overflow-y: auto;
    max-height: 200px;
    width: 100%;
    font-family: -apple-system, "Open Sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
.initiative-discussions-submit-button {
    padding: 8px;
    color: #d1d1d1;
    background-color: #979797;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    right: 8px;
    bottom: 12px;
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.initiative-discussions-submit-button.initiative-discussions-submit-button-valid {
    background-color: #2a73ae;
    color: #fff;
}

.initiative-discussions-comments {
    display: flex;
    flex-direction: column-reverse;
    gap: 28px;
    padding-bottom: 60px;
}

.initiative-discussions-comment {
    display: flex;
    align-items: flex-start;
    gap: 14px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.initiative-discussions-comment:hover {
    background-color: rgba(128, 128, 128, 0.074);
}

.initiative-discussions-comment-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 100%;
    overflow: hidden;
}

.initiative-discussions-comment-content-author {
    font-size: 14px;
    font-weight: 600;
}

.initiative-discussions-comment-content-author span {
    font-size: 12px;
    color: rgb(173, 173, 173);
    font-weight: 500;
    margin-left: 4px;
}

.initiative-discussions-comment-content-content {
    font-size: 14px;
    line-height: 1.6;
}

.initiative-section { 
    max-width: 1200px;
}

.initiative-section-banner {
    width: 100%;
    background-color: rgb(197, 197, 197);
    text-align: center;
    box-sizing: border-box;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 13px;
    margin-top: 12px;
    margin-bottom: 12px;
    color: rgb(111, 111, 111);
    font-weight: 700;
}

.initiative-section-banner-warning {
    background-color: #F5F0D8;
    color: rgb(60, 60, 60);
}

.initiative-goal-footer-done {
    color: #049921;
}

.initiative-section-resources {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.initiative-section-resource {
    display: flex;
    align-items: flex-start;
    gap: 4px;
}

.initiative-resource-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    min-width: 26px;
}

.initiative-resource-icon svg {
    font-size: 16px;
    color: #444444;
}

.initiative-resource-summary a {
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    color: #2A73AE;
    font-size: 14px;
}

.initiative-resource-summary a:hover {
    text-decoration: underline;
}

.initiative-resource-summary {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.initiative-resource-summary-description {
    color: grey;
    font-size: 14px;
    line-height: 1.5;
    min-height: 22px;
}


/* INITIATIVE THEMES: LIGHT */
.initiative-backdrop-theme-light { background-color: var(--initiative-backdrop-light); }
.initiative-theme-light { color: black; }
.initiative-theme-light .initiative-navs { border-bottom: 1px solid rgb(217, 217, 217); }
.initiative-theme-light .initiative { --network-hub-color-select: #2a73ae; }

.initiative-theme-light .network-hub-header-nav .network-hub-header-nav-sel,
.initiative-theme-light .network-hub-header-nav span:hover {
    border-bottom: 2px solid var(--network-hub-color-select-light);
    color: var(--network-hub-color-select-light);
}

.initiative-theme-light .network-hub-header-nav span { color: rgb(92, 92, 92); }

/* INITIATIVE THEMES: DARK */
.initiative-backdrop-theme-dark { background-color: var(--initiative-backdrop-dark); }

.initiative-theme-dark,
.intiative-theme-dark input {
    color: white;
}

.initiative-theme-dark .initiative-subheader-title,
.initiative-theme-dark .network-hub-header-nav span {
    color: rgb(205, 205, 205);
}
.initiative-theme-dark .workcomp-page-task-title-editable { color: white !important; }
.initiative-theme-dark .initiative-navs { border-bottom: 1px solid rgb(91, 91, 91); }

.initiative-theme-dark .network-hub-header-nav .network-hub-header-nav-sel,
.initiative-theme-dark .network-hub-header-nav span:hover {
    border-bottom: 2px solid var(--network-hub-color-select-dark);
    color: var(--network-hub-color-select-dark);
}